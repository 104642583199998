import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./PercentWise.module.css"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "./../../../../config/config";

const getAvgAttendanceBySubjects = process.env.REACT_APP_HOD_GET_AVG_ATTENDANCE_BY_SUBJECTS;

const PercentWise = ({ selectedField }) => {
  const { getDecodedToken, getToken, setShowLoader } = useContext(UserContext);
  const [studentsData, setStudentsData] = useState([]);
  const [totalClass, setTotalClass] = useState(0);
  const [minCriteria, setMinCriteria] = useState(0);
  const navigate = useNavigate();

  const fetchAttendanceData = async () => {
    try {
      const token = getToken();

      const { subjectCode, courseCode, batchId, sectionId, semester, subjectType, practicalBatchId } = selectedField || {};

      if (!(subjectCode && courseCode && batchId && sectionId && semester)) {

        return;
      }
      if (subjectType === "LAB" && !practicalBatchId) {
        return;
      }
      setShowLoader(true)

      const response = await axios.post(
        `${BASE_URL}${getAvgAttendanceBySubjects}`,
        selectedField
        ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.result) {
        setStudentsData(response.data.data.studentArray || []);
        setTotalClass(response.data.data.totalLecture);
        setMinCriteria(response.data.data?.minCriteria || 50)
      } else {
        setStudentsData([]);
        setTotalClass(0);
      }
      setShowLoader(false)

    } catch (error) {
      setStudentsData([]);
      setShowLoader(false)
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchAttendanceData();
  }, [selectedField]);

  const handleDownload = (studentsData, totalClass) => {
    try {
      if (studentsData.length === 0) {
        Toast.warning(`No data to download...!`)
        return;
      }

      const customHeadings = [
        [`Subject: `, `${selectedField.subjectName}`],
        [`Practical Batch : `, `${selectedField.practicalBatchName ? selectedField.practicalBatchName : 'N/A'}`],
        [`Download Date: `, `${new Date().toLocaleDateString()}`],
        [`Download Time: `, `${new Date().toLocaleTimeString()}`],
        ["Downloaded By :", `${getDecodedToken()?.fname} ${getDecodedToken()?.lname} - (${getDecodedToken()?.role?.toUpperCase() || "ROLE NOT FOUND"})`],
      ];

      const data = studentsData.map((row) => ({
        'Enrollment ID': row.enrollmentId,
        'Student Name': row.studentName,
        'Present Class': row.presentCount,
        'Absent Class': totalClass - row.presentCount,
        'Total Class': totalClass,
        'Percentage': ((row.presentCount / totalClass) * 100).toFixed(2),
      }));

      const headers = [
        "Enrollment ID",
        "Student Name",
        "Present Class",
        "Absent Class",
        "Total Class",
        "Percentage",
      ];

      const sheetData = [
        ...customHeadings,
        [],
        headers,
        ...data.map(row => headers.map(h => row[h]))
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Students Data');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, `Attendance Report On ${new Date().toLocaleString()}.xlsx`);
      Toast.success("Excel downloaded successfully");

    } catch (error) {
      Toast.error('Error downloading excel !');
    }
  };
  useEffect(() => {
    const {
      courseCode,
      subjectCode,
      subjectName,
      subjectType,
      practicalBatchId,
      practicalBatchName,
      semester,
      batchId,
      sectionId,
    } = selectedField;

    if (!(courseCode && subjectCode && subjectName && subjectType && semester && batchId && sectionId)) {
      navigate("../");
    }
  }, [selectedField]);

  return (
    <div className={styles.percentWiseAttendanceContainer}>
      <h2 className={styles.containerTitle}>
        Percentwise Students Attendance
      </h2>

      <Box className={styles.downloadButtonContainer}>
        <Button variant="contained" onClick={() => handleDownload(studentsData, totalClass)}>
          Download Excel
        </Button>
      </Box>

      <Box className={styles.tableBoxContainer}>
        <Box className={styles.arrowContainer} >
          <ArrowBackIcon className={styles.previousButton} onClick={() => {
            navigate(-1);
          }} />
          <ArrowForwardIcon className={styles.nextButton} onClick={() => {
            navigate(+1);
          }} />
        </Box>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table aria-label="customized table" className={styles.theTable}  >
            <TableHead>
              <TableRow >
                <TableCell className={`${styles.tableHead} ${styles.stickyLeftColumn}`}>Enrollment ID</TableCell>
                <TableCell className={`${styles.tableHead} ${styles.stickySecondColumn}`}>Student Name</TableCell>
                <TableCell className={styles.tableHead} >Present Class</TableCell>
                <TableCell className={styles.tableHead} >Absent Class</TableCell>
                <TableCell className={styles.tableHead} >Total Class</TableCell>
                <TableCell className={styles.tableHead} >Percentage</TableCell>
              </TableRow>
            </TableHead >

            <TableBody>
              {studentsData.length > 0 ? (
                studentsData.map((row) => (
                  <TableRow key={row.enrollmentId} >
                    <TableCell className={`${styles.tableData} ${styles.stickyLeftColumn}`}>
                      {row.enrollmentId}
                    </TableCell>
                    <TableCell className={`${styles.tableData} ${styles.stickySecondColumn}`}>
                      {row.studentName}
                    </TableCell>
                    <TableCell className={styles.tableData}>{row.presentCount}</TableCell>
                    <TableCell className={styles.tableData}>{totalClass - row.presentCount}</TableCell>
                    <TableCell className={styles.tableData}>{totalClass}</TableCell>
                    <TableCell className={styles.tableData}
                      style={{ backgroundColor: (((row.presentCount / totalClass) * 100).toFixed(2)) > minCriteria ? '#228B22' : '#AA0000', color: 'white' }}
                    >{((row.presentCount / totalClass) * 100).toFixed(2)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <>
                </>
              )}
            </TableBody>
          </Table >
          {!studentsData[0] && <h2 className={styles.noDataTitle} >"No Data Found"</h2>}
        </TableContainer >
      </Box >
    </div >
  );
};

export default PercentWise;
