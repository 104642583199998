import React, { useContext } from "react";
import JobPost from "./JobPost/JobPost";
import AddJob from "./AddJob/AddJob";
import JobApplications from "./JobApplications/JobApplications";
import { Routes, Route, Navigate } from "react-router-dom";
import UserContext from "../../../../Context/UserContext";

const Job = () => {
  const { getUserRole } = useContext(UserContext);

  return (
    <Routes>
      <Route path="/" element={<JobPost />} />

      <Route path="/add-job" element={getUserRole() === 'tpo' ? <Navigate to="/" /> : <AddJob />} />
      <Route path="/view-applications/:jobPostId" element={(['recruiter', "tpo"].includes(getUserRole())) ? <JobApplications /> : <Navigate to="/" />} />
      {/* {getUserRole() === "tpo" ? (
      ) : (
        <Route path="/view-applications/:jobPostId" element={<Navigate to="/" />} />
      )} */}
    </Routes>
  );
};

export default Job;
