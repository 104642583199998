import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button, Grid, TextField, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import axios from "axios";
import styles from "./VUPEditEducation.module.css";
import DropDown from "../../../../DropDown/DropDown";
import UserContext from "../../../../../../Context/UserContext";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../../config/config";

const showEducationDropdown =
  process.env.REACT_APP_DROPDOWN_SHOW_EDUCATION_DROPDOWN;

const VUPEditEducation = ({
  educationData,
  handleAddEducation,
  handleCloseModal,
  handleUpdateEducation,
}) => {
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [token] = useState(Cookies.get("token"));
  const [formData, setFormData] = useState({});
  const [currentEducationData, setCurrentEducationData] = useState(null);
  const [marksheetLink, setMarksheetLink] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState({
    courseNames: [],
    institutes: [],
    courseTypes: [],
    departments: [],
    universities: [],
  });
  const [customValues, setCustomValues] = useState({
    courseName: "",
    institute: "",
    courseType: "",
    department: "",
    university: "",
  });
  const [showCustomInputs, setShowCustomInputs] = useState({
    courseName: false,
    institute: false,
    courseType: false,
    department: false,
    university: false,
  });
  const [error, setError] = useState(null);
  const [inputErrors, setInputErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const checkDropdowns = async () => {
    try {
      if (formData?.courseName) {
        const checkCourse = dropdownOptions.courseNames.some(
          (option) => option.value === formData.courseName
        );

        if (checkCourse) {
          return { showCustomInput: false, customValue: "" };
        } else {
          return { showCustomInput: true, customValue: formData.courseName };
        }
      }

      if (formData?.institute) {
        const checkInstitute = dropdownOptions.institutes.some(
          (OBJ) => OBJ.value === formData.institute
        );
        if (!checkInstitute) {
          showCustomInputs.institute = true;
          customValues.institute = formData.institute;
          formData.institute = "other";
        }
      }
      if (formData?.university) {
        const checkUniversity = dropdownOptions.universities.some(
          (OBJ) => OBJ.value === formData.university
        );
        if (!checkUniversity) {
          showCustomInputs.university = true;
          customValues.university = formData.university;
          formData.university = "other";
        }
      }
      if (formData?.department) {
        const checkDepartment = dropdownOptions.departments.some(
          (OBJ) => OBJ.value === formData.department
        );
        if (!checkDepartment) {
          showCustomInputs.department = true;

          customValues.department = formData.department;
          formData.department = "other";
        }
      }
      if (formData?.courseType) {
        const checkCourseType = dropdownOptions.courseTypes.some(
          (OBJ) => OBJ.value === formData.courseType
        );
        if (!checkCourseType) {
          showCustomInputs.courseType = true;
          customValues.courseType = formData.courseType;
          formData.courseType = "other";
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    setCurrentEducationData(() => educationData);
  }, []);

  useEffect(() => {
    if (educationData) {
      const {
        courseName,
        institute,
        university,
        department,
        courseType,
        marksheetLink,
        ...rest
      } = educationData;

      // Set formData with rest of the data
      setFormData({
        ...rest,
        courseName: courseName || "",
        institute: institute || "",
        university: university || "",
        department: department || "",
        courseType: courseType || "",
      });

      setMarksheetLink(marksheetLink || "");

      // Handle custom values
      const updatedCustomValues = {};
      const updatedShowCustomInputs = {};

      // Check if the dropdown value exists
      const checkCustomValue = (key, value) => {
        if (
          value &&
          !dropdownOptions[key]?.some((option) => option.value === value)
        ) {
          updatedCustomValues[key] = value;
          updatedShowCustomInputs[key] = true;
        } else {
          updatedCustomValues[key] = "";
          updatedShowCustomInputs[key] = false;
        }
      };

      checkCustomValue("courseName", courseName);
      checkCustomValue("institute", institute);
      checkCustomValue("university", university);
      checkCustomValue("department", department);
      checkCustomValue("courseType", courseType);

      setCustomValues(updatedCustomValues);
      setShowCustomInputs(updatedShowCustomInputs);

      fetchDropdownOptions();
      validateForm();
    }
  }, [educationData]);

  useEffect(() => {
    checkDropdowns();
    validateForm();
  }, [formData]);

  const axiosInstance = axios.create({
    BASE_URL: `${BASE_URL}`,
    headers: {
      // "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "courseName",
      "institute",
      "university",
      "department",
      "courseType",
      "admissionDate",
      "passoutDate",
      // "marksheet",
    ];

    // Validate required fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    // Length validation for specific fields
    const lengthFields = [
      "courseName",
      "institute",
      "university",
      "department",
      "courseType",
    ];
    lengthFields.forEach((field) => {
      const value = customValues[field] || formData[field] || "";
      if (showCustomInputs[field] && value.length > 60) {
        validationErrors[field] = "**Must be less than 60 characters**";
      }
    });

    // Validate admissionDate
    if (formData.admissionDate) {
      const admissionDate = dayjs(formData.admissionDate);
      if (!admissionDate.isValid()) {
        validationErrors.admissionDate = "**Invalid date format**";
      } else if (admissionDate.isAfter(dayjs(), "day")) {
        validationErrors.admissionDate = "**Date cannot be in the future**";
      }
    }

    // Validate passoutDate
    if (formData.passoutDate) {
      const passoutDate = dayjs(formData.passoutDate);
      if (!passoutDate.isValid()) {
        validationErrors.passoutDate = "**Invalid date format**";
      } else if (passoutDate.isAfter(dayjs(), "day")) {
        validationErrors.passoutDate = "**Date cannot be in the future**";
      }
    }

    // Validate CGPA
    if (formData.cgpa) {
      const cgpa = parseFloat(formData.cgpa);
      if (isNaN(cgpa)) {
        validationErrors.cgpa = "**CGPA must be a number**";
      } else if (
        cgpa < 1 ||
        cgpa > 10 ||
        !/^\d+(\.\d{1})?$/.test(formData.cgpa)
      ) {
        validationErrors.cgpa = "**CGPA must be between 1 and 10**";
      }
    } else if (!formData.percentage) {
      validationErrors.cgpa = "**CGPA or Percentage is required**";
    }

    // Validate Percentage
    if (formData.percentage) {
      const percentage = parseFloat(formData.percentage);
      if (isNaN(percentage)) {
        validationErrors.percentage = "**Percentage must be a number**";
      } else if (
        percentage < 35 ||
        percentage > 100 ||
        !/^\d+(\.\d{1})?$/.test(formData.percentage)
      ) {
        validationErrors.percentage =
          "**Percentage must be between 35% and 100%**";
      }
    } else if (!formData.cgpa) {
      validationErrors.percentage = "**CGPA or Percentage is required**";
    }

    // Validate date
    if (formData.admissionDate && formData.passoutDate) {
      const admission = dayjs(formData.admissionDate);
      const passout = dayjs(formData.passoutDate);

      if (passout.isBefore(admission)) {
        validationErrors.passoutDate =
          "**Passout date must be after admission date**";
      }
    }

    setInputErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const fetchDropdownOptions = async () => {
    try {
      setShowLoader(true);

      const response = await axiosInstance.get(
        `${BASE_URL}${showEducationDropdown}`
      );
      const {
        courseList,
        instituteList,
        courseTypes,
        departmentList,
        universityList,
      } = response.data.data;

      setDropdownOptions({
        courseNames: courseList || [],
        institutes: instituteList || [],
        courseTypes: courseTypes || [],
        departments: departmentList || [],
        universities: universityList || [],
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");

      setError("Error fetching dropdown options. Please try again later.");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));

    // Disable percentage if CGPA is set and vice versa
    if (name === "cgpa" && value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        percentage: null,
      }));
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        percentage: null,
      }));
    }

    if (name === "percentage" && value) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        cgpa: null,
      }));
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        cgpa: null,
      }));
    }

    validateForm();
  };

  const handleDropDownChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setShowCustomInputs((prevShowCustomInputs) => ({
      ...prevShowCustomInputs,
      [name]: value === "other",
    }));

    if (value !== "other") {
      setCustomValues((prevCustomValues) => ({
        ...prevCustomValues,
        [name]: "",
      }));
    }

    validateForm();
  };

  const handleCustomChange = (setter, name) => (e) => {
    setCustomValues((prevCustomValues) => ({
      ...prevCustomValues,
      [name]: e.target.value,
    }));
  };

  const handleDateChange = (name, date) => {
    const value = date ? date.format("YYYY-MM-DD") : null;

    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      };
      // Convert values to dayjs objects for comparison
      const admissionDate = dayjs(newFormData.admissionDate);
      const passoutDate = dayjs(newFormData.passoutDate);
      const today = dayjs();

      let errors = {};

      // Check if the newly selected date is valid
      if (value) {
        const parsedDate = dayjs(value, "YYYY-MM-DD", true);

        if (!parsedDate.isValid()) {
          errors[name] = "Invalid date format";
        } else if (parsedDate.isAfter(today, "day")) {
          errors[name] = "Date cannot be in the future";
        } else if (
          name === "admissionDate" &&
          parsedDate.isAfter(passoutDate)
        ) {
          errors[name] = "Admission date must be before passout date";
        } else if (
          name === "passoutDate" &&
          parsedDate.isBefore(admissionDate)
        ) {
          errors[name] = "Passout date must be after admission date";
        } else {
          errors[name] = null;
        }
      } else {
        errors[name] = null;
      }

      if (newFormData.admissionDate && newFormData.passoutDate) {
        const admission = dayjs(newFormData.admissionDate);
        const passout = dayjs(newFormData.passoutDate);

        if (passout.isBefore(admission)) {
          errors.passoutDate = "Passout date cannot be before admission date";
        }
        if (admission.isAfter(today, "day")) {
          errors.admissionDate = "Admission date cannot be in the future";
        }
        if (passout.isAfter(today, "day")) {
          errors.passoutDate = "Passout date cannot be in the future";
        }
      }

      setInputErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }));

      return newFormData;
    });
  };

  const handleLinkChange = (event) => {
    const { value } = event.target;
    setMarksheetLink(value);

    // Basic validation for a URL
    const urlPattern = new RegExp(
      "^(https?://)?(www\\.)?(drive\\.google\\.com/.*|[a-z0-9-]+\\.[a-z]{2,}(/[a-z0-9-._?=&%]*)?)$"
    );

    if (!value.trim()) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        marksheet: "**This field is required**",
      }));
    } else if (!urlPattern.test(value)) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        marksheet: "**Please enter a valid URL.**",
      }));
    } else {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        marksheet: null, // Clear error if valid
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    if (isSaveDisabled) {
      return;
    }

    const { cgpa, percentage, marksheet } = formData;

    if (cgpa && (cgpa < 1 || cgpa > 10)) {
      setError("CGPA must be between 1 and 10.");
      return;
    }

    if (percentage && (percentage < 35 || percentage > 100)) {
      setError("Percentage must be between 35% and 100%.");
      return;
    }
    const updatedFormData = {
      ...formData,
      courseName: showCustomInputs.courseName
        ? customValues.courseName
        : formData.courseName,
      institute: showCustomInputs.institute
        ? customValues.institute
        : formData.institute,
      courseType: showCustomInputs.courseType
        ? customValues.courseType
        : formData.courseType,
      department: showCustomInputs.department
        ? customValues.department
        : formData.department,
      university: showCustomInputs.university
        ? customValues.university
        : formData.university,
    };

    updatedFormData.marksheetLink = marksheetLink;
    if (educationData && educationData._id) {
      showConfirmation(
        "want to update user education details ?",
        handleUpdateEducation,
        updatedFormData
      );
    } else {
      showConfirmation(
        "want to add new education details ?",
        handleAddEducation,
        updatedFormData
      );
    }
  };

  return (
    <Box className={styles.editEducationContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton
          className={styles.closeButtonEditEducation}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.editEducationHeader}>
        {educationData && educationData._id
          ? "EDIT EDUCATION"
          : "ADD EDUCATION"}
      </h2>

      <div className={styles.editEducationScrollable}>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="courseName" className={styles.formLabel}>
                  Course
                </label>
                <DropDown
                  id="courseName"
                  name="courseName"
                  value={formData.courseName || ""}
                  onChange={handleDropDownChange}
                  showOtherOption
                  options={dropdownOptions.courseNames}
                  showCustomInput={showCustomInputs.courseName}
                  customValue={customValues.courseName}
                  onCustomChange={handleCustomChange(
                    setCustomValues,
                    "courseName"
                  )}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                  }}
                />
                {showCustomInputs.courseName && (
                  <div className={styles.error}>
                    {customValues.courseName.length === 60 && (
                      <>**Course must be at most 60 characters**</>
                    )}
                  </div>
                )}
                {inputErrors.courseName && (
                  <div className={styles.error}>{inputErrors.courseName}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="institute" className={styles.formLabel}>
                  Institute Name
                </label>
                <DropDown
                  id="institute"
                  name="institute"
                  value={formData.institute || ""}
                  onChange={handleDropDownChange}
                  showOtherOption
                  options={dropdownOptions.institutes}
                  showCustomInput={showCustomInputs.institute}
                  customValue={customValues.institute}
                  onCustomChange={handleCustomChange(
                    setCustomValues,
                    "institute"
                  )}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                  }}
                />
                {showCustomInputs.institute && (
                  <div className={styles.error}>
                    {customValues.institute.length === 60 && (
                      <>**Institute must be at most 60 characters**</>
                    )}
                  </div>
                )}
                {inputErrors.institute && (
                  <div className={styles.error}>{inputErrors.institute}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="university" className={styles.formLabel}>
                  University
                </label>
                <DropDown
                  id="university"
                  name="university"
                  value={formData.university || ""}
                  onChange={handleDropDownChange}
                  showOtherOption
                  options={dropdownOptions.universities}
                  showCustomInput={showCustomInputs.university}
                  customValue={customValues.university}
                  onCustomChange={handleCustomChange(
                    setCustomValues,
                    "university"
                  )}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                  }}
                />
                {showCustomInputs.university && (
                  <div className={styles.error}>
                    {customValues.university.length === 60 && (
                      <>**University must be at most 60 characters**</>
                    )}
                  </div>
                )}
                {inputErrors.university && (
                  <div className={styles.error}>{inputErrors.university}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="department" className={styles.formLabel}>
                  Department Name
                </label>
                <DropDown
                  id="department"
                  name="department"
                  value={formData.department || ""}
                  onChange={handleDropDownChange}
                  showOtherOption
                  options={dropdownOptions.departments}
                  showCustomInput={showCustomInputs.department}
                  customValue={customValues.department}
                  onCustomChange={handleCustomChange(
                    setCustomValues,
                    "department"
                  )}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                  }}
                />
                {showCustomInputs.department && (
                  <div className={styles.error}>
                    {customValues.department.length === 60 && (
                      <>**Department must be at most 60 characters**</>
                    )}
                  </div>
                )}
                {inputErrors.department && (
                  <div className={styles.error}>{inputErrors.department}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="courseType" className={styles.formLabel}>
                  Course Type
                </label>

                <DropDown
                  id="courseType"
                  name="courseType"
                  value={formData.courseType || ""}
                  onChange={handleDropDownChange}
                  showOtherOption
                  options={dropdownOptions.courseTypes}
                  showCustomInput={showCustomInputs.courseType}
                  customValue={customValues.courseType}
                  onCustomChange={handleCustomChange(
                    setCustomValues,
                    "courseType"
                  )}
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                    },
                  }}
                />
                {showCustomInputs.courseType && (
                  <div className={styles.error}>
                    {customValues.courseType.length === 60 && (
                      <>**Course Type must be at most 60 characters**</>
                    )}
                  </div>
                )}
                {inputErrors.courseType && (
                  <div className={styles.error}>{inputErrors.courseType}</div>
                )}
              </div>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} key="admissionDate">
                <div className={styles.editInputs}>
                  <label htmlFor="admissionDate" className={styles.formLabel}>
                    Admission Date
                  </label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    value={
                      formData.admissionDate
                        ? dayjs(formData.admissionDate)
                        : null
                    }
                    onChange={(date) => handleDateChange("admissionDate", date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onBlur={() =>
                          handleDateChange(
                            "admissionDate",
                            dayjs(params.inputProps.value)
                          )
                        }
                      />
                    )}
                  />
                  {inputErrors.admissionDate && (
                    <div className={styles.error}>
                      {inputErrors.admissionDate}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} key="passoutDate">
                <div className={styles.editInputs}>
                  <label htmlFor="passoutDate" className={styles.formLabel}>
                    Passout Date
                  </label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    value={
                      formData.passoutDate ? dayjs(formData.passoutDate) : null
                    }
                    onChange={(date) => handleDateChange("passoutDate", date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        onBlur={() =>
                          handleDateChange(
                            "admissionDate",
                            dayjs(params.inputProps.value)
                          )
                        }
                      />
                    )}
                  />
                  {inputErrors.passoutDate && (
                    <div className={styles.error}>
                      {inputErrors.passoutDate}
                    </div>
                  )}
                </div>
              </Grid>
            </LocalizationProvider>

            {/* <Grid item xs={12}>
              <div className={styles.editInputsInline}>
                <label htmlFor="isPursuing" className={styles.formLabel}>
                  Currently Pursuing?
                </label>
                <input
                  type="checkbox"
                  id="isPursuing"
                  name="isPursuing"
                  checked={isPursuing}
                  onChange={handleChange}
                />
              </div>
            </Grid> */}

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="cgpa" className={styles.formLabel}>
                  CGPA <span className={styles.span}>(in numbers)</span>
                </label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="cgpa"
                  placeholder="CGPA"
                  name="cgpa"
                  value={formData.cgpa || ""}
                  onChange={handleChange}
                  disabled={!!formData.percentage}
                  className={styles.disabled}
                  required={!formData.percentage}
                  min="1"
                  max="10"
                  step="0.1"
                />
                {inputErrors.cgpa && (
                  <div className={styles.error}>{inputErrors.cgpa}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="percentage" className={styles.formLabel}>
                  Percentage <span className={styles.span}>(in numbers)</span>
                </label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="percentage"
                  placeholder="Percentage"
                  name="percentage"
                  value={formData.percentage || ""}
                  onChange={handleChange}
                  disabled={!!formData.cgpa}
                  className={styles.disabled}
                  required={!formData.cgpa}
                  min="35"
                  max="100"
                  step="0.1"
                />
                {inputErrors.percentage && (
                  <div className={styles.error}>{inputErrors.percentage}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.editInputs}>
                <label htmlFor="marksheet" className={styles.formLabel}>
                  Marksheet
                  <span className={styles.span}>(Google Drive Link)</span>
                </label>
                <input
                  type="text"
                  id="marksheet"
                  placeholder="Marksheet Upload Link"
                  name="marksheet"
                  value={marksheetLink}
                  onChange={handleLinkChange}
                />
                {inputErrors.marksheet && (
                  <div className={styles.error}>{inputErrors.marksheet}</div>
                )}
              </div>
            </Grid>

            <Grid item xs={12} className={styles.submitButton}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
};

export default VUPEditEducation;
