import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentFilter from "./StudentFilter/StudentFilter";
import RecruiterList from "./RecruiterList/RecruiterList";

const TNPComponent = () => {

  return (
    <Routes>
      <Route path="/" element={<RecruiterList />}></Route>
      <Route path="/" element={<StudentFilter />} />
    </Routes>
  );
};

export default TNPComponent;
