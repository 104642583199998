import React, { useContext, useEffect, useState } from "react";
import styles from "./HODTimetable.module.css";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import axios from "axios";
import UserContext from "../../../../../Context/UserContext";
import ViewTimetable from "../HODViewTimetable/HODViewTimetable";
import { Button, Box } from "@mui/material";
import TimeSlots from "./TimeSlots/TimeSlots";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../config/config";

const editTimeTableDropdown =
  process.env.REACT_APP_TIMETABLE_EDIT_TIMETABLE_DROPDOWN;
const getTimeTable = process.env.REACT_APP_TIMETABLE_GET_TIMETABLE;
const getTimeTableDropdown = process.env.REACT_APP_TIMETABLE_GET_DROPDOWN;

const HODTimetable = () => {
  const { setShowLoader, getToken } = useContext(UserContext);
  const [selectedData, setSelectedData] = useState({
    courseCode: "",
    semester: "",
    batchId: "",
    sectionId: "",
  });
  const [dropDownData, setDropDownData] = useState({
    courseList: [],
    batchList: [],
  });
  const [selectedCourse, setSelectedCourse] = useState("");
  const [semesterDropDown, setSemesterDropDown] = useState([]);
  const [viewSearchBtn, setViewSearchBtn] = useState(false);
  const [timeTableData, setTimeTableData] = useState([]);
  const [batchDropdown, setBatchDropDown] = useState([]);
  const [sectionDropDown, setSectionDropdown] = useState([]);
  const [practicalBatch, setPracticalBatch] = useState([]);
  const [editTimetableDropdownData, setEditTimetableDropdownData] = useState(
    []
  );
  const [open, setOpen] = React.useState(false);
  const [dropdownSelected, setDropdownSelected] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => { }, [timeTableData]);

  const getEditTimetableDropdownData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${editTimeTableDropdown}`,
        {
          courseCode: selectedData.courseCode,
          semester: selectedData.semester,
        },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.result) {
        setEditTimetableDropdownData(response.data.data);
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  // To Fetch Whole TimeTable Data
  const fetchTimeTableData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getTimeTable}?batchId=${selectedData.batchId}&sectionId=${selectedData.sectionId}&courseCode=${selectedData?.courseCode}&semester=${selectedData?.semester}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      setPracticalBatch(() => response?.data?.practicalBatchList);
      setTimeTableData(() => response.data.data);
      setViewSearchBtn(false);
      setShowLoader(false);
      if (!response?.data?.result) {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchDropDownData = async (req, res) => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getTimeTableDropdown}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response.data.result) {
        setDropDownData(response.data.data);
        setBatchDropDown(() => response?.data?.data?.batches);
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const filterSemesterList = async () => {
    try {
      dropDownData.map((course) => {
        if (course.value === selectedCourse) {
          setSemesterDropDown(course.semesters);
        }
      });
    } catch (error) { }
  };

  const handleSearchTimetable = async () => {
    if (
      !(
        selectedData?.courseCode &&
        selectedData?.semester &&
        selectedData?.batchId &&
        selectedData?.sectionId
      )
    ) {
      Toast.error("Plz Select All Dropdowns !");
      return;
    }

    fetchTimeTableData();
    getEditTimetableDropdownData();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setSelectedData({ ...selectedData, [name]: value });

    // if (value) {
    //   setDropdownSelected(true);
    // }
  };

  useEffect(() => {
    const { courseCode, semester, batchId, sectionId } = selectedData;
    if (courseCode && semester && batchId && sectionId) {
      setDropdownSelected(true);
    }
  }, [selectedData]);

  useEffect(() => {
    if (!selectedData.batchId) {
      return;
    }
    batchDropdown?.map((DATA) => {
      if (selectedData.batchId === DATA.value) {
        setSectionDropdown(() => DATA?.sections);
      }
    });
  }, [selectedData.batchId]);

  // useEffect(() => {}, [selectedData.section]);

  useEffect(() => {
    if (!selectedData.courseCode) {
      setBatchDropDown([]);
      setSectionDropdown([]);
      setSemesterDropDown([]);
      setSelectedData({
        ...selectedData,
        // ["semester"]: "",
        // ["batchId"]: "",
        // ["sectionId"]: "",
      });
      return;
    }
    const semList = dropDownData.courseList.filter(
      (data) => data.value === selectedData.courseCode
    );
    setSemesterDropDown(semList[0]?.semesters);

    const batchList = dropDownData.batchList.filter(
      (data) => data.value === selectedData.courseCode
    );
    setBatchDropDown(batchList[0]?.batches);
    setSectionDropdown([]);
  }, [selectedData.courseCode]);

  useEffect(() => {
    filterSemesterList();
  }, [selectedCourse]);

  useEffect(() => {
    fetchDropDownData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleContainer}>Time Table</h1>
      <Box className={styles.container}>
        <Box className={styles.dropDownParentContainer}>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="courseCode" className={styles.dropDownLabel}>
              Course Code
            </label>
            <DropDown
              options={dropDownData.courseList}
              name={"courseCode"}
              id="dropDown"
              onChange={handleChange}
              value={selectedData.courseCode}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="batch" className={styles.dropDownLabel}>
              Batch
            </label>
            <DropDown
              options={batchDropdown}
              name={"batchId"}
              id="dropDown"
              onChange={handleChange}
              disabled={!selectedData.courseCode}
              value={selectedData.batchId}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="section" className={styles.dropDownLabel}>
              Section
            </label>
            <DropDown
              options={sectionDropDown || []}
              name={"sectionId"}
              id="dropDown"
              onChange={handleChange}
              disabled={!selectedData.batchId}
              value={selectedData.sectionId}
            />
          </Box>

          <Box className={styles.dropDownContainer}>
            <label htmlFor="semester" className={styles.dropDownLabel}>
              Semester
            </label>
            <DropDown
              options={semesterDropDown}
              name={"semester"}
              id="dropDown"
              onChange={handleChange}
              disabled={!selectedData.sectionId}
              value={selectedData.semester}
            />
          </Box>
        </Box>

        <Box className={styles.buttonsContainer}>
          {true && (
            <Button
              variant="contained"
              className={styles.viewTimeTableButton}
              id="viewTimeTable"
              onClick={handleSearchTimetable}
              disabled={!dropdownSelected}
            >
              {" "}
              View TimeTable
            </Button>
          )}
          <Button
            className={styles.timeSlotButton}
            variant="contained"
            onClick={handleClickOpen}
          >
            View TimeSlots
          </Button>
        </Box>

        <Box className={styles.viewTimeTableContainer}>
          <ViewTimetable
            timeTableData={timeTableData}
            fetchTimeTableData={fetchTimeTableData}
            courseCode={selectedData.courseCode}
            batchId={selectedData.batchId}
            sectionId={selectedData.sectionId}
            semester={selectedData.semester}
            practicalBatchDropdown={practicalBatch}
            editTimetableDropdownData={editTimetableDropdownData}
          />
          {open && (
            <TimeSlots
              handleClickOpen={handleClickOpen}
              open={open}
              setOpen={setOpen}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default HODTimetable;
