import React, { useState } from "react";
import { Card, CardContent, Button, Modal, Box, TextField, IconButton } from "@mui/material";
import styles from "./JobCard.module.css";
import CloseIcon from "@mui/icons-material/Close";

const JobCard = ({ jobData, handleApplyJob }) => {
  const {
    jobType,
    jobDescription,
    keySkills,
    companyName,
    companyEmail,
    companyPhone,
    applicationDeadline,
    salary,
    jobTitle,
    applicationCount,
    location,
    isApplied,
    applicationStatus
  } = jobData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [error, setError] = useState("");

  const descriptionLines = jobDescription?.split("\n") || [];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = () => {
    setSelectedJob(jobData);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
    setUserInput("");
  };

  const handleSave = () => {
    handleApplyJob(selectedJob?.jobId, userInput);
    handleCloseModal();
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 150) {
      setUserInput(value);
      setError("");
    } else {
      setError("Please enter your message less than 150 characters.");
    }
  };

  // Function to get color for applyNowContainer based on applicationStatus
  const getCardColor = (status) => {
    let bgColor = "#ffffff"; // Default white background
    let textColor = "#ffffff"; // Default white text color

    switch (status) {
      case "Pending":
        bgColor = "#f7c100"; // Yellowish
        textColor = "black"; // White text
        break;
      case "Shortlisted":
        bgColor = "#46923c"; // Greenish
        textColor = "#ffffff"; // White text
        break;
      case "Rejected":
        bgColor = "#e74c3c"; // Reddish
        textColor = "#ffffff"; // White text
        break;
      default:
        bgColor = "#ffffff"; // Default background
        textColor = "#333333"; // Dark text
        break;
    }

    return { bgColor, textColor };
  };

  const { bgColor, textColor } = getCardColor(applicationStatus);

  return (
    <div className={styles.cardsContainer}>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <div className={styles.jobInfo}>
            <h2 className={styles.jobTitle}>{jobTitle}</h2>
            <h3 className={styles.salary}>
              Salary: {salary} /- <span>&#8377;</span>
            </h3>
          </div>
          <p className={styles.jobType}>{jobType}</p>

          <div className={styles.additionalJobInfo}>
            <h3 className={styles.description}>
              Job Description:
              <>
                {descriptionLines?.map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </>
            </h3>
            <h3>
              Key Skills:
              <>
                {keySkills?.map((skill, index) => (
                  <Button
                    key={index}
                    variant="outlined"
                    className={styles.skillButton}
                  >
                    {skill}
                  </Button>
                ))}
              </>
            </h3>
            <h3>
              Company Name: <p>{companyName}</p>
            </h3>
            <h3>
              Company Email: <p>{companyEmail}</p>
            </h3>
            <h3>
              Company Phone: <p>{companyPhone}</p>
            </h3>
            <h3>
              Last Date for Application:{" "}
              <p>{formatDate(applicationDeadline)}</p>
            </h3>
            <h3>
              Total Applications for this Job: <p>{applicationCount}</p>
            </h3>
            <h3>
              Location: <p>{location}</p>
            </h3>
          </div>

          <div className={styles.applyNowContainer} style={{
            backgroundColor: bgColor,
            color: textColor,
          }}>
            {!isApplied ? (
              Date.now() > Date.parse(applicationDeadline) ? (
                <h2 className={styles.deadlineText}> You are Late! This application deadline has already passed.</h2>
              ) : (
                <Button
                  className={styles.applyButton}
                  variant="contained"
                  onClick={handleOpenModal}
                  disabled={Date.now() > Date.parse(applicationDeadline)} // Disabled state for button
                >
                  Apply Now!
                </Button>
              )
            ) : (
              <h2
                className={styles.statusHeader}>
                {applicationStatus === "Pending"
                  ? "Please wait while we process your application."
                  : applicationStatus === "Shortlisted"
                    ? "Congratulations! Your application is shortlisted."
                    : applicationStatus === "Rejected"
                      ? "Oops! Unfortunately, Your application is rejected."
                      : applicationStatus}
              </h2>
            )}
          </div>

        </CardContent>
      </Card>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modalBox}>
            <Box className={styles.closeIconContainer}>
              <IconButton
                onClick={handleCloseModal}
                className={styles.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <h2 className={styles.messageTitle}>Your Message</h2>
            <div className={styles.modalContent}>
              <p>Job Title: {selectedJob?.jobTitle}</p>
              <p>Company: {selectedJob?.companyName}</p>
            </div>

            <TextField
              label="Enter your message (optional)"
              multiline
              rows={4}
              value={userInput}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              margin="normal"
              error={!!error}
              helperText={error}
              sx={{
                "& .MuiFormHelperText-root": {
                  fontSize: "0.8rem",
                },
              }}
            />

            <div className={styles.buttons}>
              <Button disabled={!!error || userInput.length === 0} variant="contained" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default JobCard;
