import React, { useContext } from "react";
import styles from "./CardComponent.module.css";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestoreIcon from "@mui/icons-material/Restore"; // Import recover icon
import LockResetIcon from "@mui/icons-material/LockReset";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip component
import UserContext from "../../../Context/UserContext";

const CardComponent = ({
  enrollmentId,
  userFullName,
  actions,
  isClassRespresentative,
  role,
  email,
  departmentCode,
  isdeleted,
}) => {
  const { getUserRole } = useContext(UserContext);

  return (
    <div className={`${styles.card} ${isdeleted ? styles.deletedCard : ""}`}>
      <div className={styles.cardContent}>
        <h4 className={styles.cardRole}>
          {role} {isClassRespresentative ? "(CR)" : ""}
        </h4>
        <div className={styles.descriptionData}>
          <p className={styles.cardDescription}>{userFullName}</p>
          <p className={styles.cardEmail}>{email}</p>
          <h3 className={styles.cardDescription}>{enrollmentId}</h3>
        </div>
      </div>

      <div className={styles.cardActionContainer}>
        <div className={styles.cardActions}>
          {actions &&
            actions.map((action) => {
              if (action.label === "View Details") {
                return (
                  <Tooltip title="View Profile" arrow key="view-details">
                    <RemoveRedEyeOutlinedIcon
                      className={styles.icon}
                      onClick={action.onClick}
                    />
                  </Tooltip>
                );
              }
              if (
                action.label === "Edit" &&
                ["superadmin", "instituteadmin", "hod"].includes(
                  getUserRole()
                ) &&
                (getUserRole() === "hod"
                  ? localStorage.getItem("deptCode") === departmentCode
                  : true)
              ) {
                return (
                  <Tooltip title="Reset Password" arrow key="edit">
                    <LockResetIcon
                      className={styles.icon}
                      onClick={action.onClick}
                    />
                  </Tooltip>
                );
              }
              if (
                action.label === "Delete" &&
                !isdeleted &&
                ["superadmin", "instituteadmin", "hod"].includes(
                  getUserRole()
                ) &&
                (getUserRole() === "hod"
                  ? localStorage.getItem("deptCode") === departmentCode
                  : true)
              ) {
                return (
                  <Tooltip title="Delete" arrow key="delete">
                    <DeleteOutlineIcon
                      className={styles.icon}
                      onClick={action.onClick}
                    />
                  </Tooltip>
                );
              }
              if (action.label === "Recover" && isdeleted) {
                return (
                  <Tooltip title="Recover" arrow key="recover">
                    <RestoreIcon
                      className={styles.icon}
                      onClick={action.onClick}
                    />
                  </Tooltip>
                );
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
