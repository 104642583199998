
import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import Dropdown from '../../DropDown/DropDown';
import UserContext from '../../../../Context/UserContext';
import Toast from '../../ToastContainer/ToastContainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "./DateWise.module.css"
import { BASE_URL } from "./../../../../config/config";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const getStudentAttendanceInHod = process.env.REACT_APP_HOD_GET_STUDENT_ATTENDANCE;

const months = [
  { label: 'January (1)', value: 0 },
  { label: 'February (2)', value: 1 },
  { label: 'March (3)', value: 2 },
  { label: 'April (4)', value: 3 },
  { label: 'May (5)', value: 4 },
  { label: 'June (6)', value: 5 },
  { label: 'July (7)', value: 6 },
  { label: 'August (8)', value: 7 },
  { label: 'September (9)', value: 8 },
  { label: 'October (10)', value: 9 },
  { label: 'November (11)', value: 10 },
  { label: 'December (12)', value: 11 }
];

const DateWise = ({ selectedField }) => {

  const { getToken, getDecodedToken, setShowLoader } = useContext(UserContext)
  const [attendanceData, setAttendanceData] = useState([]);
  const [wholeData, setWholeData] = useState([]);

  const [selectedMonth, setSelectedMonth] = useState('');
  const navigate = useNavigate();

  function filterDataByMonth() {
    const data = wholeData?.filter(record => {
      const recordDate = new Date(record.date);

      return recordDate.getMonth() === Number(selectedMonth);
    });
    if (data.length === 0 && selectedMonth === '') {
      setAttendanceData(() => wholeData)
      return
    }

    setAttendanceData(() => data);
    return;
  }

  useEffect(() => {
    filterDataByMonth()
  }, [selectedMonth])
  const fetchAttendanceData = async () => {
    try {

      const { subjectCode, courseCode, batchId, sectionId, semester, subjectType, practicalBatchId } = selectedField || {};

      if (!(subjectCode && courseCode && batchId && sectionId && semester)) {
        setAttendanceData(() => []);
        setWholeData(() => []);
        return;
      }

      if (subjectType === "LAB" && !practicalBatchId) {
        setAttendanceData(() => []);
        setWholeData(() => []);
        return;
      }


      setShowLoader(true)

      const response = await axios.post(`${BASE_URL}${getStudentAttendanceInHod}`,
        selectedField,
        { headers: { Authorization: `Bearer ${getToken()}` } })
      if (response?.data?.result) {

        setAttendanceData(() => response?.data?.data || [])
        setWholeData(() => response?.data?.data || [])
      }
      else {
        setAttendanceData(() => [])
        setWholeData(() => [])
        Toast.error(response?.data?.message);
      }
      setShowLoader(false)


    } catch (error) {
      setAttendanceData(() => [])
      setWholeData(() => [])
      setShowLoader(false)
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleChange = async (e) => {
    setSelectedMonth(() => e.target.value)
  }

  const downloadExcel = (data) => {
    try {
      if (data.length === 0) {
        Toast.warning(`No data to download...!`);
        return;
      }
      setShowLoader(true);

      const customHeadings = [
        ["Subject : ", `${selectedField.subjectName}`],
        [`Practical Batch : `, `${selectedField.practicalBatchName ? selectedField.practicalBatchName : 'N/A'}`],
        ["Download Date : ", `${new Date().toISOString().slice(0, 10).split("-").reverse().join("/")}`],
        [`Download Time : `, `${new Date().toLocaleTimeString()}`],
        [`Download By : `, ` ${getDecodedToken()?.fname} ${getDecodedToken()?.lname} - (${getDecodedToken()?.role ? (getDecodedToken()?.role).toUpperCase() : "ROLE NOT FOUND"})`]
      ];

      const dates = data.map(entry => new Date(entry.date).toISOString().slice(0, 10).split("-").reverse().join("/"));

      const headers = ["Enrollment ID", "Student Name", ...dates];

      const studentMap = {};

      data.forEach((entry) => {
        entry.students.forEach((student) => {
          if (!studentMap[student.studentId]) {
            studentMap[student.studentId] = {
              "Enrollment ID": student.enrollmentId,
              "Student Name": student.studentName,
              ...dates.reduce((acc, date) => ({ ...acc, [date]: "N/A" }), {}),
            };
          }
          studentMap[student.studentId][
            new Date(entry.date).toISOString().slice(0, 10).split("-").reverse().join("/")
          ] = student.presentStatus;
        });
      });

      const rows = Object.values(studentMap);

      const formatTime = (isoTime) => {
        return dayjs(isoTime).local().format('hh:mm A');
      };

      const dateWithTimes = data.map((entry) => {
        const dateFormatted = dayjs(entry.date).format('DD/MM/YYYY');
        const startTimeFormatted = formatTime(entry.startTime);
        const endTimeFormatted = formatTime(entry.endTime);

        return `${dateFormatted} (${startTimeFormatted} - ${endTimeFormatted})`;
      });

      const columnNameWithtime = ["Enrollment ID", "Student Name", ...dateWithTimes];
      const sheetData = [
        ...customHeadings,
        [],
        columnNameWithtime,
        ...rows.map((row) => headers.map((h) => row[h])),
      ];

      const ws = XLSX.utils.aoa_to_sheet(sheetData);

      // Apply styles for custom headings
      const customHeadingRowIndex = 0; // Adjust as necessary
      for (let C = 0; C < customHeadings[0].length; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: customHeadingRowIndex, c: C });
        if (ws[cellAddress]) {
          ws[cellAddress].s = {
            fill: { fgColor: { rgb: "FFFF00" } }, // Yellow background for custom headings
            font: { bold: true },
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
      }

      // Apply styles for column names (headers)
      const columnHeaderRowIndex = customHeadings.length + 1; // Row index for column names
      for (let C = 0; C < columnNameWithtime.length; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: columnHeaderRowIndex, c: C });
        if (ws[cellAddress]) {
          ws[cellAddress].s = {
            fill: { fgColor: { rgb: "00BFFF" } }, // Blue background for column names
            font: { bold: true, color: { rgb: "FFFFFF" } }, // White font color
            alignment: { horizontal: "center", vertical: "center" },
          };
        }
      }

      ws["!cols"] = [
        { wch: 18 }, // Enrollment ID column
        { wch: 25 }, // Student Name column
        ...dates.map(() => ({ wch: 15, wrapText: true })), // Date columns
      ];

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Attendance Report');

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, `Attendance Report On ${new Date().toLocaleString()}.xlsx`);
      Toast.success("Excel downloaded!");

    } catch (error) {
      Toast.error("Error downloading excel!");

    } finally {
      setShowLoader(false);
    }
  };

  const handleEditAttendance = async (studentData) => {
    const data = {
      courseCode: selectedField.courseCode,
      subjectCode: selectedField.subjectCode,
      studentId: studentData.studentId,
      enrollmentId: studentData.enrollmentId,
    }
    navigate('../edit-attendance', { state: data });
  }

  useEffect(() => {
    const {
      courseCode,
      subjectCode,
      subjectName,
      subjectType,
      practicalBatchId,
      practicalBatchName,
      semester,
      batchId,
      sectionId,
    } = selectedField;

    if (!(courseCode && subjectCode && subjectName && subjectType && semester && batchId && sectionId)) {
      navigate("../");
    }
  }, [selectedField]);

  useEffect(() => {
    fetchAttendanceData()
    setSelectedMonth(() => '');
  }, [selectedField])

  return (
    <Box className={styles.dateWiseAttendanceContainer}>
      <h2 className={styles.containerTitle}>
        Datewise Students Attendance
      </h2>

      <Box className={styles.monthDropDownContainer}>
        <Dropdown className={styles.monthDropDown} options={months} onChange={handleChange} value={selectedMonth} />
        <Button variant='contained' className={styles.downloadButton} onClick={() => {
          downloadExcel(attendanceData)
        }} >Download Excel</Button>
      </Box>


      <Box className={styles.tableBoxContainer}>
        <Box className={styles.arrowContainer} >
          <ArrowBackIcon className={styles.previousButton} onClick={() => {
            navigate(-1);
          }} />
          <ArrowForwardIcon className={styles.nextButton} onClick={() => {
            navigate(+1);
          }} />
        </Box>

        <TableContainer component={Paper} className={styles.tableContainer} >
          <Table aria-label="customized table" className={styles.theTable}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableHead}>EnrollmentID</TableCell>
                <TableCell className={styles.tableHead}>Student Name</TableCell>
                {attendanceData &&
                  attendanceData.map((record, index) => (
                    <TableCell className={styles.tableHead} key={index}>
                      {new Date(record.date)
                        .toISOString()
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/")}
                    </TableCell>
                  ))}
                <TableCell className={styles.tableHead}>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {attendanceData[0] &&
                attendanceData[0].students.map((student, studentIndex) => (
                  <TableRow key={student.studentId}>
                    <TableCell className={styles.tableData}>{student.enrollmentId}</TableCell>
                    <TableCell className={styles.tableData}>
                      {String(student.studentName).toUpperCase()}
                    </TableCell>
                    {attendanceData?.map((record, recordIndex) => {
                      const studentAttendance = record.students.find(
                        (s) => s.studentId === student.studentId
                      );
                      return (
                        <TableCell className={styles.tableData} key={recordIndex}>
                          {studentAttendance ? studentAttendance.presentStatus : "N/A"}
                        </TableCell>
                      );
                    })}
                    <TableCell className={styles.tableData}>
                      <Tooltip title="View Attendance Details" arrow>
                        <IconButton className={styles.viewIcon} onClick={() => handleEditAttendance(student)}>
                          <RemoveRedEyeOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {!attendanceData[0] && <h2 className={styles.noDataTitle} >"No Data Found"</h2>}
        </TableContainer>
      </Box>

    </Box >
  );
};

export default DateWise;

