import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import dayjs from "dayjs";
import styles from "./RecruiterRegistration.module.css";
import { Button, Grid } from "@mui/material";
import { BASE_URL } from "../../../../../config/config";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UserContext from "../../../../../Context/UserContext";

const regRecruiter =
    process.env.REACT_APP_ADMIN_REGISTER_RECRUITER;
const getDepartmentDropdown =
    process.env.REACT_APP_ADMIN_GET_DEPARTMENT_DROPDOWN;

const RecruiterRegistration = () => {
    const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
    const [departmentDropdown, setDepartmentDropdown] = useState([]);
    const [companyNameDropdown, setCompanyNameDropdown] = useState([]);
    // const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [DropDownData, setDropDownData] = useState({
        genderList: [],
        departmentList: [],
        salutationList: [],
        companyList: [],
    });
    const [errors, setErrors] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [formData, setFormData] = useState({
        salutation: "",
        fname: "",
        mname: "",
        lname: "",
        gender: "",
        primaryPhone: "",
        secondaryPhone: "",
        email: "",
        designation: "",
        companyId: "",
        dob: "",
        aadharNo: "",
        defaultPassword: "",
        confirmPassword: "",
    });

    useEffect(() => {
        validateForm();
    }, [formData]);

    const validateForm = () => {
        let validationErrors = {};
        const requiredFields = [
            "salutation",
            "fname",
            "mname",
            "lname",
            "gender",
            "primaryPhone",
            // "secondaryPhone",
            "email",
            "companyId",
            "designation",
            "dob",
            "defaultPassword",
            "confirmPassword",
            // "aadharNo",
        ];

        // Check required fields
        requiredFields.forEach((field) => {
            const value = formData[field];
            // Check if value is a string before using .trim()
            if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
                validationErrors[field] = "**This field is required**";
            } else if (typeof value === "string" && value.trim() === "") {
                validationErrors[field] = "**This field is required**";
            }
        });

        const aadharNo = formData["aadharNo"];
        if (aadharNo && !/^\d{12}$/.test(aadharNo)) {
            validationErrors["aadharNo"] = "**Aadhar number must be 12 digits**";
        }

        // Validate phone fields for 10-digit numbers
        const phoneFields = ["primaryPhone", "secondaryPhone", "companyPhone"];
        phoneFields.forEach((field) => {
            const value = formData[field];
            if (value && !/^\d{10}$/.test(value)) {
                validationErrors[field] = "**Phone number must be 10 digits**";
            }
        });

        // Validate password strength
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
        if (
            formData.defaultPassword &&
            !passwordRegex.test(formData.defaultPassword)
        ) {
            validationErrors.defaultPassword =
                "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
        }

        if (
            formData.confirmPassword &&
            !passwordRegex.test(formData.confirmPassword)
        ) {
            validationErrors.confirmPassword =
                "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
        }

        // Validate passwords match
        if (
            formData.defaultPassword &&
            formData.confirmPassword &&
            formData.defaultPassword !== formData.confirmPassword
        ) {
            validationErrors.confirmPassword = "**Passwords do not match**";
        }

        // Validate DOB (cannot be in the future)
        if (!formData.dob) {
            validationErrors.dob = "**This field is required**";
        } else {
            const dob = dayjs(formData.dob);
            if (!dob.isValid()) {
                validationErrors.dob = "**Invalid date format**";
            } else if (dob.isAfter(dayjs(), "day")) {
                validationErrors.dob = "**Date of Birth cannot be in the future**";
            }
        }

        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            validationErrors["email"] = "**Please enter a valid email address**";
        }

        // if (selectedDepartments.length < 1) {
        //     validationErrors["departmentList"] = "**Please select at least one department**";
        // }

        if (formData.companyWebsite && !/^(https?:\/\/)?[^\s]+\.[^\s]+$/.test(formData.companyWebsite)) {
            validationErrors["companyWebsite"] = "**Please enter a valid website URL**";
        }

        setErrors(validationErrors);
        setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            const dob = dayjs(newValue);

            // Check if the date is valid
            if (!dob.isValid()) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    dob: "**Invalid date format**",
                }));
            }
            // Check if the date is in the future
            else if (dob.isAfter(dayjs(), "day")) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    dob: "**Date of Birth cannot be in the future**",
                }));
            }
            // If valid, update the state and clear errors
            else {
                setFormData({
                    ...formData,
                    dob: dob.toISOString(), // Store the date as an ISO string
                });
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    dob: "", // Clear any existing errors
                }));
            }
        } else {
            // Handle case when newValue is empty (e.g., user clears the input)
            setErrors((prevErrors) => ({
                ...prevErrors,
                dob: "**This field is required**",
            }));
            setFormData({
                ...formData,
                dob: "", // Reset the value if input is cleared
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateForm();

        try {
            const headers = {
                Authorization: `Bearer ${getToken()}`,
                // "Content-Type": "multipart/form-data",
            };
            setShowLoader(true);
            // formData.departmentCodeList = selectedDepartments

            const response = await axios.post(
                `${BASE_URL}${regRecruiter}`,
                formData,
                {
                    headers,
                }
            );

            // Handle response as needed
            if (response.data.result) {
                Toast.success(response.data.message || "Success !");
                setFormData({
                    salutation: "",
                    fname: "",
                    mname: "",
                    lname: "",
                    gender: "",
                    primaryPhone: "",
                    secondaryPhone: "",
                    email: "",
                    // enrollmentId: "",
                    companyId: "",
                    // companyEmail: "",
                    // companyWebsite:"",
                    companyPhone: "",
                    designation: "",
                    // country:"",
                    // state:"",
                    // district:"",
                    // addressLine:"",
                    dob: "",
                    aadharNo: "",
                    defaultPassword: "",
                    confirmPassword: "",
                })
                setErrors({});
            } else {
                Toast.error(response.data.message || "Something went wrong!");
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    const fetchDropdown = async () => {
        try {
            setShowLoader(true); // Optional, to show a loader during the API call
            const response = await axios.get(`${BASE_URL}${getDepartmentDropdown}`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            if (response.data.result) {
                // Set department dropdown data from the response
                setDropDownData(response?.data?.data);
                setDepartmentDropdown(response?.data?.data?.departmentList || []);
                setCompanyNameDropdown(response?.data?.data?.companyNameList || []);
            } else {
                Toast.error(response.data.message || "Something went wrong!");
            }
        } catch (error) {
            console.error('Error fetching department dropdown:', error);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    useEffect(() => {
        fetchDropdown();
        // fetchCountries();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.recruiterRegContainer}>
                <h2 className={styles.recruiterRegContainerHeader}>
                    Register Recruiter{" "}
                </h2>
                <div className={styles.registerFormScrollable}>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            showConfirmation(
                                "Want to register Recruiter?",
                                handleSubmit,
                                event
                            );
                        }}
                    >
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="departmentName">Department Name</label>
                                    <div className={styles.departmentNamesContainer}>
                                        {selectedDepartments.length > 0 ? (
                                            selectedDepartments.map((value) => {
                                                const departmentLabel = departmentDropdown.find((data) => data.value === value)?.label || "Unknown";
                                                return (
                                                    <Tooltip key={value} title={departmentLabel} arrow>
                                                        <Button
                                                            onClick={() => removeDepartment(value)}
                                                            className={styles.departmentNameButton}
                                                        >
                                                            {
                                                                departmentLabel.length > 10
                                                                    ? departmentLabel.substring(0, 15) + "..."
                                                                    : departmentLabel
                                                            }
                                                        </Button>
                                                    </Tooltip>
                                                );
                                            })
                                        ) : (
                                            <p className={styles.noDepartmentText}>**No department selected**</p>
                                        )}
                                    </div>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedDepartments}
                                        onChange={handleDepartmentChange}
                                        className={styles.selectOption}
                                    >
                                        <MenuItem value="select-all">
                                            {selectedDepartments.length === departmentDropdown.length ? "Deselect All" : "Select All"}
                                        </MenuItem>

                                        {departmentDropdown.map((departmentData) => {
                                            // Only show items not already selected
                                            if (!selectedDepartments.includes(departmentData.value)) {
                                                return (
                                                    <MenuItem key={departmentData.value} value={departmentData.value}>
                                                        {departmentData.label}
                                                    </MenuItem>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Select>
                                </div>
                                {errors.departmentList && (
                                    <p className={styles.error}>{errors.departmentList}</p>
                                )}
                            </Grid> */}

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="salutation">Salutation</label>
                                    <Dropdown name="salutation" value={formData.salutation} options={DropDownData.salutationList} onChange={handleChange} />
                                </div>
                                {errors.salutation && <p className={styles.error}>{errors.salutation}</p>}
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="firstName"> First Name</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        placeholder="First Name"
                                        value={formData.fname}
                                        name="fname"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.fname && (
                                        <p className={styles.error}>{errors.fname}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="middleName"> Middle Name</label>
                                    <input
                                        type="text"
                                        id="middleName"
                                        placeholder="Middle Name"
                                        value={formData.mname}
                                        name="mname"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.mname && (
                                        <p className={styles.error}>{errors.mname}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="lastName"> Last Name</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        placeholder="Last Name"
                                        value={formData.lname}
                                        name="lname"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.lname && (
                                        <p className={styles.error}>{errors.lname}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="gender">Gender</label>
                                    <Dropdown
                                        name="gender"
                                        value={formData.gender}
                                        options={DropDownData.genderList}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.gender && (
                                    <p className={styles.error}>{errors.gender}</p>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="primaryPhone"> Primary Phone</label>
                                    <input
                                        type="number" 
                                        onWheel={(e) => e.target.blur()}
                                        aria-controls="none"
                                        id="primaryPhone"
                                        placeholder="Primary Phone"
                                        value={formData.primaryPhone}
                                        name="primaryPhone"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.primaryPhone && (
                                        <p className={styles.error}>{errors.primaryPhone}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="secondaryPhone"> Secondary Phone</label>
                                    <input
                                        type="number" 
                                        onWheel={(e) => e.target.blur()}
                                        aria-controls="none"
                                        id="secondaryPhone"
                                        placeholder="Secondary Phone"
                                        value={formData.secondaryPhone}
                                        name="secondaryPhone"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.secondaryPhone && (
                                        <p className={styles.error}>{errors.secondaryPhone}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="emailId"> Email</label>
                                    <input
                                        type="email"
                                        id="emailId"
                                        placeholder="Email Id"
                                        value={formData.email}
                                        name="email"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                        autoComplete="none"
                                    />
                                    {errors.email && (
                                        <p className={styles.error}>{errors.email}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="dob">Date of Birth</label>
                                        <DesktopDatePicker
                                            className={styles.datePicker}
                                            views={["year", "month", "day"]}
                                            inputFormat="MM/DD/YYYY"
                                            value={formData.dob ? dayjs(formData.dob) : null}
                                            onChange={handleDateChange}
                                        />
                                        {errors.dob && <p className={styles.error}>{errors.dob}</p>}
                                    </div>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="aadharNo"> Aadhar Number</label>
                                    <input
                                        type="number" 
                                        onWheel={(e) => e.target.blur()}
                                        id="aadharNo"
                                        placeholder="Aadhar Number"
                                        value={formData.aadharNo}
                                        name="aadharNo"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                        autoComplete="off"
                                    />
                                    {errors.aadharNo && <p className={styles.error}>{errors.aadharNo}</p>}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="designation">Designation</label>
                                    <input
                                        type="text"
                                        id="designation"
                                        placeholder="Designation"
                                        value={formData?.designation}
                                        name="designation"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                        autoComplete="none"
                                    />
                                    {errors.designation && (
                                        <p className={styles.error}>{errors.designation}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="companyId">Company Name</label>
                                    <Dropdown
                                        name="companyId"
                                        value={formData.companyId}
                                        options={DropDownData.companyList}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.companyId && (
                                    <p className={styles.error}>{errors.companyId}</p>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="defaultPassword">Default Password</label>
                                    <input
                                        type="password"
                                        id="defaultPassword"
                                        placeholder="Default Password"
                                        value={formData.defaultPassword}
                                        name="defaultPassword"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.defaultPassword && (
                                        <p className={styles.error}>{errors.defaultPassword}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="Confirm Default Password">
                                        Confirm Default Password
                                    </label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        placeholder="Confirm Default Password"
                                        value={formData.confirmPassword}
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        className={styles.inputbox}
                                    />
                                    {errors.confirmPassword && (
                                        <p className={styles.error}>{errors.confirmPassword}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12} className={styles.submitButtonContainer}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className={styles.submitButton}
                                    disabled={isSubmitDisabled}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RecruiterRegistration;
