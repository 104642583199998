import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
// import Draggable from "react-draggable"; // The default
import UserContext from "../../../../../Context/UserContext";
import styles from "./GroupChat.module.css";
import { FaPaperPlane } from "react-icons/fa";
import MembersList from "../MembersList/MembersList";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
// import { jwtDecode } from "jwt-decode";
import Toast from "../../../ToastContainer/ToastContainer";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { BASE_URL } from "../../../../../config/config";

const chatLeaveGroup = process.env.REACT_APP_CHAT_LEAVEGFROMROUP;
const chatDeleteGroup = process.env.REACT_APP_CHAT_DELETE_GROUP_MESSAGE;
const getHistoryOfGroupChat = process.env.REACT_APP_CHAT_GET_HISTORY_GROUP_CHAT_MESSAGES;

const GroupChat = ({ selectedGroup, onClose }) => {
  const {
    groupSocket,
    // groupChatMessages,
    Cookies,
    getToken,
    setShowLoader,
    getDecodedToken,
  } = useContext(UserContext);
  const [groupMessage, setGroupMessage] = useState("");
  const [groupChatMessages, setGroupChatMessages] = useState([]);
  const [showGroupMembersList, setShowGroupMembersList] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const chatBoxRef = useRef(null);
  const messageInputRef = useRef(null);

  // const decodedToken = jwtDecode(Cookies.get("token"));
  const isHOD = getDecodedToken().role === "HOD";

  const convertGivenDateToAMPM = (dateInput) => {
    const date = new Date(dateInput);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 becomes 12

    // Add leading zero to minutes if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Get the current date in a readable format
    const timeString = `${hours}:${minutes} ${ampm}`;
    return timeString;
  };

  const handleReceiveGroupChatMessage = (messageData) => {
    const newMessage = {
      createdAt: messageData?.createdAt || "",
      message: messageData?.message || "N/A",
      senderId: messageData?.senderId || "",
      time: messageData?.time || "",
      username: messageData?.username || "N/A",
      _id: messageData?._id || "",
    };
    if (selectedGroup._id === messageData.groupId) {
      setGroupChatMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  useEffect(() => {
    if (groupSocket) {
      groupSocket?.on("chat messagesend", handleReceiveGroupChatMessage);
    }
    // return () => {
    //   groupSocket?.off("messageReceived", handleReceiveGroupChatMessage);
    // };
  }, [groupSocket]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [groupChatMessages]);

  useEffect(() => {
    messageInputRef.current.focus();
  }, [selectedGroup]);

  const groupSetMessage = useCallback((e) => {
    setGroupMessage(e.target.value);
  }, []);

  const handleSendMessage = () => {
    if (!groupMessage.trim()) return;
    const fromId = getDecodedToken().email || localStorage.getItem("userId");
    const username = `${getDecodedToken().fname} ${getDecodedToken().lname}`;
    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const newMessage = {
      from: fromId,
      to: selectedGroup._id,
      username: username,
      message: groupMessage.trim(),
      time: currentTime,
    };
    if (selectedGroup && selectedGroup._id) {
      if (groupSocket) {
        groupSocket?.emit("chat message", newMessage);
      }
    } else {
    }
    setGroupMessage("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleLeaveGroup = async () => {
    try {
      const token = Cookies.get("token");
      return;
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${chatLeaveGroup}`,
        {
          groupId: selectedGroup._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onClose();
      setShowLoader(false);
      Toast.success(response?.data?.message || "Success !");
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleShowGroupMembersList = () => {
    setShowGroupMembersList(true);
  };

  const handleCloseGroupMembersList = () => {
    setShowGroupMembersList(false);
  };

  const deleteChat = async () => {
    try {
      const token = Cookies.get("token");
      return;
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${chatDeleteGroup}`,
        { groupId: selectedGroup._id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);
      Toast.success(response?.data?.message || "Success !");
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchGroupChatHistory = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}${getHistoryOfGroupChat}`,
        { groupCode: selectedGroup.groupCode },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setGroupChatMessages(response.data?.data || []);
        // setGroupMessage
      } else {
        Toast.error(response?.data?.message || "Error previous messages !");
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchGroupChatHistory();
  }, [selectedGroup]);

  return (
    <Box className={styles.groupChatContainer}>
      <Box className={styles.closeIconContainer}>
        <h2 className={styles.groupChatContainerTitle}>
          {selectedGroup?.groupName}
        </h2>
        <Box className={styles.closeIconContainerIcons}>
          <InfoIcon
            className={styles.infoButton}
            onClick={() => setShowButtons((prev) => !prev)}
          />
          {!isHOD && (
            <DeleteIcon
              onClick={deleteChat}
              className={styles.deleteGroupButton}
            />
          )}
          <CloseIcon onClick={onClose} className={styles.closeButton} />
        </Box>
      </Box>

      <Box className={styles.buttonContainer}>
        {showButtons && (
          <>
            <Button
              variant="contained"
              className={styles.membersButton}
              onClick={handleShowGroupMembersList}
            >
              Members
            </Button>

            <Button
              variant="contained"
              className={styles.leaveButton}
              onClick={handleLeaveGroup}
            >
              Leave
            </Button>
          </>
        )}
      </Box>

      {showGroupMembersList && (
        <div className={styles.groupMembersListContainer}>
          {/* <Draggable> */}
          <MembersList
            selectedGroup={selectedGroup}
            onClose={handleCloseGroupMembersList}
          />
          {/* </Draggable> */}
        </div>
      )}

      <div ref={chatBoxRef} className={styles.groupChatMessages}>
        {groupChatMessages.length > 0 &&
          groupChatMessages
            // .filter((grpmsg) => grpmsg.groupId === selectedGroup._id)
            .map((groupMessage, index) => (
              <div
                key={index}
                className={`${styles.groupMessage} ${groupMessage?.senderId === getDecodedToken()._id
                  ? styles.sent
                  : styles.received
                  }`}
              >
                {groupMessage.senderId !== getDecodedToken()._id && (
                  <p className={styles.messageUsername}>
                    {groupMessage.username}
                  </p>
                )}
                <div className={styles.messageText}>{groupMessage.message}</div>
                <div className={styles.messageTime}>
                  {convertGivenDateToAMPM(groupMessage.createdAt)}
                </div>
              </div>
            ))}
      </div>

      <div className={styles.groupMessageInput}>
        <input
          ref={messageInputRef}
          type="text"
          placeholder="Type your message..."
          value={groupMessage}
          onChange={groupSetMessage}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage} className={styles.sendButton}>
          <FaPaperPlane />
        </button>
      </div>
    </Box>
  );
};

export default GroupChat;
