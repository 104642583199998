import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import axios from 'axios';
import UserContext from '../../../../../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import styles from "./AverageAttendance.module.css";
import Toast from '../../../../CommonComponent/ToastContainer/ToastContainer';
import { BASE_URL } from "./../../../../../config/config";

const getOverallAttendanceStudent = process.env.REACT_APP_GET_OVERALL_ATTENDANCE_BY_STUDENT;

const PieChart = ({ subjectName, subjectType, subjectCode, presentCount, totalCount, onClick }) => {
  const absentCount = totalCount - presentCount;
  const data = [
    ['Status', 'Count'],
    ['Present', presentCount],
    ['Absent', absentCount],
  ];

  const options = {
    title: subjectName,
    titleTextStyle: {
      fontSize: 24,
      bold: true,
      color: "#333",
      alignment: "center",
    },
    pieHole: 0.2,
    is3D: true,
    pieSliceText: 'label',
    tooltip: { text: 'percentage' },
    legend: { position: 'bottom' },
    chartArea: { width: '100%', height: '80%' },
    backgroundColor: "transparent",
  };

  return (
    <Box className={styles.pieChartContainer} onClick={onClick}>
      <h3 className={styles.subjectName}>{subjectName} - ({subjectType})</h3>
      <h3 className={styles.subjectCode}>{subjectCode}</h3>

      <div className={styles.chartContent}>
        {totalCount > 0 ? (
          <Chart
            chartType="PieChart"
            data={data}
            options={options}
            className={styles.subjectChart}
          />
        ) : (
          <h4 className={styles.noLecturesMessage}>
            No Lectures for {subjectName}
          </h4>
        )}
      </div>

      <h3 className={styles.totalCount}>Total Lectures: {totalCount}</h3>
    </Box>
  );
};

const AverageAttendance = () => {
  const { getToken, setShowLoader } = useContext(UserContext);
  const [averageData, setAverageData] = useState([]);
  const navigate = useNavigate();

  const fetchAverageData = useCallback(async () => {
    setShowLoader(true);
    try {
      const response = await axios.get(`${BASE_URL}${getOverallAttendanceStudent}`, {
        headers: { Authorization: `Bearer ${getToken()}` }
      });
      const fetchedData = response.data.data || [];
      setAverageData(fetchedData);
      if (!response.data?.result) {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
      console.error("Error fetching average data: ", error);
    } finally {
      setShowLoader(false);
    }
  }, [getToken, setShowLoader]);

  useEffect(() => {
    fetchAverageData();
  }, []);

  const handlePieChartClick = (subjectCode) => {
    navigate('datewiseAttendance', { state: { subjectCode } });
  };

  return (
    <Box className={styles.subjectContainer}>
      {averageData.length > 0 ? (
        averageData.map((subject) => (
          <PieChart
            key={subject.subjectId}
            subjectName={subject.subjectName}
            subjectType={subject.subjectType}
            subjectCode={subject.subjectCode}
            presentCount={subject.presentCount}
            totalCount={subject.totalCount}
            onClick={() => {
              if (subject.totalCount) {
                handlePieChartClick(subject.subjectCode)
              }
            }}
          />
        ))
      ) : (
        <h2 className={styles.noData}>
          No student attendance data available.
        </h2>
      )}
    </Box>
  );
};

export default AverageAttendance;
