import React, { useContext, useState, useEffect } from "react";
import { Button, TextField, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditLanguages.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import UserContext from "../../../../../../Context/UserContext";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "../../../../../../config/config";

const showLanguagesDropdown = process.env.REACT_APP_DROPDOWN_SHOW_LANGUAGES_DROPDOWN
    ;

const VUPEditLanguages = ({ languageData, handleAddLanguage, handleCloseModal }) => {
    const [language, setLanguage] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState({
        hobbies: [],
    });
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const { Cookies, setShowLoader } = useContext(UserContext);
    const [token] = useState(Cookies.get("token"));

    useEffect(() => {
        if (languageData) {
            setLanguage(languageData || "");
        }
        fetchDropdownOptions();
    }, [languageData]);

    useEffect(() => {
        validateForm();
    }, [language]);

    const fetchDropdownOptions = async () => {
        try {
            setShowLoader(true);

            const response = await axios.get(`${BASE_URL}${showLanguagesDropdown}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const languages = response.data.data;
            setDropdownOptions({
                languages: languages || [],
            });

            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setLanguage(value);
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        if (!language) {
            validationErrors.language = "**This field is required**";
            isValid = false;
        } else if (language.length > 50) {
            validationErrors.language = "**Language must be at most 50 characters**";
            isValid = false;
        }

        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedLanguage = {
            language: language || "",
        };

        handleAddLanguage(formattedLanguage);
    };

    return (
        <div className={styles.editLanguageContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteLanguage} />
                </IconButton>
            </Box>
            <h2 className={styles.editLanguageHeader}>Add Language</h2>
            <form onSubmit={handleSubmit} className={styles.editLanguageScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editLanguageInputs}>
                            <label htmlFor="language">Language</label>
                            <Autocomplete
                                id="language"
                                freeSolo
                                options={dropdownOptions?.languages}
                                value={
                                    dropdownOptions?.languages?.find(
                                        (option) => option.value === language
                                    ) || null
                                }
                                onChange={(e, value) => {
                                    handleChange({
                                        target: {
                                            value: value ? value.value : language,
                                        },
                                    });
                                }}
                                onInputChange={(e, value) => {
                                    handleChange({
                                        target: {
                                            value: value,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        sx={{
                                            height: 40,
                                            "& .MuiInputBase-root": {
                                                height: "100%",
                                            },
                                        }}
                                    />
                                )}
                            />
                            {error.language && <p className={styles.error}>{error.language}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButtonEditLanguage}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default VUPEditLanguages;
