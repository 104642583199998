import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Grid,
  Typography,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../Context/UserContext";
import TimerClock from "../../../CommonComponent/TimerClock/TimerClock";
import axios from "axios";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./StudentDashboard.module.css";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Chart } from "react-google-charts";
import { BASE_URL } from "./../../../../config/config";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const getLectures = process.env.REACT_APP_GET_LECTURES;
const getStudentWholeAttendance =
  process.env.REACT_APP_STUDENT_GET_WHOLE_ATTENDANCE;
const getNewsLetter = process.env.REACT_APP_HOD_GET_NEWSLETTER;

// Utility function to format the time
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const StudentDashboard = () => {
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [pieChartData, setPieChartData] = useState([["Task", "Hours per Day"]]);
  const [remainingTime, setRemainingTime] = useState(0);
  const { createAttendanceSocketConnection, attendanceSocket, facultyId, setShowLoader, getDecodedToken } = useContext(UserContext);
  const [lectureData, setLectureData] = useState([]);
  const { getToken } = useContext(UserContext);
  const [markedAttendanceLectures, setMarkedAttendanceLectures] = useState(new Set());
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLecture, setCurrentLecture] = useState(null);
  const [timerRunning, setTimerRunning] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [activeLectures, setActiveLectures] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState({});
  const [currentActiveLecture, setCurrentActiveLecture] = useState(null);
  const [locationData, setLocationData] = useState({
    latitude: '',
    longitude: '',
  });
  const [classroomDetails, setClassroomDetails] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    radiusInMeters: 20, // Geofence radius in meters
  });
  const [currentLat, setCurrentLat] = useState(null);
  const [currentLon, setCurrentLon] = useState(null);
  const [accuracy, setAccuracy] = useState(null); // Accuracy of the location
  const [status, setStatus] = useState("");
  const [distance, setDistance] = useState(0);
  const [radiusInMeters, setRadiusInMeters] = useState(30)
  const [accuracyThreshold, setAccuracyThreshold] = useState(50);
  const [altitudeThreshold, setAltitudeThreshold] = useState(50);
  const [checkLocation, setCheckLocation] = useState(false);
  const [allowMarkAttendance, setAllowMarkAttendance] = useState(false);
  const navigate = useNavigate();

  // Configurable Parameters
  const readingsCount = 5; // Number of geolocation readings to get stability
  const readingInterval = 1000; // Interval between readings in milliseconds
  // const radiusInMeters = 20; // Set radius in meters for proximity check
  // const accuracyThreshold = 50; // Threshold for accuracy, in meters
  // const altitudeThreshold = 50; // Threshold for altitude, in meters (if available)

  const checkLectureActive = (startTimeISO, endTimeISO) => {
    const currentTime = new Date();

    const currentHours = currentTime.getUTCHours();
    const currentMinutes = currentTime.getUTCMinutes();

    const startTime = new Date(startTimeISO);
    const startHours = startTime.getUTCHours();
    const startMinutes = startTime.getUTCMinutes();

    const endTime = new Date(endTimeISO);
    const endHours = endTime.getUTCHours();
    const endMinutes = endTime.getUTCMinutes();

    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;


    return (
      currentTotalMinutes >= startTotalMinutes &&
      currentTotalMinutes < endTotalMinutes
    );
  };

  const fetchLectures = async () => {
    try {
      const currentDate = new Date();
      const token = getToken();
      setShowLoader(true);

      const studentResponse = await axios.get(
        `${BASE_URL}${getLectures}?date=${currentDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);

      const lectures = studentResponse.data.data?.lectureData || [];
      setAccuracyThreshold(studentResponse.data.data?.accuracyThreshold || 50);
      setAltitudeThreshold(studentResponse.data.data?.altitudeThreshold || 50);
      setCheckLocation(studentResponse.data.data?.checkLocation || false);

      // If there are lectures, proceed to sort them based on whether they're active or not
      if (lectures.length > 0) {
        // Sort lectures based on whether they're active
        const sortedLectures = lectures.sort((a, b) => {
          const aIsActive = checkLectureActive(a.startTime, a.endTime);
          const bIsActive = checkLectureActive(b.startTime, b.endTime);

          // If `a` is active and `b` is not, `a` should come first, hence return -1
          if (aIsActive && !bIsActive) return -1;
          // If `b` is active and `a` is not, `b` should come first, hence return 1
          if (!aIsActive && bIsActive) return 1;
          return 0;
        });

        const firstLecture = sortedLectures[0];
        console.log("firstLecture ===>", firstLecture);
        setClassroomDetails({
          latitude: firstLecture.latitude,
          longitude: firstLecture.longitude,
          altitude: firstLecture.altitude,
          radiusInMeters: firstLecture?.radiusInMeters || 30
        });
        console.log("setClassroomDetails===>", {
          latitude: firstLecture.latitude,
          longitude: firstLecture.longitude,
          altitude: firstLecture.altitude,
          radiusInMeters: firstLecture?.radiusInMeters || 30
        });

        // Update lecture data with sorted lectures
        setLectureData(sortedLectures);
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };


  useEffect(() => {
    fetchLectures();
  }, []);


  // Haversine Distance Formula to calculate distance between two points on Earth
  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    console.log("Inside haversineDistance()");

    if (lat1 == null || lon1 == null || lat2 == null || lon2 == null) {
      console.error("Invalid coordinates for haversine distance calculation");
      return NaN;
    }

    const R = 6371e3; // Earth's radius in meters
    const toRadians = (deg) => (deg * Math.PI) / 180;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in meters
  };

  // Delay Utility Function
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to Verify User Location
  const verifyUserLocation = async () => {
    console.log("Inside verifyUserLocation()");
    Toast.info("Please wait while we are fetching your location!", 9000);
    setShowLoader(true);

    let latestLatitude = null;
    let latestLongitude = null;
    let latestAccuracy = null;
    let latestAltitude = null;

    let previousLatitude = null;
    let previousLongitude = null;
    let stablePrecision = null; // Precision to determine stability
    let callCount = 0; // Track the number of geolocation requests
    const maxCalls = 30; // Upper cap for maximum number of calls
    const minCalls = 5; // Minimum number of calls before we take a final reading

    const getLocation = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude, accuracy, altitude } = position.coords;
            resolve({ latitude, longitude, accuracy, altitude });
          },
          (error) => {
            reject(error);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      });

    try {
      while (callCount < maxCalls) {
        callCount++;
        const location = await getLocation();
        const { latitude, longitude, accuracy, altitude } = location;

        console.log(`Call #${callCount}:`, { latitude, longitude, accuracy, altitude });

        // Set precision dynamically based on decimal places in received latitude/longitude
        const latitudeStr = latitude.toString();
        const longitudeStr = longitude.toString();
        let currentPrecision = Math.max(
          latitudeStr.split(".")[1]?.length || 0,
          longitudeStr.split(".")[1]?.length || 0
        );

        // Adjust precision rules
        if (currentPrecision > 10) {
          stablePrecision = currentPrecision - 3; // Round to n-3 digits if more than 10 decimals
        } else if (currentPrecision >= 7) {
          stablePrecision = currentPrecision - 1; // Round to n-1 digits if exactly 7 decimals
        } else {
          stablePrecision = Math.max(7, currentPrecision - 1); // Default to at least 7 digits or n-1 digits
        }

        // Round to the stable precision
        const roundedLatitude = Number(latitude.toFixed(stablePrecision));
        const roundedLongitude = Number(longitude.toFixed(stablePrecision));

        // Check if the coordinates are stable
        if (
          previousLatitude !== null &&
          previousLongitude !== null &&
          roundedLatitude === previousLatitude &&
          roundedLongitude === previousLongitude
        ) {
          latestLatitude = latitude;
          latestLongitude = longitude;
          latestAccuracy = accuracy;
          latestAltitude = altitude;

          // Ensure minimum 5 checks before taking the final location
          if (callCount >= minCalls) {
            break;
          }
        }

        previousLatitude = roundedLatitude;
        previousLongitude = roundedLongitude;

        // Delay between calls to avoid spamming
        await delay(1000);
      }

      if (callCount >= maxCalls) {
        console.log(`Max calls reached: ${maxCalls}`);
        Toast.error("Maximum calls reached. Unable to stabilize location.");
        return;
      }

      console.log(`Stable location achieved after ${callCount} calls.`);
      Toast.success(`Stable location achieved after ${callCount} calls.`);

      // Calculate distance to classroom and verify attendance eligibility
      if (latestLatitude !== null && latestLongitude !== null && latestAccuracy !== null) {
        const distanceToClassroom = haversineDistance(
          latestLatitude,
          latestLongitude,
          classroomDetails.latitude,
          classroomDetails.longitude
        );
        console.log("Distance to Classroom:", distanceToClassroom);

        const isWithinRadius =
          distanceToClassroom <= radiusInMeters && latestAccuracy <= accuracyThreshold;

        const isAltitudeValid =
          latestAltitude !== null && classroomDetails.altitude !== null && classroomDetails.altitude !== 0
            ? Math.abs(latestAltitude - classroomDetails.altitude) <= altitudeThreshold
            : true;

        if (isWithinRadius && isAltitudeValid) {
          console.log("You are within the classroom area. Attendance marked!");
          Toast.success(
            `You are within ${distanceToClassroom.toFixed(
              2
            )} meters of the classroom. You can mark your attendance!`
          );
          setAllowMarkAttendance(true);
        } else {
          if (!isAltitudeValid) {
            console.log("Invalid altitude. Not at the same altitude as the classroom.");
            Toast.error("Your altitude is invalid. You are not at the same altitude as the classroom.");
          } else {
            console.log(
              `You are ${distanceToClassroom.toFixed(
                2
              )} meters away from the classroom. Attendance cannot be marked.`
            );
            Toast.error(
              `You are ${distanceToClassroom.toFixed(
                2
              )} meters away from the classroom. Attendance cannot be marked.`
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      Toast.error("Unable to fetch location. Please enable GPS.");
    } finally {
      setShowLoader(false);
    }
  };


  const handleMarkAttendance = async () => {

    // let check = await verifyUserLocation(currentLat, currentLon);
    // let check = await verifyUserLocation();
    let check = true;
    if (check) {
      handleMarkAttendance2();
    } else {
      Toast.error("Unable to mark attendance. Please check your location.");
    }
  };

  const getPiechartData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getStudentWholeAttendance}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      let subjectData = response?.data?.data?.subjectList;

      subjectData = subjectData.map((subjectData) => {
        return [
          `${subjectData.subjectName}`,
          Number(`${subjectData.presentCount}`),
        ];
      });

      subjectData.push([`Absent`, Number(response?.data?.data?.wholeAbsent)]);

      setPieChartData([...pieChartData, ...subjectData]);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    const stopper = setInterval(() => {
      if (lectureData.length > 0) {
        const activeLecturesList = lectureData.filter((OBJ) =>
          checkLectureActive(OBJ.startTime, OBJ.endTime)
        );
        setActiveLectures(activeLecturesList);

        // Set the current active lecture
        if (activeLecturesList.length > 0) {
          setCurrentActiveLecture(activeLecturesList[0]);
        }
      }
    }, 1000);

    return () => {
      clearInterval(stopper);
    };
  }, [lectureData]);

  useEffect(() => {
    if (remainingTime === 0) {
      // setIsAttendanceMarked(true);
      setTimerRunning(false);
    }
  }, [remainingTime]);

  useEffect(() => {
    fetchLectures();
  }, [facultyId]);

  // useEffect(() => { }, [activeLectures, currentActiveLecture]);

  useEffect(() => {
    getPiechartData();
  }, []);

  const handleOpen = async (lecture) => {
    console.log("lectures : ", lecture);

    setRadiusInMeters(lecture?.radiusInMeters || 30);
    console.log("after change lecture?.radiusInMeters : ", lecture?.radiusInMeters);
    console.log("lecture?.radiusInMeters || 30 : ", lecture?.radiusInMeters || 30);

    await setSelectedLecture(lecture);
    console.log("calling fetchStudentLocation()");

    // await fetchStudentLocation();
    if (checkLocation) {
      console.log("CHECK LOCATION TRUE===== >>>> ")
      await verifyUserLocation();
    } else {
      setAllowMarkAttendance(true);
    }

    if (attendanceSocket) {
      attendanceSocket?.disconnect();
    } else {
      await createAttendanceSocketConnection();
    }
    // createAttendanceSocketConnection();
    if (markedAttendanceLectures.has(lecture.lectureId)) return;
    setSelectedLecture(() => lecture);
    setCurrentLecture(lecture.lectureId);
    setOpen(true);
    // fetchLocation();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSecondModalClose = () => {
    setOpenSecondModal(false);
  };

  const joinRoom = () => {
    const userId = getDecodedToken()._id;

    attendanceSocket?.off(`${userId}`);
    attendanceSocket?.off("setTimeoutValue");
    attendanceSocket?.off(`${selectedLecture?.lectureId}`);
    attendanceSocket?.off(`${selectedLecture?.lectureId}-close`);

    // Set new event listeners
    attendanceSocket?.on(`${userId}`, async (data) => {
      Toast.success("Attendance Marked...🎉");
      setOpenSecondModal(false);
      setOpen(false);
    });

    attendanceSocket?.on("setTimeoutValue", (data) => {
      setTotalTime(() => Number(data.time));
      setOpenSecondModal(true);
    });

    attendanceSocket?.on(`${selectedLecture?.lectureId}`, (data) => {
      if (currentLecture === selectedLecture?.lectureId) {
        setRemainingTime(Number(data.time));
      }
    });

    attendanceSocket?.on(`${selectedLecture?.lectureId}-close`, () => {
      Toast.error("Times Up...❗❗");
      setCurrentLecture(null);
      setOpen(false);
      setOpenSecondModal(false);
    });

    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      timer: "1",
      userId: userId,
      batchId: selectedLecture?.batchId,
      sectionId: selectedLecture?.sectionId,
    };

    attendanceSocket?.emit("joinRoom", data);
  };
  const handleMarkAttendance2 = () => {
    if (attendanceStatus[selectedLecture?.lectureId]) {
      return;
    }

    const userId = getDecodedToken()._id;

    attendanceSocket.on(`${userId}-presentStatus`, (data) => {
      if (data === "P") {
        setAttendanceStatus((prev) => ({
          ...prev,
          [selectedLecture?.lectureId]: true,
        }));
      }
    });

    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      studentId: userId,
    };

    attendanceSocket?.emit("markAttendance", data);

    attendanceSocket?.on("attendanceData", () => {
      Toast.success("Attendance Marked...🎉");
      setMarkedAttendanceLectures((prevSet) =>
        new Set(prevSet).add(selectedLecture?.lectureId)
      );
    });
  };

  const pieChartOptions = {
    pieHole: 0.4,
    is3D: true,
    slices: [
      { color: "#FF7F50" }, // Coral (lighter shade of Vibrant Red-Orange)
      { color: "red" }, // Light Green (lighter shade of Bright Green)
      { color: "#6699FF" }, // Light Blue (lighter shade of Vibrant Blue)
      { color: "#F9E79F" }, // Light Yellow (lighter shade of Bright Yellow)
      { color: "#BB8FCE" }, // Light Purple (lighter shade of Purple)
      { color: "#F39C12" }, // Light Orange (lighter shade of Orange)
      { color: "#2ECC71" }, // Emerald Green (keeping as is)
      { color: "#D0E1E9" }, // Light Blue (lighter shade of Light Blue)
    ],
    // pieSliceText: "label",
    legend: {
      position: "none",
    },
    backgroundColor: "transparent",
  };

  const getColor = (index) => {
    const colors = [
      { color: "#FF7F50" }, // Coral (lighter shade of Vibrant Red-Orange)
      { color: "red" }, // Light Green (lighter shade of Bright Green)
      { color: "#6699FF" }, // Light Blue (lighter shade of Vibrant Blue)
      { color: "#F9E79F" }, // Light Yellow (lighter shade of Bright Yellow)
      { color: "#BB8FCE" }, // Light Purple (lighter shade of Purple)
      { color: "#F39C12" }, // Light Orange (lighter shade of Orange)
      { color: "#2ECC71" }, // Emerald Green (keeping as is)
      { color: "#D0E1E9" }, // Light Blue (lighter shade of Light Blue)
    ];
    return colors[index % colors.length];
  };

  const getNews = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BASE_URL}${getNewsLetter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.result) {
        setNewsList(response.data.data || []);
      } else {
        // setNewsList([]);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const itemsPerPage = 1;
  const totalPages = Math.ceil(newsList.length / itemsPerPage);

  const pageColors = [
    "#f0f8ff",
    "#ffe4e1",
    "#e6fffa",
    "#ffebcd",
    "#fffacd",
    "#f5f5dc",
    "#fafad2",
    "#d3d3d3",
    "#add8e6",
    "#90ee90",
    "#dcdcdc",
    "#f0fff0",
    "#ffb6c1",
    "#ff6347",
    "#ff4500",
  ];

  useEffect(() => {
    if (totalPages === 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1);
    }

    const intervalId = setInterval(() => {
      setCurrentPage((prevPage) => {
        if (totalPages === 0) return 0;
        return prevPage < totalPages ? prevPage + 1 : 1; // Loop back to page 1 when reaching last page
      });
    }, 7000);

    return () => clearInterval(intervalId);
  }, [totalPages]);

  const currentNewsItem = newsList[currentPage - 1]; // Adjusted for 1-based indexing

  // Handle next page
  const nextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const pageStyle = {
    backgroundColor: pageColors[currentPage] || "#ffffff",
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainDashboard}>
        <h1 className={styles.titleDashboard}>Today's Lectures</h1>
        <Box className={styles.cardsContainer}>
          <Box className={styles.lectureContainer}>
            <Grid container spacing={3}>
              {lectureData.length > 0 ? (
                lectureData.map((lecture, index) => (
                  <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                    <Card
                      className={
                        activeLectures.some(
                          (data) => data.lectureId === lecture.lectureId
                        )
                          ? styles.activeCard
                          : styles.cards
                      }
                      onClick={() => handleOpen(lecture)}
                    >
                      <Box className={styles.cardContent}>
                        <Typography variant="h6" className={styles.cardHeader}>
                          {lecture.subjectName}
                        </Typography>
                        <Typography variant="body2" className={styles.cardPara}>
                          Room No: {lecture.roomNo}
                        </Typography>
                        <Typography variant="body2" className={styles.cardPara}>
                          Type: {lecture.subjectType}
                        </Typography>
                        <div className={styles.lectureTime}>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Start Time: {formatTime(lecture.startTime)}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            End Time: {formatTime(lecture.endTime)}
                          </Typography>
                        </div>
                      </Box>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Box className={styles.noLectureContainer}>
                  <h2 className={styles.noLecture}>No lectures available</h2>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>
      </div>

      <Box className={styles.sideContainer}>
        <div className={styles.marqueeContainer}>
          <h2 className={styles.newsTitle}>News / Events</h2>
          <Box className={styles.marqueeScrollable} style={pageStyle}>
            <Box className={styles.newsContent}>
              {currentNewsItem ? (
                <div className={styles.contentInfo}>
                  <div className={styles.descContainer}>
                    <span>
                      <p>Title</p>
                      <p>{currentNewsItem.title}</p>
                    </span>
                    <span>
                      <p>Date</p>
                      <p>
                        {" "}
                        {new Date(currentNewsItem.date).toLocaleDateString()}
                      </p>
                    </span>
                    <span>
                      <p>Details</p>
                      <p>{currentNewsItem.description}</p>
                    </span>
                  </div>
                </div>
              ) : (
                <p className={styles.noData}>No news available.</p>
              )}
            </Box>
          </Box>

          <Box
            className={styles.paginationContainer}
            display="flex"
            alignItems="center"
          >
            <IconButton
              className={styles.pageButton}
              onClick={prevPage}
              disabled={currentPage === 1 || totalPages === 0}
            >
              <ArrowBackIosIcon />
            </IconButton>

            <h4 style={{ fontStyle: "italic" }}>
              {totalPages === 0 ? "0 of 0" : `${currentPage} of ${totalPages}`}
            </h4>

            <IconButton
              className={styles.pageButton}
              onClick={nextPage}
              disabled={currentPage === totalPages || totalPages === 0}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </div>

        <Box className={styles.pieChartContainer}>
          <h2 className={styles.pieChartTitle}>Total Attendance</h2>
          <div
            onClick={() => {
              navigate("./attendance");
            }}
          >
            <Chart
              className={styles.pichart}
              chartType="PieChart"
              data={pieChartData}
              options={pieChartOptions}
            />
          </div>
          <Box className={styles.customLegend}>
            {pieChartData.slice(1).map(([label, value], index) => (
              <Box key={index} className={styles.legendItem}>
                <span
                  className={styles.legendColor}
                  style={{ backgroundColor: getColor(index).color }}
                ></span>
                <span className={styles.legendLabel}>{label}</span>
              </Box>
            ))}
          </Box>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            className={styles.calendar}
            sx={{
              "& .MuiPickersDay-root": {
                color: "white",
              },
              "& .Mui-selected": {
                backgroundColor: "yourSelectedColor",
              },
              "& .MuiIconButton-root": {
                color: "white",
              },
              "& .MuiTypography-root": {
                color: "white",
              },
            }}
          />{" "}
        </LocalizationProvider>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleClose} className={styles.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedLecture && (
              <Box className={styles.modalContent}>
                <h2 className={styles.modalSubjectHeader}>
                  {selectedLecture.subjectName}
                </h2>
                <h3 className={styles.modalSubCodeHeader}>
                  ({selectedLecture.subjectCode})
                </h3>
                <p className={styles.modalPara}>
                  Course Code: {selectedLecture.courseCode}
                </p>
                <p className={styles.modalPara}>
                  Course Name: {selectedLecture.courseName}
                </p>
                <p className={styles.modalPara}>
                  Room No: {selectedLecture.roomNo}
                </p>
                <p className={styles.modalPara}>
                  Type: {selectedLecture.subjectType}
                </p>
                <p className={styles.modalPara}>Day: {selectedLecture.day}</p>
                <p className={styles.modalPara}>
                  Start Time: {formatTime(selectedLecture.startTime)}
                </p>
                <p className={styles.modalPara}>
                  End Time: {formatTime(selectedLecture.endTime)}
                </p>
                <p className={styles.modalPara}>
                  {selectedLecture.facultyName}
                </p>

                {currentActiveLecture?.lectureId ===
                  selectedLecture?.lectureId ? (
                  <Button
                    className={styles.modalButton}
                    variant="contained"
                    color="primary"
                    disabled={!allowMarkAttendance}
                    onClick={() => {
                      joinRoom();
                    }}
                  >
                    Join Lecture
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.modalInactiveButton}
                    disabled
                  >
                    Lecture Inactive
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal open={openSecondModal} onClose={handleSecondModalClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.lectureModal}>
            <Box className={styles.closeIconContainer}>
              <IconButton>
                <CloseIcon
                  onClick={handleSecondModalClose}
                  className={styles.closeIconSecondModal}
                />
              </IconButton>
            </Box>
            <h2 className={styles.lectureTimeHeader}>
              {currentActiveLecture?.lectureId === selectedLecture?.lectureId
                ? "Time is Ticking"
                : "This Lecture is inactive"}
            </h2>
            {currentActiveLecture?.lectureId === selectedLecture?.lectureId &&
              remainingTime !== null && (
                <TimerClock
                  className={styles.timer}
                  remainingTime={remainingTime}
                  initTimer={totalTime}
                />
              )}
            {remainingTime > 0 &&
              !attendanceStatus[selectedLecture?.lectureId] &&
              currentActiveLecture?.lectureId ===
              selectedLecture?.lectureId && (
                <Button
                  className={styles.markAttendanceButton}
                  variant="contained"
                  color="primary"
                  disabled={!allowMarkAttendance}
                  onClick={handleMarkAttendance}
                >
                  Mark Attendance
                </Button>
              )}
            {remainingTime > 0 &&
              attendanceStatus[selectedLecture?.lectureId] &&
              currentActiveLecture?.lectureId ===
              selectedLecture?.lectureId && (
                <Typography className={styles.attendanceStatus}>
                  You have already marked your attendance
                </Typography>
              )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StudentDashboard;