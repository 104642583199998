import React, { useContext, useEffect, useState } from "react";
import styles from "./ClassroomList.module.css";
import { Box, Button, Grid, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import TableComponent from "../../CommonTable/CommonTable";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";
import Toast from "../../ToastContainer/ToastContainer";
import Dropdown from "../../DropDown/DropDown";

const dropdownClassroomRegister = process.env.REACT_APP_ADMIN_DROPDOWN_CLASSROOM;
const getClassrooms = process.env.REACT_APP_ADMIN_GET_CLASSROOMS;
const updateClassroom = process.env.REACT_APP_ADMIN_UPDATE_CLASSROOM;
const deleteClassroom = process.env.REACT_APP_ADMIN_DELETE_CLASSROOM;

const ClassroomList = () => {
    const { getToken, setShowLoader, getUserRole } =
        useContext(UserContext);
    const [formData, setFormData] = useState({
        roomNo: "",
        roomType: "",
        floorNo: "",
        latitude: "",
        longitude: "",
        altitude: "",
        radius: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [classroomData, setClassroomData] = useState([]);
    const [filteredClassroomData, setFilteredClassroomData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [dropDownData, setDropDownData] = useState({
        roomTypes: [],
    });
    const [locationData, setLocationData] = useState({
        latitude: '',
        longitude: '',
        altitude: '',
    });
    const navigate = useNavigate();

    const columns = [
        "Room Type",
        "Room Number",
        "Floor Number",
        "Latitude",
        "Longitude",
        "Altitude",
        "Radius",
    ];

    const handleAddClassroom = () => {
        navigate(`/${getUserRole()}/classrooms/add-classroom`);
    };

    useEffect(() => {
        validateForm();
    }, [formData]);

    const fetchClassroomData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getClassrooms}`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });
            if (response.data.result) {
                const transformedRows = response?.data?.data?.map((classroom) => ({
                    ...classroom,
                    "Room Type": classroom.roomType || "N/A",
                    "Room Number": classroom.roomNo || "N/A",
                    "Floor Number": classroom.floorNo || "N/A",
                    "Latitude": classroom.latitude || "N/A",
                    "Longitude": classroom.longitude || "N/A",
                    "Altitude": classroom.altitude || "N/A",
                    "Radius": classroom.radius || "N/A",
                }));
                setClassroomData(transformedRows);
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    const fetchDropdown = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(
                `${BASE_URL}${dropdownClassroomRegister}`,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );
            if (response.data.result) {
                setDropDownData(response.data.data);
            }
        } catch (error) {
            Toast.error("Failed to fetch room types.");
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        fetchClassroomData();
        fetchDropdown();
    }, []);

    /*   const fetchLocation = () => {
          if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                  (position) => {
                      const { latitude, longitude, altitude } = position.coords;
  
                      const altitudeValue = altitude !== null ? altitude : ''; // Use '' or 0 as fallback
  
                      setLocationData({
                          latitude,
                          longitude,
                          altitude: altitudeValue,
                      });
  
                      setFormData({
                          ...formData,
                          latitude: latitude.toString(),
                          longitude: longitude.toString(),
                          altitude: altitudeValue ? altitudeValue.toString() : '',
                      });
                  },
                  (error) => {
                      Toast.error("Unable to retrieve your location.");
                  }
              );
          } else {
              Toast.error("Geolocation is not supported by this browser.");
          }
      }; */

    // Delay Utility Function
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Function to Get Current Location Readings
    const fetchLocation = async () => {
        console.log("Inside fetchLocation()");
        Toast.info("Please wait while we are fetching your location!", 9000);
        setShowLoader(true);

        let latestLatitude = null;
        let latestLongitude = null;
        let latestAccuracy = null;
        let latestAltitude = null;

        let previousLatitude = null;
        let previousLongitude = null;
        let stablePrecision = null; // Precision to determine stability
        let callCount = 0; // Track the number of geolocation requests
        const maxCalls = 30; // Upper cap for maximum number of calls
        const minCalls = 5; // Minimum number of calls before we take a final reading

        const getLocation = () =>
            new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude, accuracy, altitude } = position.coords;
                        resolve({ latitude, longitude, accuracy, altitude });
                    },
                    (error) => {
                        reject(error);
                    },
                    { enableHighAccuracy: true, maximumAge: 0 }
                );
            });

        try {
            while (callCount < maxCalls) {
                callCount++;
                const location = await getLocation();
                const { latitude, longitude, accuracy, altitude } = location;

                console.log(`Call #${callCount}:`, { latitude, longitude, accuracy, altitude });

                // Set precision dynamically based on decimal places in received latitude/longitude
                const latitudeStr = latitude.toString();
                const longitudeStr = longitude.toString();
                let currentPrecision = Math.max(
                    latitudeStr.split(".")[1]?.length || 0,
                    longitudeStr.split(".")[1]?.length || 0
                );

                // Adjust precision rules
                if (currentPrecision > 10) {
                    stablePrecision = currentPrecision - 3; // Round to n-3 digits if more than 10 decimals
                } else if (currentPrecision >= 7) {
                    stablePrecision = currentPrecision - 1; // Round to n-1 digits if exactly 7 decimals
                } else {
                    stablePrecision = Math.max(7, currentPrecision - 1); // Default to at least 7 digits or n-1 digits
                }

                // Round to the stable precision
                const roundedLatitude = Number(latitude.toFixed(stablePrecision));
                const roundedLongitude = Number(longitude.toFixed(stablePrecision));

                // Check if the coordinates are stable
                if (
                    previousLatitude !== null &&
                    previousLongitude !== null &&
                    roundedLatitude === previousLatitude &&
                    roundedLongitude === previousLongitude
                ) {
                    latestLatitude = latitude;
                    latestLongitude = longitude;
                    latestAccuracy = accuracy;
                    latestAltitude = altitude;

                    // Ensure minimum 5 checks before taking the final location
                    if (callCount >= minCalls) {
                        break;
                    }
                }

                previousLatitude = roundedLatitude;
                previousLongitude = roundedLongitude;

                // Delay between calls to avoid spamming
                await delay(1000);
            }

            if (callCount >= maxCalls) {
                console.log(`Max calls reached: ${maxCalls}`);
                Toast.error("Maximum calls reached. Unable to stabilize location.");
                return;
            }

            console.log(`Stable location achieved after ${callCount} calls.`);
            Toast.success(`Stable location achieved after ${callCount} calls.`);

            // Calculate distance to classroom and verify attendance eligibility
            if (latestLatitude !== null && latestLongitude !== null && latestAccuracy !== null) {

                setLocationData({
                    latestLatitude,
                    latestLongitude,
                    altitude: latestAltitude ? latestAltitude : 0,
                });

                setFormData({
                    ...formData,
                    latitude: latestLatitude,
                    longitude: latestLongitude,
                    altitude: latestAltitude ? latestAltitude : 0,
                });

            }
        } catch (error) {
            console.error("Error fetching location:", error);
            Toast.error("Unable to fetch location. Please enable GPS.");
        } finally {
            setShowLoader(false);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        validateForm();
    };

    const validateForm = () => {
        let validationErrors = {};
        const requiredFields = [
            "roomType",
            "roomNo",
            "floorNo",
            "latitude",
            "longitude",
            "altitude",
            "radius",
        ];

        requiredFields.forEach((field) => {
            const value = formData[field];
            // Check if value is a string before using .trim()
            if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
                validationErrors[field] = "**This field is required**";
            } else if (typeof value === "string" && value.trim() === "") {
                validationErrors[field] = "**This field is required**";
            }
        });

        if (formData.floorNo && (formData.floorNo < 1 || formData.floorNo > 10)) {
            validationErrors["floorNo"] = "**Floors must be between 1 and 10**";
        }

        const longitude = parseFloat(formData.longitude);
        const latitude = parseFloat(formData.latitude);
        const radius = parseFloat(formData.radius);

        if (longitude < -180 || longitude > 180) {
            validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
        }

        if (latitude < -90 || latitude > 90) {
            validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
        }

        if (!formData.altitude || formData.altitude <= 0) {
            validationErrors["altitude"] = formData.altitude ? "**Altitude must be greater than 0**" : "**This field is required**";
        }

        if (radius < 0) {
            validationErrors["radius"] = "**Radius must be a positive number**";
        } else if (radius > 10000) {
            validationErrors["radius"] = "**Radius must be less than or equal to 10,000 kilometers**";
        }

        setErrors(validationErrors);
        setIsSaveDisabled(Object.keys(validationErrors).length > 0);
    };

    const handleEdit = (row) => {
        setCurrentRow(row);
        setFormData({
            roomNo: row.roomNo,
            roomType: row.roomType,
            floorNo: row.floorNo,
            latitude: row.latitude,
            longitude: row.longitude,
            altitude: row.altitude,
            radius: row.radius,
        });
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setFormData({
            roomType: "",
            roomNo: "",
            floorNo: "",
            latitude: "",
            longitude: "",
            altitude: "",
            radius: "",
        });
        setErrors({});
    };

    const handleSave = async (e) => {
        e.preventDefault();
        validateForm();

        try {
            setShowLoader(true);

            const updatedFormData = {
                ...formData,
                roomId: currentRow?.roomId,
            };

            const response = await axios.put(
                `${BASE_URL}${updateClassroom}`,
                updatedFormData,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );

            if (response.data.result) {
                Toast.success("Classroom updated successfully.");
                setOpen(false);
                fetchClassroomData();
            } else {
                Toast.error(response?.data?.message || "Failed to update classroom.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    const handleDelete = async (roomData) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(
                `${BASE_URL}${deleteClassroom}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                    data: { roomId: roomData.roomId },
                }
            );

            if (response.data.result) {
                Toast.success("Classroom deleted successfully.");
                fetchClassroomData();
            } else {
                Toast.error(response?.data?.message || "Failed to delete classroom.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleChangeSearch = (e) => {
        setSearchText(() => e.target.value);
    };

    useEffect(() => {
        const data = classroomData?.filter((classroom) =>
            String(classroom?.roomNo).toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredClassroomData(data);
    }, [classroomData, searchText]);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Department Classrooms</h1>
            <Box className={styles.classroomParentContainer}>
                <Box className={styles.inputParentContainer}>
                    <Box className={styles.inputContainer}>
                        <label htmlFor="searchClassroom" className={styles.searchLabel}>
                            Search Room No.
                        </label>
                        <input
                            id="searchClassroom"
                            className={styles.searchClassroom}
                            variant="outlined"
                            onChange={handleChangeSearch}
                        />
                    </Box>

                    <Box className={styles.btnContainer}>
                        <Button
                            variant="contained"
                            className={styles.addCourseButton}
                            onClick={handleAddClassroom}
                        >
                            Add Classroom
                        </Button>
                    </Box>
                </Box>

                {classroomData && classroomData.length > 0 ? (
                    <TableComponent
                        columns={columns}
                        rows={filteredClassroomData}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                    />
                ) : (
                    <h2 className={styles.noData}>
                        No Classroom data available. You can add a new classroom.
                    </h2>
                )}

                <Modal className={styles.modal} open={open} onClose={handleClose}>
                    <Box className={styles.updateClassroomContainer}>
                        <Box className={styles.closeIconContainer}>
                            <IconButton>
                                <CloseIcon
                                    className={styles.closeButton}
                                    onClick={handleClose}
                                />
                            </IconButton>
                        </Box>

                        <h2 className={styles.updateClassroomHeader}>EDIT CLASSROOM</h2>
                        <div className={styles.updateClassroomScrollable}>
                            <form className={styles.formGroup}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="roomType">Room Type</label>
                                            <Dropdown
                                                id={"roomType"}
                                                value={formData.roomType}
                                                options={dropDownData.roomTypes}
                                                name={"roomType"}
                                                onChange={handleChange}
                                            />
                                            {errors.roomType && (
                                                <p className={styles.error}>{errors.roomType}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="roomNo">Room Number</label>
                                            <input
                                                type="text"
                                                id="roomNo"
                                                placeholder="Room No."
                                                value={formData.roomNo}
                                                name="roomNo"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.roomNo && <p className={styles.error}>{errors.roomNo}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="floorNo">Floor Number</label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                id="floorNo"
                                                placeholder="Floor No."
                                                value={formData.floorNo}
                                                name="floorNo"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.floorNo && <p className={styles.error}>{errors.floorNo}</p>}
                                        </div>
                                    </Grid>

                                    <div className={styles.locationButtonContainer}>
                                        <Button className={styles.locationButton} onClick={fetchLocation} variant="contained" color="primary">
                                            Get Current Location
                                        </Button>
                                    </div>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="latitude">
                                                Latitude <span className={styles.span}>(Degrees in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                id="latitude"
                                                placeholder="Latitude"
                                                value={formData.latitude}
                                                name="latitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="longitude">
                                                Longitude <span className={styles.span}>(Degrees in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                id="longitude"
                                                placeholder="Longitude"
                                                value={formData.longitude}
                                                name="longitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="altitude">
                                                Altitude <span className={styles.span}>(Meters in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                id="altitude"
                                                placeholder="Altitude"
                                                value={formData.altitude}
                                                name="altitude"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="radius">
                                                Radius <span className={styles.span}>(Meters in number)</span>
                                            </label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                id="radius"
                                                placeholder="Radius"
                                                value={formData.radius}
                                                name="radius"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.radius && <p className={styles.error}>{errors.radius}</p>}
                                        </div>
                                    </Grid>

                                </Grid>

                                <Grid item xs={12} className={styles.submitButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSave}
                                        disabled={isSaveDisabled}
                                    >
                                        Save
                                    </Button>
                                </Grid>

                            </form>
                        </div>
                    </Box>
                </Modal>
            </Box>
        </div>
    );
};

export default ClassroomList;
