import React, { useContext, useState, useEffect } from "react";
import { Button, TextField, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./EditSkills.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import UserContext from "../../../../../../Context/UserContext";
import DropDown from "../../../../DropDown/DropDown";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../../config/config";

const showSkillsDropdown = process.env.REACT_APP_DROPDOWN_SHOW_SKILLS_DROPDOWN;

const EditSkills = ({ skillData, handleAddSkill, handleCloseModal }) => {
  const [skill, setSkill] = useState({ skill: "", level: "", type: "" });
  const [dropdownOptions, setDropdownOptions] = useState({
    skills: [],
    skillLevels: [],
    skillTypes: [],
  });
  const [error, setError] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const { Cookies, setShowLoader } = useContext(UserContext);
  const [token] = useState(Cookies.get("token"));

  useEffect(() => {
    if (skillData) {
      setSkill(skillData);
    }
    fetchDropdownOptions();
  }, [skillData]);

  useEffect(() => {
    validateForm();
  }, [skill]);

  const fetchDropdownOptions = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${showSkillsDropdown}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { skills, skillLevels, skillTypes } = response.data.data;
      setDropdownOptions({
        skills: skills || [],
        skillLevels: skillLevels || [],
        skillTypes: skillTypes || [],
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSkill((prevSkill) => ({
      ...prevSkill,
      [name]: value,
    }));
  };

  const handleCustomChange = (e, fieldName) => {
    setSkill((prevSkill) => ({
      ...prevSkill,
      [fieldName]: e.target.value,
    }));
  };

  const validateForm = () => {
    let validationErrors = {};
    let isValid = true;

    if (!skill.skill) {
      validationErrors.skill = "**This field is required**";
      isValid = false;
    } else if (skill.skill.length > 50) {
      validationErrors.skill = "**Skill must be at most 50 characters**";
      isValid = false;
    }

    if (!skill.level) {
      validationErrors.level = "**This field is required**";
      isValid = false;
    }
    if (!skill.type) {
      validationErrors.type = "**This field is required**";
      isValid = false;
    }

    setError(validationErrors);
    setIsSaveDisabled(!isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateForm();

    if (isSaveDisabled) {
      return;
    }

    const formattedSkill = {
      skill:
        typeof skill.skill === "object"
          ? skill.skill.inputValue || skill.skill.value
          : skill.skill,
      level: skill.level || "",
      type: skill.type || "",
    };

    handleAddSkill(formattedSkill);
  };

  return (
    <div className={styles.editSkillContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon className={styles.deleteSkill} />
        </IconButton>
      </Box>
      <h2 className={styles.editSkillHeader}>Add Skill</h2>
      <form onSubmit={handleSubmit} className={styles.editSkillScrollable}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={styles.editSkillInputs}>
              <label htmlFor="skill">Skill</label>
              <Autocomplete
                id="skill"
                freeSolo
                options={dropdownOptions.skills}
                value={
                  dropdownOptions.skills.find(
                    (option) => option.value === skill.skill
                  ) || null
                }
                onChange={(e, value) => {
                  handleChange({
                    target: {
                      name: "skill",
                      value: value ? value.value : skill.skill,
                    },
                  });
                }}
                onInputChange={(e, value) => {
                  handleCustomChange(e, "skill");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    sx={{
                      height: 40,
                      "& .MuiInputBase-root": {
                        height: "100%",
                      },
                    }}
                  />
                )}
              />
              {error.skill && <p className={styles.error}>{error.skill}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editSkillInputs}>
              <label htmlFor="level">Level</label>
              <DropDown
                id="level"
                name="level"
                options={dropdownOptions.skillLevels}
                value={skill.level}
                onChange={handleChange}
                showCustomInput={false}
                customValue=""
                onCustomChange={(e) => handleCustomChange(e, "level")}
                customInputLabel=""
                showOtherOption={false}
              />
              {error.level && <p className={styles.error}>{error.level}</p>}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.editSkillInputs}>
              <label htmlFor="type">Type</label>
              <DropDown
                id="type"
                name="type"
                options={dropdownOptions.skillTypes}
                value={skill.type}
                onChange={handleChange}
                showCustomInput={false}
                customValue=""
                onCustomChange={(e) => handleCustomChange(e, "type")}
                customInputLabel=""
                showOtherOption={false}
              />
              {error.type && <p className={styles.error}>{error.type}</p>}
            </div>
          </Grid>

          <Grid item xs={12} className={styles.submitButtonEditSkill}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditSkills;
