import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./CreateGroup.module.css";
import { Avatar, Box, Button } from "@mui/material";
import { FaCommentDots } from "react-icons/fa";
import GroupChat from "../GroupChat/GroupChat.jsx";
import { useContext } from "react";
import UserContext from "../../../../../Context/UserContext";
import { jwtDecode } from "jwt-decode";
import Toast from "../../../ToastContainer/ToastContainer";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { BASE_URL } from "./../../../../../config/config";

const chatGetUserList = process.env.REACT_APP_CHAT_GROUP_USERLIST;
const chatGetGroupList = process.env.REACT_APP_CHAT_GET_GROUPLIST;
const chatCreateGroup = process.env.REACT_APP_CHAT_CREATE_GROUP;
const chatUpdateGroupMessageCount =
  process.env.REACT_APP_CHAT_UPDATE_GROUP_MESSAGE_COUNT;

const CreateGroup = ({
  onClose,
  groupMessageCount,
  fetchGroupMessageCount,
}) => {
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupNameError, setGroupNameError] = useState("");
  const [userSelectionError, setUserSelectionError] = useState("");
  const [showNewGroupComponent, setShowNewGroupComponent] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const {
    groupSocket,
    handleGroupMessageCall,
    creategroupSocketClient,
    GroupMessageCounts,
    handleGroupNameClick1,
    getDecodedToken,
    Cookies,
    getToken,
    setShowLoader,
  } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");

  const decodedToken = jwtDecode(Cookies.get("token"));
  const isstudent = decodedToken.role === "student";

  const fetchUserData = async () => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${chatGetUserList}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUsers(response?.data.userList);
      setLoadingUsers(false);
      setShowLoader(false);
    } catch (error) {
      setLoadingUsers(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
      setShowLoader(false);
    }
  };

  const fetchUserListForGroupCreation = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${chatGetUserList}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        setUsers(response?.data?.userList || []);
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }

  }
  const fetchGroupData = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${chatGetGroupList}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      setGroups(response?.data?.data);
      setFilteredGroups(response?.data?.data);
      setLoadingGroups(false);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");

      setLoadingGroups(false);
    }
  };
  useEffect(() => {
    // fetchUserData();
    fetchGroupData();
  }, []);

  useEffect(() => {
    fetchUserListForGroupCreation();
  }, [])
  // useEffect(() => { }, [users]);


  // const filteredGroups = groups?.filter((group) => {
  //   return group.groupName.toLowerCase().includes(searchTerm.toLowerCase());
  // });

  const handleCheckboxChange = (event, email) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedUsers((prevState) => [...prevState, email]);
    } else {
      setSelectedUsers((prevState) =>
        prevState?.filter((selectedEmail) => selectedEmail !== email)
      );
    }
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    setGroupNameError("");
  };

  const handleCreateGroup = async () => {
    if (selectedUsers.length === 0) {
      setUserSelectionError("**Please select at least one user**");
      return;
    } else {
      setUserSelectionError("");
    }

    if (groupName.trim() === "") {
      setGroupNameError("**Group name cannot be empty**");
      return;
    } else {
      setGroupNameError("");
    }

    try {
      const token = Cookies.get("token");
      const selectedUserData = users
        ?.filter((data) => selectedUsers.includes(data.email))
        .map((data) => ({
          name: `${data.fname} ${data.lname}`,
          email: data.email,
        }));

      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${chatCreateGroup}`,
        { groupName, users: selectedUserData },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      onClose();
      Toast.success(response?.data?.message || "Success!");
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const handleShowNewGroupComponent = () => {
    setShowNewGroupComponent(true);
  };


  const handleSocketConnection = (groupId) => {
    const userId = getDecodedToken().email;
    groupSocket?.emit("joinRoom", { groupId, userId });
  };
  const handleGroupNameClick = (group) => {
    // fetchUserListForGroupCreation();
    // creategroupSocketClient();
    setSelectedGroup(group);
  };


  const handleCloseGroupChatBox = () => {
    if (groupSocket) {
      groupSocket?.emit("leaveRoom", { groupId: selectedGroup._id, userId: getDecodedToken().email });
    }
    setSelectedGroup(null);
  };

  const handleUpdateGroupMessageCount = async (groupId) => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${chatUpdateGroupMessageCount}`,
        { groupId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleGroupMessageCount = () => {
    // fetchGroupMessageCount();
  };

  const handleGroupNameClickass = (group) => {
    handleGroupNameClick1(group);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setFilteredGroups(groups)
    } else {
      const filtered = groups?.filter(group => String(group.groupName).toLowerCase().includes(value))
      setFilteredGroups(filtered)
    }
    setSearchTerm(value);
  };

  const handleJoinGroup = (groupData) => {
    if (groupSocket) {
      groupSocket?.emit("joinRoom", { groupId: groupData._id, userId: getDecodedToken().email });
    }
  }

  // const handleLeaveRoom = async(groupData) => {

  // }
  const handleGroupOpen = (groupData) => {

    setSelectedGroup(groupData)
    handleJoinGroup(groupData);
    // handleSocketConnection()

  }

  return (
    <div className={styles.createGroupContainer}>
      <Box className={styles.closeIconContainer}>
        <h2 className={styles.createGroupContainerTitle}>Groups</h2>
        <Box className={styles.closeIconContainerIcons}>
          {!isstudent && (
            <AddIcon
              className={styles.createGroupButton}
              onClick={handleShowNewGroupComponent}
            />
          )}
          <CloseIcon onClick={onClose} className={styles.closeIcon} />
        </Box>
      </Box>

      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search group..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <SearchIcon className={styles.searchIcon} />
      </div>

      {loadingGroups ? (
        <p className={styles.loadingText}>Loading groups...</p>
      ) : (
        <div className={styles.chatGroups}>
          {filteredGroups?.map((group) => {
            const groupId = group?._id;
            const countData = groupMessageCount?.find(
              (item) => item.groupId === groupId
            );
            const count = countData?.count || 0;
            // const totalCount = count + (GroupMessageCounts[groupId] || 0);
            // const isActiveGroup = selectedGroup?._id === groupId;

            // const handleClick = () => {
            //   handleGroupNameClick(group);
            //   // handleGroupMessageCall(group);
            //   // handleUpdateGroupMessageCount(groupId);
            //   // handleGroupMessageCount();
            //   // handleGroupNameClickass(groupId);
            //   handleSocketConnection(groupId);
            // };

            return (
              <div
                key={groupId}
                className={styles.groupListContainer}
                onClick={() => handleGroupOpen(group)}
              >
                <Avatar
                  style={{
                    backgroundColor: "#69c",
                    height: "20px",
                    width: "20px",
                    padding: "8px",
                    fontSize: "16px",
                  }}
                >
                  {group.groupName[0].toUpperCase()}
                </Avatar>
                {group.groupName}
                <FaCommentDots style={{ color: "#69c", fontSize: "20px" }} />
                {/* {!isActiveGroup && totalCount > 0 && (
                  <div className="count">{totalCount}</div>
                )} */}
              </div>
            );
          })}
        </div>
      )}

      {showNewGroupComponent && (
        <div className={styles.createNewGroupContainer}>
          <Box className={styles.closeIconCreateNewGroupContainer}>
            <h2 className={styles.createNewGroupContainerTitle}>
              Create Group
            </h2>
            <CloseIcon
              onClick={() => setShowNewGroupComponent(false)}
              className={styles.closeButton}
            />
          </Box>

          <div className={styles.createNewGroupMembersList}>
            <h3 className={styles.createNewGroupMembersListTitle}>
              Select Members
            </h3>

            <div className={styles.members}>
              {users?.map((user) => (
                <label key={user.id} className={styles.label}>
                  <input
                    className={styles.checkBox}
                    type="checkbox"
                    checked={selectedUsers.includes(user.email)}
                    onChange={(e) => handleCheckboxChange(e, user.email)}
                  />
                  {user.fname} {user.lname}
                </label>
              ))}

              <div className={styles.inputGroupNameContainer}>
                <input
                  type="text"
                  value={groupName}
                  onChange={handleGroupNameChange}
                  placeholder="Set Your Group Name"
                  className={styles.inputGroupName}
                />
              </div>
              {userSelectionError && (
                <p className={styles.errorMessage}>{userSelectionError}</p>
              )}

              {groupNameError && (
                <p className={styles.errorMessage}>{groupNameError}</p>
              )}
            </div>

            <div className={styles.confirmGroupButtonContainer}>
              <Button
                variant="contained"
                className={styles.confirmGroupButton}
                onClick={handleCreateGroup}
              >
                Create Group
              </Button>
            </div>
          </div>
        </div>
      )}

      {selectedGroup && (
        <GroupChat
          selectedGroup={selectedGroup}
          onClose={handleCloseGroupChatBox}
        // onOpen={() => handleGroupNameClick1(selectedGroup)}
        />
      )}
    </div>
  );
};

export default CreateGroup;
