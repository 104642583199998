import React from "react";
import styles from "./ViewDocument.module.css"; // Import your modal styles
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ViewDocument = ({ isOpen, onClose, documentUrl }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Box className={styles.closeIconContainer}>
          <IconButton
            className={styles.closeButtonEditEducation}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {documentUrl ? (
          <iframe
            src={documentUrl}
            title="Resume Preview"
            className={styles.modalIframe}
          />
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default ViewDocument;
