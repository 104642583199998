import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Card,
  Grid,
  Typography,
  Modal,
  IconButton,
  Box,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import styles from "./HODDashboard.module.css";
import UserContext from "../../../../Context/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import TimerClock from "../../../CommonComponent/TimerClock/TimerClock";
import { BASE_URL } from "./../../../../config/config";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";

const getFacultyLectures = process.env.REACT_APP_GET_FACULTY_LECTURES;
const postNewsLetter = process.env.REACT_APP_HOD_POST_NEWSLETTER;
const getNewsLetter = process.env.REACT_APP_HOD_GET_NEWSLETTER;
const deleteNewsLetter = process.env.REACT_APP_HOD_DELETE_NEWSLETTER;

const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const HODDashboard = () => {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null);
  const [totalTime, setTotalTime] = useState(0);
  const [currentLecture, setCurrentLecture] = useState(null);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectureData, setLectureData] = useState([]);
  const [activeLectures, setActiveLectures] = useState([]);
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [markedAttendanceLectures, setMarkedAttendanceLectures] = useState(
    new Set()
  );
  const prevActiveLectures = useRef([]);

  const {
    Cookies,
    setShowLoader,
    createAttendanceSocketConnection,
    attendanceSocket,
    getToken,
    showConfirmation,
    getDecodedToken,
  } = useContext(UserContext);

  const checkLectureActive = (startTimeISO, endTimeISO) => {
    const currentTime = new Date();

    // Extract the time parts from the current time
    const currentHours = currentTime.getUTCHours();
    const currentMinutes = currentTime.getUTCMinutes();

    // Parse the ISO strings to get the time parts
    const startTime = new Date(startTimeISO);
    const startHours = startTime.getUTCHours();
    const startMinutes = startTime.getUTCMinutes();

    const endTime = new Date(endTimeISO);
    const endHours = endTime.getUTCHours();
    const endMinutes = endTime.getUTCMinutes();

    // Convert everything to minutes since the start of the day
    const currentTotalMinutes = currentHours * 60 + currentMinutes;
    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;

    // Check if the current time is within the range
    return (
      currentTotalMinutes >= startTotalMinutes &&
      currentTotalMinutes < endTotalMinutes
    );
  };

  useEffect(() => {
    // The interval will run every second
    const stopper = setInterval(() => {
      if (lectureData.length > 0) {
        let actives = [];

        // Iterate over each lecture and check if it's active
        lectureData.forEach((OBJ) => {
          const startTimeISO = OBJ.startTime; // Start time: July 9, 2024, 15:00:00 UTC
          const endTimeISO = OBJ.endTime;
          const check = checkLectureActive(startTimeISO, endTimeISO);

          if (check) {
            actives.push(OBJ.lectureId);
          }
        });

        // Check if the active lectures list has changed
        if (
          JSON.stringify(actives) !== JSON.stringify(prevActiveLectures.current)
        ) {
          // Update state only if the active lectures have changed
          setActiveLectures(actives);
          prevActiveLectures.current = actives; // Update ref to track previous state
        }
      }
    }, 1000); // Check every second

    // Cleanup the interval when the component unmounts or lectureData changes
    return () => {
      clearInterval(stopper);
    };
  }, [lectureData]); // Re-run the effect when lectureData changes

  const handleOpen = (lecture) => {
    if (markedAttendanceLectures.has(lecture.lectureId)) return;
    if (!attendanceSocket) {
      // attendanceSocket?.disconnect();
      createAttendanceSocketConnection();
    }
    setSelectedLecture(lecture);
    setOpen(true);
  };

  const fetchLectures = async () => {
    try {
      const token = Cookies.get("token");
      const currentDate = new Date();
      setShowLoader(true);

      const facultyResponse = await axios.get(
        `${BASE_URL}${getFacultyLectures}?date=${currentDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const lectureData = facultyResponse.data?.data?.lectureData || []
      // Sorting lectures to put active ones at the start
      const sortedLectures = lectureData?.sort((a, b) => {
        const aIsActive = checkLectureActive(a.startTime, a.endTime);
        const bIsActive = checkLectureActive(b.startTime, b.endTime);

        // Place active lectures before inactive ones
        if (aIsActive && !bIsActive) return -1;
        if (!aIsActive && bIsActive) return 1;
        return 0;
      });
      setLectureData(sortedLectures);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchLectures();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setSelectedCourse(null);
    setSelectedSemester(null);
  };

  const handleSecondModalClose = () => {
    setOpenSecondModal(false);
  };
  const navigateToTakeAttendance = (lectureId) => {
    navigate(`./takeAttendance/${lectureId}`);
  };

  const StopTime = async () => {
    try {
      const userId = getDecodedToken()._id;
      attendanceSocket.emit(`stop-Timer`, {
        lectureId: selectedLecture.lectureId,
        userId: userId,
      });
    } catch (error) { }
  };

  const joinRoom = () => {
    const userId = getDecodedToken()._id;
    if (currentLecture && currentLecture !== selectedLecture.lectureId) {
      Toast.error("Another Lecture Already Running....");
      return;
    }

    if (currentLecture === selectedLecture.lectureId) {
      setOpenSecondModal(true);
      return;
    }

    setCurrentLecture(() => selectedLecture?.lectureId);
    const data = {
      lectureId: selectedLecture?.lectureId,
      subjectName: selectedLecture?.subjectName,
      startTime: selectedLecture?.startTime,
      endTime: selectedLecture?.endTime,
      timer: selectedLecture?.timer || "0.3",
      sectionId: selectedLecture?.sectionId,
      batchId: selectedLecture?.batchId,
      userId: userId,
      // batchId: selectedLecture?.batchId,
      // sectionId: selectedLecture?.sectionId,
    };

    setTotalTime(Number(data.timer) * 60);
    attendanceSocket.emit("joinRoom", data);
    attendanceSocket.on("setTimeoutValue", (data) => {
      setTotalTime(data.time);
      setOpenSecondModal(true);
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}`, (data) => {
      setRemainingTime(Number(data.time));
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}-close`, (data) => {
      setCurrentLecture(null);
      setOpen(false);
      setOpenSecondModal(false);
    });

    attendanceSocket.on(`${selectedLecture?.lectureId}-present`, (data) => {
      let msg = `${data.fname} ${data.lname} PRESENT 🎉`;
      Toast.success(msg);
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "description") {
      setDescription(value);
    } else if (name === "date") {
      setDate(value);
    } else if (name === "title") {
      setTitle(value);
    }
  };

  const handleDateChange = (field, newDate) => {
    setDate(newDate ? newDate.format("YYYY-MM-DD") : null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowLoader(true);
      const token = getToken();
      const data = { title, description, date };

      const response = await axios.post(`${BASE_URL}${postNewsLetter}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.result) {
        setShowLoader(false);
        setIsEditing(false);
        setTitle("");
        setDate("");
        setDescription("");
        getNews();
      } else {
        setShowLoader(false);
        alert(response.data.message || "Submission failed");
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error submitting message:", error);
      alert("An error occurred while submitting the message.");
    }
  };

  const getNews = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${BASE_URL}${getNewsLetter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.result) {
        setNewsList(response.data.data || []);
      } else {
        // setNewsList([]);
      }
    } catch (error) {
      console.error("Error fetching news:", error);
    }
  };

  useEffect(() => {
    getNews();
  }, []);

  const handleOpenNewsModal = () => {
    setIsEditing(true);
  };

  const handleCloseNewsModal = () => {
    setIsEditing(false);
  };

  const handleDeleteClick = async (_id) => {
    showConfirmation("want to delete this news ?", handleDeleteNews, _id);
  };

  const handleDeleteNews = async (_id) => {
    try {
      setShowLoader(true);

      const token = getToken();

      const response = await axios.delete(`${BASE_URL}${deleteNewsLetter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: { _id: _id },
      });

      if (response.data.result) {
        setShowLoader(false);
        getNews();
        Toast.success(response?.data?.message || "News deleted successfully!");
      } else {
        setShowLoader(false);
        Toast.error(response?.data?.message || "Failed to delete the news.");
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error deleting news:", error);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const itemsPerPage = 1;
  const totalPages = Math.ceil(newsList.length / itemsPerPage);

  const pageColors = [
    "#f0f8ff",
    "#ffe4e1",
    "#e6fffa",
    "#ffebcd",
    "#fffacd",
    "#f5f5dc",
    "#fafad2",
    "#d3d3d3",
    "#add8e6",
    "#90ee90",
    "#dcdcdc",
    "#f0fff0",
    "#ffb6c1",
    "#ff6347",
    "#ff4500",
  ];

  useEffect(() => {
    // If there are no pages, set currentPage to 0
    if (totalPages === 0) {
      setCurrentPage(0);
    } else {
      setCurrentPage(1); // Initialize to 1 if totalPages is not zero
    }

    // Automatic page change every 7 seconds
    const intervalId = setInterval(() => {
      setCurrentPage((prevPage) => {
        // Loop to the next page, reset to 1 if at the last page
        if (totalPages === 0) return 0; // Avoid page changes when no pages
        return prevPage < totalPages ? prevPage + 1 : 1; // Loop back to page 1 when reaching last page
      });
    }, 7000); // 7 seconds interval

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [totalPages]);

  const currentNewsItem = newsList[currentPage - 1]; // Adjusted for 1-based indexing

  // Handle next page
  const nextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  // Handle previous page
  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const pageStyle = {
    backgroundColor: pageColors[currentPage] || "#ffffff",
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Box className={styles.mainDashboard}>
          <h1 className={styles.titleDashboard}>Today's Lecture</h1>
          <Box className={styles.cardsContainer}>
            <Box className={styles.lectureContainer}>
              <Grid container spacing={3}>
                {lectureData.length > 0 ? (
                  lectureData.map((lecture, index) => (
                    <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                      <Card
                        className={
                          activeLectures.some(
                            (data) => data === lecture.lectureId
                          )
                            ? styles.activeCard
                            : styles.cards
                        }
                        onClick={() => handleOpen(lecture)}
                      >
                        <Box className={styles.cardContent}>
                          <Typography
                            variant="h6"
                            className={styles.cardHeader}
                          >
                            {lecture.subjectName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            {lecture.courseName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Section: {lecture.section} | Sem: {lecture.sem} |
                            Room No: {lecture.roomNo}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Practical Batch: {lecture.practicalBatch ? lecture.practicalBatch : 'N/A'}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.cardPara}
                          >
                            Type: {lecture.subjectType}
                          </Typography>
                          <div className={styles.lectureTime}>
                            <Typography
                              variant="body2"
                              className={styles.cardPara}
                            >
                              Start Time: {formatTime(lecture.startTime)}
                            </Typography>
                            <Typography
                              variant="body2"
                              className={styles.cardPara}
                            >
                              End Time: {formatTime(lecture.endTime)}
                            </Typography>
                          </div>
                        </Box>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Box className={styles.noLectureContainer}>
                    <h2 className={styles.noLecture}>No lectures available</h2>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box className={styles.sideContainer}>
          <div className={styles.marqueeContainer}>
            <h2 className={styles.newsTitle}>News / Events</h2>
            <Box className={styles.addIconContainer}>
              <IconButton
                onClick={handleOpenNewsModal}
                className={styles.addIcon}
              >
                <AddCircleIcon />
              </IconButton>
            </Box>

            <Box className={styles.marqueeScrollable} style={pageStyle}>
              <Box className={styles.newsContent}>
                {currentNewsItem ? (
                  <div className={styles.contentInfo}>
                    <Box className={styles.deleteContainer}>
                      <IconButton
                        onClick={() => handleDeleteClick(currentNewsItem._id)}
                        className={styles.deleteIcon}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <div className={styles.descContainer}>
                      <span>
                        <p>Title</p>
                        <p>{currentNewsItem.title}</p>
                      </span>
                      <span>
                        <p>Date</p>
                        <p>
                          {" "}
                          {new Date(currentNewsItem.date).toLocaleDateString()}
                        </p>
                      </span>
                      <span>
                        <p>Details</p>
                        <p>{currentNewsItem.description}</p>
                      </span>
                    </div>
                  </div>
                ) : (
                  <p className={styles.noData}>No news available.</p>
                )}
              </Box>
            </Box>

            <Box
              className={styles.paginationContainer}
              display="flex"
              alignItems="center"
            >
              <IconButton
                className={styles.pageButton}
                onClick={prevPage}
                disabled={currentPage === 1 || totalPages === 0}
              >
                <ArrowBackIosIcon />
              </IconButton>

              <h4 style={{ fontStyle: "italic" }}>
                {totalPages === 0
                  ? "0 of 0"
                  : `${currentPage} of ${totalPages}`}
              </h4>

              <IconButton
                className={styles.pageButton}
                onClick={nextPage}
                disabled={currentPage === totalPages || totalPages === 0}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              className={styles.calendar}
              sx={{
                "& .MuiPickersDay-root": {
                  color: "white",
                },
                "& .Mui-selected": {
                  backgroundColor: "yourSelectedColor",
                },
                "& .MuiIconButton-root": {
                  color: "white",
                },
                "& .MuiTypography-root": {
                  color: "white",
                },
              }}
            />
          </LocalizationProvider>

          <Modal open={isEditing}>
            <Box className={styles.modalBackground}>
              <Box className={styles.modal}>
                <Box className={styles.closeIconContainer}>
                  <IconButton
                    onClick={handleCloseNewsModal}
                    className={styles.closeIcon}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <h2 className={styles.newsModalTitle}>Add News / Events</h2>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="title">Title</label>
                    <input
                      value={title}
                      name="title"
                      onChange={handleInputChange}
                      fullWidth
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="date">Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        views={["year", "month", "day"]}
                        value={date ? dayjs(date) : null}
                        onChange={(newDate) =>
                          handleDateChange("date", newDate)
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="description">Description</label>
                    <input
                      value={description}
                      name="description"
                      onChange={handleInputChange}
                      fullWidth
                      multiline
                      rows={4}
                    />
                  </div>
                </Grid>

                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1rem" }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton onClick={handleClose} className={styles.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Box>
            {selectedLecture && (
              <Box className={styles.modalContent}>
                <h2 className={styles.modalSubjectHeader}>
                  {selectedLecture.subjectName}
                </h2>
                <h3 className={styles.modalSubCodeHeader}>
                  ({selectedLecture.subjectCode})
                </h3>
                <p className={styles.modalPara}>
                  Course Code: {selectedLecture.courseCode}
                </p>
                <p className={styles.modalPara}>
                  Course Name: {selectedLecture.courseName}
                </p>
                <p className={styles.modalPara}>
                  Section: {selectedLecture.section}
                </p>
                <p className={styles.modalPara}>
                  Semester: {selectedLecture.sem}
                </p>
                <p className={styles.modalPara}>
                  Room No: {selectedLecture.roomNo}
                </p>
                <p className={styles.modalPara}>
                  Practical Batch: {selectedLecture.practicalBatch}
                </p>
                <p className={styles.modalPara}>Day: {selectedLecture.day}</p>
                <p className={styles.modalPara}>
                  Start Time: {formatTime(selectedLecture.startTime)}
                </p>
                <p className={styles.modalPara}>
                  End Time: {formatTime(selectedLecture.endTime)}
                </p>

                {activeLectures.includes(selectedLecture.lectureId) ? (
                  <Button
                    className={styles.modalButton}
                    variant="contained"
                    color="primary"
                    onClick={joinRoom}
                  >
                    {currentLecture !== null
                      ? "View Timer"
                      : "Start Lecture Timer"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={styles.modalInactiveButton}
                    disabled
                  >
                    Lecture Inactive
                  </Button>
                )}
                {activeLectures.includes(selectedLecture.lectureId) && (
                  <Button
                    variant="contained"
                    className={styles.modalButton}
                    onClick={() => {
                      navigateToTakeAttendance(selectedLecture.lectureId);
                    }}
                  >
                    Roll Call
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Modal open={openSecondModal} onClose={handleSecondModalClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.lectureModal}>
            <Box className={styles.closeIconContainer}>
              <IconButton
                onClick={handleSecondModalClose}
                className={styles.closeIconSecondModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <h2 className={styles.lectureTimeHeader}>Remaining Time</h2>
            {remainingTime !== null && (
              <TimerClock
                className={styles.timer}
                remainingTime={remainingTime}
                initTimer={totalTime}
              />
            )}
            <Button
              className={styles.finishTimerButton}
              variant="contained"
              color="primary"
              onClick={StopTime}
            >
              Finish Timer
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default HODDashboard;
