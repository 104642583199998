import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../../ToastContainer/ToastContainer";
import EditCertifications from "./EditCertifications/EditCertifications";
import EditAchievements from "./EditAchievements/EditAchievements";
import UserContext from "../../../../../Context/UserContext";
import { BASE_URL } from "./../../../../../config/config";
import certificationIcon from "../../../../../images/certificate.png";
import issueDateIcon from "../../../../../images/calendar.png";
import issuedByIcon from "../../../../../images/circular-label-with-certified-stamp (1).png";
import descriptionIcon from "../../../../../images/edit-info (1).png";
import linkIcon from "../../../../../images/link.png";
import achievementIcon from "../../../../../images/success.png";
import styles from "./Certifications.module.css";
import { format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";

const getCertifications = process.env.REACT_APP_GET_CERTIFICATIONS;
const addCertification = process.env.REACT_APP_ADD_CERTIFICATION;
const deleteCertification = process.env.REACT_APP_DELETE_CERTIFICATION;
const getAchievements = process.env.REACT_APP_GET_ACHIEVEMENTS;
const addAchievement = process.env.REACT_APP_ADD_ACHIEVEMENT;
const deleteAchievement = process.env.REACT_APP_DELETE_ACHIEVEMENT;
const showCertificationsDropdown = process.env.REACT_APP_DROPDOWN_SHOW_CERTIFICATIONS_DROPDOWN;

const Certifications = () => {
    const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [certifications, setCertifications] = useState([]);
    const [achievements, setAchievements] = useState([]);
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        fetchCertificationsData();
        fetchAchievementsData();
    }, []);

    const fetchCertificationsData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getCertifications}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            setCertifications(response.data.data);
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const fetchAchievementsData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getAchievements}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            setAchievements(response.data.data);
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleOpenModal = (data, type) => {
        setSelectedData(data);
        setModalType(type);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedData({});
        setModalType("");
    };

    const handleAddCertification = async (data) => {
        try {
            setShowLoader(true);
            const response = await axios.post(`${BASE_URL}${addCertification}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.result === true) {
                setCertifications((prevCertifications) => [
                    ...prevCertifications,
                    { ...data, _id: response.data.certificationId },
                ]);
                Toast.success(response?.data?.message || "Certification added successfully.");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            handleCloseModal();
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleAddAchievement = async (data) => {
        try {
            setShowLoader(true);
            const response = await axios.post(`${BASE_URL}${addAchievement}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.result === true) {
                setAchievements((prevAchievements) => [
                    ...prevAchievements,
                    { ...data, _id: response.data.achievementId },
                ]);
                Toast.success(response?.data?.message || "Achievement added successfully.");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            handleCloseModal();
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDeleteCertification = async (_id) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(`${BASE_URL}${deleteCertification}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                data: { certificationId: _id },
            });

            if (response.data.result) {
                setCertifications((prevCertifications) =>
                    prevCertifications.filter((cert) => cert._id !== _id)
                );
                Toast.success(response?.data?.message || "Certification deleted successfully.");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDeleteAchievement = async (_id) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(`${BASE_URL}${deleteAchievement}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                data: { achievementId: _id },
            });

            if (response.data.result) {
                setAchievements((prevAchievements) =>
                    prevAchievements.filter((ach) => ach._id !== _id)
                );
                Toast.success(response?.data?.message || "Achievement deleted successfully.");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const formatDate = (date) => {
        const parsedDate = new Date(date);
        return parsedDate.getTime() ? format(parsedDate, "dd/MM/yyyy") : "N/A";
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.certificationsSection}>
                <h2 className={styles.headingCertifications}>
                    Certifications{" "}
                    <img src={certificationIcon} alt="Certifications Icon" className={styles.icon} />
                </h2>
                {certifications.length > 0 ? (
                    <div className={styles.certificationsList}>
                        {certifications.map((certification, index) => (
                            <div className={styles.yourCertification} key={certification._id}>
                                <h3>{index + 1}. {certification.certification}</h3>
                                <hr className={styles.horizontalRow} />
                                <Box className={styles.closeIconContainer}>
                                    <IconButton
                                        onClick={() =>
                                            showConfirmation(
                                                "Are you sure you want to delete this certification?",
                                                handleDeleteCertification,
                                                certification._id
                                            )
                                        }
                                    >
                                        <DeleteIcon className={styles.deleteCertificationButton} />
                                    </IconButton>
                                </Box>
                                <div className={styles.certificationContent}>
                                    <p>
                                        {" "}
                                        <img
                                            src={issueDateIcon}
                                            alt="Date Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Date of Issue: </span>
                                        {formatDate(certification.issuedDate)}
                                    </p>
                                    <p>
                                        {" "}
                                        <img
                                            src={issuedByIcon}
                                            alt="Issued By Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Issued By: </span> {certification.issuedBy}
                                    </p>
                                    <p>
                                        {" "}
                                        <img
                                            src={descriptionIcon}
                                            alt="Description Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Description: </span> {certification.description}
                                    </p>
                                    <p>
                                        {" "}
                                        <img
                                            src={linkIcon}
                                            alt="Link Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Link: </span>{`${certification?.link ? certification.link : "N/A"}`}

                                    </p>

                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles.noData}>No certification records available.</p>
                )}
                <Button
                    variant="contained"
                    onClick={() => handleOpenModal(null, "certification")}
                    className={styles.addButton}
                >
                    <AddIcon />
                    <span>Add Certification</span>
                </Button>
            </div>

            <div className={styles.achievementsSection}>
                <h2 className={styles.headingAchievements}>
                    Achievements{" "}
                    <img src={achievementIcon} alt="Achievements Icon" className={styles.icon} />
                </h2>
                {achievements.length > 0 ? (
                    <div className={styles.achievementsList}>
                        {achievements.map((achievement, index) => (
                            <div className={styles.yourAchievement} key={achievement._id}>
                                <h3>{index + 1}. {achievement.achievement}</h3>
                                <hr className={styles.horizontalRow} />
                                <Box className={styles.closeIconContainer}>
                                    <IconButton
                                        onClick={() =>
                                            showConfirmation(
                                                "Are you sure you want to delete this achievement?",
                                                handleDeleteAchievement,
                                                achievement._id
                                            )
                                        }
                                    >
                                        <DeleteIcon className={styles.deleteAchievementButton} />
                                    </IconButton>
                                </Box>
                                <div className={styles.achievementContent}>
                                    <p>
                                        {" "}
                                        <img
                                            src={descriptionIcon}
                                            alt="Description Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Description: </span>
                                        {achievement.description}                                    </p>
                                    <p>
                                        {" "}
                                        <img
                                            src={linkIcon}
                                            alt="Link Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Link: </span>{`${achievement?.link ? achievement.link : "N/A"}`}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles.noData}>No achievement records available.</p>
                )}
                <Button
                    variant="contained"
                    onClick={() => handleOpenModal(null, "achievement")}
                    className={styles.addButton}
                >
                    <AddIcon />
                    <span>Add Achievement</span>
                </Button>
            </div>

            <Modal
                className={styles.modal}
                open={isModalOpen}
                onClose={handleCloseModal}
            >
                {modalType === "certification" ? (
                    <EditCertifications
                        certificationData={selectedData}
                        handleAddCertification={handleAddCertification}
                        handleCloseModal={handleCloseModal}
                        dropdownEndpoint={showCertificationsDropdown}
                    />
                ) : (
                    <EditAchievements
                        achievementData={selectedData}
                        handleAddAchievement={handleAddAchievement}
                        handleCloseModal={handleCloseModal}
                    />
                )}
            </Modal>
        </div >
    );
};

export default Certifications;
