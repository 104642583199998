import React, { useContext, useState, useEffect } from "react";
import { Button, TextField, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditHobbies.module.css";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import UserContext from "../../../../../../Context/UserContext";
import Toast from "../../../../ToastContainer/ToastContainer";
import { BASE_URL } from "../../../../../../config/config";

const showHobbiesDropdown = process.env.REACT_APP_DROPDOWN_SHOW_HOBBIES_DROPDOWN;

const EditHobbies = ({ hobbyData, handleAddHobby, handleCloseModal }) => {
    const [hobby, setHobby] = useState("");
    const [dropdownOptions, setDropdownOptions] = useState({
        hobbies: [],
    });
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const { Cookies, setShowLoader } = useContext(UserContext);
    const [token] = useState(Cookies.get("token"));

    useEffect(() => {
        if (hobbyData) {
            setHobby(hobbyData || "");
        }
        fetchDropdownOptions();
    }, [hobbyData]);

    useEffect(() => {
        validateForm();
    }, [hobby]);

    const fetchDropdownOptions = async () => {
        try {
            setShowLoader(true);

            const response = await axios.get(`${BASE_URL}${showHobbiesDropdown}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const hobbies = response.data.data;
            setDropdownOptions({
                hobbies: hobbies || [],
            });
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setHobby(value); // Update hobby value when the user types or selects
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        if (!hobby) {
            validationErrors.hobby = "**This field is required**";
            isValid = false;
        } else if (hobby.length > 50) {
            validationErrors.hobby = "**Hobby must be at most 50 characters**";
            isValid = false;
        }

        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedHobby = {
            hobby: hobby || "",
        };

        handleAddHobby(formattedHobby); // Pass the hobby data to parent component
    };

    return (
        <div className={styles.editHobbyContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteHobby} />
                </IconButton>
            </Box>
            <h2 className={styles.editHobbyHeader}>Add Hobby</h2>
            <form onSubmit={handleSubmit} className={styles.editHobbyScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editHobbyInputs}>
                            <label htmlFor="hobby">Hobby</label>
                            <Autocomplete
                                id="hobby"
                                freeSolo
                                options={dropdownOptions.hobbies}
                                value={
                                    dropdownOptions.hobbies.find(
                                        (option) => option.value === hobby
                                    ) || null
                                }
                                onChange={(e, value) => {
                                    handleChange({
                                        target: {
                                            value: value ? value.value : hobby,
                                        },
                                    });
                                }}
                                onInputChange={(e, value) => {
                                    handleChange({
                                        target: {
                                            value: value,
                                        },
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        sx={{
                                            height: 40,
                                            "& .MuiInputBase-root": {
                                                height: "100%",
                                            },
                                        }}
                                    />
                                )}
                            />
                            {error.hobby && <p className={styles.error}>{error.hobby}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButtonEditHobby}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default EditHobbies;
