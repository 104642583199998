import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import { Button } from "@mui/material";
import DownloadingSharpIcon from "@mui/icons-material/DownloadingSharp";
import axios from "axios";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./BulkRegistration.module.css";
import { BASE_URL } from "./../../../../../config/config";

const bulkRegistration = process.env.REACT_APP_BULK_REGISTRATION;
const excelTemplate = process.env.REACT_APP_EXCEL_TEMPLATE;
const getDropdownData = process.env.REACT_APP_GET_DATA_FOR_DROPDOWN;

const BulkRegistration = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [showPasswordFields, setShowPasswordFields] = useState(true);
  const [roleType, setRoleType] = useState("");
  const [fileName, setFileName] = useState("");
  const [dropDownData, setDropDownData] = useState({
    userTypes: [],
    courseList: [],
    batchList: [],
  });
  const [batchList, setBatchList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [semesterList, setSemesterList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [formData, setFormData] = useState({
    defaultPassword: "",
    confirmPassword: "",
    roleType: "",
    excelFile: null,
    courseCode: "",
    batchId: "",
    sectionId: "",
    semester: "",
  });

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFileName(file.name);
      setShowPasswordFields(true);
      setFile(file);
      setFormData({ ...formData, ["excelFile"]: file });
    } else {
      setFileName("");
      setShowPasswordFields(false);
    }
  };

  const handleDownload = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${excelTemplate}`, {
        responseType: "blob",
        headers,
      });

      const blob = new Blob([response.data]);

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = url;

      link.setAttribute("download", "template.xlsx");

      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "roleType",
      "courseCode",
      "sectionId",
      "batchId",
      "semester",
      "defaultPassword",
      "confirmPassword",
    ];

    // Check required fields
    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (
      formData.defaultPassword &&
      !passwordRegex.test(formData.defaultPassword)
    ) {
      validationErrors.defaultPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    if (
      formData.confirmPassword &&
      !passwordRegex.test(formData.confirmPassword)
    ) {
      validationErrors.confirmPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    // Validate passwords match
    if (
      formData.defaultPassword &&
      formData.confirmPassword &&
      formData.defaultPassword !== formData.confirmPassword
    ) {
      validationErrors.confirmPassword = "**Passwords do not match**";
    }

    setErrors(validationErrors);
    setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const FetchDataForaddUserRegistration = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getDropdownData}`, {
        headers,
      });
      const data = response?.data;
      setDropDownData(() => data?.data);
      setShowLoader(false);
      return data;
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      };
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${bulkRegistration}`,
        formData,
        {
          headers,
        }
      );

      if (response.data.result) {
        Toast.success(response.data?.message || "Success...");
        setFormData({
          defaultPassword: "",
          confirmPassword: "",
          roleType: "",
          excelFile: null,
          courseCode: "",
          batchId: "",
          sectionId: "",
          semester: "",
        });
        setFileName("");
        setErrors({});
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  // const handleRoleChange = (e) => {
  //   setRoleType(e.target.value);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => { }, [formData]);

  useEffect(() => {
    if (dropDownData.batchList.length !== 0 && formData.courseCode) {
      const batches = dropDownData.batchList.filter(
        (batchData) => batchData.value === formData.courseCode
      );
      if (batches.length !== 0) {
        setBatchList(() => batches[0].batches);
      }
    }

    if (dropDownData.courseList.length !== 0 && formData.courseCode) {
      const semesters = dropDownData.courseList.filter(
        (semester) => semester.value === formData.courseCode
      );

      if (semesters.length !== 0) {
        setSemesterList(() => semesters[0].semesters);
      }
    }
  }, [formData.courseCode]);

  useEffect(() => {
    if (batchList.length !== 0 && formData.batchId) {
      const sections = batchList.filter(
        (section) => section.value === formData.batchId
      );

      if (sections.length !== 0) {
        setSectionList(() => sections[0].sections);
      }
    }
  }, [formData.batchId]);

  useEffect(() => {
    FetchDataForaddUserRegistration();
  }, []);

  return (
    <div className={styles.bulkRegisterationContainer}>
      <h2 className={styles.bulkTitle}>BULK USER REGISTRATION</h2>
      <form className={styles.formSection} onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <Button
            onClick={handleDownload}
            className={styles.downloadTempelateButton}
            variant="contained"
          >
            <DownloadingSharpIcon className={styles.excelDownloadButton} />
            Download Excel Template
          </Button>
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor="roleType">Role Type</label>
          <DropDown
            id="roleType"
            name="roleType"
            value={formData.roleType}
            onChange={handleChange}
            options={dropDownData.userTypes}
          />
          {errors.roleType && <p className={styles.error}>{errors.roleType}</p>}
        </div>

        {!(formData.roleType === "faculty") && (
          <>
            <div className={styles.inputGroup}>
              <label htmlFor="courseName">Course Name</label>
              <DropDown
                id="courseCode"
                name="courseCode"
                value={formData.courseCode}
                onChange={handleChange}
                options={dropDownData.courseList}
              />
              {errors.courseCode && (
                <p className={styles.error}>{errors.courseCode}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="batch">Batch</label>
              <DropDown
                id="batch"
                name="batchId"
                value={formData.batchId}
                onChange={handleChange}
                options={batchList}
              />
              {errors.batchId && (
                <p className={styles.error}>{errors.batchId}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="section">Section</label>
              <DropDown
                id="section"
                name="sectionId"
                value={formData.sectionId}
                onChange={handleChange}
                options={sectionList}
              />
              {errors.sectionId && (
                <p className={styles.error}>{errors.sectionId}</p>
              )}
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="semester">Semester</label>
              <DropDown
                id="semester"
                name="semester"
                value={formData.semester}
                onChange={handleChange}
                options={semesterList}
              />
              {errors.semester && (
                <p className={styles.error}>{errors.semester}</p>
              )}
            </div>
          </>
        )}

        {showPasswordFields && (
          <>
            {formData.roleType && (
              <div className={styles.inputGroup}>
                <Button
                  component="label"
                  role={roleType}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  className={styles.uploadTempelateButton}
                >
                  UPLOAD FILE - {fileName ? fileName : "Upload file"}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            )}
            {fileName && (
              <div className={styles.inputGroup}>
                <label htmlFor="Default Password"> Default Password</label>
                <input
                  type="password"
                  id="defaultPassword"
                  placeholder="Default Password"
                  value={formData.defaultPassword}
                  name="defaultPassword"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.defaultPassword && (
                  <p className={styles.error}>{errors.defaultPassword}</p>
                )}
              </div>
            )}
            {fileName && (
              <div className={styles.inputGroup}>
                <label htmlFor="Confirm Default Password">
                  Confirm Default Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Default Password"
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.confirmPassword && (
                  <p className={styles.error}>{errors.confirmPassword}</p>
                )}
              </div>
            )}

            <Button
              type="button"
              variant="contained"
              className={styles.saveButton}
              onClick={(e) => {
                e.preventDefault();
                showConfirmation(" Want to Register Users ?", handleSubmit, e);
              }}
            >
              Save
            </Button>
          </>
        )}
      </form>
    </div>
  );
};

export default BulkRegistration;
