import React, { useState, useEffect, useContext } from "react";
import { Grid, Button, Modal, Box, IconButton } from "@mui/material";
import CardComponent from "../../CardComponent/CardComponent";
import UserContext from "../../../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./CommunityList.module.css";
import Toast from "../../ToastContainer/ToastContainer";
import DropDown from "../../DropDown/DropDown";
import { BASE_URL } from "./../../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";

const searchPattern = process.env.REACT_APP_SEARCH_PATTERN;
const viewUserDropdown = process.env.REACT_APP_VIEW_USER_DROPDOWN;
const getFilteredUserList = process.env.REACT_APP_GET_FILTERED_USERLIST;
const deleteStudent = process.env.REACT_APP_DELETE_STUDENT;
const recoverStudent = process.env.REACT_APP_RECOVER_STUDENT;
const getInstituteDropdown = process.env.REACT_APP_GET_INSTITUTE_DROPDOWN;
const editUserDetails = process.env.REACT_APP_RESET_USER_PASSWORD;
const getBatchListByCourse = process.env.REACT_APP_GET_BATCH_LIST_BY_COURSE;

function CommunityList() {
  const {
    getToken,
    setSelectedStudent,
    setStudentList,
    setShowLoader,
    getUserRole,
    showConfirmation,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [StudentData, setStudentData] = useState([]);
  const [dropDownData, setDropDownData] = useState([]);
  const [roleTypeDropdown, setRoleTypeDropdown] = useState([]);
  const [salutationDropdown, setSalutationDropdown] = useState([]);
  const [courseDropDown, setCourseDropDown] = useState([]);
  const [batchDropdown, setBatchDropdown] = useState([]);
  const [semesterDropDown, setSemesterDropDown] = useState([]);
  const [instituteDropdown, setInstituteDropdown] = useState([]);
  const [sectionDropDown, setSectionDropDown] = useState([]);
  const [userData, setUserData] = useState({
    salutation: "",
    fname: "",
    mname: "",
    lname: "",
    email: "",
    role: "",
    primaryPhone: "",
    enrollmentId: "",
    userId: "",
    resetPassword: "",
    confirmResetPassword: "",
    isClassRespresentative: false,
  });
  const [formData, setFormData] = useState({
    roleType: "",
    instituteId: "",
    departmentCode: "",
    courseCode: "",
    batch: "",
    semester: "",
    section: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [filteredStudents, setFilteredStudents] = useState([]);

  const userRole = getUserRole();

  const handleSearch = async () => {
    try {
      if (!searchText) {
        return;
      }
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${searchPattern}`,
        { toSearch: searchText, instituteId: formData.instituteId },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      setStudentData(() => response?.data?.data);
      setFilteredStudents(() => response?.data?.data);
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${viewUserDropdown}?instituteId=${formData.instituteId}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      if (response?.data?.result) {
        setDropDownData(() => response?.data?.data?.departmentList);
        setRoleTypeDropdown(() => response?.data?.data?.roleTypes);
        if (response?.data?.data?.departmentCode) {
          localStorage.setItem(
            "deptCode",
            response?.data?.data?.departmentCode
          );
        }
        setBatchDropdown(() => response?.data?.data?.batches);
        setSalutationDropdown(() => response?.data?.data?.salutationList);
      }
      setShowLoader(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
      setShowLoader(false);
    }
  };

  useEffect(() => {
    // Check if salutationDropdown is populated before opening the modal
    if (salutationDropdown.length > 0 && userData.salutation !== "") {
      setIsModalOpen(true);
    }
  }, [salutationDropdown, userData.salutation]);

  const fetchInstituteDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getInstituteDropdown}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response?.data?.result) {
        setInstituteDropdown(response?.data?.data.instituteList || []);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchBatchDropdown = async () => {
    try {
      const data = {
        instituteId: formData.instituteId,
        departmentCode: formData.departmentCode,
        courseCode: formData.courseCode,
      };
      const queryParam = new URLSearchParams(data);
      const response = await axios.get(
        `${BASE_URL}${getBatchListByCourse}?${queryParam}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      setBatchDropdown(response.data.data);
    } catch (error) { }
    // dropdown/getBatchListByCourse
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // validateForm();
  };
  // userData
  const handleEditUserDataChange = (e) => {
    const { name, value } = e.target;
    if (name === "isClassRespresentative") {
      setUserData({
        ...userData,
        [name]: e.target.checked,
      });
      return;
    }
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (formData?.courseCode && formData?.departmentCode) {
      fetchBatchDropdown();
    }
  }, [formData?.courseCode]);

  const handleViewDetails = (student) => {
    setSelectedStudent(() => student);
    const userRole = getUserRole();
    navigate(`/${userRole}/community/view-user/${student.enrollmentId}`);
  };

  const handleEdit = (student) => {
    if (salutationDropdown.length > 0) {
      setUserData({
        fname: student.fname,
        mname: student.mname,
        lname: student.lname,
        email: student.email,
        role: student.role,
        primaryPhone: student.primaryPhone,
        enrollmentId: student.enrollmentId,
        userId: student.userId,
        isClassRespresentative: student?.isClassRespresentative || false,
        salutation: student.salutation || "",
      });
      setIsModalOpen(true);
    };
  };

  const handleDelete = async (enrollmentId) => {
    try {
      setShowLoader(true);
      const response = await axios.delete(
        `${BASE_URL}${deleteStudent}/${enrollmentId}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          // params: { enrollmentId: student.enrollmentId },
        }
      );

      if (response.data.result) {
        setFilteredStudents((prevStudents) =>
          prevStudents.map((s) =>
            s.enrollmentId === enrollmentId ? { ...s, isDeleted: true } : s
          )
        );
        Toast.success("Student deleted successfully!");
      } else {
        Toast.error("Failed to delete student");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleRecover = async (enrollmentId) => {
    try {
      setShowLoader(true);
      const response = await axios.put(
        `${BASE_URL}${recoverStudent}/${enrollmentId}`,
        {},
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      if (response.data.result) {
        setFilteredStudents((prevStudents) =>
          prevStudents.map((s) =>
            s.enrollmentId === enrollmentId ? { ...s, isDeleted: false } : s
          )
        );
        Toast.success("Student recovered successfully!");
      } else {
        Toast.error("Failed to recover student");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    validateForm();
  }, [userData]);

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      // "salutation",
      // "fname",
      // "mname",
      // "lname",
      // "role",
      // "email",
      // "enrollmentId",
      "resetPassword",
      "confirmResetPassword",
    ];

    requiredFields.forEach((field) => {
      const value = userData[field];
      // Check if value is a string before using .trim()
      if (
        typeof value !== "number" &&
        (value == null || String(value).trim() === "")
      ) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (userData.resetPassword && !passwordRegex.test(userData.resetPassword)) {
      validationErrors.resetPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }
    if (
      userData.confirmResetPassword &&
      !passwordRegex.test(userData.confirmResetPassword)
    ) {
      validationErrors.confirmResetPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    // Validate passwords match
    if (
      userData.resetPassword &&
      userData.confirmResetPassword &&
      userData.resetPassword !== userData.confirmResetPassword
    ) {
      validationErrors.confirmResetPassword = "**Passwords do not match**";
    }

    setErrors(validationErrors);
    setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSubmitDisabled) return;

    try {
      const token = getToken();

      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${editUserDetails}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === true) {
        Toast.success(response.data.message || "Success !");
        setUserData({
          // salutation: "",
          // fname: "",
          // mname: "",
          // lname: "",
          // role: "",
          // email: "",
          // enrollmentId: "",
          resetPassword: "",
          confirmResetPassword: "",
        });
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchFilteredUserList = async () => {
    try {
      const queryString = new URLSearchParams(formData).toString();
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getFilteredUserList}?${queryString}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      setStudentList(response?.data?.data);
      setStudentData(response?.data?.data);
      setFilteredStudents(response?.data?.data);

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (getUserRole() === "superadmin") {
      fetchInstituteDropdown();
    } else {
      fetchDropdown();
    }

    return () => {
      localStorage.removeItem("deptCode");
    };
  }, []);

  useEffect(() => {
    if (formData?.instituteId) {
      fetchDropdown();
    }
  }, [formData?.instituteId]);

  useEffect(() => {
    const getData = setTimeout(() => {
      handleSearch();
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchText]);

  useEffect(() => {
    if (!formData.roleType) {
      return;
    }
    if (formData.roleType === "student" || formData.roleType === "faculty") {
      if (!formData.departmentCode) {
        return;
      }
    }
    if (formData.roleType === "hod" || formData.roleType === "instituteadmin" || formData.roleType === "tpo") {
      fetchFilteredUserList();
    }
    if (formData.departmentCode || formData.batch) {
      fetchFilteredUserList();
    } else {
      setFilteredStudents([]);
      setStudentData([]);
    }
  }, [
    formData?.semester,
    formData?.courseCode,
    formData?.departmentCode,
    formData?.batch,
    formData?.section,
    formData.roleType,
  ]);

  const handleChangeSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setFormData({
      ...formData,
      departmentCode: "",
      courseCode: "",
      batch: "",
      semester: "",
      section: "",
    });
    if (!formData.roleType) {
      return;
    }
    setFormData({ ...formData, ["departmentCode"]: "" });
    setSemesterDropDown([]);
    setCourseDropDown([]);
  }, [formData?.roleType]);

  useEffect(() => {
    setFormData({
      ...formData,
      courseCode: "",
      batch: "",
      semester: "",
      section: "",
    });
    if (!formData.departmentCode) {
      return;
    }
    const filteredList = dropDownData?.filter(
      (OBJ) => OBJ.value === formData?.departmentCode
    );
    setFormData({ ...formData, ["courseCode"]: "" });
    setCourseDropDown(() => filteredList[0]?.courseList);
    setSemesterDropDown([]);
    setSectionDropDown([]);
    setBatchDropdown([]);
  }, [formData?.departmentCode]);

  useEffect(() => {
    setFormData({
      ...formData,
      batch: "",
      semester: "",
      section: "",
    });
    if (!formData.courseCode) {
      return;
    }
    const filteredList = courseDropDown?.filter(
      (OBJ) => OBJ.value === formData?.courseCode
    );
    if (filteredList) {
      setFormData({ ...formData, ["semester"]: "" });
      setSemesterDropDown(() => filteredList[0]?.semesterList);
      setSectionDropDown([]);
    }

    // setCourseDropDown(() => filteredList[0]?.courseList)
  }, [formData?.courseCode]);

  useEffect(() => {
    setFormData({
      ...formData,
      semester: "",
      section: "",
    });
    if (!formData.batch) {
      return;
    }
    const filteredList = batchDropdown.filter(
      (data) => data.value === formData.batch
    );

    setSectionDropDown(() => filteredList[0]?.sections || []);
  }, [formData.batch]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.communityHeader}>Community</h1>
      <div className={styles.searchAndFilterContainer}>
        <Box className={styles.searchBarContainer}>
          <label htmlFor="searchBar" className={styles.searchLabel}>
            Search Members <span className={styles.searchMemberSpanTag}>(By Name, Roll, email)</span>
          </label>
          <input
            id="searchBar"
            className={styles.searchBar}
            value={searchText}
            onChange={handleChangeSearch}
            variant="outlined"
          />
        </Box>

        <Box className={styles.dropDownParentContainer}>
          {userRole === "superadmin" && (
            <Box className={styles.dropDownContainer}>
              <label htmlFor="institute" className={styles.dropDownLabel}>
                Institute Name
              </label>
              <DropDown
                name="instituteId"
                value={formData?.instituteId}
                options={instituteDropdown}
                onChange={handleChange}
                className={styles.dropDownSelect}
              />
            </Box>
          )}
          <Box className={styles.dropDownContainer}>
            <label htmlFor="roleType" className={styles.dropDownLabel}>
              Role Type
            </label>
            <DropDown
              name={"roleType"}
              onChange={handleChange}
              options={roleTypeDropdown}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="departmentName" className={styles.dropDownLabel}>
              Department Name
            </label>
            <DropDown
              name={"departmentCode"}
              value={formData?.departmentCode}
              options={dropDownData}
              onChange={handleChange}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="courseName" className={styles.dropDownLabel}>
              Course Name
            </label>
            <DropDown
              name={"courseCode"}
              value={formData?.courseCode}
              options={courseDropDown}
              onChange={handleChange}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="batch" className={styles.dropDownLabel}>
              Batch
            </label>
            <DropDown
              name={"batch"}
              value={formData?.batch}
              options={batchDropdown || []}
              onChange={handleChange}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="semester" className={styles.dropDownLabel}>
              Semester
            </label>
            <DropDown
              name={"semester"}
              value={formData?.semester}
              options={semesterDropDown}
              onChange={handleChange}
              className={styles.dropDownSelect}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="section" className={styles.dropDownLabel}>
              Section
            </label>
            <DropDown
              name={"section"}
              value={formData?.section}
              options={sectionDropDown}
              onChange={handleChange}
              className={styles.dropDownSelect}
            />
          </Box>
        </Box>
      </div>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {filteredStudents && filteredStudents.length > 0 ? (
          filteredStudents.map((student, index) => (
            <CardComponent
              enrollmentId={student.enrollmentId}
              userFullName={`${student.fname} ${student.mname} ${student.lname}`}
              email={student.email}
              isdeleted={student.isDeleted}
              role={student.role}
              isClassRespresentative={student?.isClassRespresentative || false}
              departmentCode={student?.departmentCode || null}
              key={index}
              actions={[
                {
                  label: "View Details",
                  onClick: () => handleViewDetails(student),
                },
                { label: "Edit", onClick: () => handleEdit(student) },
                {
                  label: "Delete",
                  onClick: () =>
                    showConfirmation("You want to delete this student?", () =>
                      handleDelete(student.enrollmentId)
                    ),
                },
                {
                  label: "Recover",
                  onClick: () => handleRecover(student.enrollmentId),
                },
              ].filter(Boolean)}
            />
          ))
        ) : (
          <h2 className={styles.noData}>
            No members available. Please search or select dropdowns.
          </h2>
        )}
      </Box>

      <Modal open={isModalOpen}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton
                className={styles.closeButtonEditEducation}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <h2 className={styles.formScrollableHeader}>Reset Password</h2>
            <form onSubmit={handleSubmit} className={styles.formScrollable}>
              <Grid container spacing={2}>

                {/* <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="salutation">Salutation</label>
                    <Dropdown
                      id="salutation"
                      name="salutation"
                      value={userData.salutation}
                      onChange={handleChange} 
                      options={salutationDropdown}
                    />
                    {errors.salutation && (
                      <p className={styles.error}>{errors.salutation}</p>
                    )}
                  </div>
                </Grid> */}

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="fname">First Name</label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      value={userData.fname}
                      onChange={handleEditUserDataChange}
                      placeholder="First Name"
                      disabled
                      className={styles.disabled}
                    />
                    {errors.fname && (
                      <p className={styles.error}>{errors.fname}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="mname">Middle Name</label>
                    <input
                      type="text"
                      id="mname"
                      name="mname"
                      value={userData.mname}
                      onChange={handleEditUserDataChange}
                      placeholder="Middle Name"
                      disabled
                      className={styles.disabled}
                    />
                    {errors.mname && (
                      <p className={styles.error}>{errors.mname}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="lname">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      name="lname"
                      value={userData.lname}
                      onChange={handleEditUserDataChange}
                      placeholder="Last Name"
                      disabled
                      className={styles.disabled}
                    />
                    {errors.lname && (
                      <p className={styles.error}>{errors.lname}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="role">Role Type</label>
                    <DropDown
                      name="role"
                      onChange={handleEditUserDataChange}
                      options={roleTypeDropdown}
                      disabled
                      value={userData.role}
                      sx={{ backgroundColor: "#f6f6f6", color: "#a0a0a0" }}
                    />
                    {errors.role && (
                      <p className={styles.error}>{errors.role}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={userData.email}
                      onChange={handleEditUserDataChange}
                      placeholder="email"
                      disabled
                      className={styles.disabled}
                    />
                    {errors.email && (
                      <p className={styles.error}>{errors.email}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="enrollmentId">
                      Enrollment / Faculty Id
                    </label>
                    <input
                      type="text"
                      id="enrollmentId"
                      name="enrollmentId"
                      value={userData.enrollmentId}
                      onChange={handleEditUserDataChange}
                      placeholder="ID"
                      disabled
                      className={styles.disabled}
                    />
                    {errors.enrollmentId && (
                      <p className={styles.error}>{errors.enrollmentId}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="resetPassword">Reset Password</label>
                    <input
                      type="password"
                      id="resetPassword"
                      name="resetPassword"
                      value={userData.resetPassword}
                      onChange={handleEditUserDataChange}
                      placeholder="Reset Password"
                    />
                    {errors.resetPassword && (
                      <p className={styles.error}>{errors.resetPassword}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.loginGroup}>
                    <label htmlFor="confirmResetPassword">
                      Confirm Reset Password
                    </label>
                    <input
                      type="password"
                      id="confirmResetPassword"
                      name="confirmResetPassword"
                      value={userData.confirmResetPassword}
                      onChange={handleEditUserDataChange}
                      placeholder="Confirm Reset Password"
                    />
                    {errors.confirmResetPassword && (
                      <p className={styles.error}>
                        {errors.confirmResetPassword}
                      </p>
                    )}
                  </div>
                </Grid>

                {userData.role === "student" && (
                  <Grid item xs={12}>
                    <div className={styles.loginGroup}>
                      <label htmlFor="confirmResetPassword">
                        is Class Respresentative (CR)
                      </label>
                      <Switch
                        name="isClassRespresentative"
                        value={userData.isClassRespresentative}
                        checked={userData.isClassRespresentative}
                        onChange={handleEditUserDataChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className={styles.submitButtonContainer}>
                <Button
                  disabled={isSubmitDisabled}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default CommunityList;
