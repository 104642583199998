import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './ResetPassword.module.css';
import Button from '@mui/material/Button';
import UserContext from "../../../../../../Context/UserContext";
import Toast from '../../../../ToastContainer/ToastContainer';
import { BASE_URL } from "./../../../../../../config/config";

const resetPassword = process.env.REACT_APP_RESET_PASSWORD;

const ResetPassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { getToken, setShowLoader } = useContext(UserContext);

    const navigate = useNavigate();

    const validateCurrentPassword = (password) => {
        return password.trim() !== '' ? '' : 'Current password is required';
    };

    const validateNewPassword = (password) => {
        if (!password.trim()) return 'New password is required';
        if (!/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}/.test(password)) {
            return 'Password must start with a capital letter, include at least one number, one symbol, and be at least 8 characters long.';
        }
        return '';
    };

    const validateConfirmPassword = (password) => {
        if (password !== newPassword) return 'Passwords do not match';
        return '';
    };

    const handleChange = (setter, validator) => (e) => {
        const value = e.target.value;
        setter(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: validator(value) // Validate on change
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});

        // Validate all fields
        const currentPasswordError = validateCurrentPassword(currentPassword);
        const newPasswordError = validateNewPassword(newPassword);
        const confirmPasswordError = validateConfirmPassword(confirmPassword);

        if (currentPasswordError || newPasswordError || confirmPasswordError) {
            setErrors({
                currentPassword: currentPasswordError,
                newPassword: newPasswordError,
                confirmPassword: confirmPasswordError
            });
            return;
        }

        try {
            setShowLoader(true);
            const response = await axios.post(
                `${BASE_URL}${resetPassword}`,
                { currentPassword, newPassword, confirmPassword },
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );
            const data = response.data;

            if (data?.result) {
                Toast.success(data?.message || "Success!");
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                navigate("/");
            } else {
                Toast.error(data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    return (
        <div className={styles.main}>
            <div className={styles.resetPasswordContainer}>
                <h2>Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                        <label>Current Password</label>
                        <input
                            type="password"
                            name="currentPassword"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={handleChange(setCurrentPassword, validateCurrentPassword)}
                        />
                        {errors.currentPassword && <p className={styles.error}>{errors.currentPassword}</p>}
                    </div>

                    <div className={styles.inputGroup}>
                        <label>New Password</label>
                        <input
                            type="password"
                            name="newPassword"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={handleChange(setNewPassword, validateNewPassword)}
                        />
                        {errors.newPassword && <p className={styles.error}>{errors.newPassword}</p>}
                    </div>

                    <div className={styles.inputGroup}>
                        <label>Confirm New Password</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={handleChange(setConfirmPassword, validateConfirmPassword)}
                        />
                        {errors.confirmPassword && <p className={styles.error}>{errors.confirmPassword}</p>}
                    </div>

                    <div className={styles.loginGroup}>
                        <Button type="submit" variant="contained" color="primary" className={styles.submitButton}>
                            Reset Password
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
