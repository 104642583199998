import React, { useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./Component/Forms/Login/Login";
import StudentDesktop from "./Component/StudentComponent/StudenDesktop/StudenDesktop";
import UserContext from "./Context/UserContext";
import UpdatePassword from "./Component/Forms/UpdatePassword/UpdatePassword";
import SuperAdminDesktop from "./Component/SuperAdminComponent/SuperAdminDesktop/SuperAdminDesktop";
import "./App.css";
import Loader from "./Component/CommonComponent/Loader/Loader";
import FacultyDesktop from "./Component/FacultyComponent/FacultyDesktop/FacultyDesktop";
import HODDesktop from "./Component/HODComponent/HODDesktop/HODDesktop";
import InstituteAdminDesktop from "./Component/InstituteAdminComponent/InstituteAdminDesktop/InstituteAdminDesktop";
import NotFound from "./Component/CommonComponent/NotFound/NotFound";
import Toast from "./Component/CommonComponent/ToastContainer/ToastContainer";
import ConfirmtionPopup from "./Component/CommonComponent/ConfirmationPopup/ConfirmationPopup";
import RecruiterDesktop from "./Component/RecruiterComponent/RecruiterDesktop/RecruiterDesktop";
import TNPOfficerdesktop from "./Component/TNPOfficer/TNPOfficerDesktop/TNPOfficerdesktop";

function App() {
  const {
    Cookies,
    showConfirmationPopup,
    showLoader,
    getDecodedToken,
    getUserRole,
    getToken,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const token = getToken();
      if (token) {
        const decodedToken = getDecodedToken();
        localStorage.setItem("userId", decodedToken.email);
        navigate("/login");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove("token");
    Toast.error("Logged out successfully");
    navigate("/login");
  };

  return (
    <div>
      {showConfirmationPopup && <ConfirmtionPopup />}
      {showLoader && <Loader />}
      <Routes>
        <Route
          path="/superadmin/*"
          exact
          element={
            getUserRole() === "superadmin" ? (
              <SuperAdminDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/instituteadmin/*"
          exact
          element={
            getUserRole() === "instituteadmin" ? (
              <InstituteAdminDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/hod/*"
          exact
          element={
            getUserRole() === "hod" ? (
              <HODDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/recruiter/*"
          exact
          element={
            getUserRole() === "recruiter" ? (
              <RecruiterDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/faculty/*"
          exact
          element={
            getUserRole() === "faculty" ? (
              <FacultyDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/tpo/*"
          exact
          element={
            getUserRole() === "tpo" ? (
              <TNPOfficerdesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/student/*"
          exact
          element={
            getUserRole() === "student" ? (
              <StudentDesktop user={getUserRole()} logout={logout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          exact
          element={
            !getUserRole() ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to={`/${getUserRole()}`} />
            )
          }
        />
        <Route
          path="/"
          exact
          element={
            !getUserRole() ? (
              <Login onLogin={handleLogin} />
            ) : (
              <Navigate to={`/${getUserRole()}`} />
            )
          }
        />
        <Route path="/updatepassword" exact element={<UpdatePassword />} />
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
