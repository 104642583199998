import React from "react";
import Sidebar from '../../CommonComponent/Sidebar/Sidebar'

function RecruiterDesktop({ logout, user }) {
  return (
    <div>
      <Sidebar logout={logout} user={user} />
    </div>
  );
}

export default RecruiterDesktop;
