import React, { useContext, useState, useEffect } from "react";
import styles from "./VUPBasicDetails.module.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Card, Stack, Modal } from "@mui/material";
import ViewUserProfilePhotoUpload from "../../VUPPhotoUpload/VUPPhotoUpload";
import VUPEditAddress from "./VUPEditAddress/VUPEditAddress";
import UserContext from "../../../../../Context/UserContext";
import axios from "axios";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Toast from "../../../ToastContainer/ToastContainer";
import VUPEditBasicDetails from "./VUPEditBasicDetails/VUPEditBasicDetails";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../../../config/config";
import idCard from "../../../../../images/id-card (1).png";
import userName from "../../../../../images/user (3).png";
import gender from "../../../../../images/gender.png";
import birthday from "../../../../../images/birthday.png";
import email from "../../../../../images/email.png";
import phone from "../../../../../images/phone.png";
import institute from "../../../../../images/institute.png";
import branch from "../../../../../images/hierarchy.png";
import usertype from "../../../../../images/who.png";
import rollnumber from "../../../../../images/id.png";
import aadhar from "../../../../../images/id-card (2).png";
import father from "../../../../../images/dad.png";
import mother from "../../../../../images/mother.png";
import parentOccupation from "../../../../../images/businessman.png";
import location from "../../../../../images/location.png";
import defaultUserImage from "../../../../../images/user (1).png";

const getUserData = process.env.REACT_APP_VUP_ADMIN_GET_USER_DATA;
const editPermanentAddress = process.env.REACT_APP_VUP_ADMIN_EDIT_PERM_ADDRESS;
const editCurrentAddress = process.env.REACT_APP_VUP_ADMIN_EDIT_CURRENT_ADDRESS;

const VUPBasicDetails = ({ currentUser, isEditable, userData }) => {
  const { Cookies, setShowLoader } = useContext(UserContext);
  const [BasicData, setBasicData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [UserImage, setUserImage] = useState(
    userData?.image || defaultUserImage
  );
  const { enrollmentId } = useParams();

  const fetchData = async () => {
    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${getUserData}/${enrollmentId}`,
        {
          headers,
        }
      );

      setShowLoader(false);
      setBasicData(() => response?.data.data);
    } catch (error) {
      setShowLoader(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [userData]);

  const isStudent = BasicData.userType === "student";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [permanentAddress, setPermanentAddress] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [currentAddress, setCurrentAddress] = useState({
    addressLine: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
  });
  const [currentAddressType, setCurrentAddressType] = useState(null);

  useEffect(() => {
    fetchData();
    setUserImage(() => localStorage.getItem("viewUserImage"));
  }, []);

  useEffect(() => {
    if (BasicData?.permanentAddress) {
      setPermanentAddress(BasicData.permanentAddress);
    }
    if (BasicData?.currentAddress) {
      setCurrentAddress(BasicData.currentAddress);
    }
  }, [BasicData]);

  const handleOpenModal = (addressType) => {
    setIsModalOpen(true);
    setCurrentAddressType(addressType);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseShowModal = () => {
    setShowModal(false);
  };

  const handleSaveAddress = async (data) => {
    const { updatedPermanentAddress, updatedCurrentAddress } = data;
    const token = Cookies.get("token");

    if (!enrollmentId) {
      Toast.error(`User Enrollment Not Found...!`);
      return;
    }

    try {
      setShowLoader(true);

      const updatedData = {};
      if (updatedPermanentAddress && currentAddressType === "permanent") {
        const permanentResponse = await axios.put(
          `${BASE_URL}${editPermanentAddress}/${enrollmentId}`,
          updatedPermanentAddress,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        Toast.success(permanentResponse?.data?.message || "Success !");

        updatedData.permanentAddress = permanentResponse.data.address;
      }
      if (updatedCurrentAddress && currentAddressType === "current") {
        const currentResponse = await axios.put(
          `${BASE_URL}${editCurrentAddress}/${enrollmentId}`,
          updatedCurrentAddress,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        Toast.success(currentResponse?.data?.message || "Success !");

        updatedData.currentAddress = currentResponse.data.address;
      }

      if (updatedData.permanentAddress) {
        setPermanentAddress(updatedData.permanentAddress);
        BasicData.permanentAddress = permanentAddress;
      }
      if (updatedData.currentAddress) {
        setCurrentAddress(updatedData.currentAddress);
        BasicData.currentAddress = currentAddress;
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }

    handleCloseModal();
  };

  return (
    <>
      <Card className={styles.imgUploader}>
        <Stack direction={styles.column}>
          <ViewUserProfilePhotoUpload
            isEditable={isEditable}
            className={styles.profilePhotoUpload}
            UserImage={UserImage}
          />
          <h3>{`${BasicData?.fname} ${BasicData?.mname} ${BasicData?.lname}`}</h3>
        </Stack>
      </Card>
      <h2 className={styles.headerPersonalDetails}>
        Personal Details{" "}
        <img src={idCard} alt="Personal Details Icon" className={styles.icon} />
      </h2>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutHeader}>
          <h3>About</h3>
          {isEditable && (
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className={styles.editButton}
            >
              <span className={styles.editIcon}>
                <ModeEditIcon sx={{ fontSize: "1rem" }} />
              </span>
              Edit
            </button>
          )}
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            {" "}
            <img
              src={userName}
              alt="Name Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Name: </span>{" "}
            {`${BasicData?.salutation}`} {`${BasicData?.fname} ${BasicData?.mname} ${BasicData?.lname}`}
          </p>
          <p>
            {" "}
            <img
              src={gender}
              alt="Gender Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Gender: </span>{" "}
            {`${BasicData?.gender ? BasicData.gender : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={birthday}
              alt="Birthday Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Date of Birth: </span>{" "}
            {`${BasicData?.dob
              ? new Date(BasicData?.dob).toLocaleDateString()
              : "N/A"
              }`}
          </p>
          <p>
            {" "}
            <img
              src={email}
              alt="Email Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Email: </span>{" "}
            {`${BasicData?.email ? BasicData.email : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={phone}
              alt="Phone Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Primary Phone: </span>{" "}
            {BasicData?.isPrivate ? (
              <VisibilityOffIcon
                sx={{
                  marginLeft: "5px",
                  marginBottom: "-6px",
                  fontSize: "1.5rem",
                }}
              />
            ) : (
              `${BasicData?.primaryPhone || "N/A"}`
            )}
          </p>

          <p>
            {" "}
            <img
              src={phone}
              alt="Telephone Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Secondary Phone: </span>{" "}
            {BasicData?.isPrivate ? (
              <VisibilityOffIcon
                sx={{
                  marginLeft: "5px",
                  marginBottom: "-6px",
                  fontSize: "1.5rem",
                }}
              />
            ) : (
              `${BasicData?.secondaryPhone ? BasicData.secondaryPhone : "N/A"}`
            )}
            {/* {`${BasicData?.secondaryPhone ? BasicData.secondaryPhone : "N/A"}`} */}
          </p>
          <p>
            {" "}
            <img
              src={institute}
              alt="Institute Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Institute Name: </span>{" "}
            {`${BasicData?.instituteName ? BasicData.instituteName : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={branch}
              alt="Department Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>Department: </span>{" "}
            {`${BasicData?.departmentName ? BasicData.departmentName : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={usertype}
              alt="Usertype Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>User Type: </span>{" "}
            {`${BasicData?.userType ? BasicData?.userType.toUpperCase() : "N/A"
              }`}
          </p>
          <p>
            {" "}
            <img
              src={rollnumber}
              alt="Id Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>User Enroll / Faculty ID: </span>{" "}
            {`${BasicData?.enrollmentId ? BasicData.enrollmentId : "N/A"}`}
          </p>
          <p>
            {" "}
            <img
              src={aadhar}
              alt="Aadhar Icon"
              className={styles.aboutIcon}
            />{" "}
            <span>User Aadhar: </span>{" "}
            {BasicData?.isPrivate ? (
              <VisibilityOffIcon
                sx={{
                  marginLeft: "5px",
                  marginBottom: "-6px",
                  fontSize: "1.5rem",
                }}
              />
            ) : (
              `${BasicData?.aadharNo || "N/A"}`
            )}
          </p>
          {isStudent && (
            <>
              <p>
                <img
                  src={father}
                  alt="Father Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Father's / Guardian's Name: </span>{" "}
                {`${BasicData?.fatherName ? BasicData.fatherName : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={mother}
                  alt="Mother Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Mother's / Guardian's Name: </span>{" "}
                {`${BasicData?.motherName ? BasicData.motherName : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={phone}
                  alt="ParentPhone Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Parent / Guardian's Phone: </span>{" "}
                {`${BasicData?.parentPhone ? BasicData.parentPhone : "N/A"}`}
              </p>
              <p>
                {" "}
                <img
                  src={parentOccupation}
                  alt="ParentOccupation Icon"
                  className={styles.aboutIcon}
                />{" "}
                <span>Parent / Guardian's Occupation: </span>{" "}
                {`${BasicData?.parentOccupation
                  ? BasicData.parentOccupation
                  : "N/A"
                  }`}
              </p>
            </>
          )}
        </div>
      </div>

      <h2 className={styles.headerAddress}>
        Address{" "}
        <img src={location} alt="Address Icon" className={styles.icon} />
      </h2>
      <div className={styles.addressContainer}>
        <div className={styles.permanentHeader}>
          <h3>Permanent</h3>
          {isEditable && (
            <button
              className={styles.editButton}
              onClick={() => handleOpenModal("permanent")}
            >
              <span className={styles.editIcon}>
                <ModeEditIcon sx={{ fontSize: "1rem" }} />
              </span>
              Edit
            </button>
          )}
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            {`${permanentAddress.addressLine}   ${permanentAddress.city}  ${permanentAddress.district}  ${permanentAddress.state}  ${permanentAddress.country}  ${permanentAddress.pincode}`}
          </p>
        </div>

        <div className={styles.currentHeader}>
          <h3>Current</h3>
          {isEditable && (
            <button
              className={styles.editButton}
              onClick={() => handleOpenModal("current")}
            >
              <span className="editIcon">
                <ModeEditIcon sx={{ fontSize: "1rem" }} />
              </span>
              Edit
            </button>
          )}
        </div>
        <hr className={styles.horizontalRow} />
        <div className={styles.personalDetailsContent}>
          <p>
            {`${currentAddress.addressLine}  ${currentAddress.city}  ${currentAddress.district}  ${currentAddress.state}  ${currentAddress.country}  ${currentAddress.pincode}`}
          </p>
        </div>
      </div>

      <Modal
        className={styles.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        <VUPEditAddress
          handleCloseModal={handleCloseModal}
          handleSaveAddress={handleSaveAddress}
          permanentAddress={permanentAddress}
          currentAddress={currentAddress}
          addressType={currentAddressType}
        />
      </Modal>

      <Modal
        className={styles.modal}
        open={showModal}
        onClose={handleCloseShowModal}
      >
        <VUPEditBasicDetails
          BasicData={BasicData}
          fetchData={fetchData}
          handleCloseModal={handleCloseShowModal}
          isStudent={isStudent}
        />
      </Modal>
    </>
  );
};

export default VUPBasicDetails;
