import React, { useState, useEffect } from "react";
import { Button, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditProjects.module.css";

const VUPEditProjects = ({ projectData, handleAddProject, handleCloseModal }) => {
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [link, setLink] = useState("");
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (projectData) {
            setProjectName(projectData.projectName || "");
            setProjectDescription(projectData.projectDescription || "");
            setLink(projectData.link || "");
        }
    }, [projectData]);

    useEffect(() => {
        validateForm();
    }, [projectName, projectDescription, link]);

    const handleProjectChange = (e) => {
        setProjectName(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setProjectDescription(e.target.value);
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        // Validate project name
        if (!projectName) {
            validationErrors.projectName = "**This field is required**";
            isValid = false;
        } else if (projectName.length > 100) {
            validationErrors.projectName = "**Project must be at most 100 characters**";
            isValid = false;
        }

        // Validate project description
        if (!projectDescription) {
            validationErrors.projectDescription = "**This field is required**";
            isValid = false;
        } else if (projectDescription.length > 500) {
            validationErrors.projectDescription = "**Description must be at most 500 characters**";
            isValid = false;
        }

        // Validate URL (optional)
        if (link && !isValidUrl(link)) {
            validationErrors.link = "**Please enter a valid URL**";
            isValid = false;
        }

        // Set the error state
        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };


    const isValidUrl = (url) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(url);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedProject = {
            projectName: projectName || "",
            projectDescription: projectDescription || "",
            link: link || "",
        };

        handleAddProject(formattedProject);
    };

    return (
        <div className={styles.editProjectContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteProject} />
                </IconButton>
            </Box>
            <h2 className={styles.editProjectHeader}>Add Project</h2>
            <form onSubmit={handleSubmit} className={styles.editProjectScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="project">Project</label>
                            <input
                                id="project"
                                type="text"
                                value={projectName}
                                onChange={handleProjectChange}
                            // maxLength="100"
                            />
                            {error.projectName && <p className={styles.error}>{error.projectName}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="description">Description</label>
                            <textarea
                                type="text"
                                rows="10"
                                cols="50"
                                id="description"
                                className={styles.textareaContainer}
                                value={projectDescription}
                                onChange={handleDescriptionChange}
                            // maxLength="500"
                            />
                            {error.projectDescription && <p className={styles.error}>{error.projectDescription}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="link">Link <span className={styles.span}> (Optional)</span></label>
                            <input
                                id="link"
                                type="text"
                                value={link}
                                onChange={handleLinkChange}
                            />
                            {error.link && <p className={styles.error}>{error.link}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButtonEditProject}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default VUPEditProjects;
