import React, { useContext, useEffect, useState } from "react";
import styles from "./StudentTimeTable.module.css";
import {
    Card,
    Typography,
    Box,
    Grid,
} from "@mui/material";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import dayjs from "dayjs";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";

const getWeeklyTimeTable = process.env.REACT_APP_STUDENT_GET_WEEK_TIME_TABLE;

const StudentTimeTable = () => {
    const { Cookies, setShowLoader } = useContext(UserContext);
    const [timeTableData, setTimeTableData] = useState([]);

    useEffect(() => {
        const fetchTimeTableData = async () => {
            setShowLoader(true);
            try {
                const token = Cookies.get("token");
                const response = await axios.get(
                    `${BASE_URL}${getWeeklyTimeTable}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                // Update state with the correct data field
                setTimeTableData(response.data.data);
                setShowLoader(false);
            } catch (error) {
                Toast.error(error?.response?.data?.message || "Something went wrong!")
                setShowLoader(false);
            }
        };

        fetchTimeTableData();
    }, [Cookies, setShowLoader]);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.titleContainer}>
                Week Timetable
            </h1>
            <div className={styles.timetable}>
                {timeTableData.length > 0 ? (
                    timeTableData.map((day, dayIndex) => (
                        <Box className={styles.lectureContainer} key={dayIndex}>
                            <div className={styles.dayTitleContainer}>
                                <h2 className={styles.dayTitle}>
                                    {day.day}
                                </h2>
                            </div>
                            <Grid container spacing={0.5} className={styles.cardContainer}>
                                {day.lectures.length > 0 ? (
                                    day.lectures
                                        .sort((a, b) => {
                                            const timeA = dayjs(a.startTime).hour() + dayjs(a.startTime).minute() / 60; // Convert to decimal hours
                                            const timeB = dayjs(b.startTime).hour() + dayjs(b.startTime).minute() / 60; // Convert to decimal hours
                                            return timeA - timeB; // Sort ascending
                                        })
                                        .map((lecture, lectureIndex) => (
                                            <Grid item key={lectureIndex} xs={9} sm={4} md={3} lg={2}>
                                                <Card className={styles.timetableCard}>
                                                    <Box className={styles.cardContent}>
                                                        <Typography variant="h6" className={styles.subjectName}>
                                                            {lecture.subjectName}
                                                        </Typography>
                                                        <Typography variant="body2" className={styles.time}>
                                                            {lecture.startTime ? dayjs(lecture.startTime).format("h:mm A") : "NA"} to{" "}
                                                            {lecture.endTime ? dayjs(lecture.endTime).format("h:mm A") : "NA"}
                                                        </Typography>
                                                        <Box className={styles.cardDetails}>
                                                            <Typography variant="body2">{lecture.courseName}</Typography>
                                                            <Typography variant="body2">{lecture.facultyName}</Typography>
                                                            <Typography variant="body2">Room No: {lecture.roomNo}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                        ))
                                ) : (
                                    <h3 className={styles.noLectures}>
                                        No lectures scheduled for this day.
                                    </h3>
                                )}
                            </Grid>
                        </Box>
                    ))
                ) : (
                    <h2 className={styles.noData}>
                        No timetable data available.
                    </h2>
                )}
            </div>
        </div>
    );
};

export default StudentTimeTable;
