import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import styles from "./MembersList.module.css";
import UserContext from "../../../../../Context/UserContext";
import Toast from "../../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../config/config";

const getGroupMembersList = process.env.REACT_APP_CHAT_GET_GROUP_MEMBERS_LIST;
const getGroupUserList = process.env.REACT_APP_CHAT_GROUP_USERLIST;
const updateGroupMembersList = process.env.REACT_APP_CHAT_UPDATE_GROUP_MEMBERS_LIST;

const MembersList = ({ onClose, groupId, selectedGroup }) => {
  const [usersNotInGroup, setUsersNotInGroup] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [newEditedMemberList, setNewEditedMemberList] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const { Cookies, setShowLoader, getToken } = useContext(UserContext);

  const fetchGroupMemberList = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}${getGroupMembersList}`,
        { groupCode: selectedGroup.groupCode },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setUserList(response?.data?.data?.groupMemberList || [])
      } else {
        Toast.error(response.data.message || "Error fetching group members list !")
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }
  }

  const fetchUserListForGroupCreation = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${getGroupUserList}`, { headers: { Authorization: `Bearer ${getToken()}` } });  //changes here
      if (response.data.result) {
        setAllUsers(response?.data?.userList || []);
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }

  }

  const handleCheckboxChange = (event, user) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setNewEditedMemberList((prevList) => [
        ...prevList,
        user
      ])
      // setSelectedUsers((prevState) => [
      //   ...prevState,
      //   { name: `${user.fname} ${user.lname}`, id: user.email },
      // ]);
    } else {
      setNewEditedMemberList((prevList) => prevList.filter((userData) => userData._id !== user?._id))
      // setSelectedUsers((prevState) =>
      //   prevState.filter((selectedUser) => selectedUser.email !== user.email)
      // );
    }
  };

  const handleShowAddNewMembers = () => {
    setNewEditedMemberList([...userList]);
    setIsAdding(true);
  }
  const checkIsMember = (userData) => {
    const check = newEditedMemberList.some(data => data._id === userData?._id);
    return check

  }

  const handleUpdateMemberSave = async () => {
    try {
      const response = await axios.put(`${BASE_URL}${updateGroupMembersList}`, { newMemberList: newEditedMemberList || [], groupCode: selectedGroup.groupCode }, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        Toast.success(response.data.message || "Group members updated successfully !")
      } else {
        Toast.error(response.data.message || "Failed to update group members !");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  }
  useEffect(() => {
    fetchGroupMemberList();
    fetchUserListForGroupCreation();
  }, [])

  return (
    <div className={styles.userListPopup}>
      {!isAdding && (<>
        <h3>Group Members</h3>
        <ul>
          {userList.length > 0 ? (
            userList.map((user, index) => (
              <li key={index}>
                <label>
                  {/* <input
                  type="checkbox"
                  value={user?._id}
                  onChange={(e) => handleCheckboxChange(e, user)}
                /> */}
                  {user?.fname} {user?.lname} {user?._id ? "" : "(Missing ID)"}
                </label>
              </li>
            ))
          ) : (
            <h1>No User Found !</h1>
          )}
        </ul>
        <div className={styles.buttons}>
          {/* <button onClick={onClose}>Close</button> */}
          <button onClick={handleShowAddNewMembers}>Edit Members</button>
        </div></>)}

      {isAdding && (<>
        <h3>
          Edit Members <span onClick={() => setIsAdding(false)} >X</span>
        </h3>
        <ul>
          {allUsers.length > 0 ? (
            allUsers.map(
              (user, index) =>
              (

                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      // value={checkIsMember(user)}
                      checked={checkIsMember(user)}
                      // value={userList?.some((data) => data?._id === user?._id)} 
                      onChange={(e) => handleCheckboxChange(e, user)}
                    />
                    {user?.fname} {user?.lname}{" "}
                    {user?._id ? "" : "(Missing ID)"}
                  </label>
                </li>
              )
            )
          ) : (
            <h1>No User Found !</h1>
          )}
        </ul>
        <div className={styles.buttons}>
          <button onClick={onClose}>Cancel</button>
          <button onClick={handleUpdateMemberSave}>Save</button>
          {/* <button onClick={onAdd}>Add</button> */}
        </div></>)}
    </div>
  );
};

export default MembersList;
