import React, { useContext, useEffect, useState } from "react";
import styles from "./AddJob.module.css";
import dayjs from "dayjs";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { BASE_URL } from "../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import CloseIcon from "@mui/icons-material/Close";

const postJob = process.env.REACT_APP_ADMIN_RECRUITER_POST_JOB
const getDropdownJobPost = process.env.REACT_APP_ADMIN_RECRUITER_GET_DROPDOWN_JOB_POST

const AddJob = () => {
  const { getToken } = useContext(UserContext);
  const [formData, setFormData] = useState({
    jobTitle: "",
    jobType: "",
    jobDescription: "",
    keySkills: [],
    location: "",
    salary: "",
    applicationDeadline: null,
  });
  const [skills, setSkills] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);

  const navigate = useNavigate();

  const handleDepartmentChange = (e) => {
    const { name, value } = e.target;
    if (value.includes('deselect-all')) {
      setSelectedDepartments([]); // Deselect all
      return
    }
    if (value.includes('select-all')) {
      setSelectedDepartments(departmentDropdown.map(data => data.value));
      return
    }
    setSelectedDepartments(value);
  };

  const removeDepartment = (value) => {
    setSelectedDepartments(() =>
      selectedDepartments.filter((item) => item !== value)
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSkillChange = (event) => {
    const { target: { value } } = event;

    // Deselect all skills
    if (value.includes('deselect-all')) {
      setSkills([]);
      setFormData((prevData) => ({
        ...prevData,
        keySkills: [],
      }));
      return;
    }

    // Select all skills
    if (value.includes('select-all')) {
      setSkills(skillList);
      setFormData((prevData) => ({
        ...prevData,
        keySkills: skillList,
      }));
      return;
    }

    // Add or remove individual skills
    const selectedSkills = typeof value === "string" ? value.split(",") : value;
    setSkills(selectedSkills);

    // Update formData.keySkills as well
    setFormData((prev) => ({
      ...prev,
      keySkills: selectedSkills,
    }));
  };

  // Remove individual skill
  const removeSkill = (value) => {
    setSkills((prevSkills) => {
      const updatedSkills = prevSkills.filter((skill) => skill !== value);
      setFormData((prevData) => ({
        ...prevData,
        keySkills: updatedSkills,
      }));
      return updatedSkills;
    });
  };


  const validateForm = () => {
    let validationErrors = {};

    const requiredFields = [
      "jobTitle",
      "jobType",
      "jobDescription",
      "keySkills",
      "location",
      "salary",
      "applicationDeadline",
      "hiringDepartments",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];

      if (typeof value === "string" && (!value || value.trim() === "")) {
        validationErrors[field] = "**This field is required**";
      }

      if (Array.isArray(value) && value.length === 0) {
        validationErrors[field] = "**This field is required**";
      }

      if (field === "jobTitle" && value && value.length > 40) {
        validationErrors["jobTitle"] = "**Job Title must be less than 40 characters**";
      }

      if (field === "location" && value && value.length > 40) {
        validationErrors["location"] = "**Location must be less than 40 characters**";
      }

      if (field === "jobDescription" && value && value.length > 500) {
        validationErrors["jobDescription"] = "**Description must be less than 500 characters**";
      }

      if (!formData.applicationDeadline || !dayjs(formData.applicationDeadline).isValid()) {
        validationErrors["applicationDeadline"] = "**This field is required**";
      } else if (dayjs(formData.applicationDeadline).isBefore(dayjs(), "day")) {
        validationErrors["applicationDeadline"] = "**Application deadline cannot be in the past**";
      }
    });

    if (selectedDepartments.length === 0) {
      validationErrors["hiringDepartments"] = "**This field is required**";
    }

    if (formData.keySkills.length === 0) {
      validationErrors["keySkills"] = "**This field is required**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };



  useEffect(() => {
    validateForm();
  }, [formData, selectedDepartments, skills]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      formData.hiringDepartments = selectedDepartments;
      formData.keySkills = skills;
      const response = await axios.post(`${BASE_URL}${postJob}`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data?.result) {
        Toast.success(response.data?.message || "Job Added Successfuly...!");
        setFormData({
          jobTitle: "",
          jobType: "",
          jobDescription: "",
          keySkills: [],
          location: "",
          salary: "",
          applicationDeadline: null,
          hiringInstitutes: [],
        });
        setErrors({});
      } else {
        Toast.error(response.data?.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      navigate(-1);
    }
  };

  const fetchDropdown = async (req, res) => {
    try {
      const response = await axios.get(`${BASE_URL}${getDropdownJobPost}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        const data = response.data.data;
        setSkillList(data?.skillsList || []);
        setJobTypes(data?.jobTypes || []);
        setDepartmentDropdown(data?.departmentList || []);
        // departmentList
      }
    } catch (error) { }
  };

  const handleDateChange = (newValue) => {
    if (newValue && !dayjs(newValue).isValid()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        applicationDeadline: "**Invalid date format**",
      }));
    } else if (newValue && dayjs(newValue).isBefore(dayjs(), "day")) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        applicationDeadline: "**Application deadline cannot be in the past**",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        applicationDeadline: "",
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      applicationDeadline: newValue ? dayjs(newValue).format("YYYY-MM-DD") : null,
    }));
  };

  useEffect(() => {
    fetchDropdown();
  }, []);

  return (
    <Box>
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        <Box className={styles.closeIconContainer}>
          <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
            <CloseIcon />
          </IconButton>
        </Box>

        <h2 className={styles.addJobtitle}>Add New Job</h2>

        <Box className={styles.addJobContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="jobType">Job Type</label>
                <Dropdown
                  options={jobTypes}
                  onChange={handleChange}
                  value={formData.jobType}
                  name="jobType"
                />
                {errors.jobType && <p className={styles.error}>{errors.jobType}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  placeholder="Job Title"
                  className={styles.inputbox}
                />
                {errors.jobTitle && <p className={styles.error}>{errors.jobTitle}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="departmentList">Department List</label>
                <div className={styles.departmentListContainer}>
                  {selectedDepartments.length > 0 ? (
                    selectedDepartments.map((value) => {
                      const departmentLabel =
                        departmentDropdown.find((data) => data.value === value)?.label || "Unknown";
                      return (
                        <Tooltip key={value} title={departmentLabel} arrow>
                          <Button
                            onClick={() => removeDepartment(value)}
                            className={styles.departmentNameButton}
                          >
                            {
                              departmentLabel.length > 10
                                ? departmentLabel.substring(0, 10) + "..."  // Truncate long labels
                                : departmentLabel
                            }
                          </Button>
                        </Tooltip>
                      );
                    })
                  ) : (
                    <p className={styles.noDepartmentText}>**No department selected**</p>
                  )}
                </div>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedDepartments}
                  onChange={handleDepartmentChange}
                  className={styles.selectOption}
                >
                  <MenuItem
                    value={
                      selectedDepartments.length ===
                        departmentDropdown.length
                        ? "deselect-all"
                        : "select-all"
                    }
                  >
                    {selectedDepartments.length ===
                      departmentDropdown.length
                      ? "Deselect All"
                      : "Select All"}
                  </MenuItem>
                  {departmentDropdown.map((departmentData, index) => {
                    if (
                      !selectedDepartments.some(
                        (data) => data === departmentData.value
                      )
                    ) {
                      return (
                        <MenuItem key={index} value={departmentData?.value}>
                          {departmentData?.label}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                {errors.hiringDepartments && <p className={styles.error}>{errors.hiringDepartments}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="skills">Key Skills</label>
                <div className={styles.skillsContainer}>
                  {skills.length > 0 ? (
                    skills.map((value) => {
                      return (
                        <Tooltip key={value} title={value} arrow>
                          <Button
                            onClick={() => removeSkill(value)}
                            className={styles.skillButton}
                          >
                            {value.length > 7 ? value.substring(0, 7) + "..." : value}
                          </Button>
                        </Tooltip>
                      );
                    })
                  ) : (
                    <p className={styles.noSkillsText}>**No skills selected**</p>
                  )}
                </div>

                <Select
                  labelId="skills-select-label"
                  id="skills-select"
                  multiple
                  value={skills}
                  onChange={handleSkillChange}
                  className={styles.selectOption}
                >
                  <MenuItem
                    value={
                      skills.length ===
                        skillList.length
                        ? "deselect-all"
                        : "select-all"
                    }
                  >
                    {skills.length ===
                      skillList.length
                      ? "Deselect All"
                      : "Select All"}
                  </MenuItem>

                  {skillList.map((skillName) => {
                    // Only show unselected skills
                    if (!skills.includes(skillName)) {
                      return (
                        <MenuItem key={skillName} value={skillName}>
                          {skillName}
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
                </Select>
                {errors.keySkills && <p className={styles.error}>{errors.keySkills}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="location">Job Location</label>
                <input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder="Job Location"
                  className={styles.inputbox}
                />
                {errors.location && <p className={styles.error}>{errors.location}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="salary">Salary / Stipend</label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="salary"
                  name="salary"
                  value={formData.salary}
                  onChange={handleChange}
                  placeholder="Salary / Stipend"
                  className={styles.inputbox}
                />
                {errors.salary && <p className={styles.error}>{errors.salary}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className={styles.inputGroup}>
                  <label htmlFor="applicationDeadline">Application Deadline</label>
                  <DesktopDatePicker
                    className={styles.datePicker}
                    views={["year", "month", "day"]}
                    inputFormat="MM/DD/YYYY"
                    value={
                      formData.applicationDeadline
                        ? dayjs(formData.applicationDeadline)
                        : null
                    }
                    onChange={handleDateChange}
                  />
                  {errors.applicationDeadline && <p className={styles.error}>{errors.applicationDeadline}</p>}
                </div>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="jobDescription">Job Description</label>
                <textarea
                  id="jobDescription"
                  name="jobDescription"
                  value={formData.jobDescription}
                  onChange={handleChange}
                  placeholder="Job Description"
                  className={styles.jobDescriptionInput}
                  rows="10"
                  cols="50"
                />
                {errors.jobDescription && <p className={styles.error}>{errors.jobDescription}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                className={styles.submitButton}
                type="submit"
                variant="contained"
                disabled={isSaveDisabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default AddJob;
