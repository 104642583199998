import React, { useContext, useEffect, useState } from "react";
import styles from "./CompanyList.module.css";
import { Box, Button, Grid, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import TableComponent from "../../CommonTable/CommonTable";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";
import Toast from "../../ToastContainer/ToastContainer";
import Dropdown from "../../DropDown/DropDown";

// const dropdownCompanyRegister = process.env.REACT_APP_ADMIN_DROPDOWN_COMPANY;
const getCompanies = process.env.REACT_APP_ADMIN_GET_COMPANIES;
const updateCompany = process.env.REACT_APP_ADMIN_UPDATE_COMPANY;
const deleteCompany = process.env.REACT_APP_ADMIN_DELETE_COMPANY;
const recoverCompany = process.env.REACT_APP_ADMIN_RECOVER_COMPANY
const dropdownCountryName = process.env.REACT_APP_DROPDOWN_GET_COUNTRY_NAMES;
const dropdownStateName = process.env.REACT_APP_DROPDOWN_GET_STATE_NAMES;
const dropdownDistrictName = process.env.REACT_APP_DROPDOWN_GET_DISTRICT_NAMES;
const dropdownCompanyRegister = process.env.REACT_APP_ADMIN_DROPDOWN_FOR_REGISTER_COMPANY;

const CompanyList = () => {
  const { getToken, setShowLoader, getUserRole, showConfirmation } =
    useContext(UserContext);
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: "",
    companyEmail: "",
    companyPhone: "",
    employeeSize: "",
    companyType: "",
    country: "",
    state: "",
    district: "",
    city: "",
    addressLine: "",
    pincode: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [companyData, setCompanyData] = useState([]);
  const [filteredCompanyData, setFilteredCompanyData] = useState([]);
  const [dropDownData, setDropDownData] = useState({
    companyTypeList: [],
  });
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  const columns = [
    "Company Name",
    "Company Website",
    "Company Email",
    "Company Phone",
    "Employee Size",
    "Company Type",
    "country",
    "state",
    "district",
    "city",
    "Address Line",
    "pincode",
  ];

  const handleAddCompany = () => {
    navigate(`/${getUserRole()}/company/register-company`);
  };

  const handleChangeSearch = (e) => {
    setSearchText(() => e.target.value);
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {

    if (!searchText) {
      setFilteredCompanyData([...companyData])
    } else {
      const filteredData = companyData.filter((company) =>
        String(company?.companyName)
          .toLowerCase()
          .includes(String(searchText).toLowerCase())
      );
      setFilteredCompanyData([...filteredData]);
    }

  }, [searchText])

  const fetchCompanyData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getCompanies}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        const transformedRows = response?.data?.data?.map((company) => ({
          ...company,
          "Company Name": company.companyName || "N/A",
          "Company Website": company.companyWebsite || "N/A",
          "Company Email": company.companyEmail || "N/A",
          "Company Phone": company.companyPhone || "N/A",
          "Employee Size": company.employeeSize || "N/A",
          "Company Type": company.companyType || "N/A",
          // "Country": company.companyAddress.country || "N/A",
          // "State": company.companyAddress.state || "N/A",
          "District": company.companyAddress.district || "N/A",
          // "City": company.companyAddress.city || "N/A",
          "Address Line": company.companyAddress.addressLine || "N/A",
          // "Pincode": company.companyAddress.pincode || "N/A",
          "companyType": company.companyType || "N/A",
          "country": company.companyAddress.country || "N/A",
          "state": company.companyAddress.state || "N/A",
          "district": company.companyAddress.district || "N/A",
          "city": company.companyAddress.city || "N/A",
          "addressLine": company.companyAddress.addressLine || "N/A",
          "pincode": company.companyAddress.pincode || "N/A",
        }));
        setCompanyData(transformedRows);
        setFilteredCompanyData(transformedRows)
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  // const fetchDropdown = async () => {
  //   try {
  //     setShowLoader(true);
  //     const response = await axios.get(
  //       `${BASE_URL}${dropdownCompanyRegister}`,
  //       { headers: { Authorization: `Bearer ${getToken()}` } }
  //     );
  //     if (response.data.result) {
  //       setDropDownData(response.data.data);
  //     }
  //   } catch (error) {
  //     Toast.error(error?.response?.data?.message || "Something went wrong!");
  //   } finally {
  //     setShowLoader(false);
  //   }
  // };

  useEffect(() => {
    fetchCompanyData();
    // fetchDropdown();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateForm();
  };

  const fetchCountryNames = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownCountryName}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response.data.result) {
        setCountries(response.data.data);
      } else {
        Toast.error(response.data.message || "Failed to fetch countries!");
      }
    } catch (error) {
      Toast.error("Failed to fetch countries!");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchStates = async (country) => {
    if (!country) {
      setStates([]);
      return;
    }
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownStateName}?country=${country}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setStates(response.data.data);
      }
    } catch (error) {
      Toast.error("Failed to fetch states!");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchDistricts = async (country, state) => {
    if (!country || !state) {
      setDistricts([]);
      return;
    }
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownDistrictName}?country=${country}&state=${state}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setDistricts(response.data.data);
      } else {
        Toast.error("Failed to fetch districts!");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Failed to fetch districts!");
    } finally {
      setShowLoader(false);
    }
  };


  useEffect(() => {
    if (formData.country) {
      fetchStates(formData.country);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      fetchDistricts(formData.country, formData.state);
    }
  }, [formData.state]);

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "companyName",
      "companyWebsite",
      "companyEmail",
      "companyPhone",
      "employeeSize",
      "companyType",
      "country",
      "state",
      "district",
      "city",
      "addressLine",
      "pincode",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownCompanyRegister}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setDropDownData(response.data.data);
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };


  const handleEdit = (row) => {
    fetchDropdown();
    fetchCountryNames();
    setCurrentRow({ ...row });
    setFormData({
      companyName: row.companyName ? row.companyName : "N/A",
      companyWebsite: row.companyWebsite ? row.companyWebsite : "N/A",
      companyEmail: row.companyEmail ? row.companyEmail : "N/A",
      companyPhone: row.companyPhone ? row.companyPhone : "N/A",
      employeeSize: row.employeeSize ? row.employeeSize : "N/A",
      companyType: row.companyType ? row.companyType : "N/A",
      country: row.country ? row.country : "N/A",
      state: row.state ? row.state : "N/A",
      district: row.district ? row.district : "N/A",
      city: row.city ? row.city : "N/A",
      addressLine: row.addressLine ? row.addressLine : "N/A",
      pincode: row.pincode ? row.pincode : "N/A",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEditing(false);
    setFormData({
      companyName: "",
      companyWebsite: "",
      companyEmail: "",
      companyPhone: "",
      employeeSize: "",
      companyType: "",
      country: "",
      state: "",
      district: "",
      city: "",
      addressLine: "",
      pincode: "",
    });
    setErrors({});
  };

  const handleSave = async (e) => {
    e.preventDefault();
    validateForm();

    try {
      setShowLoader(true);

      const updatedFormData = {
        ...formData,
        companyId: currentRow?._id,
      };

      const response = await axios.put(
        `${BASE_URL}${updateCompany}`,
        updatedFormData,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.result) {
        Toast.success("Company updated successfully.");
        setOpen(false);
        fetchCompanyData();
      } else {
        Toast.error(response?.data?.message || "Failed to update company!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };


  const handleDelete = async (companyData) => {
    try {
      setShowLoader(true);
      const response = await axios.delete(
        `${BASE_URL}${deleteCompany}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          data: { companyId: companyData._id },
        }
      );

      if (response.data.result) {
        Toast.success("Company deleted successfully.");
        fetchCompanyData();
      } else {
        Toast.error(response?.data?.message || "Failed to delete company.");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const handleRecover = async (companyData) => {
    try {
      // const token = Cookies.get("token");
      setShowLoader(true);
      // return
      const data = { companyId: companyData?._id, isDeleted: true };

      const response = await axios.put(`${BASE_URL}${recoverCompany}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchCompanyData();
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Companies</h1>
      <Box className={styles.companyParentContainer}>
        <Box className={styles.inputParentContainer}>
          <Box className={styles.addCompanyButtonContainer}>
            <Button
              variant="contained"
              className={styles.addCompanyButton}
              onClick={handleAddCompany}
            >
              Add Company
            </Button>
          </Box>
        </Box>

        <Box className={styles.inputContainer}>
          <label htmlFor="searchCompany" className={styles.searchLabel}>
            Search Company
          </label>
          <input
            id="searchCompany"
            className={styles.searchCompany}
            onChange={handleChangeSearch}
            variant="outlined"
          />
        </Box>

        {filteredCompanyData && filteredCompanyData.length > 0 ? (
          <TableComponent
            className={styles.table}
            columns={columns}
            rows={filteredCompanyData}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onRecover={handleRecover}

          />
        ) : (
          <h2 className={styles.noData}>
            No Company data available. You can add a new company.
          </h2>
        )}

        <Modal className={styles.modal} open={open} onClose={handleClose}>
          <Box className={styles.updateCompanyContainer}>
            <Box className={styles.closeIconContainer}>
              <IconButton>
                <CloseIcon
                  className={styles.closeButton}
                  onClick={handleClose}
                />
              </IconButton>
            </Box>

            <h2 className={styles.updateCompanyHeader}>EDIT COMPANY</h2>
            <div className={styles.updateCompanyScrollable}>
              <form className={styles.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="companyName">Company Name</label>
                      <input
                        type="text"
                        id="companyName"
                        placeholder="Company Name"
                        value={formData.companyName}
                        name="companyName"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.companyName && <p className={styles.error}>{errors.companyName}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="companyWebsite">Company Website</label>
                      <input
                        type="text"
                        id="companyWebsite"
                        placeholder="Company Website"
                        value={formData.companyWebsite}
                        name="companyWebsite"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.companyWebsite && <p className={styles.error}>{errors.companyWebsite}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="companyEmail">Company Email</label>
                      <input
                        type="email"
                        id="companyEmail"
                        placeholder="Company Email"
                        value={formData.companyEmail}
                        name="companyEmail"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.companyEmail && <p className={styles.error}>{errors.companyEmail}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="companyPhone">Company Phone</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="companyPhone"
                        placeholder="Company Phone"
                        value={formData.companyPhone}
                        name="companyPhone"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.companyPhone && <p className={styles.error}>{errors.companyPhone}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="employeeSize">Employee Size</label>
                      <input
                        type="text"
                        id="employeeSize"
                        placeholder="Employee Size"
                        value={formData.employeeSize}
                        name="employeeSize"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.employeeSize && <p className={styles.error}>{errors.employeeSize}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="companyType">Company Type</label>
                      <Dropdown
                        id="companyType"
                        name="companyType"
                        options={dropDownData.companyTypeList}
                        value={formData.companyType}
                        onChange={handleChange}
                      />
                      {errors.companyType && <p className={styles.error}>{errors.companyType}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="country">Country</label>
                      <Dropdown
                        id="country"
                        name="country"
                        options={countries}
                        value={formData.country}
                        onChange={handleChange}
                      />
                      {errors.country && <p className={styles.error}>{errors.country}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="state">State</label>
                      <Dropdown
                        id="state"
                        name="state"
                        options={states}
                        value={formData.state}
                        onChange={handleChange}
                      />
                      {errors.state && <p className={styles.error}>{errors.state}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="district">District</label>
                      <Dropdown
                        id="district"
                        name="district"
                        options={districts}
                        value={formData.district}
                        onChange={handleChange}
                      />
                      {errors.district && <p className={styles.error}>{errors.district}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        id="city"
                        placeholder="City"
                        value={formData.city}
                        name="city"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.city && <p className={styles.error}>{errors.city}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="pincode">Pincode</label>
                      <input
                        type="text"
                        id="pincode"
                        placeholder="Pincode"
                        value={formData.pincode}
                        name="pincode"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.pincode && <p className={styles.error}>{errors.pincode}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="addressLine">Address Line</label>
                      <input
                        type="text"
                        id="addressLine"
                        placeholder="Address Line"
                        value={formData.addressLine}
                        name="addressLine"
                        onChange={handleChange}
                        className={styles.inputbox}
                      />
                      {errors.addressLine && <p className={styles.error}>{errors.addressLine}</p>}
                    </div>
                  </Grid>

                  <Grid item xs={12} className={styles.submitButton}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      disabled={isSaveDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>

              </form>
            </div>
          </Box>
        </Modal>
      </Box >
    </div >
  );
};

export default CompanyList;
