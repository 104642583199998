import React, { useContext, useEffect, useState } from "react";
import styles from "./JobApplications.module.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewDocument from "../../../../CommonComponent/ViewDocument/ViewDocument";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getStudentResume = process.env.REACT_APP_ADMIN_RECRUITER_GET_STUDENT_RESUME;
const getJobApplications = process.env.REACT_APP_ADMIN_RECRUITER_GET_JOB_APPLICATIONS;
const updateApplicationStatus = process.env.REACT_APP_ADMIN_RECRUITER_UPDATE_APPLICATION_STATUS;
const viewJobApplications = process.env.REACT_APP_ADMIN_RECRUITER_DROPDOWN_VIEW_JOB_APPLICATIONS;


const JobApplications = () => {
  const { getToken, getUserRole } = useContext(UserContext);
  const { jobPostId } = useParams();
  const [jobPostData, setJobPostData] = useState({});
  const [jobApplications, setJobApplications] = useState([]);
  const [filteredJobApplications, setFilteredJobApplications] = useState([]);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [filterSelected, setFilterSelected] = useState({
    searchText: "",
    applicationStatus: ""
  })
  const navigate = useNavigate();

  const fetchJobApplications = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}${getJobApplications}`,
        { jobPostId: jobPostId },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setJobApplications(response?.data?.data?.jobApplications || []);
        setFilteredJobApplications(response?.data?.data?.jobApplications || []);
        setJobPostData(response?.data?.data || {});
      }
    } catch (error) { }
  };

  const handleFilterChange = async (event) => {
    setFilterSelected({ ...filterSelected, [event.target.name]: event.target.value });

  }

  const handleChangeApplicationStatus = async (applicationdata, status) => {
    try {
      const dataToSend = {
        jobPostId: jobPostId,
        applicationStatus: status,
        applicationId: applicationdata.applicationId,
      };

      const response = await axios.post(
        `${BASE_URL}${updateApplicationStatus}`,
        dataToSend,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        Toast.success(
          response.data.message || "Job Application Updated Successfully..!"
        );
        localStorage.setItem("notificationCount", response.data?.notificationCount)
        fetchJobApplications();
      } else {
        Toast.error(
          response.data.message || "Failed to update Job Application Status..!"
        );
      }
    } catch (error) {
      Toast.error(
        error.response.data.message ||
        "Failed to update Job Application Status..!"
      );
    }
  };

  const handleViewResume = async (applicationdata) => {
    try {
      const response = await axios.post(
        `${BASE_URL}${getStudentResume}`,
        { applicationId: applicationdata.applicationId, jobPostId: jobPostId },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.result) {
        generatePdf(response.data.data);
        // Debug: Log the resume data to see what format it is in
      } else {
        Toast.error(response.data.message || "Failed to view resume!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong! ..!");
    }
  };

  const generatePdf = (resume) => {
    const docDefinition = {
      content: [
        {
          text: 'My Resume',
          style: 'header',
        },
        {
          text: 'Personal Details',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        {
          columns: [
            {
              text: `Name: ${resume?.fname} ${resume?.mname} ${resume?.lname}`, style: 'normal',
            },
            {
              text: `Email: ${resume?.email}`, style: 'normal',
            },
          ],
          columnGap: 25,
        },
        {
          columns: [
            { text: `Phone: ${resume?.primaryPhone}`, style: 'normal' },
            { text: `Linkedin: ${resume?.socialMedia?.find(sm => sm.name.toLowerCase() === 'linkedin')?.link || 'N/A'}`, style: 'normal' },
          ],
          columnGap: 25,
        },
        {
          columns: [
            { text: `Institute Name: ${resume?.educationalDetails?.[0]?.institute || 'N/A'}`, style: 'normal' },
            { text: `Github: ${resume?.socialMedia?.find(sm => sm?.name?.toLowerCase() === 'github')?.link || 'N/A'}`, style: 'normal' },
          ],
          columnGap: 25,
        },
        { text: `Department: ${resume?.educationalDetails?.[0]?.department || 'N/A'}`, style: 'normal' },
        { text: `My Role: ${resume?.userRole || 'N/A'}`, style: 'normal' },
        { text: `DOB: ${resume.dob ? new Date(resume.dob).toLocaleDateString() : "N/A"}`, style: 'normal' },
        {
          text: "Summary",
          style: 'subHeader',
          decoration: 'underline',
        },
        {
          text: `${resume?.summary || 'N/A'}`,
          style: 'normal',
          alignment: 'center',
        },

        // Education Section
        {
          text: 'Education',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume?.educationalDetails) ? resume?.educationalDetails.map((item, index) => {
          let displayValue = "";
          if (item?.percentage !== null && item?.percentage !== undefined) {
            displayValue = `Percentage - ${item?.percentage}%`;
          } else if (item?.cgpa !== null && item?.cgpa !== undefined) {
            displayValue = `CGPA - ${item?.cgpa.toFixed(2)}`;
          } else {
            displayValue = "N/A";
          }

          return {
            text: [
              { text: `${item?.courseName || 'N/A'}`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
              { text: ` at ${item?.institute || 'N/A'}`, style: 'normal', bold: true },
              { text: ` (${item?.university || 'N/A'})`, style: 'normal', margin: [0, 0, 0, 0] },
              { text: `[ ${displayValue} ]`, style: 'normal', margin: [0, 5, 0, 0] },
            ],
            margin: [0, 5, 0, 10],
          };
        }) : []),


        // Work Experience Section
        {
          text: 'Work Experience',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume?.pastExperience) ? resume?.pastExperience?.map((item, index) => ({
          text: [
            { text: `${item?.position} at ${item.company} (${item?.location})\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
            { text: `${item.roleDescription}`, style: 'normal', margin: [0, 5, 0, 0] },
          ],
          margin: [0, 5, 0, 10],
        })) : []),

        // Projects Section
        {
          text: 'Projects',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.projects) ? resume.projects.map((item, index) => ({
          text: [
            { text: `${item.projectName}\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
            { text: `${item.projectDescription}\n`, style: 'normal', margin: [0, 5, 0, 0] },
            { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
          ],
          margin: [0, 5, 0, 10],
        })) : []),

        // Skills Section
        {
          text: 'Skills',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.skills) ? resume.skills.map(item => ({
          text: `${item.skill} - ${item.level}`,
          style: 'normal',
          margin: [0, 5, 0, 0],
        })) : []),

        // Languages Section
        {
          text: 'Languages',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.languages) ? resume.languages.map(item => ({
          text: item,
          style: 'normal',
          margin: [0, 5, 0, 0],
        })) : []),

        // Hobbies Section
        {
          text: 'Hobbies',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.hobbies) ? resume.hobbies.map(item => ({
          text: item,
          style: 'normal',
          margin: [0, 5, 0, 0],
        })) : []),

        // Certifications Section
        {
          text: 'Certifications',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.certifications) ? resume.certifications.map(item => ({
          text: [
            { text: `${item.certification}`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
            { text: ` by ${item.issuedBy}\n`, style: 'normal', margin: [0, 5, 0, 0] },
            { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
          ],
          margin: [0, 5, 0, 5],
        })) : []),

        // Achievements Section
        {
          text: 'Achievements',
          style: 'sectionHeader',
          decoration: 'underline',
        },
        ...(Array.isArray(resume.achievements) ? resume.achievements.map(item => ({
          text: [
            { text: `${item.achievement}\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
            { text: `${item.description}\n`, style: 'normal', margin: [0, 0, 0, 5] },
            { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
          ],
          margin: [0, 5, 0, 10],
        })) : []),
      ],
      styles: {
        header: {
          fontSize: 24,
          bold: true,
          alignment: 'center',
          margin: [0, 10, 0, 10],
        },
        subHeader: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 10, 0, 5],
        },
        sectionHeader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        normal: {
          fontSize: 12,
          margin: [0, 5, 0, 5],
        },
      },
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);

    // Save the PDF as a Blob
    pdfDoc.getBlob((blob) => {
      setPdfBlob(blob);
      setIsPreviewVisible(true); // Show the preview modal
    });
  };

  const handleViewProfile = (enrollmentId) => {
    navigate(`/${getUserRole()}/community/view-user/${enrollmentId}`);
  };


  const fetchDropdown = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${viewJobApplications}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        setStatusDropdown(response.data?.data?.applicationStatusList)
      } else {
        Toast.error(response.data.message || "Something went wrong!")
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");

    }
  }

  const handlePreviousClick = () => {
    navigate(-1);
  }

  useEffect(() => {
    let filtered = []
    if (filterSelected.searchText) {
      filtered = jobApplications.filter(item => (item.candidateName.toLowerCase().includes(filterSelected.searchText.toLowerCase()) || item.candidateEnrollmentId.toLowerCase().includes(filterSelected.searchText.toLowerCase())))
    } else {
      filtered = [...jobApplications]
    }

    if (filterSelected.applicationStatus) {
      filtered = filtered.filter(item => item.applicationStatus === filterSelected.applicationStatus)
    }
    setFilteredJobApplications(filtered);

  }, [filterSelected])

  useEffect(() => {
    fetchDropdown();
    fetchJobApplications();
  }, [])

  const handleMessageIconClick = (message) => {
    setUserMessage(message);
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={styles.jobApplicationContainer}>
      <h1 className={styles.title}>Job Applications</h1>
      <Box className={styles.closeIconContainer}>
        <IconButton
          className={styles.closeButtonEditEducation}
          onClick={handlePreviousClick}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Box>
      <div className={styles.dropDownParentContainer}>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="searchBar" className={styles.searchLabel}>
            Search ( Name or enrollmentId )
          </label>
          <input
            id="searchBar"
            className={styles.searchBar}
            variant="outlined"
            value={filterSelected.searchText}
            name="searchText"
            onChange={handleFilterChange}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="filterJobType" className={styles.dropDownLabel}>
            Application Status
          </label>
          <Dropdown
            options={statusDropdown}
            onChange={handleFilterChange}
            value={filterSelected.applicationStatus}
            name={"applicationStatus"}
          />
        </Box>
      </div>
      <div className={styles.applicationMainContainer}>
        {filteredJobApplications.length > 0 ? (
          filteredJobApplications?.map((application) => {
            return (
              <div
                className={styles.applicationCard}
                key={application.applicationId}
              >
                <h1 className={styles.candidateName}>
                  {application.candidateName}
                </h1>
                <div className={styles.messageContainer}>
                  {/* <IconButton > */}
                  <MailOutlineIcon
                    onClick={() => handleMessageIconClick(application?.additionalComment || "No message")}
                    className={styles.messageIcon}
                  />
                  {/* </IconButton> */}
                </div>

                <div className={styles.additionalDetails}>
                  <h3>
                    Email: <p>{application.candidateEmail}</p>
                  </h3>
                  <h3>
                    Phone: <p>{application.candidatePhone}</p>
                  </h3>
                  <h3>
                    Enrollment Id: <p>{application.candidateEnrollmentId}</p>
                  </h3>
                  <h3>
                    Applied On:
                    <p>
                      {new Date(application.appliedDate).toLocaleDateString(
                        "en-US"
                      )}
                    </p>
                  </h3>
                </div>

                <h2
                  className={`${styles.applicationStatus} ${application.applicationStatus !== "Pending"
                    ? application.applicationStatus === "Shortlisted"
                      ? styles.approved
                      : styles.rejected
                    : styles.pending
                    }`}
                >
                  Application Status: {application.applicationStatus}
                </h2>


                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    onClick={() => handleViewResume(application)} // Fetch and preview the resume
                  >
                    <VisibilityIcon sx={{ marginRight: "3px", height: "19px" }} />
                    Resume
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.viewProfileButton}
                    onClick={() => {
                      handleViewProfile(application.candidateEnrollmentId);
                    }}
                  >
                    <VisibilityIcon sx={{ marginRight: "3px", height: "19px" }} />
                    Profile
                  </Button>

                  {application.applicationStatus === "Pending" && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          handleChangeApplicationStatus(
                            application,
                            "Shortlisted"
                          )
                        }
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleChangeApplicationStatus(application, "Rejected")
                        }
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <h1 className={styles.noApplicationsText}>
            **No Applications Found!**
          </h1>
        )}
      </div>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Message</DialogTitle>
        <DialogContent>
          <p>{userMessage}</p>
        </DialogContent>
        <DialogActions>
          {/* Close button */}
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <ViewDocument
        isOpen={isPreviewVisible}
        onClose={() => {
          setIsPreviewVisible(false);
          setPdfBlob(null);
        }}
        documentUrl={pdfBlob ? URL.createObjectURL(pdfBlob) : ""}
      />
    </div>
  );
};

export default JobApplications;
