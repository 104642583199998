import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../../ToastContainer/ToastContainer";
import UserContext from "../../../../../Context/UserContext";
import { BASE_URL } from "./../../../../../config/config";
import styles from "./Summary.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditSummary from "./EditSummary/EditSummary";
import summaryIcon from "../../../../../images/calendar (1).png"

const getSummary = process.env.REACT_APP_GET_SUMMARY;
const addSummary = process.env.REACT_APP_ADD_SUMMARY;
const deleteSummary = process.env.REACT_APP_DELETE_SUMMARY;

const Summary = () => {
    const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [summary, setSummary] = useState("");
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(true);

    useEffect(() => {
        fetchSummaryData();
    }, []);

    const fetchSummaryData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getSummary}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = response.data.data || "";
            setSummary(data);
            setIsAddButtonVisible(data === "");
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleOpenModal = (data) => {
        setSelectedData(data);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedData({});
    };

    const handleAddSummary = async (data) => {
        try {
            setShowLoader(true);
            const response = await axios.post(`${BASE_URL}${addSummary}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.result === true) {
                setSummary(data);
                Toast.success(response?.data?.message || "Summary added.");
                setIsAddButtonVisible(false);
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            handleCloseModal();
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDeleteSummary = async () => {
        try {
            setShowLoader(true);
            const response = await axios.delete(`${BASE_URL}${deleteSummary}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                data: {},
            });

            if (response.data.result) {
                setSummary("");
                Toast.success(response?.data?.message || "Summary deleted!");
                setIsAddButtonVisible(true);
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.summarySection}>
                <h2 className={styles.headingSummary}>
                    Summary{" "}
                    <img src={summaryIcon} alt="Summary Icon" className={styles.icon} />
                </h2>
                {summary ? (
                    <div className={styles.summarList}>
                        <div className={styles.yourSummary}>
                            <div className={styles.summaryHeader}>
                                <h3>My Summary</h3>
                                <Box className={styles.closeIconContainer}>
                                    <IconButton
                                        onClick={() =>
                                            showConfirmation(
                                                "Are you sure you want to delete this summary?",
                                                handleDeleteSummary
                                            )
                                        }
                                    >
                                        <DeleteIcon className={styles.deleteSummaryButton} />
                                    </IconButton>
                                </Box>
                            </div>
                            <hr className={styles.horizontalRow} />
                            <div className={styles.summaryContent}>
                                <p>
                                    {summary}
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p className={styles.noData}>No summary records available.</p>
                )}
                {isAddButtonVisible && (
                    <Button
                        variant="contained"
                        onClick={() => handleOpenModal(null)}
                        className={styles.addButton}
                    >
                        <AddIcon />
                        <span>Add Summary</span>
                    </Button>
                )}
            </div>

            <Modal
                className={styles.modal}
                open={isModalOpen}
                onClose={handleCloseModal}
            >
                <EditSummary
                    summaryData={selectedData}
                    handleAddSummary={handleAddSummary}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>
        </div>
    );
};

export default Summary;
