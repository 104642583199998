import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import styles from "./CreateClassroom.module.css";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const addRoom = process.env.REACT_APP_ADMIN_ADD_ROOM;
const dropdownClassroomRegister = process.env.REACT_APP_ADMIN_DROPDOWN_CLASSROOM;

const CreateClassroom = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [formData, setFormData] = useState({
    roomNo: "",
    roomType: "",
    floorNo: "",
    latitude: "",
    longitude: "",
    altitude: "",
    radius: "",
  });
  const [dropDownData, setDropDownData] = useState({
    roomTypes: [],
  });
  const [locationData, setLocationData] = useState({
    latitude: '',
    longitude: '',
    altitude: '',
  });
  const navigate = useNavigate();

  const readingsCount = 5; // Number of geolocation readings to get stability
  const readingInterval = 1000; // Interval between readings in milliseconds
  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "roomNo",
      "roomType",
      "floorNo",
      "latitude",
      "longitude",
      "altitude",
      "radius",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.roomNo.length > 6) {
      validationErrors["roomNo"] = "**Maximum Characters reached.**";
    }

    if (formData.floorNo && (formData.floorNo < 1 || formData.floorNo > 10)) {
      validationErrors["floorNo"] = "**Floors must be between 1 and 10**";
    }

    const longitude = parseFloat(formData.longitude);
    const latitude = parseFloat(formData.latitude);
    const radius = parseFloat(formData.radius);

    if (longitude < -180 || longitude > 180) {
      validationErrors["longitude"] = "**Longitude must be between -180 and 180**";
    }

    if (latitude < -90 || latitude > 90) {
      validationErrors["latitude"] = "**Latitude must be between -90 and 90**";
    }

    if (!formData.altitude || formData.altitude <= 0) {
      validationErrors["altitude"] = formData.altitude ? "**Altitude must be greater than 0**" : "**This field is required**";
    }

    if (radius < 0) {
      validationErrors["radius"] = "**Radius must be a positive number**";
    } else if (radius > 10000) {
      validationErrors["radius"] = "**Radius must be less than or equal to 10,000 kilometers**";
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${addRoom}`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response.data.result) {
        Toast.success(
          response.data.message || "Classroom Added Successfully...!"
        );
        setFormData({
          roomNo: "",
          roomType: "",
          floorNo: "",
          latitude: "",
          longitude: "",
          altitude: "",
          radius: "",
        });
      } else {
        Toast.error(response.data.message || "Failed to add classroom!");
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
      navigate(-1)
    }
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownClassroomRegister}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        setDropDownData(response.data.data);
      }
    } catch (error) {
      Toast.error("Failed to fetch room types.");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchDropdown();
  }, []);


   // Delay Utility Function
   const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to Get Current Location Readings
  const fetchLocation = async () => {
    console.log("Inside fetchLocation()");
    Toast.info("Please wait while we are fetching your location!", 9000);
    setShowLoader(true);

    let latestLatitude = null;
    let latestLongitude = null;
    let latestAccuracy = null;
    let latestAltitude = null;

    let previousLatitude = null;
    let previousLongitude = null;
    let stablePrecision = null; // Precision to determine stability
    let callCount = 0; // Track the number of geolocation requests
    const maxCalls = 30; // Upper cap for maximum number of calls
    const minCalls = 5; // Minimum number of calls before we take a final reading

    const getLocation = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude, accuracy, altitude } = position.coords;
            resolve({ latitude, longitude, accuracy, altitude });
          },
          (error) => {
            reject(error);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      });

    try {
      while (callCount < maxCalls) {
        callCount++;
        const location = await getLocation();
        const { latitude, longitude, accuracy, altitude } = location;

        console.log(`Call #${callCount}:`, { latitude, longitude, accuracy, altitude });

        // Set precision dynamically based on decimal places in received latitude/longitude
        const latitudeStr = latitude.toString();
        const longitudeStr = longitude.toString();
        let currentPrecision = Math.max(
          latitudeStr.split(".")[1]?.length || 0,
          longitudeStr.split(".")[1]?.length || 0
        );

        // Adjust precision rules
        if (currentPrecision > 10) {
          stablePrecision = currentPrecision - 3; // Round to n-3 digits if more than 10 decimals
        } else if (currentPrecision >= 7) {
          stablePrecision = currentPrecision - 1; // Round to n-1 digits if exactly 7 decimals
        } else {
          stablePrecision = Math.max(7, currentPrecision - 1); // Default to at least 7 digits or n-1 digits
        }

        // Round to the stable precision
        const roundedLatitude = Number(latitude.toFixed(stablePrecision));
        const roundedLongitude = Number(longitude.toFixed(stablePrecision));

        // Check if the coordinates are stable
        if (
          previousLatitude !== null &&
          previousLongitude !== null &&
          roundedLatitude === previousLatitude &&
          roundedLongitude === previousLongitude
        ) {
          latestLatitude = latitude;
          latestLongitude = longitude;
          latestAccuracy = accuracy;
          latestAltitude = altitude;

          // Ensure minimum 5 checks before taking the final location
          if (callCount >= minCalls) {
            break;
          }
        }

        previousLatitude = roundedLatitude;
        previousLongitude = roundedLongitude;

        // Delay between calls to avoid spamming
        await delay(1000);
      }

      if (callCount >= maxCalls) {
        console.log(`Max calls reached: ${maxCalls}`);
        Toast.error("Maximum calls reached. Unable to stabilize location.");
        return;
      }

      console.log(`Stable location achieved after ${callCount} calls.`);
      Toast.success(`Stable location achieved after ${callCount} calls.`);

      // Calculate distance to classroom and verify attendance eligibility
      if (latestLatitude !== null && latestLongitude !== null && latestAccuracy !== null) {

        setLocationData({
          latestLatitude,
          latestLongitude,
          altitude: latestAltitude ? latestAltitude : 0,
        });

        setFormData({
          ...formData,
          latitude: latestLatitude,
          longitude: latestLongitude,
          altitude: latestAltitude ? latestAltitude : 0,
        });

      }
    } catch (error) {
      console.error("Error fetching location:", error);
      Toast.error("Unable to fetch location. Please enable GPS.");
    } finally {
      setShowLoader(false);
    }
  };


  return (
    <div className={styles.classroomContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.header}>REGISTER DEPARTMENT CLASSROOMS</h2>
      <div className={styles.addClassroomScrollable}>
        <form
          className={styles.formGroup}
          onSubmit={(e) => {
            e.preventDefault();
            showConfirmation(
              `Want to register new ${formData.roomType} classroom?`,
              handleSubmit,
              e
            );
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="roomType">Room Type</label>
                <Dropdown
                  id={"roomType"}
                  value={formData.roomType}
                  options={dropDownData.roomTypes}
                  name={"roomType"}
                  onChange={handleChange}
                />
                {errors.roomType && (
                  <p className={styles.error}>{errors.roomType}</p>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="roomNo">Room Number</label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="roomNo"
                  placeholder="Room No."
                  value={formData.roomNo}
                  name="roomNo"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.roomNo && <p className={styles.error}>{errors.roomNo}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="floorNo">Floor Number</label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="floorNo"
                  placeholder="Floor No."
                  value={formData.floorNo}
                  name="floorNo"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.floorNo && <p className={styles.error}>{errors.floorNo}</p>}
              </div>
            </Grid>


            <div className={styles.locationButtonContainer}>
              <Button className={styles.locationButton} onClick={fetchLocation} variant="contained" color="primary">
                Get Current Location
              </Button>
            </div>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="latitude">
                  Latitude <span className={styles.span}>(Degrees in number)</span>
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="latitude"
                  placeholder="Latitude"
                  value={formData.latitude}
                  name="latitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.latitude && <p className={styles.error}>{errors.latitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="longitude">
                  Longitude <span className={styles.span}>(Degrees in number)</span>
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="longitude"
                  placeholder="Longitude"
                  value={formData.longitude}
                  name="longitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.longitude && <p className={styles.error}>{errors.longitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="altitude">
                  Altitude <span className={styles.span}>(Meters in number)</span>
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="altitude"
                  placeholder="Altitude"
                  value={formData.altitude}
                  name="altitude"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.altitude && <p className={styles.error}>{errors.altitude}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="radius">
                  Radius <span className={styles.span}>(Meters in number)</span>
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  id="radius"
                  placeholder="Radius"
                  value={formData.radius}
                  name="radius"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.radius && <p className={styles.error}>{errors.radius}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                className={styles.submitButton}
                disabled={isSaveDisabled}
                variant="contained"
                color="primary"
                type="submit"
              >
                Register Classroom
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CreateClassroom;