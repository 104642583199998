
import React, { useState, useEffect, useContext } from "react";
import { Button, Grid } from "@mui/material";
import axios from "axios";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import styles from './TPORegistration.module.css'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import UserContext from "../../../../../Context/UserContext";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import { BASE_URL } from "../../../../../config/config";

const registerTpo = process.env.REACT_APP_ADMIN_REGISTER_TPO;
const getDropdownTpoRegister = process.env.REACT_APP_ADMIN_GET_DROPDOWN_FOR_HOD_REGISTER;


function TPORegistration({ isCollageAdmin }) {
    const { getToken, setShowLoader } = useContext(UserContext);
    const [DropDownData, setDropDownData] = useState({
        genderList: [],
        salutationList: [],
    });
    const [errors, setErrors] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const [formData, setFormData] = useState({
        salutation: "",
        fname: "",
        mname: "",
        lname: "",
        gender: "",
        primaryPhone: "",
        secondaryPhone: "",
        email: "",
        enrollmentId: "",
        dob: null,
        aadharNo: "",
        defaultPassword: "",
        confirmPassword: "",
    });


    useEffect(() => {
        validateForm();
    }, [formData]);

    const validateForm = () => {
        let validationErrors = {};
        const requiredFields = [
            "salutation",
            "fname",
            "mname",
            "lname",
            "gender",
            "primaryPhone",
            // "secondaryPhone",
            "email",
            "enrollmentId",
            "dob",
            "aadharNo",
            "defaultPassword",
            "confirmPassword",
        ];

        // Check required fields
        requiredFields.forEach((field) => {
            const value = formData[field];
            // Check if value is a string before using .trim()
            if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
                validationErrors[field] = "**This field is required**";
            } else if (typeof value === "string" && value.trim() === "") {
                validationErrors[field] = "**This field is required**";
            }
        });

        const aadharNo = formData["aadharNo"];
        if (aadharNo && !/^\d{12}$/.test(aadharNo)) {
            validationErrors["aadharNo"] = "**Aadhar number must be 12 digits**";
        }

        const phoneFields = ["primaryPhone", "secondaryPhone"];
        phoneFields.forEach((field) => {
            const value = formData[field];
            if (value && !/^\d{10}$/.test(value)) {
                validationErrors[field] = "**Phone number must be 10 digits**";
            }
        });

        // Validate password strength
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
        if (formData.defaultPassword && !passwordRegex.test(formData.defaultPassword)) {
            validationErrors.defaultPassword = "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
        }

        if (formData.confirmPassword && !passwordRegex.test(formData.confirmPassword)) {
            validationErrors.confirmPassword = "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
        }

        // Validate passwords match
        if (
            formData.defaultPassword &&
            formData.confirmPassword &&
            formData.defaultPassword !== formData.confirmPassword
        ) {
            validationErrors.confirmPassword = "**Passwords do not match**";
        }

        if (!formData.dob) {
            validationErrors.dob = "**This field is required**";
        } else if (!dayjs(formData.dob).isValid()) {
            validationErrors.dob = "**Invalid date format**";
        } else if (dayjs(formData.dob).isAfter(dayjs(), "day")) {
            validationErrors.dob = "**Date of Birth cannot be in the future**";
        }

        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            validationErrors["email"] = "**Please enter a valid email address**";
        }

        setErrors(validationErrors);
        setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateChange = (field, date) => {
        let parsedDate = dayjs(date);

        if (parsedDate.isValid()) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [field]: parsedDate.toISOString(),
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [field]: null,
            }));
        }
        validateForm();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        validateForm();

        try {
            const headers = {
                Authorization: `Bearer ${getToken()}`,
            };
            setShowLoader(true);

            const response = await axios.post(
                `${BASE_URL}${registerTpo}`,
                formData,
                {
                    headers,
                }
            );
            if (response.data.result) {
                Toast.success(response.data.message || "Success !");
                setFormData({
                    salutation: "",
                    fname: "",
                    mname: "",
                    lname: "",
                    gender: "",
                    primaryPhone: "",
                    secondaryPhone: "",
                    email: "",
                    enrollmentId: "",
                    dob: "",
                    aadharNo: "",
                    defaultPassword: "",
                    confirmPassword: "",
                });
                setErrors({});
            } else {
                Toast.error(response.data.message || "Something went wrong!");

            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };


    useEffect(() => {
    }, [formData]);


    const fetchDropdown = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}${getDropdownTpoRegister}`,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );
            if (response.data.result) {

                setDropDownData(response?.data?.data);
            } else {
                Toast.error(response.data.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response.data.message || "Something went wrong!");
        }
    };

    useEffect(() => {
        fetchDropdown();
    }, []);

    return (
        <div className={styles.registerTpoContainer}>
            <h2 className={styles.registerHeader}>Register Training & Placement Officer</h2>
            <div className={styles.registerFormScrollable}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="salutation">Salutation</label>
                                <Dropdown name="salutation" value={formData.salutation} options={DropDownData.salutationList} onChange={handleChange} />
                            </div>
                            {errors.salutation && <p className={styles.error}>{errors.salutation}</p>}
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="firstName"> First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    placeholder="First Name"
                                    value={formData.fname}
                                    name="fname"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.fname && <p className={styles.error}>{errors.fname}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="middleName"> Middle Name</label>
                                <input
                                    type="text"
                                    id="middleName"
                                    placeholder="Midlle Name"
                                    value={formData.mname}
                                    name="mname"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.mname && <p className={styles.error}>{errors.mname}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="lastName"> Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    placeholder="Last Name"
                                    value={formData.lname}
                                    name="lname"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.lname && <p className={styles.error}>{errors.lname}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="gender">Gender</label>
                                <Dropdown
                                    name="gender"
                                    value={formData.gender}
                                    options={DropDownData.genderList}
                                    onChange={handleChange}
                                />
                                {errors.gender && <p className={styles.error}>{errors.gender}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="primaryPhone"> Primary Phone</label>
                                <input
                                    type="number" 
                                    onWheel={(e) => e.target.blur()}
                                    aria-controls="none"
                                    id="primaryPhone"
                                    placeholder="Primary Phone"
                                    value={formData.primaryPhone}
                                    name="primaryPhone"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.primaryPhone && (
                                    <p className={styles.error}>{errors.primaryPhone}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="secondaryPhone"> Secondary Phone</label>
                                <input
                                    type="number" 
                                    onWheel={(e) => e.target.blur()}
                                    aria-controls="none"
                                    id="secondaryPhone"
                                    placeholder="Secondary Phone"
                                    value={formData.secondaryPhone}
                                    name="secondaryPhone"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.secondaryPhone && (
                                    <p className={styles.error}>{errors.secondaryPhone}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="emailId"> Email</label>
                                <input
                                    type="email"
                                    id="emailId"
                                    placeholder="Email Id"
                                    value={formData.email}
                                    name="email"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                    autoComplete="none"
                                />
                                {errors.email && <p className={styles.error}>{errors.email}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="enrollmentId">Staff Id</label>
                                <input
                                    type="text"
                                    id="enrollmentId"
                                    placeholder="Staff Id"
                                    value={formData.enrollmentId}
                                    name="enrollmentId"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                    autoComplete="none"
                                />
                                {errors.enrollmentId && (
                                    <p className={styles.error}>{errors.enrollmentId}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="dob">Date of Birth</label>
                                    <DesktopDatePicker
                                        views={["year", "month", "day"]}
                                        className={styles.datePicker}
                                        value={formData.dob ? dayjs(formData.dob) : null}
                                        onChange={(date) => handleDateChange('dob', date)} />
                                    {errors.dob && <p className={styles.error}>{errors.dob}</p>}
                                </div>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="aadharNo"> Aadhar Number</label>
                                <input
                                    type="number" 
                                    onWheel={(e) => e.target.blur()}
                                    id="aadharNo"
                                    placeholder="Aadhar Number"
                                    value={formData.aadharNo}
                                    name="aadharNo"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                    autoComplete="off"
                                />
                                {errors.aadharNo && <p className={styles.error}>{errors.aadharNo}</p>}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="defaultPassword">Default Password</label>
                                <input
                                    type="password"
                                    id="defaultPassword"
                                    placeholder="Default Password"
                                    value={formData.defaultPassword}
                                    name="defaultPassword"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.defaultPassword && (
                                    <p className={styles.error}>{errors.defaultPassword}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="Confirm Default Password">
                                    Confirm Default Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    placeholder="Confirm Default Password"
                                    value={formData.confirmPassword}
                                    name="confirmPassword"
                                    onChange={handleChange}
                                    className={styles.inputbox}
                                />
                                {errors.confirmPassword && (
                                    <p className={styles.error}>{errors.confirmPassword}</p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} className={styles.saveButtonContainer}>
                            <Button
                                type="submit"
                                variant="contained"
                                className={styles.submitButton}
                                disabled={isSubmitDisabled}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </div>
        </div>
    );
}

export default TPORegistration;
