import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../../ToastContainer/ToastContainer";
import UserContext from "../../../../../Context/UserContext";
import { BASE_URL } from "./../../../../../config/config";
import descriptionIcon from "../../../../../images/edit-info (1).png";
import linkIcon from "../../../../../images/link.png";
import projectIcon from "../../../../../images/project.png";
import styles from "./VUPProjects.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import VUPEditProjects from "./VUPEditProjects/VUPEditProjects";
import { useParams } from "react-router-dom";

const getProjects = process.env.REACT_APP_VUP_ADMIN_GET_PROJECTS;
const addProject = process.env.REACT_APP_VUP_ADMIN_ADD_PROJECT;
const deleteProject = process.env.REACT_APP_VUP_ADMIN_DELETE_PROJECT;

const VUPProjects = () => {
    const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [projects, setProjects] = useState([]);
    const { enrollmentId } = useParams();

    useEffect(() => {
        fetchProjectsData(enrollmentId);
    }, [enrollmentId]);

    const fetchProjectsData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getProjects}/${enrollmentId}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            setProjects(response.data.data);
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };


    const handleOpenModal = (data, type) => {
        setSelectedData(data || {});
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedData({});
    };

    const handleAddProject = async (data) => {
        try {
            setShowLoader(true);
            const response = await axios.post(`${BASE_URL}${addProject}/${enrollmentId}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.data.result === true) {
                setProjects((prevProjects) => [
                    ...prevProjects,
                    { ...data, _id: response.data.projectId },
                ]);
                Toast.success(response?.data?.message || "Project added!");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            handleCloseModal();
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDeleteProject = async (_id) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(`${BASE_URL}${deleteProject}/${enrollmentId}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                data: { projectId: _id },
            });

            if (response.data.result) {
                setProjects((prevProjects) =>
                    prevProjects.filter((proj) => proj._id !== _id)
                );
                Toast.success(response?.data?.message || "Project deleted!");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.projectsSection}>
                <h2 className={styles.headingProjects}>
                    Projects{" "}
                    <img src={projectIcon} alt="Project Icon" className={styles.icon} />
                </h2>
                {projects.length > 0 ? (
                    <div className={styles.projectsList}>
                        {projects.map((project, index) => (
                            <div className={styles.yourProject} key={project._id}>
                                <div className={styles.projectHeader}>
                                    <h3>Project Name: <span>{project.projectName}</span></h3>
                                    <Box className={styles.closeIconContainer}>
                                        <IconButton
                                            onClick={() =>
                                                showConfirmation(
                                                    "Are you sure you want to delete this project?",
                                                    () => handleDeleteProject(project._id)
                                                )
                                            }
                                        >
                                            <DeleteIcon className={styles.deleteProjectButton} />
                                        </IconButton>
                                    </Box>
                                </div>
                                <hr className={styles.horizontalRow} />
                                <div className={styles.projectContent}>
                                    <p>
                                        {" "}
                                        <img
                                            src={descriptionIcon}
                                            alt="Description Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Description: </span> {project.projectDescription}
                                    </p>
                                    <p>
                                        {" "}
                                        <img
                                            src={linkIcon}
                                            alt="Link Icon"
                                            className={styles.aboutIcon}
                                        />{" "}
                                        <span>Link: </span>{`${project?.link ? project.link : "N/A"}`}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles.noData}>No project records available.</p>
                )}
                <Button
                    variant="contained"
                    onClick={() => handleOpenModal({}, "project")}
                    className={styles.addButton}
                >
                    <AddIcon />
                    <span>Add Project</span>
                </Button>
            </div>


            <Modal
                className={styles.modal}
                open={isModalOpen}
                onClose={handleCloseModal}>
                <VUPEditProjects
                    projectData={selectedData}
                    handleAddProject={(data) => handleAddProject(data, enrollmentId)}
                    handleCloseModal={handleCloseModal}
                />
            </Modal>
        </div >
    );
};

export default VUPProjects;
