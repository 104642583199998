import { toast } from "react-toastify";

const Toast = {
  success: (message,timeout) => {
    if(timeout){
      toast.success(message,{autoClose:timeout});
    }else{
      toast.success(message);
    }
  },
  error: (message,timeout) => {
    if(timeout){
      toast.error(message,{autoClose:timeout});
    }else{
      toast.error(message);
    }
  },
  info: (message, timeout) => {
    if(timeout){
      toast.info(message,{autoClose:timeout});
    }else{
      toast.info(message);
    }
  },
  warning: (message,timeout) => {
    if(timeout){
      toast.warning(message,{autoClose:timeout});
    }else{
      toast.warning(message);
    }
  },
  update: (toastId, message) => {
    toast.update(toastId, { render: message });
  },
};

export default Toast;
