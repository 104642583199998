import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Checkbox, ListItemText, Select, Button, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import * as XLSX from 'xlsx'; // Import xlsx
import { saveAs } from 'file-saver'; // Import file-saver to trigger file download
import Dropdown from '../../DropDown/DropDown';
import axios from 'axios';
import { BASE_URL } from '../../../../config/config';
import UserContext from '../../../../Context/UserContext';
import styles from "./StudentFilter.module.css";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";

const dropdownFilterStudent = process.env.REACT_APP_RECRUITER_DROPDOWN_FILTER_STUDENT
const searchFilteredStudent = process.env.REACT_APP_RECRUITER_SEARCH

const StudentFilter = () => {
  const { getToken } = useContext(UserContext);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [educationCriteria, setEducationCriteria] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [courseTypeDropdown, setCourseTypeDropdown] = useState([]);
  const [currentData, setCurrentData] = useState({
    courseType: "",
    percentage: "",
    cgpa: "",
  });
  const [studentData, setStudentData] = useState([]);
  const [showSummary, setShowSummary] = useState(true);  // To toggle the display of selected info
  const navigate = useNavigate();

  // Handle change for skills (multi-select)
  const handleSkillChange = (event) => {
    setSelectedSkills(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentData({ ...currentData, [name]: value });
  };

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${dropdownFilterStudent}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data?.result) {
        setSkillsList(response.data?.data?.skillList);
        setCourseTypeDropdown(response.data?.data?.courseTypeList);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  // Handle change for education criteria
  const handleEducationChange = (index, field, value) => {
    const newCriteria = [...educationCriteria];
    newCriteria[index][field] = value;
    setEducationCriteria(newCriteria);
  };

  const addEducationCriteria = () => {
    if (currentData.courseType && currentData.percentage !== "" && currentData.cgpa !== "") {
      setEducationCriteria([...educationCriteria, currentData]);
      setCurrentData({ courseType: '', percentage: '', cgpa: '' });
    } else {
      console.error("Incomplete data. Please fill all fields.");
    }
  };

  const serachFilteredStudents = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}${searchFilteredStudent}`,
        { skillList: selectedSkills, educationCriteria: educationCriteria },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setStudentData(response.data.data || []);
      }
    } catch (error) { }
  };

  const removeCriteria = (criteria) => {
    // Use the full object comparison for removal
    const filtered = educationCriteria.filter(data => data.courseType !== criteria.courseType || data.percentage !== criteria.percentage || data.cgpa !== criteria.cgpa);
    setEducationCriteria(filtered);
  };

  const handleSubmit = () => {
    serachFilteredStudents();
    setShowSummary(true);
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      studentData?.map(student => ({
        'Student Name': student.studentName,
        'Email': student.email,
        'Phone': student.primaryPhone,
        'Enrollment ID': student.enrollmentId,
        'Skills': student.skills.join(', '),
        'Education Data': student.educationData?.map(edu => `${edu.courseType}: ${edu.percentage !== null ? `${edu.percentage}%` : 'N/A'}, CGPA: ${edu.cgpa !== null ? edu.cgpa : 'N/A'}`).join('; ')
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Student Data");

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { bookType: 'xlsx', type: 'application/octet-stream' });
    saveAs(data, 'student_data.xlsx');
  };

  const handlePreviousClick = () => {
    navigate(-1);
  }

  return (
    <div className={styles.wrapper}>
      <Box className={styles.closeIconContainer}>
        <IconButton
          className={styles.closeButtonEditEducation}
          onClick={handlePreviousClick}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Box>

      <Box className={styles.studentFilterContainer}>
        <h2 className={styles.title}>Please select the dropdowns as per your criteria to filter out students</h2>

        <Box className={styles.dropDownParentContainer}>

          <Box className={styles.dropDownContainer}>
            <label htmlFor="searchCompany" className={styles.searchLabel}>
              Select Skills <span style={{ color: "red" }}>(Optional)</span>
            </label>
            <Select
              multiple
              value={selectedSkills}
              onChange={handleSkillChange}
              renderValue={(selected) => selected.join(", ")}
              fullWidth
              className={styles.selectOption}
            >
              {skillsList?.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  <Checkbox checked={selectedSkills.indexOf(skill) > -1} />
                  <ListItemText primary={skill} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="courseType" className={styles.dropDownLabel}>
              Course Type
            </label>
            <Dropdown
              name={"courseType"}
              options={courseTypeDropdown?.filter((data) => !educationCriteria?.some((data2) => data2?.courseType === data?.value))}
              onChange={handleChange}
            />
          </Box>

          <Box className={styles.dropDownContainer}>
            <label htmlFor="percentage" className={styles.dropDownLabel}>
              Percentage
            </label>
            <input
              type="number" 
              onWheel={(e) => e.target.blur()}
              name="percentage"
              value={currentData.percentage}
              onChange={handleChange}
              className={styles.inputArea}
            />
          </Box>

          <Box className={styles.dropDownContainer}>
            <label htmlFor="cgpa" className={styles.dropDownLabel}>
              CGPA
            </label>
            <input
              type="number" 
              onWheel={(e) => e.target.blur()}
              name="cgpa"
              value={currentData.cgpa}
              onChange={handleChange}
              className={styles.inputArea}
            />
          </Box>

          <Box className={styles.addButtonContainer}>
            <Button className={styles.btn} variant="contained" onClick={addEducationCriteria}> Add</Button>
          </Box >
        </Box >


        <h2 className={styles.criteriaTitle}>Your Selected Criteria</h2>

        {showSummary && (
          <Box className={styles.parentCardContainer}>
            <Box className={styles.selectedSkillCriteria}>
              <h3 className={styles.skillCriteriaTitle}>Skills Criteria</h3>
              <div className={styles.cardContent}>
                {selectedSkills.length > 0 ? (
                  <p>{selectedSkills.join(", ")}</p>
                ) : (
                  <p>No skills selected</p>
                )}
              </div>
            </Box>

            <Box className={styles.selectedEducationCriteria}>
              {/* Always render the Education Criteria header */}
              <h3 className={styles.eduCriteriaTitle}>Education Criteria</h3>

              <div className={styles.cardContent}>
                {educationCriteria.length > 0 ? (
                  educationCriteria?.map((item, index) => (
                    <Box key={index} className={styles.educationItem}>
                      <span>{index + 1}</span>
                      <p><strong>Course Type:</strong> {item.courseType || "Not Selected"}</p>
                      <p><strong>Percentage:</strong> {item.percentage || "Not Entered"}</p>
                      <p><strong>CGPA:</strong> {item.cgpa || "Not Entered"}</p>
                      <Button
                        variant="contained"
                        className={styles.deleteButton}
                        onClick={() => removeCriteria(item)}
                      >
                        Delete
                      </Button>
                    </Box>
                  ))
                ) : (
                  <p>No education criteria selected</p>
                )}
              </div>
            </Box>
          </Box>
        )}

        <div className={styles.buttonContainer}>
          <Button className={styles.btn} variant="contained" onClick={handleSubmit}>Submit Criteria</Button>
          <Button className={styles.exportButton} variant="contained" color="primary" onClick={exportToExcel} >
            Download Excel
          </Button>
        </div>

        {studentData.length > 0 && (
          <Box className={styles.studentListContainer}>
            <h2 className={styles.tableContainerTitle}>Students List</h2>
            <TableContainer className={styles.tableContainer}
              component={Paper}>
              <Table
                aria-label="customized table"
                className={styles.theTable}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.tableHead}>No.</TableCell>
                    <TableCell className={styles.tableHead}>Student Name</TableCell>
                    <TableCell className={styles.tableHead}>Email</TableCell>
                    <TableCell className={styles.tableHead}>Phone</TableCell>
                    <TableCell className={styles.tableHead}>Enrollment ID</TableCell>
                    <TableCell className={styles.tableHead}>Skills</TableCell>
                    <TableCell className={styles.tableHead}>Education Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentData?.map((student, index) => (
                    <TableRow key={student.userId}>
                      <TableCell className={styles.tableData}>{index + 1}</TableCell>
                      <TableCell className={styles.tableData}>{student.studentName}</TableCell>
                      <TableCell className={styles.tableData}>{student.email}</TableCell>
                      <TableCell className={styles.tableData}>{student.primaryPhone}</TableCell>
                      <TableCell className={styles.tableData}>{student.enrollmentId}</TableCell>
                      <TableCell className={styles.tableData}>{student.skills.join(', ')}</TableCell>
                      <TableCell className={styles.tableData}>
                        {student.educationData?.map((edu, index) => (
                          <div key={index}>
                            <Typography variant="body2">
                              <strong>{edu.courseType}:</strong>
                              {edu.percentage !== null ? `${edu.percentage}%` : 'N/A'},
                              {edu.cgpa !== null ? `CGPA: ${edu.cgpa}` : 'N/A'}
                            </Typography>
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </div >
  );
};

export default StudentFilter;
