import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box
} from "@mui/material";
import axios from "axios";
import UserContext from "../../../../../Context/UserContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./DatewiseAttendance.module.css"
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../../config/config";

const getSubjectWiseAttendance = process.env.REACT_APP_GET_SUBJECTWISE_ATTENDANCE;

const DateWiseAttendance = () => {
  const { getToken, setShowLoader } = useContext(UserContext);
  const [student, setStudent] = useState();
  const [attendanceRecords, setAttendanceRecords] = useState(
    student?.attendanceRecords
  );

  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state;

  const fetchIndividualAttendance = async () => {
    setShowLoader(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${getSubjectWiseAttendance}/${data.subjectCode}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );

      setStudent(() => response.data?.data);
      setAttendanceRecords(() => response?.data?.data?.attendanceRecords || []);
      setShowLoader(false);
      if (!response?.data?.result) {
        Toast.error(response?.data?.message || "Something went wrong!")

      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!")
      setShowLoader(false);
    }
  };


  useEffect(() => {
    fetchIndividualAttendance();
  }, []);


  return (
    <Box className={styles.dateWiseAttendanceContainer}>
      <h2 className={styles.titleName}>
        Attendance Records for {student?.studentName} (
        {student?.enrollmentId})
      </h2>
      <h2 className={styles.titleSubject}>
        Subject : {student?.subjectName} ({student?.subjectType})
      </h2>
      <Box className={styles.arrowContainer}>
        <ArrowBackIcon className={styles.previousButton} onClick={() => {
          navigate(-1);
        }} />
      </Box>

      <TableContainer component={Paper} className={styles.dateWiseContainerTable}>
        <Table className={styles.theTable}>
          <TableHead >
            <TableRow >
              <TableCell className={styles.tableHead} >Date</TableCell>
              <TableCell className={styles.tableHead}>Present Status</TableCell>
              <TableCell className={styles.tableHead}>Is Present</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceRecords &&
              attendanceRecords?.map((record, index) => (
                <TableRow key={record.date}>
                  <TableCell className={styles.tableData} >
                    {new Date(record.date)
                      .toISOString()
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")}
                  </TableCell>
                  <TableCell className={styles.tableData}>
                    {record.presentStatus}
                  </TableCell>
                  <TableCell className={styles.tableData}>{record.isPresent ? "Yes" : "No"}</TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box >
  );
};

export default DateWiseAttendance;
