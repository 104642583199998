import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Button,
  Box,
} from "@mui/material";
import styles from "./RollCall.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../config/config";
import Toast from "../../ToastContainer/ToastContainer";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const getLectureStudentList = process.env.REACT_APP_HOD_GET_LECTURE_STUDENT_LIST;
const uploadManualAttendance = process.env.REACT_APP_HOD_UPLOAD_MANUAL_ATTENDANCE;

const RollCall = () => {
  const { getToken, getUserRole, setShowLoader, showConfirmation, showLoader } =
    useContext(UserContext);
  const [students, setStudents] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const [lectureData, setLectureData] = useState({});
  const [presentCount, setPresentCount] = useState(0);

  const { lectureId } = useParams();

  const fetchLectureData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${getLectureStudentList}/${lectureId}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setStudents(() => response?.data?.data.studentList);
        const lectureData = response.data?.data.lectureData;
        setLectureData({ ...lectureData });
      } else {
        Toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const handleSaveAttendance = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${uploadManualAttendance}`,
        { students, lectureId },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data?.result) {
        Toast.success(response.data?.message || "Attendance saved successfully!");
        navigate(`/${getUserRole()}`);
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedStudents = [...students];
    updatedStudents[index].isPresent = !updatedStudents[index].isPresent;
    updatedStudents[index].presentStatus = updatedStudents[index].isPresent
      ? "P"
      : "A";
    updatedStudents[index].initialStatus = updatedStudents[index].isPresent
      ? "P"
      : "A";
    if (updatedStudents[index].isPresent) {
      setPresentCount(presentCount + 1);
    } else {
      setPresentCount(presentCount - 1);
    }
    setStudents(updatedStudents);
  };

  const handleSelectAllChange = () => {
    const updatedStudents = students.map(student => ({
      ...student,
      isPresent: !selectAll,
      presentStatus: !selectAll ? "P" : "A",
      initialStatus: !selectAll ? "P" : "A",
    }));
    setSelectAll(!selectAll);
    setPresentCount(!selectAll ? students.length : 0);
    setStudents(updatedStudents);
  };

  useEffect(() => {
    fetchLectureData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.containerTitle}>Manual Attendance</h1>
      <div className={styles.headerContainer}>
        <h2 className={styles.title}>Course Name : {lectureData?.courseName}</h2>
        <h2 className={styles.title}>Academic Batch : {lectureData?.batchName}</h2>
        <h2 className={styles.title}>Section Name : {lectureData?.section}</h2>
        <h2 className={styles.title}>Subject Name : {lectureData?.subjectName}</h2>
        {lectureData.practicalBatch && (
          <h2 className={styles.title}>Practical Batch : {lectureData?.practicalBatch}</h2>
        )}
        <div className={styles.countContainer}>
          <h2 className={styles.title}>Present : {presentCount}</h2>
          <h2 className={styles.title}>Absent : {students.length - presentCount}</h2>
        </div>
      </div>

      <div className={styles.tableBox}>
        <Box className={styles.arrowContainer}>
          <ArrowBackIcon className={styles.previousButton} onClick={() => navigate(-1)} />
          <ArrowForwardIcon className={styles.nextButton} onClick={() => navigate(+1)} />
        </Box>

        <TableContainer component={Paper} className={styles.tableContainer}>
          {students.length > 0 ? (
            <Table className={styles.theTable}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.tableHead}>
                    <Checkbox variant="outlined" onClick={handleSelectAllChange}>
                      {selectAll ? "Deselect All" : "Select All"}
                    </Checkbox>
                  </TableCell>
                  <TableCell className={styles.tableHead}>Enrollment ID</TableCell>
                  <TableCell className={styles.tableHead}>Student Name</TableCell>
                  <TableCell className={styles.tableHead}>Present Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => (
                  <TableRow key={student?.enrollmentId}>
                    <TableCell className={styles.tableData}>
                      <Checkbox
                        style={{
                          backgroundColor: student?.isPresent ? "green" : "red",
                          color: "white",
                        }}
                        checked={student.isPresent}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </TableCell>
                    <TableCell className={styles.tableData}>{student?.enrollmentId}</TableCell>
                    <TableCell className={styles.tableData}>{student?.studentName}</TableCell>
                    <TableCell className={styles.tableData}>
                      {student?.isPresent ? "Present" : "Absent"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <h1 className={styles.noData}>{showLoader ? "Loading..." : "No Data Found!"}</h1>
          )}
        </TableContainer>

        <Button
          className={styles.saveBtn}
          variant="contained"
          onClick={() => {
            showConfirmation("want to insert attendance record?", handleSaveAttendance);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default RollCall;
