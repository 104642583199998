import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Modal, Button, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../../ToastContainer/ToastContainer";
import EditSocialMedia from "./EditSocialMedia/EditSocialMedia";
import UserContext from "../../../../../Context/UserContext";
import { BASE_URL } from "./../../../../../config/config";
import styles from "./SocialMedia.module.css"
import socialMediaIcon from "./../../../../../images/interaction.png"
import DeleteIcon from "@mui/icons-material/Delete";

const SocialMedia = () => {
    const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [socialMediaData, setSocialMediaData] = useState([]);

    const getSocialMedia = process.env.REACT_APP_GET_SOCIAL_MEDIA_LINKS;
    const addSocialMedia = process.env.REACT_APP_ADD_SOCIAL_MEDIA_LINK;
    const deleteSocialMedia = process.env.REACT_APP_DELETE_SOCIAL_MEDIA_LINK;

    useEffect(() => {
        fetchSocialMediaData();
    }, []);

    const fetchSocialMediaData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getSocialMedia}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            setSocialMediaData(response.data.data);
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleAddSocialMedia = async (data) => {
        try {
            setShowLoader(true);
            const response = await axios.post(`${BASE_URL}${addSocialMedia}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });

            if (response.data.result === true) {
                setSocialMediaData((prevSocialMedia) => [
                    ...prevSocialMedia,
                    { ...data, _id: response.data.socialId },
                ]);
                Toast.success(response?.data?.message || "Success !");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            handleCloseModal();
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDeleteSocialMedia = async (_id) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(`${BASE_URL}${deleteSocialMedia}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
                data: { socialId: _id },
            });

            if (response.data.result) {
                setSocialMediaData((prevSocialMedia) =>
                    prevSocialMedia.filter((socialMedia) => socialMedia._id !== _id)
                );
                Toast.success(response?.data?.message || "Social media deleted.");
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleOpenModal = (data, type) => {
        setSelectedData(data);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedData({});
    };

    return (
        <div className={styles.parentContainer}>
            <div className={styles.socialMediaSection}>
                <h2 className={styles.headingsocialMedia}>
                    Social Media{" "}
                    <img src={socialMediaIcon} alt="Social Media Icon" className={styles.icon} />
                </h2>
                {socialMediaData.length > 0 ? (
                    <div className={styles.socialMediaList}>
                        {socialMediaData.map((socialMediaData, index) => (
                            <div className={styles.yourSocialMedia} key={socialMediaData._id}>
                                <h3>{index + 1}. {socialMediaData.name}</h3>
                                <hr className={styles.horizontalRow} />
                                <Box className={styles.closeIconContainer}>
                                    <IconButton
                                        onClick={() =>
                                            showConfirmation(
                                                "Are you sure you want to delete this Social Media link?",
                                                handleDeleteSocialMedia,
                                                socialMediaData._id
                                            )
                                        }
                                    >
                                        <DeleteIcon className={styles.deleteSocialMediaButton} />
                                    </IconButton>
                                </Box>
                                <div className={styles.socialMediaContent}>
                                    <div className={styles.socialMediaStats}>
                                        <p>
                                            <span>Link: </span> {socialMediaData.link}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className={styles.noData}>No social media link records available.</p>
                )}
                <Button
                    variant="contained"
                    onClick={() => handleOpenModal(null, "socialMedia")}
                    className={styles.addButton}
                >
                    <AddIcon />
                    <span>Add Social Media</span>
                </Button>
            </div>

            <Modal
                className={styles.modal}
                open={isModalOpen}
                onClose={handleCloseModal}
            >
                <EditSocialMedia
                    socialMediaData={selectedData}
                    handleCloseModal={handleCloseModal}
                    handleAddSocialMedia={handleAddSocialMedia}
                />
            </Modal>
        </div>
    )
}

export default SocialMedia