import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../../../../Context/UserContext";
import {
  Modal,
  Button,
  IconButton,
  Box,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./ListOfInstitute.module.css";
import { BASE_URL } from "./../../../../config/config";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import TableComponent from "../../../CommonComponent/CommonTable/CommonTable";

const getInstituteList = process.env.REACT_APP_SUPERADMIN_GET_INSTITUTE_LIST;
const updateInstitute = process.env.REACT_APP_SUPERADMIN_UPDATE_INSTITUTE;
const deleteInstitute = process.env.REACT_APP_SUPERADMIN_DELETE_INSTITUTE;
const recoverInstitute = process.env.REACT_APP_SUPERADMIN_RECOVER_INSTITUTE
const getAdminList = process.env.REACT_APP_SUPERADMIN_GET_ADMIN_LIST

const InstituteList = () => {
  const [institutes, setInstitutes] = useState([]);
  const [filteredInstitutes, setFilteredInstitutes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editedInstitute, setEditedInstitute] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [instituteAdmins, setInstituteAdmins] = useState([]);
  const navigate = useNavigate();
  // const [dropdownData, setDropdownData] = useState({
  //   instituteAdmins: []
  // });
  const [checkLocation, setCheckLocation] = useState(false);

  const columns = [
    "Serial No.",
    "Institute Type",
    "Institute Code",
    "Institute Name",
    "University Name",
    "Institute Phone",
    "Institute Email",
    "Institute Admin",
    "Admin Phone",
    "Admin Email",
    "Institute TPO",
    "State / Province",
    "District",
    "City / Town",
    "Pin / Zipcode",
    "Address Line",
    "Latitude",
    "Longitude",
    "Altitude",
    "Accuracy Threshold",
    "Altitude Threshold",
  ];

  const fetchInstitutes = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getInstituteList}`, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });

      if (response.data.result) {
        // Transform data similar to how it was done in fetchCompanyData
        const transformedRows = response.data.Institutedata.map((institute, index) => ({
          ...institute,
          "Serial No.": index + 1,
          "Institute Type": institute.instituteType ? institute.instituteType : "N/A",
          "Institute Code": institute.instituteCode ? institute.instituteCode : "N/A",
          "Institute Name": institute.instituteName ? institute.instituteName : "N/A",
          "University Name": institute.university ? institute.university : "N/A",
          "Institute Phone": institute.phoneNo ? institute.phoneNo : "N/A",
          "Institute Email": institute.emailId ? institute.emailId : "N/A",
          "Institute Admin": institute.adminName ? institute.adminName : "N/A",
          "Admin Phone": institute.adminPhone ? institute.adminPhone : "N/A",
          "Admin Email": institute.adminEmail ? institute.adminEmail : "N/A",
          "Institute TPO": institute.instituteTPO ? institute.instituteTPO : "N/A",
          "State / Province": institute.state ? institute.state : "N/A",
          "District": institute.district ? institute.district : "N/A",
          "City / Town": institute.city ? institute.city : "N/A",
          "Pin / Zipcode": institute.pincode ? institute.pincode : "N/A",
          "Address Line": institute.addressLine ? institute.addressLine : "N/A",
          "Latitude": institute.latitude ? institute.latitude : "N/A",
          "Longitude": institute.longitude ? institute.longitude : "N/A",
          "Altitude": institute.altitude ? institute.altitude : "N/A",
          "Accuracy Threshold": institute.accuracyThreshold ? institute.accuracyThreshold : "N/A",
          "Altitude Threshold": institute.altitudeThreshold ? institute.altitudeThreshold : "N/A",
        }));

        setInstitutes(transformedRows);
        setFilteredInstitutes(transformedRows)
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchAdmin = async (instituteId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}${getAdminList}?instituteId=${instituteId}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
          withCredentials: true
        }
      );
      // Set the fetched admin list to the state
      setInstituteAdmins(response?.data?.data.adminList || []);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchInstitutes();
  }, []);

  useEffect(() => {
    if (editedInstitute.instituteId) {
      fetchAdmin(editedInstitute.instituteId); // Trigger fetching admins
    }
  }, [editedInstitute]);

  const openModal = (instituteData) => {
    validateForm(instituteData)
    setEditedInstitute({ ...instituteData }); // Ensure this sets the state correctly
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditedInstitute({});
  };

  const handleSave = async () => {
    validateForm(editedInstitute);
    try {
      const { _id, ...updatedData } = editedInstitute;
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${updateInstitute}`,
        {
          ...updatedData,
          instituteId: editedInstitute.instituteId,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.result) {
        fetchInstitutes();  // Re-fetch the institutes after successful save

        // Optionally show a success message
        Toast.success(response?.data?.message || "Success!");

        // Close modal and reset edit mode
        setModalIsOpen(false);
        setEditMode(false);
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };


  const handleDelete = async (instituteData) => {
    try {
      // const token = Cookies.get("token");
      setShowLoader(true);
      // return
      const data = { instituteId: instituteData?._id, isDeleted: true };

      const response = await axios.post(`${BASE_URL}${deleteInstitute}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchInstitutes();
      }
      // setInstitutes(institutes.filter(institute => institute.instituteId !== id));
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleChangeSearch = (e) => {
    setSearchText(() => e.target.value);
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredInstitutes(institutes)
    } else {
      const filteredData = institutes.filter(data => String(data.instituteName).toLowerCase().includes(searchText.toLowerCase()));
      setFilteredInstitutes(filteredData)
    }
  }, [searchText])


  const validateForm = (updatedInstitute) => {
    const newErrors = {};
    // const updatedInstitute = {...editedInstitute}

    // Validate Institute Logo (File Check)
    // if (!updatedInstitute.instituteLogo) {
    //   newErrors.instituteLogo = "**Institute Logo is required**";
    // }

    // Other form validation checks (text fields)
    // if (!updatedInstitute.instituteCode || updatedInstitute.instituteCode.trim() === "") {
    //   newErrors.instituteCode = "**Institute Code is required**";
    // }
    if (!updatedInstitute.instituteName || updatedInstitute.instituteName.trim() === "") {
      newErrors.instituteName = "**Institute Name is required**";
    }
    if (!updatedInstitute.emailId || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updatedInstitute.emailId)) {
      newErrors.emailId = "**Invalid email format**";
    }
    if (!updatedInstitute.phoneNo || !/^\d{10}$/.test(updatedInstitute.phoneNo)) {
      newErrors.phoneNo = "**Phone number must be 10 digits**";
    }
    if (!updatedInstitute.pincode || !/^\d{6}$/.test(updatedInstitute.pincode) || updatedInstitute.pincode < 110000 || updatedInstitute.pincode > 855999) {
      newErrors.pincode = "**Pincode must be between 110000 and 855999**";
    }
    if (!updatedInstitute.latitude || updatedInstitute.latitude === "" || updatedInstitute.latitude < -90 || updatedInstitute.latitude > 90) {
      newErrors.latitude = "**Latitude must be between -90 and 90**";
    }
    if (!updatedInstitute.longitude || updatedInstitute.longitude === "" || updatedInstitute.longitude < -180 || updatedInstitute.longitude > 180) {
      newErrors.longitude = "**Longitude must be between -180 and 180**";
    }
    if (!updatedInstitute.altitude || updatedInstitute.altitude <= 0) {
      newErrors.altitude = "**Altitude must be greater than 0**";
    }
    if (!updatedInstitute.addressLine || updatedInstitute.addressLine.trim() === "") {
      newErrors.addressLine = "**Address Line is required**";
    } else if (updatedInstitute.addressLine.length > 100) {
      newErrors.addressLine = "**Address Line must be less than 100 characters**";
    }
    // if (!updatedInstitute.instituteAdminId) {
    //   newErrors.adminName = "**Admin selection is required**";
    // }
    // if (!updatedInstitute.instituteType) {
    //   newErrors.instituteType = "**Institute Type is required**";
    // }
    if (!updatedInstitute?.accuracyThreshold) {
      newErrors.accuracyThreshold = "**Accuracy Threshold is required**";
    }
    if (!updatedInstitute?.altitudeThreshold) {
      newErrors.altitudeThreshold = "**Altitude Threshold is required**";
    }

    setErrors(newErrors);

    // Check if the form is valid
    const isFormValid = Object.values(newErrors).every((error) => !error);
    setIsSaveDisabled((prev) => !isFormValid);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // Handle file input (like instituteLogo)
    if (name === "instituteLogo" && files) {
      const file = files[0];

      // Check if file size is within the limit (300KB)
      if (file.size > 300 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          instituteLogo: "**File size exceeds. Please choose a file less than 300KB**",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          instituteLogo: null, // Clear error if file size is valid
        }));
      }

      // Update the state with the file
      setEditedInstitute((prevState) => ({
        ...prevState,
        [name]: file,
      }));
    } else {
      // For text fields and other inputs, just update the state
      setEditedInstitute((prevState) => {
        const updatedInstitute = { ...prevState, [name]: value };

        // Validate the updated form
        validateForm(updatedInstitute);
        return updatedInstitute;
      });
    }
  };

  const handleToggleCheckLocation = (event) => {
    const isChecked = event.target.checked;
    setEditedInstitute({
      ...editedInstitute,
      checkLocation: isChecked,  // `checkLocation` flag
    });
  };

  const handleRecover = async (instituteData) => {
    try {
      // const token = Cookies.get("token");
      setShowLoader(true);
      // return
      const data = { instituteId: instituteData?._id, isDeleted: true };

      const response = await axios.post(`${BASE_URL}${recoverInstitute}`, data, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      if (response?.data?.result) {
        Toast.success(response?.data?.message || "Success!");
        fetchInstitutes();
      }
      // setInstitutes(institutes.filter(institute => institute.instituteId !== id));
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handelAddInstitute = () => {
    navigate("/superadmin/add-institute");
  };

  // const HandleViewInstitute = (id) => {
  //   navigate(`/ListOfInstitute/InstituteHomePage/${id}`);
  // };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.instituteHeader}>List of Institutes</h1>
      <Box className={styles.addInstituteButtonContainer}>
        <Button className={styles.addInstituteButton} variant="contained" onClick={handelAddInstitute}>
          Add Institute
        </Button>
      </Box>

      <Box className={styles.dropdownParentContainer}>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="searchBar" className={styles.searchLabel}>
            Search Institute
          </label>
          <input
            id="searchBar"
            className={styles.searchBar}
            variant="outlined"
            onChange={handleChangeSearch}
          />
        </Box>
      </Box>

      {filteredInstitutes && filteredInstitutes.length > 0 ? (
        <TableComponent
          className={styles.table}
          columns={columns}
          rows={filteredInstitutes}
          onEdit={openModal}
          onDelete={handleDelete}
          onRecover={handleRecover}

        />
      ) : (
        <h2 className={styles.noData}>
          No Institute data available. You can add a new institute.
        </h2>
      )}

      <Modal open={modalIsOpen} onClose={closeModal} className={styles.modal}>
        <Box className={styles.editInstituteContainer}>
          <Box className={styles.closeIconContainer}>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <h2 className={styles.editInstituteHeader}>EDIT INSTITUTE</h2>
          <div className={styles.editInstituteScrollable}>
            <form className={styles.formGroup}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteType">Institute Type</label>
                    <input
                      type="text"
                      id="instituteType"
                      name="instituteType"
                      required
                      placeholder="Institute Type"
                      value={editedInstitute.instituteType || ""}
                      onChange={handleChange}
                      className={styles.disabled}
                      disabled
                    />
                    {errors.instituteType && <p className={styles.error}>{errors.instituteType}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteCode">Institute Code</label>
                    <input
                      type="text"
                      id="instituteCode"
                      name="instituteCode"
                      required
                      placeholder="Institute Code"
                      value={editedInstitute.instituteCode || ""}
                      onChange={handleChange}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.instituteCode && <p className={styles.error}>{errors.instituteCode}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteName">Institute Name</label>
                    <input
                      type="text"
                      id="instituteName"
                      name="instituteName"
                      placeholder="Institute Name"
                      required
                      value={editedInstitute.instituteName || ""}
                      onChange={handleChange}
                    />
                    {errors.instituteName && (
                      <p className={styles.error}>{errors.instituteName}</p>
                    )}
                  </div>
                </Grid>

                {/* <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="instituteLogo">Institute Logo</label>
                    <input
                      type="file"
                      id="instituteLogo"
                      name="instituteLogo"
                      accept="image/*"
                      onChange={handleChange}
                    />
                    {errors.instituteLogo && <p className={styles.error}>{errors.instituteLogo}</p>}
                  </div>
                </Grid> */}

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="university">University Name</label>
                    <input
                      type="text"
                      id="university"
                      name="university"
                      placeholder="University Name"
                      required
                      value={editedInstitute.university || ""}
                      onChange={handleChange}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.university && (
                      <p className={styles.error}>{errors.university}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="phoneNo">Institute Phone / Telephone</label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="phoneNo"
                      name="phoneNo"
                      placeholder="Institute Contact"
                      required
                      value={editedInstitute.phoneNo || ""}
                      onChange={handleChange}
                    />
                    {errors.phoneNo && (
                      <p className={styles.error}>{errors.phoneNo}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="emailId">Institute Email</label>
                    <input
                      type="email"
                      id="emailId"
                      name="emailId"
                      placeholder="Institute Email"
                      required
                      value={editedInstitute.emailId || ""}
                      onChange={handleChange}
                    />
                    {errors.emailId && (
                      <p className={styles.error}>{errors.emailId}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="adminName">Institute Admin</label>
                    <Dropdown
                      options={instituteAdmins.map(admin => ({
                        value: admin.value,
                        label: admin.label,
                      }))}
                      value={editedInstitute.instituteAdminId || ""}
                      name="instituteAdminId"
                      onChange={handleChange}
                    />

                    {errors.adminName && <p className={styles.error}>{errors.adminName}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="state">State / Province</label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      placeholder="State"
                      required
                      value={editedInstitute.state || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.state && (
                      <p className={styles.error}>{errors.state}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="district">District</label>
                    <input
                      type="text"
                      id="district"
                      name="district"
                      placeholder="District"
                      required
                      value={editedInstitute.district || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.district && (
                      <p className={styles.error}>{errors.district}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="city">City / Town</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City"
                      required
                      value={editedInstitute.city || ""}
                      disabled
                      className={styles.disabled}
                    />
                    {errors.city && (
                      <p className={styles.error}>{errors.city}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="pincode">Pincode / Zipcode</label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="pincode"
                      name="pincode"
                      placeholder="Pincode"
                      required
                      value={editedInstitute.pincode || ""}
                      onChange={handleChange}
                    />
                    {errors.pincode && (
                      <p className={styles.error}>{errors.pincode}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="addressLine">Address Line</label>
                    <input
                      type="text"
                      id="addressLine"
                      name="addressLine"
                      placeholder="Address Line"
                      required
                      value={editedInstitute.addressLine || ""}
                      onChange={handleChange}
                    />
                    {errors.addressLine && (
                      <p className={styles.error}>{errors.addressLine}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="latitude">Latitude <span className={styles.span}>(Degrees in number)</span></label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="latitude"
                      name="latitude"
                      placeholder="Latitude"
                      required
                      value={editedInstitute.latitude || ""}
                      onChange={handleChange}
                    />
                    {errors.latitude && (
                      <p className={styles.error}>{errors.latitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="longitude">Longitude <span className={styles.span}>(Degrees in number)</span></label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="longitude"
                      name="longitude"
                      placeholder="Longitude"
                      required
                      value={editedInstitute.longitude || ""}
                      onChange={handleChange}
                    />
                    {errors.longitude && (
                      <p className={styles.error}>{errors.longitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="altitude">Altitude <span className={styles.span}>(Metres in number)</span></label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="altitude"
                      name="altitude"
                      placeholder="Altitude"
                      required
                      value={editedInstitute.altitude || ""}
                      onChange={handleChange}
                    />
                    {errors.altitude && (
                      <p className={styles.error}>{errors.altitude}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="accuracyThreshold">Accuracy Threshold <span className={styles.span}>(Metres in number)</span></label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="accuracyThreshold"
                      name="accuracyThreshold"
                      value={editedInstitute.accuracyThreshold}
                      onChange={handleChange}
                      placeholder="Accuracy Threshold"
                    />
                    {errors.accuracyThreshold && <p className={styles.error}>{errors.accuracyThreshold}</p>}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="altitudeThreshold">Altitude Threshold <span className={styles.span}>(Metres in number)</span></label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      id="altitudeThreshold"
                      name="altitudeThreshold"
                      value={editedInstitute.altitudeThreshold}
                      onChange={handleChange}
                      placeholder="Altitude Threshold"
                    />
                    {errors.altitudeThreshold && <p className={styles.error}>{errors.altitudeThreshold}</p>}
                  </div>
                </Grid>

                <span className={styles.toggleSwitchContainer}>
                  <FormControlLabel
                    className={styles.toggleSwitch}
                    label={
                      <label
                        sx={{
                          fontWeight: "bold",
                          color: "#494949",
                          fontSize: "1rem",
                        }}
                      >
                        Enable Location Check ?
                      </label>
                    }
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={editedInstitute.checkLocation}
                        onChange={handleToggleCheckLocation} />
                    }
                  />
                </span>

                <Grid item xs={12} className={styles.submitButton}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSaveDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      showConfirmation(
                        " Want to edit this Institute ?",
                        handleSave,
                        e
                      );
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </div >
  );
};

export default InstituteList;
