import React, { useState, useEffect } from "react";
import { Button, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./EditSummary.module.css";

const EditSumamry = ({ summaryData, handleAddSummary, handleCloseModal }) => {
    const [summary, setSummary] = useState("");
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (summaryData) {
            setSummary(summaryData.summary || "");
        }
    }, [summaryData]);

    useEffect(() => {
        validateForm();
    }, [summary]);

    const handleSummaryChange = (e) => {
        setSummary(e.target.value);
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        if (!summary) {
            validationErrors.summary = "**This field is required**";
            isValid = false;
        } else if (summary.length > 500) {
            validationErrors.summary = "**Summary must be at most 500 characters**";
            isValid = false;
        }

        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedSummary = {
            summary: summary || "",
        };

        handleAddSummary(formattedSummary);
    };

    return (
        <div className={styles.editSummaryContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteSummary} />
                </IconButton>
            </Box>
            <h2 className={styles.editSummaryHeader}>Add Summary</h2>
            <form onSubmit={handleSubmit} className={styles.editSummaryScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="summary">Summary</label>
                            <textarea
                                id="summary"
                                type="text"
                                rows="10"
                                cols="50"
                                value={summary}
                                onChange={handleSummaryChange}
                                // maxLength="500"
                                className={styles.textareaContainer}
                            />
                            {error.summary && <p className={styles.error}>{error.summary}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButtonEditSummary}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default EditSumamry;
