import React, { useContext, useEffect, useState } from 'react'
import styles from './JobList.module.css';
import UserContext from '../../../../Context/UserContext';
import axios from 'axios';
import { BASE_URL } from '../../../../config/config';
import Toast from '../../ToastContainer/ToastContainer';
import JobCard from '../JobCard/JobCard';
import Dropdown from '../../DropDown/DropDown';
import { Box } from "@mui/material";

const getJobs = process.env.REACT_APP_STUDENT_GET_JOBS
const applyJob = process.env.REACT_APP_STUDENT_APPLY_JOB
const updateJobSeen = process.env.REACT_APP_STUDENT_UPDATE_JOB_SEEN

const JobList = () => {
  const { getToken } = useContext(UserContext);
  const [jobList, setJobList] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [applicationDropdown, setApplicationDropdown] = useState([
    { label: "All", value: "All" },
    { label: "Applied", value: true },
    { label: "Non-Applied", value: false },
  ])
  const [statusDropdown, setStatusDropdown] = useState([
    { label: "Pending", value: "Pending" },
    { label: "Shortlisted", value: "Shortlisted" },
    { label: "Rejected", value: "Rejected" },
  ]);

  const [selectedDropdown, setSelectedDropdown] = useState({
    application: "",
    applicationStatus: "",
    date: null,
    searchText: ""
  })
  const handleUpdateJobSeen = async () => {
    // return
    try {
      const response = await axios.put(`${BASE_URL}${updateJobSeen}`, {}, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        localStorage.setItem('jobNotify', false);
      }
      // updateJobSeen
    } catch (error) {

    }
  }
  const fetJobList = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${getJobs}`, { headers: { Authorization: `Bearer ${getToken()}` } })
      if (response.data.result) {
        setJobList(response.data.data);
        setFilteredJobList(response.data.data);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    const handleScroll = () => {
      handleUpdateJobSeen();
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const filterJobList = async () => {
    const { application, applicationStatus, date, searchText } = selectedDropdown;
    let filtered = jobList;
    if (searchText) {
      filtered = jobList.filter((job) => job?.jobTitle?.toLowerCase().includes(searchText.toLowerCase()))
    }

    if (applicationStatus) {
      filtered = filtered.filter((job) => job.applicationStatus === applicationStatus);
    }
    if (application) {
      if (application !== "All") {
        filtered = filtered.filter((job) => String(job.isApplied) === String(application));
      }
    }
    // if (date) {
    //   filtered = filtered.filter((job) => new Date(job.created_at).toDateString() === date.toDateString());
    // }
    setFilteredJobList(() => filtered);
  }

  const handleChangeSearch = (e) => {
    setSelectedDropdown({ ...selectedDropdown, searchText: e.target.value });
    filterJobList();
  }

  useEffect(() => {
    filterJobList();
  }, [selectedDropdown, jobList])

  const handleApplyJob = async (jobId, message) => {
    try {
      const response = await axios.post(`${BASE_URL}${applyJob}`, { jobId: jobId, message: message }, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        Toast.success(response.data.message || "Job applied successfully. !");

      } else {
        Toast.error(response.data.message || "Failed to apply job!");
      }
    } catch (error) {
      Toast.error(error.response.data.message || "Something went wrong!");
    }
  }

  const handleDropdownChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'application') {
      if (value === "false") {
        selectedDropdown.applicationStatus = ""
      }
    }

    setSelectedDropdown({ ...selectedDropdown, [name]: value });
    fetJobList();
  }

  useEffect(() => {
    fetJobList();
  }, [])

  return (
    <div className={styles.jobListContainer}>
      <Box className={styles.dropDownParentContainer}>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="searchBar" className={styles.searchLabel}>
            Search Job
          </label>
          <input
            id="searchBar"
            className={styles.searchBar}
            variant="outlined"
            onChange={handleChangeSearch}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="institute" className={styles.dropDownLabel}>
            View Applications
          </label>
          <Dropdown
            options={applicationDropdown}
            name={"application"}
            value={selectedDropdown.application}
            onChange={handleDropdownChange}
          />
        </Box>

        <Box className={styles.dropDownContainer}>
          <label htmlFor="status" className={styles.dropDownLabel}>
            Application Status
          </label>
          <Dropdown
            options={statusDropdown}
            name={"applicationStatus"}
            value={selectedDropdown.applicationStatus}
            onChange={handleDropdownChange}
            disabled={String(selectedDropdown.application) === "false"}
          />
        </Box>
      </Box>

      <div className={styles.jobPostCardsContainer}>
        {filteredJobList &&
          filteredJobList?.map((job, index) => {
            return (
              <>
                <JobCard
                  key={index}
                  jobData={job}
                  handleApplyJob={handleApplyJob}
                />
              </>
            );
          })}
      </div>
    </div >
  );
};

export default JobList