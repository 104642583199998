
import React, { useContext, useEffect, useState } from 'react';
import UserContext from "../../../../../Context/UserContext";
import { BASE_URL } from "./../../../../../config/config";
import Toast from "../../../ToastContainer/ToastContainer";
import axios from "axios";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import ViewDocument from "../../../ViewDocument/ViewDocument";
import styles from "./VUPResume.module.css"
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams } from 'react-router-dom';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getResumeData = process.env.REACT_APP_VUP_ADMIN_GET_RESUME_DATA;

const VUPResume = () => {
    const { getToken, setShowLoader } = useContext(UserContext);
    const [resume, setResume] = useState({});
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);
    const { enrollmentId } = useParams();

    const fetchResumeData = async (enrollmentId) => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getResumeData}/${enrollmentId}`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            setResume(response.data.data);
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        fetchResumeData(enrollmentId);
    }, []);


    const generatePdf = () => {
        const docDefinition = {
            content: [
                {
                    text: 'My Resume',
                    style: 'header',
                },
                {
                    text: 'Personal Details',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                {
                    columns: [
                        {
                            text: `Name: ${resume.fname} ${resume.mname} ${resume.lname}`, style: 'normal',
                        },
                        {
                            text: `Email: ${resume.email}`, style: 'normal',
                        },
                    ],
                    columnGap: 25,
                },
                {
                    columns: [
                        { text: `Phone: ${resume.primaryPhone}`, style: 'normal' },
                        { text: `Linkedin: ${resume.socialMedia?.find(sm => sm.name.toLowerCase() === 'linkedin')?.link || 'N/A'}`, style: 'normal' },
                    ],
                    columnGap: 25,
                },
                {
                    columns: [
                        { text: `Institute Name: ${resume.educationalDetails?.[0]?.institute || 'N/A'}`, style: 'normal' },
                        { text: `Github: ${resume.socialMedia?.find(sm => sm.name.toLowerCase() === 'github')?.link || 'N/A'}`, style: 'normal' },
                    ],
                    columnGap: 25,
                },
                { text: `Department: ${resume.educationalDetails?.[0]?.department || 'N/A'}`, style: 'normal' },
                { text: `My Role: ${resume.userRole || 'N/A'}`, style: 'normal' },
                { text: `DOB: ${resume.dob ? new Date(resume.dob).toLocaleDateString() : "N/A"}`, style: 'normal' },
                {
                    text: "Summary",
                    style: 'subHeader',
                    decoration: 'underline',
                },
                {
                    text: `${resume.summary || 'N/A'}`,
                    style: 'normal',
                    alignment: 'center',
                },

                // Education Section
                {
                    text: 'Education',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.educationalDetails) ? resume.educationalDetails.map((item, index) => {
                    let displayValue = "";
                    if (item.percentage !== null && item.percentage !== undefined) {
                        displayValue = `Percentage - ${item.percentage}%`;
                    } else if (item.cgpa !== null && item.cgpa !== undefined) {
                        displayValue = `CGPA - ${item.cgpa.toFixed(2)}`;
                    } else {
                        displayValue = "N/A";
                    }

                    return {
                        text: [
                            { text: `${item.courseName || 'N/A'}`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
                            { text: ` at ${item.institute || 'N/A'}`, style: 'normal', bold: true },
                            { text: ` (${item.university || 'N/A'})`, style: 'normal', margin: [0, 0, 0, 0] },
                            { text: `[ ${displayValue} ]`, style: 'normal', margin: [0, 5, 0, 0] },
                        ],
                        margin: [0, 5, 0, 10],
                    };
                }) : []),


                // Work Experience Section
                {
                    text: 'Work Experience',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.pastExperience) ? resume.pastExperience.map((item, index) => ({
                    text: [
                        { text: `${item.position} at ${item.company} (${item.location})\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
                        { text: `${item.roleDescription}`, style: 'normal', margin: [0, 5, 0, 0] },
                    ],
                    margin: [0, 5, 0, 10],
                })) : []),

                // Projects Section
                {
                    text: 'Projects',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.projects) ? resume.projects.map((item, index) => ({
                    text: [
                        { text: `${item.projectName}\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
                        { text: `${item.projectDescription}\n`, style: 'normal', margin: [0, 5, 0, 0] },
                        { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
                    ],
                    margin: [0, 5, 0, 10],
                })) : []),

                // Skills Section
                {
                    text: 'Skills',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.skills) ? resume.skills.map(item => ({
                    text: `${item.skill} - ${item.level}`,
                    style: 'normal',
                    margin: [0, 5, 0, 0],
                })) : []),

                // Languages Section
                {
                    text: 'Languages',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.languages) ? resume.languages.map(item => ({
                    text: item,
                    style: 'normal',
                    margin: [0, 5, 0, 0],
                })) : []),

                // Hobbies Section
                {
                    text: 'Hobbies',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.hobbies) ? resume.hobbies.map(item => ({
                    text: item,
                    style: 'normal',
                    margin: [0, 5, 0, 0],
                })) : []),

                // Certifications Section
                {
                    text: 'Certifications',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.certifications) ? resume.certifications.map(item => ({
                    text: [
                        { text: `${item.certification}`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
                        { text: ` by ${item.issuedBy}\n`, style: 'normal', margin: [0, 5, 0, 0] },
                        { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
                    ],
                    margin: [0, 5, 0, 5],
                })) : []),

                // Achievements Section
                {
                    text: 'Achievements',
                    style: 'sectionHeader',
                    decoration: 'underline',
                },
                ...(Array.isArray(resume.achievements) ? resume.achievements.map(item => ({
                    text: [
                        { text: `${item.achievement}\n`, style: 'normal', bold: true, margin: [0, 0, 0, 5] },
                        { text: `${item.description}\n`, style: 'normal', margin: [0, 0, 0, 5] },
                        { text: `Link - ${item.link || 'N/A'}`, style: 'normal', margin: [0, 5, 0, 0] },
                    ],
                    margin: [0, 5, 0, 10],
                })) : []),
            ],
            styles: {
                header: {
                    fontSize: 24,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 10, 0, 10],
                },
                subHeader: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 10, 0, 5],
                },
                sectionHeader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                },
                normal: {
                    fontSize: 12,
                    margin: [0, 5, 0, 5],
                },
            },
        };

        const pdfDoc = pdfMake.createPdf(docDefinition);

        // Save the PDF as a Blob
        pdfDoc.getBlob((blob) => {
            setPdfBlob(blob);
            setIsPreviewVisible(true); // Show the preview modal
        });
    };


    return (
        <div>
            <h2 className={styles.resumeHeader}>My Resume</h2>
            <Button variant="contained"
                className={styles.viewResumeButton} onClick={generatePdf}>
                <VisibilityIcon className={styles.viewIcon} />
                <span>View Resume</span>
            </Button>

            <ViewDocument
                isOpen={isPreviewVisible}
                onClose={() => {
                    setIsPreviewVisible(false);
                    setPdfBlob(null);
                }}
                documentUrl={pdfBlob ? URL.createObjectURL(pdfBlob) : ""} // Pass the Blob URL directly
            />
        </div>
    );
};

export default VUPResume;
