import React, { useContext, useEffect, useState } from "react";
import styles from "./JobPost.module.css";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../../../../../config/config";
import UserContext from "../../../../../Context/UserContext";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const myPostedJobs = process.env.REACT_APP_ADMIN_RECRUITER_POSTED_JOBS
const editPostedJob = process.env.REACT_APP_ADMIN_RECRUITER_EDIT_JOB
const getDropDownPostJob = process.env.REACT_APP_ADMIN_RECRUITER_DROPDOWN_POST_JOB
const viewJobDropDown = process.env.REACT_APP_ADMIN_DROPDOWN_VIEW_JOB
const getJobs = process.env.REACT_APP_ADMIN_GET_JOBS

const JobPost = () => {
  const { getToken, showConfirmation, getUserRole } = useContext(UserContext);
  const [jobData, setJobData] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [editingJobData, setEditingJobData] = useState();
  const [jobTypeList, setJobTypeList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState({
    companyId: "",
    searchText: "",
  });

  const handleChange = (event) => {
    setEditingJobData({
      ...editingJobData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectDropdown = (e) => {
    setSelectedDropdown({ ...selectedDropdown, [e.target.name]: e.target.value });
  }


  const handleDepartmentChange = async (e) => {
    const { name, value } = e.target;

    if (value.includes('deselect-all')) {
      setSelectedDepartments([]); // Deselect all
      return
    }
    if (value.includes('select-all')) {
      setSelectedDepartments(departmentDropdown.map(data => data.value));
      return
    }
    const data = departmentDropdown.filter(
      (data) => data.value === value[value.length - 1]
    );
    setSelectedDepartments(value);
  };

  useEffect(() => {
    let filtered = []
    if (selectedDropdown.searchText) {
      filtered = jobData.filter((job) => job?.jobTitle?.toLowerCase().includes(selectedDropdown.searchText.toLowerCase()))
    } else {
      filtered = jobData
    }

    if (selectedDropdown.filterJobType) {
      filtered = jobData.filter((job) => job?.jobType === selectedDropdown.filterJobType)
    }

    if (selectedDropdown.companyId) {
      filtered = jobData.filter((job) => job?.companyId === selectedDropdown.companyId)
    }
    setFilteredJobData(() => filtered)
  }, [selectedDropdown])

  const handleSkillChange = (event) => {
    const {
      name, value
    } = event.target;
    if (value.includes('deselect-all')) {
      setSkills([]);
      return
    }
    if (value.includes('select-all')) {
      setSkills(skillList)
      return
    }
    setSkills(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const removeSkill = (value) => {
    setSkills(skills.filter((skill) => skill !== value));
  };

  const removeDepartment = (value) => {
    setSelectedDepartments(() =>
      selectedDepartments.filter((item) => item !== value)
    );
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      const applicationDeadline = dayjs(newValue);
      setEditingJobData({
        ...editingJobData,
        applicationDeadline: applicationDeadline ? dayjs(newValue) : null,
      });

      // Check if the date is valid
      if (!applicationDeadline.isValid()) {
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   applicationDeadline: "**Invalid date format**",
        // }));
      }
      else if (applicationDeadline.isAfter(dayjs(), "day")) {
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   dob: "**Date of Birth cannot be in the future**",
        // }));
      }
      // If valid, update the state and clear errors
      else {
        setEditingJobData({
          ...editingJobData,
          applicationDeadline: applicationDeadline.toISOString(), // Store the date as an ISO string
        });
        // setErrors((prevErrors) => ({
        //   ...prevErrors,
        //   applicationDeadline: "", // Clear any existing errors
        // }));
      }
    } else {
      // Handle case when newValue is empty (e.g., user clears the input)
      // setErrors((prevErrors) => ({
      //   ...prevErrors,
      //   applicationDeadline: "**This field is required**",
      // }));
      setEditingJobData({
        ...editingJobData,
        applicationDeadline: "", // Reset the value if input is cleared
      });
    }
  };

  // #####################################
  // <AdapterDayjs

  const navigate = useNavigate();
  const getJobData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${myPostedJobs}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setJobData(response?.data?.data || []);
      setFilteredJobData(response?.data?.data || []);
      // setJobData( []);
    } catch (error) { }
  };

  const handleEditedSave = async (e) => {
    e.preventDefault();
    validateForm();

    try {
      editingJobData.keySkills = skills;
      editingJobData.hiringDepartments = selectedDepartments;
      editingJobData.jobId = editingJobData._id;
      const response = await axios.post(
        `${BASE_URL}${editPostedJob}`,
        editingJobData,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      if (response.data.result) {
        Toast.success(response.data.message || "Job Updated Successfully");
        setOpen(false);
        getJobData();
      } else {
        Toast.error(response.data.message || "Failed to update job");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Failed to update job");
    }
  };

  const handleDateFilter = async (event, dateOf) => {
    setFilteredJobData(jobData.filter(job => dayjs(job[dateOf]).isSame(new Date(event), 'day')))
  }

  const handleEditJob = (jobData) => {
    if (jobData?.hiringDepartments?.length > 0) {
      const data = departmentDropdown?.map((department) => {
        if (jobData.hiringDepartments?.includes(department.departmentId)) {
          return department.value;
        } else {
          return ""
        }
      }).filter(value => value !== "");
      setSelectedDepartments([...data]);
    }
    if (jobData?.keySkills?.length > 0) {
      const data = skillList?.reduce((acc, skillName) => {
        if (jobData.keySkills.includes(skillName)) {
          acc.push(skillName); // Only push if condition is met
        }
        return acc;
      }, []);
      setSkills(data);
    }
    setEditingJobData(jobData);
    setOpen(true);
  };

  const fetchDropdown = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${getDropDownPostJob}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        const data = response.data.data;
        setSkillList(data?.skillsList || []);
        // setInstituteDropdown(data?.instituteList || []);
        setJobTypeList(data?.jobTypes || []);
        setDepartmentDropdown(data?.departmentList || []);
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchViewDropdown = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${viewJobDropDown}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        setCompanyDropdown(response?.data?.data?.companyList || []);
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  const fetchJobsByAdmin = async (req, res) => {
    try {
      const response = await axios.get(`${BASE_URL}${getJobs}`, { headers: { Authorization: `Bearer ${getToken()}` } });
      if (response.data.result) {
        setJobData(response?.data?.data || []);
        setFilteredJobData(response?.data?.data || []);
      }

    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong!");
    }
  }

  useEffect(() => {
    validateForm();
  }, []);

  const validateForm = () => {
    let validationErrors = {};

    // Ensure editingJobData is not undefined
    if (!editingJobData) {
      validationErrors["general"] = "**Job data is missing**";
      setErrors(validationErrors);
      setIsSaveDisabled(true);
      return; // Stop further validation if job data is not defined
    }

    const requiredFields = [
      "jobTitle",
      "jobType",
      "jobDescription",
      "keySkills",
      "location",
      "salary",
      "applicationDeadline",
      "hiringDepartments", // Add hiringDepartments to required fields
    ];

    // Check if required fields are empty
    requiredFields.forEach((field) => {
      const value = editingJobData[field]; // Access field value from editingJobData

      if (typeof value === "string" && (!value || value.trim() === "")) {
        validationErrors[field] = "**This field is required**";
      }

      // Handle array fields (for keySkills and hiringDepartments)
      if (Array.isArray(value) && value.length === 0) {
        validationErrors[field] = "**This field is required**";
      }

      // Handle jobTitle length
      if (field === "jobTitle" && value && value.length > 40) {
        validationErrors["jobTitle"] = "**Job Title must be less than 40 characters**";
      }

      // Handle location length
      if (field === "location" && value && value.length > 40) {
        validationErrors["location"] = "**Location must be less than 40 characters**";
      }

      // Handle jobDescription length
      if (field === "jobDescription" && value && value.length > 500) {
        validationErrors["jobDescription"] = "**Description must be less than 500 characters**";
      }

      // Validate applicationDeadline
      if (field === "applicationDeadline") {
        if (!dayjs(value).isValid()) {
          validationErrors["applicationDeadline"] = "**Invalid date format**";
        } else if (dayjs(value).isBefore(dayjs(), "day")) {
          validationErrors["applicationDeadline"] = "**Application deadline cannot be in the past**";
        }
      }
    });

    // Check if hiringDepartments are selected
    if (selectedDepartments.length === 0) {
      validationErrors["hiringDepartments"] = "**This field is required**";
    }

    // Set the error state and disable the save button if there are errors
    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  useEffect(() => {
    validateForm();
  }, [editingJobData, skills, selectedDepartments]);

  useEffect(() => {
    if (getUserRole() === 'recruiter') {
      getJobData();
    } else {
      fetchViewDropdown();
      fetchJobsByAdmin();
    }
    fetchDropdown();
  }, [])

  const handleButtonClick = () => {
    navigate("./add-job");
  }

  const handleEditJobClick = (job) => {
    handleEditJob(job)
  }

  const handleViewJobApplicationsClick = (job) => {
    navigate(`./view-applications/${job.jobPostId}`)
  }

  const handlePreviousClick = () => {
    navigate(-1);
  }

  const filterStudentsClick = () => {
    navigate(`/${getUserRole()}/placement-cell/filter-students`);
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Jobs</h1>
      <Box className={styles.prevIconContainer}>
        <IconButton
          className={styles.closeButtonEditEducation}
          onClick={handlePreviousClick}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      </Box>

      {getUserRole() === "recruiter" ? (
        <>
          <Box className={styles.postJobButtonContainer}>
            <Button
              variant="contained"
              className={styles.filterStudentsButton}
              onClick={filterStudentsClick}>
              Filter Students
            </Button>

            <Button
              className={styles.postJobButton}
              variant="contained"
              onClick={handleButtonClick}
            >
              Post New Job
            </Button>
          </Box>

          <Box className={styles.dropDownParentContainer}>
            <Box className={styles.dropDownContainer}>
              <label htmlFor="searchBar" className={styles.searchLabel}>
                Search Job
              </label>
              <input
                id="searchBar"
                className={styles.searchBar}
                variant="outlined"
                value={selectedDropdown.searchText}
                name="searchText"
                onChange={handleSelectDropdown}
              />
            </Box>

            <Box className={styles.dropDownContainer}>
              <label htmlFor="filterJobType" className={styles.dropDownLabel}>
                Job Types
              </label>
              <Dropdown
                options={jobTypeList}
                onChange={handleSelectDropdown}
                value={selectedDropdown.filterJobType}
                name={"filterJobType"}
              />
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box className={styles.dropDownContainer}>
                <label htmlFor="filterJobType" className={styles.dropDownLabel}>
                  Application Deadline
                </label>
                <DesktopDatePicker
                  className={styles.datePicker}
                  sx={{
                    height: "40px",
                    "& .MuiInputBase-root": {
                      height: "40px",
                    },
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                    },
                    "& .MuiInputAdornment-root": {
                      height: "40px",
                    },
                  }}
                  views={["year", "month", "day"]}
                  inputFormat="MM/DD/YYYY"
                  value={
                    editingJobData?.applicationDeadline
                      ? dayjs(editingJobData?.applicationDeadline)
                      : null
                  }
                  onChange={(event) => handleDateFilter(event, "applicationDeadline")}
                />
              </Box>
              <Box className={styles.dropDownContainer}>
                <label htmlFor="filterJobType" className={styles.dropDownLabel}>
                  Posted On
                </label>
                <DesktopDatePicker
                  className={styles.datePicker}
                  sx={{
                    height: "40px",
                    "& .MuiInputBase-root": {
                      height: "40px",

                    },
                    "& .MuiOutlinedInput-root": {
                      height: "40px",
                    },
                    "& .MuiInputAdornment-root": {
                      height: "40px",
                    },
                  }}
                  views={["year", "month", "day"]}
                  inputFormat="MM/DD/YYYY"
                  value={
                    editingJobData?.applicationDeadline
                      ? dayjs(editingJobData?.applicationDeadline)
                      : null
                  }
                  onChange={(event) => handleDateFilter(event, "createdAt")}
                />
              </Box>
            </LocalizationProvider>
          </Box>
        </>
      ) : (
        <Box className={styles.dropDownParentContainer}>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="searchBar" className={styles.searchLabel}>
              Search Job
            </label>
            <input
              id="searchBar"
              className={styles.searchBar}
              variant="outlined"
              value={selectedDropdown.searchText}
              name="searchText"
              onChange={handleSelectDropdown}
            />
          </Box>
          <Box className={styles.dropDownContainer}>
            <label htmlFor="institute" className={styles.dropDownLabel}>
              Company Applications
            </label>
            <Dropdown
              options={companyDropdown}
              onChange={handleSelectDropdown}
              value={selectedDropdown.companyId}
              name={"companyId"}
            />
          </Box>

        </Box>
      )}
      <div className={styles.noJobTextContainer}>
        {filteredJobData.length === 0 && (
          <p className={styles.noJobText}>No jobs posted yet.</p>
        )}
      </div>
      {/* This section remains the same */}
      <div className={styles.jobPostContainer}>
        {filteredJobData.length > 0 && (
          filteredJobData.map((job, index) => {
            return (
              <div className={styles.jobCardContainer}>
                <Card className={styles.card}>
                  <CardContent className={styles.cardContent}>
                    <div className={styles.jobInfo}>
                      <h2 className={styles.jobTitle}>{job?.jobTitle}</h2>
                      <h3 className={styles.salary}>
                        Salary: {job.salary} /- <span>&#8377;</span>
                      </h3>
                    </div>
                    <p className={styles.jobType}>{job?.jobType}</p>
                    <div className={styles.additionalJobInfo}>
                      <h3>
                        Application Posted On:
                        <p>
                          {new Intl.DateTimeFormat("en-GB").format(
                            new Date(job?.createdAt || null)
                          )}
                        </p>
                      </h3>
                      <h3>
                        Application DeadLine:
                        <p>
                          {job?.applicationDeadline
                            ? `${new Intl.DateTimeFormat("en-GB").format(
                              new Date(job?.applicationDeadline || null)
                            )}`
                            : "N/A"}
                        </p>
                      </h3>
                      <h3 className={styles.description}>
                        Job Description:
                        <>
                          {job?.jobDescription &&
                            job?.jobDescription
                              ?.split("\n")
                              .map((data, index) => {
                                return <p key={index}>{data}</p>;
                              })}
                        </>
                      </h3>

                      <h3>
                        Key Skills:
                        <>
                          {job?.keySkills &&
                            job?.keySkills?.map((data, index) => {
                              return (
                                <Button
                                  key={index}
                                  variant="outlined"
                                  className={styles.skillButton}
                                >
                                  {data}
                                </Button>
                              );
                            })}
                        </>
                      </h3>
                      <h3>
                        Location: <p>{job?.location}</p>
                      </h3>
                    </div>

                    <div className={styles.buttonsContainer}>
                      <Tooltip title="Edit Job" arrow>
                        <ModeEditIcon
                          className={styles.editButton}
                          onClick={() => handleEditJobClick(job)}
                        ></ModeEditIcon>
                      </Tooltip>
                      {job?.applicationCount > 0 ? (
                        <Tooltip title="View Applicants" arrow>
                          <Badge badgeContent={job?.applicationCount} color="error">
                            <RemoveRedEyeOutlinedIcon
                              className={styles.editButton}
                              onClick={() => handleViewJobApplicationsClick(job)}
                            ></RemoveRedEyeOutlinedIcon>
                          </Badge>
                        </Tooltip>
                      ) : (
                        <Tooltip title="View Applicants" arrow>
                          <Badge>
                            <RemoveRedEyeOutlinedIcon
                              className={styles.editButton}
                              onClick={() => handleViewJobApplicationsClick(job)}
                            ></RemoveRedEyeOutlinedIcon>
                          </Badge>
                        </Tooltip>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            );
          })
        )}
      </div>

      <Modal
        className={styles.modal}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={styles.editJobContainer}>
          <div className={styles.closeIconContainer}>
            <IconButton
              className={styles.closeIconBtn}
              onClick={() => setOpen(false)}
            >
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          </div>

          <h2 className={styles.editJobHeader}>{"Edit Job Post"}</h2>
          <div className={styles.editJobScrollable}>
            <form className={styles.formGroup}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="jobType">Job Type</label>
                    <DropDown
                      options={jobTypeList}
                      value={editingJobData?.jobType || ""}
                      // label={"Course List"}
                      name={"jobType"}
                      onChange={handleChange}
                    />
                    {errors.jobType && (
                      <p className={styles.error}>{errors.jobType}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="jobTitle">Job Title</label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      placeholder="Job Title"
                      value={editingJobData?.jobTitle || ""}
                      onChange={handleChange}
                    />
                    {errors.jobTitle && (
                      <p className={styles.error}>{errors.jobTitle}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="departmentList">Departments List</label>
                    <div className={styles.instituteNamesContainer}>
                      {selectedDepartments.length > 0 ? (
                        selectedDepartments.map((value) => {
                          const departmentLabel =
                            departmentDropdown.find(
                              (data) => data.value === value
                            )?.label || "Unknown";
                          return (
                            <Tooltip key={value} title={departmentLabel} arrow>
                              <Button
                                onClick={() => removeDepartment(value)}
                                className={styles.instituteNameButton}
                              >
                                {departmentLabel.length > 10
                                  ? departmentLabel.substring(0, 10) + "..." // Truncate long labels
                                  : departmentLabel}
                              </Button>
                            </Tooltip>
                          );
                        })
                      ) : (
                        <p className={styles.noInstituteText}>
                          **No institute selected**
                        </p>
                      )}
                    </div>

                    <Select
                      labelId="institute-select-label"
                      id="institute-select"
                      multiple
                      value={selectedDepartments}
                      onChange={handleDepartmentChange}
                      className={styles.selectOption}
                    >
                      <MenuItem
                        value={
                          selectedDepartments.length ===
                            departmentDropdown.length
                            ? "deselect-all"
                            : "select-all"
                        }
                      >
                        {selectedDepartments.length ===
                          departmentDropdown.length
                          ? "Deselect All"
                          : "Select All"}
                      </MenuItem>

                      {departmentDropdown.map((departmentData) => {
                        // Only show unselected institutes
                        if (
                          !selectedDepartments.includes(departmentData.value)
                        ) {
                          return (
                            <MenuItem
                              key={departmentData.value}
                              value={departmentData.value}
                            >
                              {departmentData.label}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                    {errors.hiringDepartments && (
                      <p className={styles.error}>{errors.hiringDepartments}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="skills">Key Skills</label>
                    <div className={styles.skillsContainer}>
                      {skills.length > 0 ? (
                        skills.map((value) => {
                          return (
                            <Tooltip key={value} title={value} arrow>
                              <Button
                                onClick={() => removeSkill(value)}
                                className={styles.skillButton}
                              >
                                {value.length > 7
                                  ? value.substring(0, 7) + "..."
                                  : value}
                              </Button>
                            </Tooltip>
                          );
                        })
                      ) : (
                        <p className={styles.noSkillsText}>
                          **No skills selected**
                        </p>
                      )}
                    </div>

                    <Select
                      labelId="skills-select-label"
                      id="skills-select"
                      multiple
                      value={skills}
                      onChange={handleSkillChange}
                      className={styles.selectOption}
                    >
                      <MenuItem
                        value={
                          skills.length ===
                            skillList.length
                            ? "deselect-all"
                            : "select-all"
                        }
                      >
                        {skills.length ===
                          skillList.length
                          ? "Deselect All"
                          : "Select All"}
                      </MenuItem>


                      {skillList.map((skillName) => {
                        // Only show unselected skills
                        if (!skills.includes(skillName)) {
                          return (
                            <MenuItem key={skillName} value={skillName}>
                              {skillName}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                    {errors.keySkills && (
                      <p className={styles.error}>{errors.keySkills}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="jobLocation">Job Location</label>
                    <input
                      type="text"
                      id="jobLocation"
                      name="location"
                      placeholder="Job Location"
                      value={editingJobData?.location || ""}
                      onChange={handleChange}
                    />
                    {errors.location && (
                      <p className={styles.error}>{errors.location}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="salary">Salary / Stipend</label>
                    <input
                      type="number" 
                      onWheel={(e) => e.target.blur()}
                      id="salary"
                      name="salary"
                      value={editingJobData?.salary}
                      onChange={handleChange}
                      placeholder="Salary / Stipend"
                      className={styles.inputbox}
                    />
                    {errors.salary && (
                      <p className={styles.error}>{errors.salary}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="applicationDeadline">
                        Applicaion DeadLine
                      </label>
                      <DesktopDatePicker
                        className={styles.datePicker}
                        views={["year", "month", "day"]}
                        inputFormat="MM/DD/YYYY"
                        value={
                          editingJobData?.applicationDeadline
                            ? dayjs(editingJobData?.applicationDeadline)
                            : null
                        }
                        onChange={handleDateChange}
                      />
                      {errors.applicationDeadline && (
                        <p className={styles.error}>
                          {errors.applicationDeadline}
                        </p>
                      )}
                    </div>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="jobDescription">Job Description</label>
                    <textarea
                      rows="7"
                      cols="50"
                      type="text"
                      id="jobDescription"
                      name="jobDescription"
                      value={editingJobData?.jobDescription}
                      onChange={handleChange}
                      placeholder="Job Description"
                      className={styles.textareaContainer}
                    />
                    {errors.jobDescription && (
                      <p className={styles.error}>{errors.jobDescription}</p>
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} className={styles.submitButtonEditJob}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    // type="submit"
                    onClick={(e) => {
                      showConfirmation(
                        "Want to edit Job ?",
                        handleEditedSave,
                        e
                      );
                    }}
                    disabled={isSaveDisabled}
                  >
                    {"Save"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default JobPost;
