import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import { Box, Button } from "@mui/material";
import PercentWise from "../../../CommonComponent/Attendance/PercentWise/PercentWise";
import DateWise from "../../../CommonComponent/Attendance/DateWise/DateWise";
import EditAttendance from "../../../CommonComponent/Attendance/EditAttendance/EditAttendance";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import styles from "./FacultyAttendance.module.css";
import { BASE_URL } from "../../../../config/config";

const viewAttendance = process.env.REACT_APP_INIT_VIEW_ATTENDANCE;

export default function FacultyAttendance() {
  const { getToken, getUserRole, setShowLoader } = useContext(UserContext);
  const [dropDownData, setDropDownData] = useState({
    courseList: [],
    subjectList: [],
    batchList: [],
    practicalBatchList: [],
  });
  const [selectedFields, setSelectedFields] = useState({
    courseCode: "",
    semester: "",
    batchId: "",
    sectionId: "",
    subjectCode: "",
    subjectName: "",
    subjectType: "",
    practicalBatchId: "",
    practicalBatchName: "",
  });
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [courseDropdown, setCourseDropdown] = useState([]);
  const [batchDropdown, setBatchDropdown] = useState([]);
  const [sectionDropdown, setSectionDropdown] = useState([]);
  const [semesterDropDown, SetSemesterDropDown] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState(false);
  const [practicalBatchDropdown, setPracticalBatchDropdown] = useState([]);
  const navigate = useNavigate();

  const fetchDropDown = async (req, res) => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${viewAttendance}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response?.data?.result) {
        setDropDownData(response?.data?.data);
        setCourseDropdown(() => response?.data?.data?.batchList);
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchDropDown();
  }, []);

  const handleviewDateWise = () => {
    navigate(`/${getUserRole()}/attendance/datewise-attendance`);
  };

  const handleviewPercentWise = () => {
    navigate(`/${getUserRole()}/attendance/percentwise-attendance`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "subjectCode") {
      selectedFields["practicalBatchId"] = "";
      const filtered = subjectDropdown.filter(
        (subjectData) => subjectData.subjectCode === value
      );
      if (filtered.length > 0) {
        selectedFields["subjectType"] = filtered[0].subjectType;
        selectedFields["subjectName"] = filtered[0].subjectName;
      }
    }

    if (name === "practicalBatchId") {
      const filtered = practicalBatchDropdown.filter(
        (batchData) => batchData.value === value
      );
      if (filtered.length > 0) {
        selectedFields["practicalBatchName"] = filtered[0].batchName;
      }
    }

    setSelectedFields({ ...selectedFields, [name]: value });
  };

  useEffect(() => {
    if (selectedFields.courseCode) {
      const batchData = courseDropdown.filter(
        (data) => data.value === selectedFields.courseCode
      );
      setBatchDropdown(() => batchData[0].batches || []);
      setSectionDropdown([]);

      const semesterList = dropDownData.courseList?.filter(
        (data) => data.value === selectedFields.courseCode
      );
      SetSemesterDropDown(() => semesterList[0].semesters || []);
    } else {
      setSelectedFields({
        ...selectedFields,
        batchId: "",
        sectionId: "",
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
      setBatchDropdown([]);
      setSectionDropdown([]);
      SetSemesterDropDown([]);
    }
  }, [selectedFields.courseCode]);

  useEffect(() => {
    if (!(selectedFields.courseCode && selectedFields.semester)) {
      setSubjectDropdown([]);
      setSelectedFields({
        ...selectedFields,
        subjectCode: "",
        practicalBatchId: "",
      });
      return;
    }

    const subjectList = dropDownData?.subjectList?.filter((data) => {
      return (
        data.courseCode === selectedFields.courseCode &&
        data.semester === Number(selectedFields.semester)
      );
    });
    setSubjectDropdown(() => subjectList);
  }, [selectedFields.semester]);

  useEffect(() => {
    if (selectedFields.batchId) {
      const sectionData = batchDropdown.filter(
        (data) => data.value === selectedFields.batchId
      );
      setSectionDropdown(() => sectionData[0]?.sections || []);
    } else {
      setSectionDropdown([]);
      setSelectedFields({
        ...selectedFields,
        sectionId: "",
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
    }
  }, [selectedFields.batchId]);

  useEffect(() => {
    const {
      courseCode,
      batchId,
      sectionId,
      semester,
      subjectCode,
      subjectType,
      practicalBatchId,
    } = selectedFields;

    if (
      courseCode &&
      batchId &&
      sectionId &&
      semester &&
      subjectCode &&
      subjectType
    ) {
      if (subjectType !== "LAB") {
        setDropdownSelected(true);
      } else {
        if (practicalBatchId) {
          setDropdownSelected(true);
        }
      }
    }
  }, [selectedFields]);
  useEffect(() => {
    if (selectedFields.sectionId) {
      const filtered = dropDownData.practicalBatchList.filter(
        (batchData) => batchData.sectionId === selectedFields.sectionId
      );
      if (filtered.length > 0) {
        setPracticalBatchDropdown(() => filtered[0].batchList);
      }
    }
  }, [selectedFields.sectionId]);
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.attendanceHeader}>Student's Attendance</h1>
      <Box className={styles.dropDownParentContainer}>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="courseCode" className={styles.dropDownLabel}>
            Course Name
          </label>{" "}
          <Dropdown
            options={courseDropdown}
            name={"courseCode"}
            onChange={handleChange}
            className={styles.dropdown}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="batchName" className={styles.dropDownLabel}>
            Batch Name
          </label>{" "}
          <Dropdown
            options={batchDropdown}
            name={"batchId"}
            disabled={!selectedFields.courseCode}
            onChange={handleChange}
            className={styles.dropdown}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="batchName" className={styles.dropDownLabel}>
            Section Name
          </label>{" "}
          <Dropdown
            options={sectionDropdown}
            name={"sectionId"}
            onChange={handleChange}
            disabled={!selectedFields.batchId}
            className={styles.dropdown}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="semester" className={styles.dropDownLabel}>
            Semester
          </label>{" "}
          <Dropdown
            options={semesterDropDown}
            name={"semester"}
            onChange={handleChange}
            disabled={!selectedFields.sectionId}
            className={styles.dropdown}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="subjectName" className={styles.dropDownLabel}>
            My Subjects
          </label>{" "}
          <Dropdown
            options={subjectDropdown}
            name={"subjectCode"}
            onChange={handleChange}
            disabled={!selectedFields.semester}
            className={styles.dropdown}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="practicalBatch" className={styles.dropDownLabel}>
            Practical Batch
          </label>{" "}
          <Dropdown
            options={practicalBatchDropdown}
            name={"practicalBatchId"}
            onChange={handleChange}
            value={selectedFields.practicalBatchId}
            disabled={
              !(
                selectedFields.subjectCode &&
                selectedFields.subjectType === "LAB"
              )
            }
            className={styles.dropdown}
          />
        </Box>
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          className={styles.buttons}
          variant="contained"
          onClick={handleviewDateWise}
          disabled={!dropdownSelected}
        >
          View Datewise
        </Button>

        <Button
          className={styles.buttons}
          variant="contained"
          onClick={handleviewPercentWise}
          disabled={!dropdownSelected}
        >
          View Percentwise
        </Button>
      </Box>

      <div className={styles.tableContainer}>
        <Routes>
          <Route
            path="percentwise-attendance"
            element={<PercentWise selectedField={selectedFields} />}
          ></Route>
          <Route
            path="datewise-attendance"
            element={<DateWise selectedField={selectedFields} />}
          ></Route>

          <Route
            path="edit-attendance"
            element={<EditAttendance selectedField={selectedFields} />}
          ></Route>
        </Routes>
      </div>

      {!dropdownSelected && (
        <h2 className={styles.noData}>
          Please select options from the dropdowns to view attendance details.
        </h2>
      )}
    </div>
  );
}
