import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton } from "@mui/material";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import styles from "./CompanyRegistration.module.css";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const registerCompany = process.env.REACT_APP_ADMIN_REGISTER_COMPANY;
const dropdownCompanyRegister = process.env.REACT_APP_ADMIN_DROPDOWN_FOR_REGISTER_COMPANY;
const dropdownCountryName = process.env.REACT_APP_DROPDOWN_GET_COUNTRY_NAMES;
const dropdownStateName = process.env.REACT_APP_DROPDOWN_GET_STATE_NAMES;
const dropdownDistrictName = process.env.REACT_APP_DROPDOWN_GET_DISTRICT_NAMES;

const CompanyRegistration = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [formData, setFormData] = useState({
    companyName: "",
    companyWebsite: "",
    companyEmail: "",
    companyPhone: "",
    employeeSize: "",
    companyType: "",
    country: "India",
    state: "",
    district: "",
    city: "",
    addressLine: "",
    pincode: "",
  });
  const [dropDownData, setDropDownData] = useState({
    companyTypeList: [],
  });
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchDropdown();
    fetchCountryNames();
  }, []);

  useEffect(() => {
    if (formData.country) {
      fetchStates(formData.country);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      fetchDistricts(formData.country, formData.state);
    }
  }, [formData.state]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "companyName", "companyWebsite", "companyEmail", "companyPhone",
      "employeeSize", "companyType", "country", "state", "district",
      "city", "addressLine", "pincode"
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      if (!value || value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${registerCompany}`, formData, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response.data.result) {
        Toast.success(response.data.message || "Company registered successfully.");
        setFormData({
          companyName: "",
          companyWebsite: "",
          companyEmail: "",
          companyPhone: "",
          employeeSize: "",
          companyType: "",
          country: "India",
          state: "",
          district: "",
          city: "",
          addressLine: "",
          pincode: "",
        });
        setErrors({});
      } else {
        Toast.error(response.data.message || "Failed to register company!");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Something went wrong! ");
    } finally {
      setShowLoader(false);
      navigate(-1);
    }
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownCompanyRegister}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setDropDownData(response.data.data);
      }
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchCountryNames = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownCountryName}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });

      if (response.data.result) {
        setCountries(response.data.data);
      } else {
        Toast.error(response.data.message || "Failed to fetch countries.");
      }
    } catch (error) {
      Toast.error("Failed to fetch countries.");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchStates = async (country) => {
    if (!country) {
      setStates([]);
      return;
    }
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownStateName}?country=${country}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setStates(response.data.data);
      }
    } catch (error) {
      Toast.error("Failed to fetch states.");
    } finally {
      setShowLoader(false);
    }
  };

  const fetchDistricts = async (country, state) => {
    if (!country || !state) {
      setDistricts([]);
      return;
    }
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${dropdownDistrictName}?country=${country}&state=${state}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setDistricts(response.data.data);
      } else {
        Toast.error("Failed to fetch districts.");
      }
    } catch (error) {
      Toast.error(error.response?.data?.message || "Failed to fetch districts.");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className={styles.companyContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.header}>COMPANY REGISTRATION</h2>
      <div className={styles.addCompanyScrollable}>
        <form
          className={styles.formGroup}
          onSubmit={(e) => {
            e.preventDefault();
            showConfirmation(`Want to register new company?`, handleSubmit, e);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  placeholder="Company Name"
                  value={formData.companyName}
                  name="companyName"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.companyName && <p className={styles.error}>{errors.companyName}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyWebsite">Company Website</label>
                <input
                  type="text"
                  id="companyWebsite"
                  placeholder="Company Website"
                  value={formData.companyWebsite}
                  name="companyWebsite"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.companyWebsite && <p className={styles.error}>{errors.companyWebsite}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyEmail">Company Email</label>
                <input
                  type="email"
                  id="companyEmail"
                  placeholder="Company Email"
                  value={formData.companyEmail}
                  name="companyEmail"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.companyEmail && <p className={styles.error}>{errors.companyEmail}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyPhone">Company Phone</label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="companyPhone"
                  placeholder="Company Phone"
                  value={formData.companyPhone}
                  name="companyPhone"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.companyPhone && <p className={styles.error}>{errors.companyPhone}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="employeeSize">Employee Size</label>
                <input
                  type="text"
                  id="employeeSize"
                  placeholder="Employee Size"
                  value={formData.employeeSize}
                  name="employeeSize"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.employeeSize && <p className={styles.error}>{errors.employeeSize}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="companyType">Company Type</label>
                <Dropdown
                  id="companyType"
                  name="companyType"
                  options={dropDownData.companyTypeList}
                  value={formData.companyType}
                  onChange={handleChange}
                />
                {errors.companyType && <p className={styles.error}>{errors.companyType}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="country">Country</label>
                <Dropdown
                  id="country"
                  name="country"
                  options={countries}
                  value={formData.country}
                  onChange={handleChange}
                />
                {errors.country && <p className={styles.error}>{errors.country}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="state">State</label>
                <Dropdown
                  id="state"
                  name="state"
                  options={states}
                  value={formData.state}
                  onChange={handleChange}
                />
                {errors.state && <p className={styles.error}>{errors.state}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="district">District</label>
                <Dropdown
                  id="district"
                  name="district"
                  options={districts}
                  value={formData.district}
                  onChange={handleChange}
                />
                {errors.district && <p className={styles.error}>{errors.district}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  placeholder="City"
                  value={formData.city}
                  name="city"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.city && <p className={styles.error}>{errors.city}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="pincode">Pincode</label>
                <input
                  type="text"
                  id="pincode"
                  placeholder="Pincode"
                  value={formData.pincode}
                  name="pincode"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.pincode && <p className={styles.error}>{errors.pincode}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.inputGroup}>
                <label htmlFor="addressLine">Address Line</label>
                <input
                  type="text"
                  id="addressLine"
                  placeholder="Address Line"
                  value={formData.addressLine}
                  name="addressLine"
                  onChange={handleChange}
                  className={styles.inputbox}
                />
                {errors.addressLine && <p className={styles.error}>{errors.addressLine}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSaveDisabled}
                className={styles.submitButton}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CompanyRegistration;
