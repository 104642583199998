import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { FaPaperPlane } from "react-icons/fa";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../ToastContainer/ToastContainer";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./ChatPersonal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { BASE_URL } from "../../../../config/config";

const chatDeleteMessage = process.env.REACT_APP_CHAT_DELETE_MESSAGE;
const getHistoryOfMessages = process.env.REACT_APP_CHAT_GET_HISTORY_MESSAGES;

const ChatPersonal = ({ selectedUser, onClose }) => {
  const {
    socket,
    // chatMessages,
    // setChatMessages,
    Cookies,
    setShowLoader,
    getToken,
    getDecodedToken,
    showConfirmation,
  } = useContext(UserContext);
  const messageInputRef = useRef(null);
  const chatMessagesEndRef = useRef(null);

  const [message, setMessage] = useState("");

  const [chatMessages,
    setChatMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  // const [, /*newMessage*/ setnewMessage] = useState("");
  const convertGivenDateToAMPM = (dateInput) => {
    const date = new Date(dateInput);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 becomes 12

    // Add leading zero to minutes if necessary
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Get the current date in a readable format
    const timeString = `${hours}:${minutes} ${ampm}`;
    return timeString;
  };

  const getHistoryMessages = async () => {
    try {
      const response = await axios.post(`${BASE_URL}${getHistoryOfMessages}`, { userId: selectedUser.email }, { headers: { Authorization: `Bearer ${getToken()}` } })
      setChatMessages(response.data.messages || [])
    } catch (error) {

    }
  }

  const handleReceivedMessage = async (messageData) => {
    if ([messageData.from, messageData.to].includes(getDecodedToken()._id)) {
      setChatMessages((prevMessages) => [...prevMessages, messageData]);
    }
  }
  useEffect(() => {
    if (socket) {
      socket.on("messageReceived", handleReceivedMessage);
    }

    return () => {
      socket?.off("messageReceived", handleReceivedMessage);
    };
  }, [socket])

  useEffect(() => {
    getHistoryMessages()
  }, [selectedUser])

  // const filterMessages = useCallback(() => {
  //   const filtered = chatMessages.filter(
  //     (msg) =>
  //       (msg.from === getDecodedToken()._id &&
  //         msg.to === selectedUser.userId) ||
  //       (msg.from=== getDecodedToken()._id &&
  //         msg.to === selectedUser.userId)
  //   );
  //   setFilteredMessages(filtered);
  // }, [chatMessages, selectedUser]);

  // useEffect(() => {
  //   filterMessages();
  // }, [selectedUser, chatMessages, filterMessages]);

  useEffect(() => {
    messageInputRef.current.focus();
  }, [selectedUser]);

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, []);

  const handleSendMessage = () => {
    if (!message.trim()) return;


    const fromId = getDecodedToken()._id;
    const toId = selectedUser.userId;


    const currentTime = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });


    const newMessage = {
      from: fromId,
      to: toId,
      message: message.trim(),
      time: currentTime,
      fromId: getDecodedToken()._id,
      toId: selectedUser.userId,
      // createdAt:new Date()
    };


    // setChatMessages([
    //   ...chatMessages,
    //   { users: [fromId, toId], message: message.trim(), time: currentTime },
    // ]);

    setMessage("");

    if (toId === selectedUser.userId) {
      if (socket) {
        socket.emit("sendMessage", newMessage);
        setChatMessages((prevMessages) => [...prevMessages, { ...newMessage, createdAt: new Date() }]);

      }
      // setnewMessage({});
    } else {
      console.error("Socket not initialized");
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (chatMessagesEndRef.current) {
      chatMessagesEndRef.current.scrollTop = chatMessagesEndRef.current.scrollHeight;
    }
  }, [chatMessages, filteredMessages]);

  const deleteChat = async (selectedUser) => {
    const confirmDelete = showConfirmation(
      "Are you sure you want to delete this chat?"
    );
    if (!confirmDelete) return;

    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const fromId = localStorage.getItem("userId");
      const toId = selectedUser.email;

      const response = await axios.post(
        `${BASE_URL}${chatDeleteMessage}`,
        selectedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);
      Toast.success(response?.data?.message);
      // setChatMessages((prevMessages) =>
      //   prevMessages.filter(
      //     (msg) => !(msg.users.includes(fromId) && msg.users.includes(toId))
      //   )
      // );
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    // <Draggable>
    <Box className={styles.personalChatContainer}>
      <Box className={styles.closeIconContainer}>
        <DeleteIcon
          className={styles.deleteChatButton}
          onClick={() => {
            deleteChat(selectedUser);
          }}
        />
        <CloseIcon onClick={onClose} className={styles.closeButton} />
      </Box>
      <h3 className={styles.personalChatTitle}>
        {selectedUser?.fname} {selectedUser?.lname}{" "}
      </h3>

      <div className={styles.chatMessages} ref={chatMessagesEndRef}>
        {chatMessages?.map((message, index) => (
          <div
            key={index}
            className={`${styles.message} ${message.from === getDecodedToken()?._id
              ? styles.sent
              : styles.received
              }`}
          >
            <p className={styles.messageText}>{message.message}</p>
            <p className={styles.messageTime}>{convertGivenDateToAMPM(message.createdAt)}</p>{" "}
          </div>
        ))}
      </div>

      <div className={styles.messageInput}>
        <input
          ref={messageInputRef}
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage} className={styles.sendButton}>
          <FaPaperPlane />
        </button>
      </div>
    </Box>
    // </Draggable>
  );
};

export default ChatPersonal;
