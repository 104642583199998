import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Dialog,
  IconButton,
  Button,
  Slide,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import UserContext from "../../../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../../../../CommonComponent/ToastContainer/ToastContainer";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./TimeSlots.module.css";
import Dropdown from "../../../../../CommonComponent/DropDown/DropDown";
import { BASE_URL } from "./../../../../../../config/config";

const getTimeSlots = process.env.REACT_APP_HOD_TIMETABLE_GET_TIMESLOTS;
const updateTimeSlots = process.env.REACT_APP_HOD_TIMETABLE_UPDATE_TIMESLOTS;
const addTimeSlots = process.env.REACT_APP_HOD_TIMETABLE_ADD_TIMESLOTS;
const getDropdownTimeSlots = process.env.REACT_APP_HOD_DROPDOWN_TIMESLOTS;
const deleteTimeSlots = process.env.REACT_APP_HOD_DELETE_TIMESLOTS;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TimeSlots = ({ open, setOpen }) => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [timeslotList, setTimeslotList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newSlot, setNewSlot] = useState(false);
  const [timeSlotTypes, setTimeslotTypes] = useState([]);
  const scrollableRef = useRef(null);

  const fetchTimeslotTypes = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${getDropdownTimeSlots}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setTimeslotTypes(response.data?.data?.slotTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTimeSlotsList = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(`${BASE_URL}${getTimeSlots}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      const timeSlotData = response.data?.data;
      const sorted = [...timeSlotData]?.sort((a, b) => {
        let timeA =
          new Date(a?.startTime).getHours() * 60 +
          new Date(a?.startTime).getMinutes();
        let timeB =
          new Date(b?.startTime).getHours() * 60 +
          new Date(b?.startTime).getMinutes();

        return timeA - timeB;
      });
      setTimeslotList(sorted);
      setShowLoader(false);
      if (!response?.data?.result) {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    fetchTimeSlotsList();
    fetchTimeslotTypes();
  }, []);

  const sortTimeSlotList = () => {
    if (timeslotList.length < 1) {
      return;
    }
    const sorted = [...timeslotList].sort((a, b) => {
      let timeA =
        new Date(a.startTime).getHours() * 60 +
        new Date(a.startTime).getMinutes();
      let timeB =
        new Date(b.startTime).getHours() * 60 +
        new Date(b.startTime).getMinutes();

      return timeA - timeB;
    });
    setTimeslotList(sorted);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteTimeSlot = async (slotData) => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${deleteTimeSlots}`,
        slotData,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response.data.result) {
        Toast.success(response?.data?.message || "Success !");
        fetchTimeSlotsList();
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    sortTimeSlotList();
  };

  const handleSaveClick = async (index) => {
    try {
      const updatedSlot = timeslotList[index];

      if (updatedSlot._id) {
        setShowLoader(true);
        const response = await axios.put(
          `${BASE_URL}${updateTimeSlots}`,
          updatedSlot,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        setEditingIndex(null);

        if (response.data.result) {
          Toast.success(response?.data?.message || "Success !");
          fetchTimeSlotsList();
        } else {
          Toast.error(response?.data?.message || "Something went wrong!");
        }
      } else {
        const response = await axios.post(
          `${BASE_URL}${addTimeSlots}`,
          updatedSlot,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        setEditingIndex(null);

        if (response.data.result) {
          Toast.success(response?.data?.message || "Success !");
        } else {
          Toast.error(response?.data?.message || "Something went wrong!");
        }
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleTimeChange = (index, field, value) => {
    const updatedSlots = [...timeslotList];
    const timeParts = value.split(/[:\s]/); // Split by colon and space for AM/PM handling
    let hours = Number(timeParts[0]);
    const minutes = Number(timeParts[1]);
    const period = timeParts[2]?.toUpperCase();

    if (isNaN(hours) || isNaN(minutes) || minutes < 0 || minutes > 59) {
      console.error("Invalid time input");
      return;
    }

    // Convert 12-hour format to 24-hour format
    if (period === "PM" && hours !== 12) {
      hours += 12; // Convert 6 PM to 18:00
    } else if (period === "AM" && hours === 12) {
      hours = 0; // Convert 12 AM to 00:00
    }

    const currentSlotDate = new Date(updatedSlots[index][field]);
    if (isNaN(currentSlotDate.getTime())) {
      updatedSlots[index][field] = new Date().toISOString();
    }

    currentSlotDate.setHours(hours, minutes);
    updatedSlots[index][field] = currentSlotDate.toISOString();

    setTimeslotList(updatedSlots);
  };

  const handleChange = (index, field, value) => {
    const updatedSlots = [...timeslotList];
    updatedSlots[index][field] = value;
    setTimeslotList([...updatedSlots]);
  };

  const handleAddNewSlot = () => {
    setTimeslotList([
      {
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        slotType: "",
      },
      ...timeslotList,
    ]);
    setNewSlot(true);
    setEditingIndex(() => 0); // Set the editing index to 0 since it's the first slot
  };

  const formatLocalTime = (isoTime) => {
    const date = new Date(isoTime);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatTimeForInput = (isoTime) => {
    const date = new Date(isoTime);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <Box className={styles.modalBackground}>
      <Dialog
        fullWidth
        className={styles.timeSlotContainer}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflow: "hidden"
          },
        }}
      >
        <Box className={styles.navbar}>
          <h2 className={styles.navbarHeader}>Time Slots</h2>
          <Box className={styles.headerContents}>
            <Button
              variant="outlined"
              className={styles.addSlotButton}
              onClick={handleAddNewSlot}
            >
              <span className={styles.plusIcon}>+</span>Add Slot
            </Button>

            <CloseIcon onClick={handleClose} className={styles.closeIcon} />

          </Box>
        </Box>

        <Box className={styles.scrollableContent} ref={scrollableRef}>
          <List>
            {timeslotList && timeslotList.map((slot, index) => (
              <ListItem key={index}>
                {editingIndex === index ? (
                  <Box className={styles.textFieldContainer}>
                    <div className={styles.inputGroup}>
                      <label htmlFor="startTime">Start Time</label>
                      <input
                        type="time"
                        placeholder="HH:MM"
                        value={formatTimeForInput(slot.startTime)}
                        onChange={(e) => handleTimeChange(index, "startTime", e.target.value)}
                      />
                    </div>

                    <div className={styles.inputGroup}>
                      <label htmlFor="endTime">End Time</label>
                      <input
                        type="time"
                        placeholder="HH:MM"
                        value={formatTimeForInput(slot.endTime)}
                        onChange={(e) => handleTimeChange(index, "endTime", e.target.value)}
                      />
                    </div>

                    <div className={styles.inputGroup}>
                      <label htmlFor="Type">Type</label>
                      <Dropdown
                        value={slot.slotType}
                        onChange={(e) =>
                          handleChange(index, "slotType", e.target.value)
                        }
                        options={timeSlotTypes}
                      />
                    </div>

                    <SaveIcon onClick={() => handleSaveClick(index)}
                      className={styles.saveButton} />

                  </Box>
                ) : (
                  <>
                    <ListItemText
                      primary={`Start: ${formatLocalTime(
                        slot.startTime
                      )} - End: ${formatLocalTime(slot.endTime)}`}
                      secondary={`Type: ${slot.slotType ? slot.slotType : "N/A"}`}
                    />
                    <Button
                      className={styles.editButton}
                      color="inherit"
                      onClick={() => handleEditClick(index)}
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        showConfirmation(
                          "want to delete time Slot ?",
                          handleDeleteTimeSlot,
                          slot
                        );
                      }}
                      aria-label="close"
                    >
                      <Delete />
                    </IconButton>
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Dialog >
    </Box >
  );
};

export default TimeSlots;
