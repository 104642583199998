import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styles from "./InstituteAdminUserRegistration.module.css";
import HodRegistration from "./HodRegistration/HodRegistration";
import TPORegistration from "./TPORegistration/TPORegistration";
import RecruiterRegistration from "../../../InstituteAdminComponent/Pages/InstituteAdminUserRegistration/RecruiterRegistration/RecruiterRegistration";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

function InstituteAdminUserRegistration({ user }) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const isCollageAdmin = user === "collegeadmin";

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className={styles.container}>
            <Box className={styles.tabContainer}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "yellow",
                            width: "48%",
                            marginLeft: "5px",
                        },
                    }}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    className={styles.tabSection}
                >
                    <Tab
                        label="HOD Register"
                        {...a11yProps(0)}
                        className={styles.tabOption}
                    />
                    <Tab
                        label="TNP Officer Register"
                        {...a11yProps(1)}
                        className={styles.tabOption}
                    />
                    {/* <Tab
                        label="Recruiter Register"
                        {...a11yProps()}
                        className={styles.tabOption}
                    /> */}
                </Tabs>
            </Box>

            <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className={styles.tabPanel}
            >
                <HodRegistration
                    user={user}
                    isCollageAdmin={isCollageAdmin}
                />
            </TabPanel>
            <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className={styles.tabPanel}
            >
                <TPORegistration user={user} />

            </TabPanel>
            <TabPanel
                value={value}
                index={2}
                dir={theme.direction}
                className={styles.tabPanel}
            >
                <RecruiterRegistration user={user} />
            </TabPanel>
        </Box>
    );
}

export default InstituteAdminUserRegistration;
