import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CompanyList from './CompanyList/CompanyList'
import CompanyRegistration from './CompanyRegistration/CompanyRegistration'

const Company = () => {
  return (
    <Routes>
      <Route path="/" element={<CompanyList />}></Route>
      <Route path="/register-company" element={<CompanyRegistration />}></Route>
    </Routes>
  )
}

export default Company