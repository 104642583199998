import React from "react";
import styles from "./StudentJobBoard.module.css";
import { Routes, Route } from "react-router-dom";
import JobList from "../../../CommonComponent/JobRelated/JobList/JobList";

const StudentJobBoard = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Welcome to Job Portal</h1>
      <Routes>
        <Route path="/" element={<JobList />} />
      </Routes>
    </div>
  );
};

export default StudentJobBoard;
