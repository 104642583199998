import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import DropDown from "../../DropDown/DropDown";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import Toast from "../../ToastContainer/ToastContainer";
import { useNavigate } from "react-router-dom";
import styles from "./AllocateStudents.module.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BASE_URL } from "../../../../config/config";

const getFilteredUserList = process.env.REACT_APP_GET_FILTERED_USERLIST;
const allocateStudents = process.env.REACT_APP_HOD_ALLOCATING_STUDENT_TO_BATCH
const searchStudent = process.env.REACT_APP_SEARCH_STUDENT
const dropdownViewStudent = process.env.REACT_APP_DROPDOWN_VIEW_STUDENT

const AllocateStudents = () => {
  const { getToken, getUserRole, showConfirmation, setShowLoader } = useContext(UserContext);
  const [students, setStudents] = useState([]);
  const [isAllocating, setIsAllocating] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [wholeDropdownData, setWholeDropdownData] = useState("");
  const [dropDownData, setDropDownData] = useState([]);
  const [roleTypeDropdown, setRoleTypeDropdown] = useState([]);
  const [batchDropdown, setBatchDropdown] = useState([]);
  const [sectionDropdown, setSectionDropdown] = useState([]);
  const [courseDropDown, setCourseDropDown] = useState([]);
  const [semesterDropDown, setSemesterDropDown] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [formData, setFormData] = useState({
    batch: "",
    semester: "",
    section: "",
    courseCode: "",
    departmentCode: "",
  });
  const [allocateTo, setAllocateTo] = useState({
    batch: "",
    semester: "",
    section: "",
    courseCode: "",
    departmentCode: "",
  });

  const navigate = useNavigate();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = students.map((student) => student);
      setSelectedStudents(newSelecteds);
      return;
    }
    setSelectedStudents([]);
  };

  const fetchFilteredUserList = async () => {
    try {
      const queryString = new URLSearchParams(formData).toString();
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getFilteredUserList}?${queryString}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      setStudents(response?.data?.data);

      setShowLoader(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!")

      setShowLoader(false);
    }
  };
  const handleClick = (studentData) => {
    const selectedIndex = selectedStudents.findIndex(
      (data) => data.enrollmentId === studentData.enrollmentId
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStudents, studentData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStudents.slice(1));
    } else if (selectedIndex === selectedStudents.length - 1) {
      newSelected = newSelected.concat(selectedStudents.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStudents.slice(0, selectedIndex),
        selectedStudents.slice(selectedIndex + 1)
      );
    }

    setSelectedStudents(newSelected);
  };

  const handleAddSelected = () => {
    fetchDropdown();
    setIsAllocating(true);
  };

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `${BASE_URL}${dropdownViewStudent}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      if (response?.data?.result) {
        setWholeDropdownData(response?.data?.data);
        setDropDownData(() => response?.data?.data?.departmentList);
        setRoleTypeDropdown(() => response?.data?.data?.roleTypes);
        setBatchDropdown(() => response?.data?.data?.batches);
      }
      if (response.data?.data?.departmentCode) {
        // if(!isAllocating){
        setFormData({ ...formData, departmentCode: response.data?.data?.departmentCode })
        // }else{
        setAllocateTo({ ...allocateTo, departmentCode: response.data?.data?.departmentCode })
        // }
      }
      setShowLoader(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!")
      setShowLoader(false);
    }
  };

  const handleSearch = async () => {
    try {
      if (!searchText) {
        return;
      }
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${searchStudent}`,
        { toSearch: searchText },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      setShowLoader(false);

      setStudents(() => response?.data?.data);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!")
    }
  };

  const isSelected = (studentData) =>
    selectedStudents.some(
      (data) => data.enrollmentId === studentData.enrollmentId
    );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  useEffect(() => {
    if (formData.departmentCode || formData.batch) {
      if (getUserRole() !== 'hod') {
        fetchFilteredUserList();
      } else {
        if (formData.courseCode) {
          fetchFilteredUserList();
        }
      }
    } else {
      setStudents([]);
    }
  }, [
    formData.batch,
    formData.courseCode,
    formData.section,
    formData.departmentCode,
    formData.semester,
  ]);

  useEffect(() => {
    const filteredList = dropDownData?.filter(
      (OBJ) => OBJ.value === formData?.departmentCode
    );
    setFormData({ ...formData, ["courseCode"]: "" });
    setCourseDropDown(() => filteredList[0]?.courseList);
  }, [formData?.departmentCode]);

  useEffect(() => {
    const filteredList = courseDropDown?.filter(
      (OBJ) => OBJ.value === formData?.courseCode
    );
    if (filteredList) {
      setFormData({ ...formData, ["semester"]: "" });
      setSemesterDropDown(() => filteredList[0]?.semesterList);
    }

    const batchList = wholeDropdownData?.batches?.filter(
      (data) => data?.courseCode === formData?.courseCode
    );
    setBatchDropdown(batchList || [])


  }, [formData?.courseCode]);

  useEffect(() => {
    const filteredList = dropDownData?.filter(
      (OBJ) => OBJ.value === allocateTo?.departmentCode
    );
    setFormData({ ...formData, ["courseCode"]: "" });
    setCourseDropDown(() => filteredList[0]?.courseList);
    setBatchDropdown(wholeDropdownData?.batches || [])
    // wholeDropdownData.batches
  }, [allocateTo?.departmentCode]);

  useEffect(() => {
    const filteredList = courseDropDown?.filter(
      (OBJ) => OBJ.value === allocateTo?.courseCode
    );
    if (filteredList) {
      setFormData({ ...formData, ["semester"]: "" });
      setSemesterDropDown(() => filteredList[0]?.semesterList);
    }

    const batchList = wholeDropdownData?.batches?.filter(
      (data) => data?.courseCode === allocateTo?.courseCode
    );
    setBatchDropdown(batchList || [])
  }, [allocateTo?.courseCode]);


  useEffect(() => {
    const filteredList = batchDropdown?.filter(
      (OBJ) => formData.batch === OBJ?.value
    );
    if (filteredList) {
      setSectionDropdown(() => filteredList[0]?.sections);
      setFormData({ ...formData, ["section"]: "" });
    }
  }, [formData?.batch]);

  useEffect(() => {
    const filteredList = batchDropdown?.filter(
      (OBJ) => allocateTo.batch === OBJ?.value
    );
    if (filteredList) {
      setSectionDropdown(() => filteredList[0]?.sections);
      setFormData({ ...formData, ["section"]: "" });
    }
  }, [allocateTo?.batch]);

  useEffect(() => {
    fetchDropdown();
  }, []);

  const handleChangeSearch = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      handleSearch();
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchText]);

  const handleAssignmentChange = (e) => {
    const { name, value } = e.target;
    setAllocateTo({
      ...allocateTo,
      [name]: value,
    });
  };

  useEffect(() => {
  }, [formData]);

  const handleConfirmAllocation = async (allocateTo) => {
    allocateTo.studentList = selectedStudents;

    try {
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${allocateStudents}`,
        allocateTo,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );

      if (response.data.result) {
        Toast.success(`Students allocated successfully...!`);
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!")

    }
  };

  return (
    <div className={styles.wrapper}>
      {!isAllocating && (
        <div className={styles.formContainer}>
          <Box className={styles.closeIconContainer}>
            <ArrowBackIcon
              className={styles.previousButton}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>

          <h1 className={styles.formContainerTitle}>Search Students</h1>
          <form className={styles.formSection}>

            <Box className={styles.dropDownParentContainer}>
              <Box className={styles.inputGroup}>
                <label htmlFor="searchBar" className={styles.searchLabel}>
                  Search
                </label>
                <input
                  id="searchBar"
                  className={styles.searchBar}
                  value={searchText}
                  onChange={handleChangeSearch}
                  variant="outlined"
                />
              </Box>

              {!(getUserRole() === "hod") && (
                <Box className={styles.inputGroup}>
                  <label
                    htmlFor="departmentName"
                    className={styles.dropDownLabel}
                  >
                    Department Name
                  </label>
                  <DropDown
                    name={"departmentCode"}
                    value={formData?.departmentCode}
                    options={dropDownData}
                    onChange={handleChange}
                    disabled={getUserRole() === "hod" ? true : false}
                    className={styles.dropDownSelect}
                  />
                </Box>
              )}

              <Box className={styles.inputGroup}>
                <label htmlFor="courseName" className={styles.dropDownLabel}>
                  Course Name
                </label>
                <DropDown
                  name={"courseCode"}
                  value={formData?.courseCode}
                  options={courseDropDown}
                  onChange={handleChange}
                  className={styles.dropDownSelect}
                />
              </Box>

              <Box className={styles.inputGroup}>
                <label htmlFor="batch" className={styles.dropDownLabel}>
                  Batch
                </label>
                <DropDown
                  name={"batch"}
                  value={formData?.batch}
                  options={batchDropdown || []}
                  onChange={handleChange}
                  className={styles.dropDownSelect}
                />
              </Box>

              <Box className={styles.inputGroup}>
                <label htmlFor="section" className={styles.dropDownLabel}>
                  Section
                </label>
                <DropDown
                  name={"section"}
                  value={formData?.section}
                  options={sectionDropdown || []}
                  onChange={handleChange}
                  className={styles.dropDownSelect}
                />
              </Box>

              <Box className={styles.inputGroup}>
                <label htmlFor="semester" className={styles.dropDownLabel}>
                  Semester
                </label>
                <DropDown
                  name={"semester"}
                  value={formData?.semester}
                  options={semesterDropDown}
                  onChange={handleChange}
                  className={styles.dropDownSelect}
                />
              </Box>
            </Box>
          </form>
        </div>
      )}


      {!isAllocating && (
        <div className={styles.studentListContainer}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h2 className={styles.tableContainerTitle}>Students List</h2>
                <TableContainer
                  component={Paper}
                  className={styles.tableContainer}
                >
                  <Table
                    aria-label="customized table"
                    className={styles.theTable}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={styles.tableHead}
                          padding="checkbox"
                        >
                          <Checkbox
                            indeterminate={
                              selectedStudents.length > 0 &&
                              selectedStudents.length < students.length
                            }
                            checked={
                              students.length > 0 &&
                              selectedStudents.length === students.length
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{ "aria-label": "select all students" }}
                          />
                        </TableCell>
                        <TableCell className={styles.tableHead}>
                          Enrollment ID
                        </TableCell>
                        <TableCell className={styles.tableHead}>
                          Student Name
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {students.map((student, index) => {
                        const isItemSelected = isSelected(student);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            key={student?.enrollmentId}
                            hover
                            onClick={() => handleClick(student)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            selected={isItemSelected}
                            className={isItemSelected ? styles.selectedRow : ""}
                          >
                            <TableCell
                              className={styles.tableData}
                              padding="checkbox"
                            >
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </TableCell>
                            <TableCell className={styles.tableData}>
                              {student?.enrollmentId}
                            </TableCell>
                            <TableCell
                              className={styles.tableData}
                              id={labelId}
                              scope="row"
                            >
                              {student?.name}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddSelected}
                  disabled={selectedStudents.length === 0}
                  className={styles.addStudentButton}
                >
                  Add Students
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}

      {isAllocating && (
        <div className={styles.allocationContainer}>
          <Box className={styles.closeIconContainer}>
            <ArrowBackIcon
              className={styles.previousButton}
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <h1 className={styles.formContainerTitle}>
            Total Students Allocation
          </h1>
          <h2 className={styles.formContainerDetailsTitle}>To allocate selected {selectedStudents.length} students, select the below dropdowns accordingly.</h2>

          <Box className={styles.dropDownParentContainer}>
            <Box className={styles.inputGroup}>
              <label htmlFor="departmentCode" className={styles.dropDownLabel}>
                Department Code
              </label>
              <DropDown
                name={"departmentCode"}
                value={allocateTo?.departmentCode}
                options={dropDownData}
                onChange={handleAssignmentChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="courseCode" className={styles.dropDownLabel}>
                Course Code
              </label>
              <DropDown
                name={"courseCode"}
                value={allocateTo?.courseCode}
                options={courseDropDown}
                onChange={handleAssignmentChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="batch" className={styles.dropDownLabel}>
                Batch
              </label>
              <DropDown
                name={"batch"}
                value={allocateTo?.batch}
                options={batchDropdown || []}
                onChange={handleAssignmentChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="batch" className={styles.dropDownLabel}>
                Section
              </label>
              <DropDown
                name={"section"}
                value={allocateTo?.section}
                options={sectionDropdown || []}
                onChange={handleAssignmentChange}
                className={styles.dropDownSelect}
              />
            </Box>

            <Box className={styles.inputGroup}>
              <label htmlFor="semester" className={styles.dropDownLabel}>
                Semester
              </label>
              <DropDown
                name={"semester"}
                value={allocateTo?.semester}
                options={semesterDropDown}
                onChange={handleAssignmentChange}
                className={styles.dropDownSelect}
              />
            </Box>

          </Box>

          <Box className={styles.buttonContainer}>
            <Button
              onClick={
                () => {
                  showConfirmation(
                    `Want to Allocate ${selectedStudents.length} students to ?`,
                    handleConfirmAllocation,
                    allocateTo
                  );
                }
              }
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default AllocateStudents;
