import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import styles from "./RecruiterRegistration.module.css";
import { Button, Grid, Tooltip } from "@mui/material";
import { BASE_URL } from "../../../../../config/config";
import Dropdown from "../../../../CommonComponent/DropDown/DropDown";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import UserContext from "../../../../../Context/UserContext";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const getDropdown =
  process.env.REACT_APP_SUPERADMIN_DROPDOWN_INSTITUTE_ADMIN_REGISTRATION;
const registerRecruiter =
  process.env.REACT_APP_SUPERADMIN_REGISTER_RECRUITER;

const RecruiterRegistration = () => {
  const { getToken, setShowLoader, showConfirmation } = useContext(UserContext);
  const [instituteDropdown, setInstituteDropdown] = useState([]);
  const [selectedInstitutes, setSelectedInstitutes] = useState([]);
  const [dropDownData, setDropDownData] = useState({
    genderList: [],
    instituteList: [],
    salutationList: [],
  });
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [formData, setFormData] = useState({
    instituteCode: "",
    salutation: "",
    fname: "",
    mname: "",
    lname: "",
    gender: "",
    primaryPhone: "",
    secondaryPhone: "",
    email: "",
    designation: "",
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    companyWebsite: "",
    dob: "",
    defaultPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    validateForm();
  }, [formData]);

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      "salutation",
      "fname",
      "mname",
      "lname",
      "gender",
      "primaryPhone",
      // "secondaryPhone",
      "email",
      "companyName",
      "companyEmail",
      "companyWebsite",
      "companyPhone",
      "designation",
      "dob",
      "defaultPassword",
      "confirmPassword",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    const phoneFields = ["primaryPhone", "secondaryPhone", "companyPhone"];
    phoneFields.forEach((field) => {
      const value = formData[field];
      if (value && !/^\d{10}$/.test(value)) {
        validationErrors[field] = "**Phone number must be 10 digits**";
      }
    });

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    if (
      formData.defaultPassword &&
      !passwordRegex.test(formData.defaultPassword)
    ) {
      validationErrors.defaultPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    if (
      formData.confirmPassword &&
      !passwordRegex.test(formData.confirmPassword)
    ) {
      validationErrors.confirmPassword =
        "**Password must be at least 8 characters long, include one uppercase letter, and one special character**";
    }

    if (
      formData.defaultPassword &&
      formData.confirmPassword &&
      formData.defaultPassword !== formData.confirmPassword
    ) {
      validationErrors.confirmPassword = "**Passwords do not match**";
    }

    if (!formData.dob) {
      validationErrors.dob = "**This field is required**";
    } else {
      const dob = dayjs(formData.dob);
      if (!dob.isValid()) {
        validationErrors.dob = "**Invalid date format**";
      } else if (dob.isAfter(dayjs(), "day")) {
        validationErrors.dob = "**Date of Birth cannot be in the future**";
      }
    }

    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      validationErrors["email"] = "**Please enter a valid email address**";
    }

    if (selectedInstitutes.length < 1) {
      validationErrors["instituteList"] = "**Please select at least one institute**";
    }

    if (formData.companyWebsite && !/^(https?:\/\/)?[^\s]+\.[^\s]+$/.test(formData.companyWebsite)) {
      validationErrors["companyWebsite"] = "**Please enter a valid website URL**";
    }

    setErrors(validationErrors);
    setIsSubmitDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      const dob = dayjs(newValue);

      if (!dob.isValid()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "**Invalid date format**",
        }));
      }
      else if (dob.isAfter(dayjs(), "day")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "**Date of Birth cannot be in the future**",
        }));
      }
      else {
        setFormData({
          ...formData,
          dob: dob.toISOString(),
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          dob: "",
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "**This field is required**",
      }));
      setFormData({
        ...formData,
        dob: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      formData.instituteCodeList = selectedInstitutes
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${registerRecruiter}`,
        formData,
        {
          headers,
        }
      );
      if (response.data.result) {
        Toast.success(response.data.message || "Success !");
        setFormData({
          salutation: "",
          fname: "",
          mname: "",
          lname: "",
          gender: "",
          primaryPhone: "",
          secondaryPhone: "",
          email: "",
          // enrollmentId: "",
          companyName: "",
          companyEmail: "",
          // companyWebsite:"",
          companyPhone: "",
          designation: "",
          dob: "",
          defaultPassword: "",
          confirmPassword: "",
        })
        setErrors({});
      } else {
        Toast.error(response.data.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => { }, [formData]);

  const handleInstitueChange = (e) => {
    const { value } = e.target;

    if (value.includes("select-all")) {
      if (selectedInstitutes.length === instituteDropdown.length) {
        setSelectedInstitutes([]);
      } else {
        const allValues = instituteDropdown.map((data) => data.value);
        setSelectedInstitutes(allValues);
      }
    } else {
      setSelectedInstitutes(value);
    }
  };


  useEffect(() => {
    validateForm()
  }, [selectedInstitutes])

  const removeInstitute = (value) => {
    setSelectedInstitutes(() => selectedInstitutes.filter(item => item !== value))
  }

  const fetchDropdown = async () => {
    try {
      const response = await axios.get(`${BASE_URL}${getDropdown}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      if (response.data.result) {
        setDropDownData(response?.data?.data);
        setInstituteDropdown(() => response?.data?.data?.instituteList)
      } else {
        Toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      Toast.error(error?.response.data.message || "Something went wrong!");
    }
  };
  useEffect(() => {
    fetchDropdown();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.recruiterRegContainer}>
        <h2 className={styles.recruiterRegContainerHeader}>
          Register Recruiter{" "}
        </h2>
        <div className={styles.registerFormScrollable}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              showConfirmation(
                "Want to register Recruiter?",
                handleSubmit,
                event
              );
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="instituteName">Institute Name</label>
                  <div className={styles.instituteNamesContainer}>
                    {selectedInstitutes.length > 0 ? (
                      selectedInstitutes.map((value) => {
                        const instituteLabel = instituteDropdown.find((data) => data.value === value)?.label || "Unknown";
                        return (
                          <Tooltip key={value} title={instituteLabel} arrow>
                            <Button
                              onClick={() => removeInstitute(value)}
                              className={styles.instituteNameButton}
                            >
                              {
                                instituteLabel.length > 10
                                  ? instituteLabel.substring(0, 15) + "..."
                                  : instituteLabel
                              }
                            </Button>
                          </Tooltip>
                        );
                      })
                    ) : (
                      <p className={styles.noInstituteText}>**No institute selected**</p>
                    )}
                  </div>

                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedInstitutes}
                    onChange={handleInstitueChange}
                    className={styles.selectOption}
                  >
                    <MenuItem
                      value="select-all"
                      onClick={() => {
                        if (selectedInstitutes.length === instituteDropdown.length) {
                          setSelectedInstitutes([]);
                        } else {
                          const allValues = instituteDropdown.map((data) => data.value);
                          setSelectedInstitutes(allValues);
                        }
                      }}
                    >
                      {selectedInstitutes.length === instituteDropdown.length ? "Deselect All" : "Select All"}
                    </MenuItem>

                    {instituteDropdown.map((instituteData) => {
                      if (!selectedInstitutes.includes(instituteData.value)) {
                        return (
                          <MenuItem key={instituteData.value} value={instituteData.value}>
                            {instituteData.label}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>


                </div>
                {errors.instituteList && (
                  <p className={styles.error}>{errors.instituteList}</p>
                )}
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="salutation">Salutation</label>
                  <Dropdown name="salutation" value={formData.salutation} options={dropDownData.salutationList} onChange={handleChange} />
                </div>
                {errors.salutation && <p className={styles.error}>{errors.salutation}</p>}
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="firstName"> First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    value={formData.fname}
                    name="fname"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.fname && (
                    <p className={styles.error}>{errors.fname}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="middleName"> Middle Name</label>
                  <input
                    type="text"
                    id="middleName"
                    placeholder="Middle Name"
                    value={formData.mname}
                    name="mname"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.mname && (
                    <p className={styles.error}>{errors.mname}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="lastName"> Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    value={formData.lname}
                    name="lname"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.lname && (
                    <p className={styles.error}>{errors.lname}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="gender">Gender</label>
                  <Dropdown
                    name="gender"
                    value={formData.gender}
                    options={dropDownData.genderList}
                    onChange={handleChange}
                  />
                </div>
                {errors.gender && (
                  <p className={styles.error}>{errors.gender}</p>
                )}
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="primaryPhone"> Primary Phone</label>
                  <input
                    type="number" 
                    onWheel={(e) => e.target.blur()}
                    aria-controls="none"
                    id="primaryPhone"
                    placeholder="Primary Phone"
                    value={formData.primaryPhone}
                    name="primaryPhone"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.primaryPhone && (
                    <p className={styles.error}>{errors.primaryPhone}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="secondaryPhone"> Secondary Phone</label>
                  <input
                    type="number" 
                    onWheel={(e) => e.target.blur()}
                    aria-controls="none"
                    id="secondaryPhone"
                    placeholder="Secondary Phone"
                    value={formData.secondaryPhone}
                    name="secondaryPhone"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.secondaryPhone && (
                    <p className={styles.error}>{errors.secondaryPhone}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="emailId"> Email</label>
                  <input
                    type="email"
                    id="emailId"
                    placeholder="Email Id"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.email && (
                    <p className={styles.error}>{errors.email}</p>
                  )}
                </div>
              </Grid>

              {/* <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="enrollmentId">Enrollment / Faculty Id</label>
                  <input
                    type="text"
                    id="enrollmentId"
                    placeholder="Enrollment Id /Faculty Id"
                    value={formData.enrollmentId}
                    name="enrollmentId"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.enrollmentId && (
                    <p className={styles.error}>{errors.enrollmentId}</p>
                  )}
                </div>
              </Grid> */}

              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className={styles.inputGroup}>
                    <label htmlFor="dob">Date of Birth</label>
                    <DesktopDatePicker
                      className={styles.datePicker}
                      views={["year", "month", "day"]}
                      inputFormat="MM/DD/YYYY"
                      value={formData.dob ? dayjs(formData.dob) : null}
                      onChange={handleDateChange}
                    />
                    {errors.dob && <p className={styles.error}>{errors.dob}</p>}
                  </div>
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="designation">Designation</label>
                  <input
                    type="text"
                    id="designation"
                    placeholder="Designation"
                    value={formData?.designation}
                    name="designation"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.designation && (
                    <p className={styles.error}>{errors.designation}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    placeholder="Company Name"
                    value={formData?.companyName}
                    name="companyName"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.companyName && (
                    <p className={styles.error}>{errors.companyName}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="companyEmail">Company Email</label>
                  <input
                    type="email"
                    id="companyEmail"
                    placeholder="Company Email"
                    value={formData?.companyEmail}
                    name="companyEmail"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.companyEmail && (
                    <p className={styles.error}>{errors.companyEmail}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="companyWebsite">Company Website</label>
                  <input
                    type="text"
                    id="companyWebsite"
                    placeholder="Company Website"
                    value={formData?.companyWebsite}
                    name="companyWebsite"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.companyWebsite && (
                    <p className={styles.error}>{errors.companyWebsite}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="companyPhone">Company Phone</label>
                  <input
                    type="number" 
                    onWheel={(e) => e.target.blur()}
                    id="companyPhone"
                    placeholder="Company Phone"
                    value={formData?.companyPhone}
                    name="companyPhone"
                    onChange={handleChange}
                    className={styles.inputbox}
                    autoComplete="none"
                  />
                  {errors.companyPhone && (
                    <p className={styles.error}>{errors.companyPhone}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="defaultPassword">Default Password</label>
                  <input
                    type="password"
                    id="defaultPassword"
                    placeholder="Default Password"
                    value={formData.defaultPassword}
                    name="defaultPassword"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.defaultPassword && (
                    <p className={styles.error}>{errors.defaultPassword}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className={styles.inputGroup}>
                  <label htmlFor="Confirm Default Password">
                    Confirm Default Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    placeholder="Confirm Default Password"
                    value={formData.confirmPassword}
                    name="confirmPassword"
                    onChange={handleChange}
                    className={styles.inputbox}
                  />
                  {errors.confirmPassword && (
                    <p className={styles.error}>{errors.confirmPassword}</p>
                  )}
                </div>
              </Grid>

              <Grid item xs={12} className={styles.submitButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={styles.submitButton}
                  disabled={isSubmitDisabled}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RecruiterRegistration;
