import { useState, useEffect, useContext } from 'react';
import styles from "./Login.module.css";
import { Grid, Link, Box, Modal, Button, IconButton, AppBar, Toolbar, Drawer } from '@mui/material';
import Toast from '../../CommonComponent/ToastContainer/ToastContainer';
import Img from '../../../images/Confirmed attendance.gif';
import Logo from "../../../images/HM-logo.png";
import navbarLogo from "../../../images/Untitled design.png";
import axios from "axios";
import UserContext from "../../../Context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./../../../config/config";
import { jwtDecode } from "jwt-decode";

const instituteLogin = process.env.REACT_APP_INSTITUTE_LOGIN;
const recruiterLogin = process.env.REACT_APP_RECRUITER_LOGIN;
const forgotPassword = process.env.REACT_APP_FORGOT_PASSWORD;

const LoginPage = ({ onLogin }) => {
  const { Cookies, setShowLoader, } = useContext(UserContext);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const [modalEmailError, setModalEmailError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isModalEmailValid, setIsModalEmailValid] = useState(false);
  const [loginType, setLoginType] = useState('institute');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [institutes, setInstitutes] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState('');
  const [showInstituteSelector, setShowInstituteSelector] = useState(false);


  // useEffect(() => {
  //   const checkUserLogin = async () => {
  //     getDecodedToken();
  //     if (getDecodedToken() && getDecodedToken().lastLogin === null) {
  //       navigate('/settings/reset-password');
  //       return;
  //     }

  //     if (getToken()) {
  //       navigate('/');
  //     }
  //   };

  //   checkUserLogin();
  // }, [Cookies, navigate, getToken, getDecodedToken]);

  const validateEmail = (email) => {
    if (email.trim() === '') {
      return 'Email is required';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? '' : 'Invalid email format';
  };

  const validatePassword = (password) => {
    if (password.trim() === '') {
      return 'Password is required';
    }
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const newErrors = { ...errors };

    if (name === 'email') {
      newErrors.email = validateEmail(value);
    }

    if (name === 'password') {
      newErrors.password = validatePassword(value);
    }

    setErrors(newErrors);
    setIsFormValid(
      !newErrors.email && !newErrors.password
    );
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);

      const response = await axios.post(`${BASE_URL}${loginType === 'recruiter' ? recruiterLogin : instituteLogin}`, formData);
      const data = response?.data;

      if (data?.result) {
        setShowLoader(false);
        Toast.success(data.message);
        Cookies.set('token', data?.token, { expires: 30 });
        const decodedToken = jwtDecode(data?.token);
        if (!data.lastLogin) {
          navigate(`/${decodedToken.role}/profile/settings/reset-password`);
        }

        localStorage.setItem('profileImage', data.image);
        localStorage.setItem('instituteLogo', data?.instituteLogo || null);
        if (decodedToken.role === 'student') {
          localStorage.setItem('jobNotify', data.isNewJobAdded);
        } else {
          localStorage.setItem('notificationCount', data.notificationCount);
        }

        // onLogin();
      } else {
        setShowLoader(false);
        Toast.error(data.message);
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleResetPassword = async () => {
    if (!isModalEmailValid) {
      return;
    }

    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${forgotPassword}`, { email });

      if (response.data.result) {
        Toast.success("OTP sent. Please check your email.");
        navigate('/updatePassword');
      } else {
        Toast.error(response?.data?.message || "Something went wrong!");
      }
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleModalEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const validationError = validateEmail(value);
    if (validationError) {
      setModalEmailError(validationError);
      setIsModalEmailValid(false);
    } else {
      setModalEmailError('');
      setIsModalEmailValid(true);
    }
  };

  useEffect(() => {
    setIsFormValid(
      !errors.email && !errors.password && validateEmail(formData.email) === '' && validatePassword(formData.password) === ''
    );
  }, [formData, errors]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleInstituteSelection = (instituteId) => {
    if (!instituteId) {
      Toast.error("Please select an institute.");
      return;
    }

    setSelectedInstitute(instituteId);
    setShowInstituteSelector(false);  // Close the modal

    // Proceed with login for the selected institute
    handleLoginWithSelectedInstitute(instituteId);
  };

  useEffect(() => {
  }, [showInstituteSelector]);

  const handleLoginWithSelectedInstitute = async (instituteId) => {
    try {
      setShowLoader(true);
      const response = await axios.post(`${BASE_URL}${loginType === 'recruiter' ? recruiterLogin : instituteLogin}`, {
        ...formData,
        instituteId
      });
      const data = response?.data;

      if (data?.result) {
        handleLoginSuccess(data);
      } else {
        setShowLoader(false);
        Toast.error(data.message);
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleLoginSuccess = (data) => {
    setShowLoader(false);
    Toast.success(data.message);
    Cookies.set('token', data?.token, { expires: 30 });
    const decodedToken = jwtDecode(data?.token);
    if (!data.lastLogin) {
      navigate(`/${decodedToken.role}/profile/settings/reset-password`);
    }

    localStorage.setItem('profileImage', data.image);
    localStorage.setItem('instituteLogo', data?.instituteLogo || null);
    if (decodedToken.role === 'student') {
      localStorage.setItem('jobNotify', data.isNewJobAdded);
    } else {
      localStorage.setItem('notificationCount', data.notificationCount);
    }

    // onLogin();
  };



  return (
    <>
      {/* Navbar */}
      <AppBar position="sticky" className={styles.theNavbar}>
        <Toolbar className={styles.navbarflex}>
          <a href="/">
            <img src={navbarLogo} className={styles.navbarLogo} alt="logo pic" />
          </a>

          {/* Hamburger menu icon for small screens */}
          <IconButton
            onClick={toggleDrawer}
            className={styles.menuIcon}
          >
            <MenuIcon />
          </IconButton>

          {/* Desktop Menu (Visible only on large screens) */}
          <Box className={styles.navbarButtons}>
            <Button
              color="inherit"
              onClick={() => setLoginType('institute')}
              className={loginType === 'institute' ? styles.activeLink : ''}
            >
              Institute
            </Button>
            <Button
              color="inherit"
              onClick={() => setLoginType('recruiter')}
              className={loginType === 'recruiter' ? styles.activeLink : ''}
            >
              Recruitment
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer (Mobile Menu) */}
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Box
          className={styles.drawerContent}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Button
            color="inherit"
            onClick={() => setLoginType('institute')}
            className={loginType === 'institute' ? styles.activeLink : ''}
          >
            Institute
          </Button>
          <Button
            color="inherit"
            onClick={() => setLoginType('recruiter')}
            className={loginType === 'recruiter' ? styles.activeLink : ''}
          >
            Recruitment
          </Button>
        </Box>
      </Drawer>

      {/* Login Form */}
      <div className={styles.main}>
        <Box className={styles.leftContainer}>
          <img src={Logo} id={styles.logoLogin} alt='logo pic' />
          <img src={Img} id={styles.imageLogin} alt='login pic' />
        </Box>
        <div className={styles.rightContainer}>
          <h2>{loginType === 'institute' ? 'Login' : 'Recruiter Login'}</h2>
          <form onSubmit={handleSubmit} noValidate>
            <div className={styles.loginGroup}>
              <label>Email</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                name="email"
                onChange={handleChange}
              />
              {errors.email && <span className={styles.error}>{errors.email}</span>}
            </div>
            <div className={styles.loginGroup}>
              <label>Password</label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
              />
              {errors.password && <span className={styles.error}>{errors.password}</span>}
            </div>

            <Modal open={showInstituteSelector} onClose={() => setShowInstituteSelector(false)}>
              <Box className={styles.modalBackground}>
                <Box className={styles.instituteSelectorModal}>
                  <h2>Select Institute</h2>
                  <select
                    value={selectedInstitute}
                    onChange={(e) => handleInstituteSelection(e.target.value)}
                  >
                    <option value="">Select an Institute</option>
                    {institutes.map((institute) => (
                      <option key={institute.id} value={institute.id}>
                        {institute.name}
                      </option>
                    ))}
                  </select>
                  <Button onClick={() => handleInstituteSelection(selectedInstitute)}>Login</Button>
                </Box>
              </Box>
            </Modal>


            <div className={styles.loginGroup}>
              <Button variant="contained" type="submit" className={styles.btnLogin} disabled={!isFormValid}>Login</Button>
            </div>
            
            <Grid item xs id={styles.forgotPassword}>
              <Link href="#" className='link' onClick={handleOpenModal}>
                Forgot password?
              </Link>
            </Grid>

            <Modal open={openModal} onClose={handleCloseModal}>
              <Box className={styles.modalBackground}>
                <Box className={styles.forgotPassModal}>
                  <Box className={styles.closeIconContainer}>
                    <IconButton className={styles.closeIcon} onClick={handleCloseModal}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <h2>
                    Forgot Password
                  </h2>
                  <p>
                    Enter your email address to receive OTP.
                  </p>
                  <Box className={styles.loginGroup}>
                    <input
                      type="email"
                      id="modalEmail"
                      placeholder="Email"
                      name="email"
                      value={email}
                      onChange={handleModalEmailChange}
                      autoComplete="username"
                    />
                    {modalEmailError && <span className={styles.error}>{modalEmailError}</span>}
                  </Box>
                  <Box className={styles.loginGroup}>
                    <Button variant='contained' type="button" className={styles.btnReset} onClick={handleResetPassword} disabled={!isModalEmailValid}>
                      Reset Password
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </form>
        </div>
      </div>

      <div className={styles.footer}>
        <p>Copyright © {new Date().getFullYear()} HOSTEDMINDS. All rights reserved.</p>
      </div>
    </>
  );
};

export default LoginPage;
