import React, { useContext, useEffect, useState } from "react";
import styles from "./RecruiterList.module.css";
import { Box, Button, Checkbox, Grid, IconButton, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import TableComponent from "../../CommonTable/CommonTable";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "./../../../../config/config";
import Toast from "../../ToastContainer/ToastContainer";
import Dropdown from "../../DropDown/DropDown";

const getRecruiterList = process.env.REACT_APP_ADMIN_GET_RECRUITERLIST;
const getDepartmentDropdown =
    process.env.REACT_APP_ADMIN_GET_DEPARTMENT_DROPDOWN;
const updateRecruiter = process.env.REACT_APP_ADMIN_UPDATE_RECRUITER;
const deleteRecruiter = process.env.REACT_APP_ADMIN_DELETE_RECRUITER;
const recoverRecruiter = process.env.REACT_APP_ADMIN_RECOVER_RECRUITER

const RecruiterList = () => {
    const { getToken, setShowLoader, getUserRole, showConfirmation } =
        useContext(UserContext);
    const [formData, setFormData] = useState({
        salutation: "",
        fname: "",
        mname: "",
        lname: "",
        gender: "",
        primaryPhone: "",
        secondaryPhone: "",
        email: "",
        designation: "",
        companyId: "",
        defaultPassword: "",
        confirmPassword: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [recruiterData, setRecruiterData] = useState([]);
    const [filteredRecruiterData, setFilteredRecruiterData] = useState([]);
    const [changePassword, setChangePassword] = useState(false);
    const [companyNameDropdown, setCompanyNameDropdown] = useState([]);
    const [DropDownData, setDropDownData] = useState({
        genderList: [],
        departmentList: [],
        salutationList: [],
        companyList: [],
    });
    const [searchText, setSearchText] = useState("");

    const navigate = useNavigate();

    const columns = [
        "Recruiter Name",
        "Recruiter Designation",
        "Recruiter Phone",
        "Recruiter Email",
        "Company Name",
        "Company Website",
        "Company Phone",
        "Company Email",
    ];

    const handleChangeSearch = (e) => {
        setSearchText(() => e.target.value);
    };

    useEffect(() => {
        if (!searchText) {
            setFilteredRecruiterData(recruiterData);
            return;
        } else {
            const filteredData = recruiterData.filter((recruiter) =>
                `${recruiter?.fname}${recruiter?.mname}${recruiter?.lname}`
                    .toLowerCase()
                    .includes(String(searchText).toLowerCase())
            );
            setFilteredRecruiterData(filteredData);
        }
    }, [searchText])



    const getRecruitersData = async () => {
        try {
            setShowLoader(true);
            const response = await axios.get(`${BASE_URL}${getRecruiterList}`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });
            if (response.data.result) {
                const transformedRows = response?.data?.data?.map((recruiter) => ({
                    ...recruiter,
                    "Recruiter Name": `${recruiter.salutation || "N/A"} ${recruiter.fname || "N/A"} ${recruiter.mname || "N/A"} ${recruiter.lname || "N/A"}`,
                    "Recruiter Designation": recruiter.designation || "N/A",
                    "Recruiter Phone": recruiter.primaryPhone || "N/A",
                    "Recruiter Email": recruiter.email || "N/A",
                    "Company Name": recruiter.companyName || "N/A",
                    "Company Website": recruiter.companyWebsite || "N/A",
                    "Company Phone": recruiter.companyPhone || "N/A",
                    "Company Email": recruiter.companyEmail || "N/A",
                }));
                setRecruiterData(transformedRows);
                setFilteredRecruiterData(transformedRows)
            } else {
                Toast.error(response?.data?.message || "Something went wrong!");
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    useEffect(() => {
        getRecruitersData();
        fetchDropdown();
    }, []);

    const fetchDropdown = async () => {
        try {
            setShowLoader(true); // Optional, to show a loader during the API call
            const response = await axios.get(`${BASE_URL}${getDepartmentDropdown}`, {
                headers: { Authorization: `Bearer ${getToken()}` },
            });

            // Log the response for debugging
            if (response.data.result) {
                // Set department dropdown data from the response
                setDropDownData(response?.data?.data);
                setCompanyNameDropdown(response?.data?.data?.companyNameList || []);
            } else {
                Toast.error(response.data.message || "Something went wrong!");
            }
        } catch (error) {
            console.error('Error fetching department dropdown:', error);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleEdit = (recruiter) => {
        // Set the currentRow data and populate the formData state
        setCurrentRow({ ...recruiter });
        setFormData({
            salutation: recruiter.salutation || "",
            fname: recruiter.fname || "",
            mname: recruiter.mname || "",
            lname: recruiter.lname || "",
            gender: recruiter.gender || "",
            primaryPhone: recruiter.primaryPhone || "",
            secondaryPhone: recruiter.secondaryPhone || "",
            email: recruiter.email || "",
            designation: recruiter.designation || "",
            companyId: recruiter.companyId || "",
            recruiterId: recruiter.recruiterId || "",
            defaultPassword: recruiter.defaultPassword || "",
            confirmPassword: recruiter.confirmPassword || "",
        });
        setOpen(true); // Open the modal
    };

    const handleSave = async (e) => {
        e.preventDefault();
        // validateForm();

        try {
            setShowLoader(true);
            const response = await axios.put(
                `${BASE_URL}${updateRecruiter}`,
                formData,
                { headers: { Authorization: `Bearer ${getToken()}` } }
            );

            if (response.data.result) {
                Toast.success("Recruiter updated successfully.");
                setOpen(false);
                getRecruitersData();
            } else {
                Toast.error(response?.data?.message || "Failed to update recruiter.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleDelete = async (recruiterData) => {
        try {
            setShowLoader(true);
            const response = await axios.delete(
                `${BASE_URL}${deleteRecruiter}`,
                {
                    headers: { Authorization: `Bearer ${getToken()}` },
                    data: { recruiterId: recruiterData.recruiterId },
                }
            );

            if (response.data.result) {
                Toast.success("Recruiter deleted successfully.");
                getRecruitersData();
            } else {
                Toast.error(response?.data?.message || "Failed to delete recruiter.");
            }
        } catch (error) {
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        } finally {
            setShowLoader(false);
        }
    };

    const handleRecover = async (recruiterData) => {
        try {
            // const token = Cookies.get("token");
            setShowLoader(true);
            // return
            const data = { recruiterId: recruiterData.recruiterId };


            const response = await axios.patch(`${BASE_URL}${recoverRecruiter}`, data, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            if (response?.data?.result) {
                Toast.success(response?.data?.message || "Success!");
                getRecruitersData();
            }
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            Toast.error(error?.response?.data?.message || "Something went wrong!");
        }
    };

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setFormData({
            salutation: "",
            fname: "",
            mname: "",
            lname: "",
            gender: "",
            primaryPhone: "",
            secondaryPhone: "",
            email: "",
            designation: "",
            companyId: "",
            defaultPassword: "",
            confirmPassword: "",
        });
        setErrors({});
    };

    const handleCheckboxChange = (e) => {
        setChangePassword(e.target.checked);
    }

    const registerRecruiterClick = () => {
        navigate(`/${getUserRole()}/placement-cell/register-recruiter`);
    };

    const filterStudentsClick = () => {
        navigate(`/${getUserRole()}/placement-cell/filter-students`);
    };

    const viewJobsClick = () => {
        navigate(`/${getUserRole()}/job`);
    };


    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Placement Cell</h1>
            <Box className={styles.recruiterParentContainer}>
                <Box className={styles.inputParentContainer}>
                    <Box className={styles.addRecruiterButtonContainer}>
                        <Button
                            variant="contained"
                            className={styles.addRecruiterButton}
                            onClick={registerRecruiterClick}>
                            Register Recruiter
                        </Button>
                        <Button
                            variant="contained"
                            className={styles.addRecruiterButton}
                            onClick={filterStudentsClick}>
                            Filter Students
                        </Button>
                        <Button
                            variant="contained"
                            className={styles.addRecruiterButton}
                            onClick={viewJobsClick}>
                            View Jobs
                        </Button>
                    </Box>
                </Box>

                <Box className={styles.inputContainer}>
                    <label htmlFor="searchRecruiter" className={styles.searchLabel}>
                        Search Recruiters
                    </label>
                    <input
                        id="searchRecruiter"
                        className={styles.searchRecruiter}
                        onChange={handleChangeSearch}
                        variant="outlined"
                    />
                </Box>

                {filteredRecruiterData && filteredRecruiterData.length > 0 ? (
                    <TableComponent
                        className={styles.table}
                        columns={columns}
                        rows={filteredRecruiterData}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onRecover={handleRecover}

                    />
                ) : (
                    <h2 className={styles.noData}>
                        No recruiter data available. You can register a new recruiter.
                    </h2>
                )}

                <Modal className={styles.modal} open={open} onClose={handleClose}>
                    <Box className={styles.updateRecruiterContainer}>
                        <Box className={styles.closeIconContainer}>
                            <IconButton>
                                <CloseIcon
                                    className={styles.closeButton}
                                    onClick={handleClose}
                                />
                            </IconButton>
                        </Box>

                        <h2 className={styles.updateRecruiterHeader}>EDIT RECRUITER</h2>
                        <div className={styles.updateRecruiterScrollable}>
                            <form className={styles.formGroup}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="salutation">Salutation</label>
                                            <Dropdown name="salutation" value={formData.salutation} options={DropDownData.salutationList} onChange={handleChange} />
                                        </div>
                                        {errors.salutation && <p className={styles.error}>{errors.salutation}</p>}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="firstName">First Name</label>
                                            <input
                                                type="text"
                                                id="firstName"
                                                placeholder="First Name"
                                                value={formData.fname}
                                                name="fname"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.fname && <p className={styles.error}>{errors.fname}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="middleName">Middle Name</label>
                                            <input
                                                type="text"
                                                id="middleName"
                                                placeholder="Middle Name"
                                                value={formData.mname}
                                                name="mname"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.mname && <p className={styles.error}>{errors.mname}</p>}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="lastName"> Last Name</label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                placeholder="Last Name"
                                                value={formData.lname}
                                                name="lname"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.lname && (
                                                <p className={styles.error}>{errors.lname}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="gender">Gender</label>
                                            <Dropdown
                                                name="gender"
                                                value={formData.gender}
                                                options={DropDownData.genderList}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.gender && (
                                            <p className={styles.error}>{errors.gender}</p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="primaryPhone"> Primary Phone</label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                aria-controls="none"
                                                id="primaryPhone"
                                                placeholder="Primary Phone"
                                                value={formData.primaryPhone}
                                                name="primaryPhone"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.primaryPhone && (
                                                <p className={styles.error}>{errors.primaryPhone}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="secondaryPhone"> Secondary Phone</label>
                                            <input
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                aria-controls="none"
                                                id="secondaryPhone"
                                                placeholder="Secondary Phone"
                                                value={formData.secondaryPhone}
                                                name="secondaryPhone"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                            />
                                            {errors.secondaryPhone && (
                                                <p className={styles.error}>{errors.secondaryPhone}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="emailId"> Email</label>
                                            <input
                                                type="email"
                                                id="emailId"
                                                placeholder="Email Id"
                                                value={formData.email}
                                                name="email"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                                autoComplete="none"
                                            />
                                            {errors.email && (
                                                <p className={styles.error}>{errors.email}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="designation">Designation</label>
                                            <input
                                                type="text"
                                                id="designation"
                                                placeholder="Designation"
                                                value={formData?.designation}
                                                name="designation"
                                                onChange={handleChange}
                                                className={styles.inputbox}
                                                autoComplete="none"
                                            />
                                            {errors.designation && (
                                                <p className={styles.error}>{errors.designation}</p>
                                            )}
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.inputGroup}>
                                            <label htmlFor="companyId">Company Name</label>
                                            <Dropdown
                                                name="companyId"
                                                value={formData.companyId}
                                                options={DropDownData.companyList}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        {errors.companyId && (
                                            <p className={styles.error}>{errors.companyId}</p>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className={styles.checkboxGroup}>
                                            <label>
                                                <Checkbox
                                                    checked={changePassword}
                                                    onChange={handleCheckboxChange}
                                                    className={styles.passwordCheckbox}
                                                    color="primary"
                                                    size="small"
                                                />
                                                <span className={styles.changePass}>Change Password ?</span>
                                            </label>
                                        </div>
                                    </Grid>

                                    {changePassword && (
                                        <>
                                            <Grid item xs={12}>
                                                <div className={styles.inputGroup}>
                                                    <label htmlFor="defaultPassword">Default Password</label>
                                                    <input
                                                        type="password"
                                                        id="defaultPassword"
                                                        placeholder="Default Password"
                                                        value={formData.defaultPassword}
                                                        name="defaultPassword"
                                                        onChange={handleChange}
                                                        className={styles.inputbox}
                                                    />
                                                    {errors.defaultPassword && (
                                                        <p className={styles.error}>{errors.defaultPassword}</p>
                                                    )}
                                                </div>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <div className={styles.inputGroup}>
                                                    <label htmlFor="confirmPassword">Confirm Default Password</label>
                                                    <input
                                                        type="password"
                                                        id="confirmPassword"
                                                        placeholder="Confirm Default Password"
                                                        value={formData.confirmPassword}
                                                        name="confirmPassword"
                                                        onChange={handleChange}
                                                        className={styles.inputbox}
                                                    />
                                                    {errors.confirmPassword && (
                                                        <p className={styles.error}>{errors.confirmPassword}</p>
                                                    )}
                                                </div>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item xs={12} className={styles.submitButtonContainer}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className={styles.submitButton}
                                            onClick={handleSave}
                                        // disabled={isSaveDisabled}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>

                            </form>
                        </div>
                    </Box>
                </Modal>

            </Box >
        </div >
    );
};

export default RecruiterList;
