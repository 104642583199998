import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, IconButton, Box, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../CommonTable/CommonTable";
import DropDown from "../../DropDown/DropDown";
import CloseIcon from "@mui/icons-material/Close";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./CourseList.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { BASE_URL } from "./../../../../config/config";

const getDataCourseList = process.env.REACT_APP_GET_DATA_COURSE_LIST;
const updateCourse = process.env.REACT_APP_UPDATE_COURSE;
const getDropdownCourseRegister =
  process.env.REACT_APP_GET_DROPDOWN_DATA_COURSE_REG;
const deleteCourse = process.env.REACT_APP_DELETE_COURSE;
const recoverCourse = process.env.REACT_APP_RECOVER_COURSE;

function CourseList() {
  const { Cookies, getToken, setShowLoader, getUserRole, showConfirmation } =
    useContext(UserContext);
  const [currentRow, setCurrentRow] = useState({});
  const [courseData, setCourseData] = useState();
  const [filteredCourseData, setFilteredCourseData] = useState([]);
  const [dropDownData, setDropDownData] = useState("");
  const [courseType, setcourseType] = useState();
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [formData, setFormData] = useState({
    courseCode: "",
    courseName: "",
    courseCategory: "",
    startingYear: null,
    durationYear: "",
    semesters: "",
    courseType: "",
    intakeCapacity: "",
  });

  const navigate = useNavigate();

  const columns = [
    "Department Code",
    "Department Name",
    "Course Code",
    "Course Name",
    "Semester",
  ];

  useEffect(() => {
    if (open) {
      setFormData(currentRow);
    }
  }, [currentRow, open]);

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleAddCourses = () => {
    navigate(`/${getUserRole()}/courses/add-course`);
  };

  const handleEdit = (row) => {
    setCurrentRow(row);
    setOpen(true);
    FetchDataForaddCourse();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      const token = Cookies.get("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      currentRow.courseType = courseType;
      setShowLoader(true);

      const response = await axios.put(
        `${BASE_URL}${updateCourse}`,
        currentRow,
        { headers }
      );

      setShowLoader(false);

      if (response.data.result === true) {
        Toast.success(response.data.message || "Success.");
        setOpen(false);
        FetchDataForCourseList();
      } else {
        Toast.error(response.data.message || "Something went wrong!");
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentRow((prev) => ({ ...prev, [name]: value }));
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateForm();
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      // "departmentCode",
      "courseCode",
      "courseName",
      "courseCategory",
      "startingYear",
      "durationYear",
      "semesters",
      "courseType",
      "intakeCapacity",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.courseCode && formData.courseCode.length > 20) {
      validationErrors["courseCode"] =
        "**Course Code must be less than 20 characters**";
    }

    if (formData.courseName && formData.courseName.length > 50) {
      validationErrors["courseName"] =
        "**Course Name must be less than 50 characters**";
    }

    if (formData.intakeCapacity && formData.intakeCapacity <= 0) {
      validationErrors.intakeCapacity = "**Must be greater than 0**";
    }

    if (formData.semesters) {
      const semesters = Number(formData.semesters); // Convert to a number
      if (semesters <= 0 || semesters >= 16 || !Number.isInteger(semesters)) {
        validationErrors.semesters =
          "**Must be an integer greater than 0 and less than 16**";
      }
    }

    if (formData.startingYear) {
      const startingYear = dayjs(formData.startingYear);
      if (!startingYear.isValid()) {
        validationErrors.startingYear = "**Invalid date format**";
      } else if (startingYear.isAfter(dayjs(), "day")) {
        validationErrors.startingYear = "**Date cannot be in the future**";
      }
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  const handleIsDeleteChange = async (data, check) => {
    try {
      const newCourseList = courseData.map((courseData) => {
        if (
          data.departmentCode === courseData.departmentCode &&
          data.courseCode === courseData.courseCode
        ) {
          courseData.isActive = check;
        }
        return courseData;
      });

      setCourseData([...newCourseList]);
    } catch (error) {
      Toast.error("Something went wrong!");
    }
  };

  const handleCourseDelete = async (data) => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.delete(
        `${BASE_URL}${deleteCourse}/${data.departmentCode}/${data.courseCode}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setShowLoader(false);

      if (response.data.result) {
        handleIsDeleteChange(data, false);
        Toast.success(response.data.message || "Successfully deleted!");
      } else {
        Toast.error(response.data.message || "Failed to delete course!");
      }
    } catch (error) {
      setShowLoader(false);
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!";
      Toast.error(errorMessage);
    }
  };

  const handleCourseRecover = async (data) => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);

      const response = await axios.post(
        `${BASE_URL}${recoverCourse}/${data.departmentCode}/${data.courseCode}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.result) {
        setShowLoader(false);
        Toast.success(response?.data?.message || "Successfully Recovered !");

        handleIsDeleteChange(data, true);
      } else {
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleChangeSearch = (e) => {
    setSearchText(() => e.target.value);
  };


  useEffect(() => {
    const data = courseData?.filter((course) =>
      String(course?.courseName)
        .toLowerCase()
        .includes(String(searchText).toLowerCase())
    );
    setFilteredCourseData(() => data);
  }, [courseData, searchText]);

  const FetchDataForCourseList = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getDataCourseList}`, {
        headers,
      });
      const data = response?.data?.data?.courseList;

      const transformedRows = data?.map((course) => ({
        ...course,
        "Department Code": course.departmentCode || "N/A",
        "Department Name": course.departmentName || "N/A",
        "Course Code": course.courseCode || "N/A",
        "Course Name": course.courseName || "N/A",
        "Semester": course.semesters || "N/A",
      }));
      setShowLoader(false);

      setCourseData(transformedRows);
      setFilteredCourseData(transformedRows);
      return data;
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
      throw error;
    }
  };

  useEffect(() => {
    FetchDataForCourseList();
  }, []);


  const FetchDataForaddCourse = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };
      setShowLoader(true);

      const response = await axios.get(
        `${BASE_URL}${getDropdownCourseRegister}`,
        { headers }
      );
      const data = response?.data;
      setDropDownData(() => data?.data);
      setShowLoader(false);

      return data;
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message | "Something went wrong!");

      throw error;
    }
  };

  const handlecourseTypeSelect = (e) => {
    const selectedValue = e.target.value;
    setcourseType(selectedValue);
    setCurrentRow((prev) => ({ ...prev, courseType: selectedValue }));
    validateForm();
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Courses</h1>
      <Box className={styles.courseParentContainer}>
        <Box className={styles.inputParentContainer}>
          <Box className={styles.btnContainer}>
            <Button
              variant="contained"
              className={styles.addCourseButton}
              onClick={handleAddCourses}
            >
              Add Course
            </Button>
          </Box>
        </Box>
        <Box className={styles.inputContainer}>
          <label htmlFor="searchCourse" className={styles.searchLabel}>
            Search Course
          </label>
          <input
            id="searchCourse"
            className={styles.searchCourse}
            onChange={handleChangeSearch}
            variant="outlined"
          />
        </Box>

        {courseData && courseData.length > 0 ? (
          <TableComponent
            // className={styles.tableContainer}
            columns={columns}
            rows={filteredCourseData}
            onEdit={handleEdit}
            onDelete={handleCourseDelete}
            onRecover={handleCourseRecover}
          />
        ) : (
          <h2 className={styles.noData}>
            No Course available. You can add a new course.
          </h2>
        )}

        <Modal className={styles.modal} open={open} onClose={handleClose}>
          <Box className={styles.updateCourseContainer}>
            <Box className={styles.closeIconContainer}>
              <IconButton>
                <CloseIcon
                  className={styles.closeButton}
                  onClick={handleClose}
                />
              </IconButton>
            </Box>

            <h2 className={styles.updateCourseHeader}>EDIT COURSE</h2>
            <div className={styles.updateCourseScrollable}>
              <form className={styles.formGroup}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="courseCode">Course Code</label>
                      <input
                        type="text"
                        id="courseCode"
                        placeholder="courseCode"
                        name="courseCode"
                        value={currentRow?.courseCode || ""}
                        onChange={handleChange}
                        className={styles.disabled}
                        disabled
                      />
                      {errors.courseCode && (
                        <p className={styles.error}>{errors.courseCode}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="courseName">Course Name</label>
                      <input
                        type="text"
                        id="courseName"
                        placeholder="courseName"
                        name="courseName"
                        value={currentRow?.courseName || ""}
                        onChange={handleChange}
                      // className={styles.disabled}
                      // disabled
                      />
                      {errors.courseName && (
                        <p className={styles.error}>{errors.courseName}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="courseCategory">Course Category</label>
                      <DropDown
                        options={dropDownData.courseCategories}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          setCurrentRow((prev) => ({
                            ...prev,
                            courseCategory: selectedValue,
                          }));
                          validateForm();
                        }}
                        value={currentRow?.courseCategory || ""}
                        name="courseCategory"
                        disabled
                        sx={{
                          backgroundColor: "#f6f6f6",
                          color: "#a0a0a0",
                        }}
                      />
                      {errors.courseCategory && (
                        <p className={styles.error}>{errors.courseCategory}</p>
                      )}
                    </div>
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12} key="startingYear">
                      <div className={styles.courseInputGroup}>
                        <label htmlFor="startingYear">Start Date</label>
                        <DesktopDatePicker
                          views={["year", "month", "day"]}
                          className={styles.datePicker}
                          variant="filled"
                          value={
                            currentRow?.startingYear
                              ? dayjs(currentRow.startingYear)
                              : null
                          }
                          onChange={(date) => {
                            setCurrentRow({
                              ...currentRow,
                              startingYear: date,
                            });
                            setFormData((prev) => ({
                              ...prev,
                              startingYear: date,
                            }));
                            validateForm();
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                          disabled
                          sx={{
                            backgroundColor: "#f6f6f6",
                            color: "#a0a0a0",
                          }}
                        />
                        {errors.startingYear && (
                          <p className={styles.error}>{errors.startingYear}</p>
                        )}
                      </div>
                    </Grid>
                  </LocalizationProvider>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="durationYear">Course Duration</label>
                      <DropDown
                        options={dropDownData?.durationYear}
                        name="durationYear"
                        onChange={handleChange}
                        value={currentRow?.durationYear}
                        className={styles.disabled}
                        disabled
                        sx={{
                          backgroundColor: "#f6f6f6",
                          color: "#a0a0a0",
                          cursor: "not-allowed",
                        }}
                      />
                      {errors.durationYear && (
                        <p className={styles.error}>{errors.durationYear}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label className="semesters">
                        Semesters{" "}
                        <span className={styles.span}>(% in numbers) </span>
                      </label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="semesters"
                        placeholder="semesters"
                        name="semesters"
                        value={currentRow?.semesters || ""}
                        onChange={handleChange}
                        className={styles.disabled}
                        disabled
                      />
                      {errors.semesters && (
                        <p className={styles.error}>{errors.semesters}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="courseType">Course Type</label>
                      <DropDown
                        name="courseType"
                        options={dropDownData.courseType}
                        onChange={handlecourseTypeSelect}
                        value={currentRow?.courseType || ""}
                        className={styles.disabled}
                        disabled
                        sx={{
                          backgroundColor: "#f6f6f6",
                          color: "#a0a0a0",
                        }}
                      />
                      {errors.courseType && (
                        <p className={styles.error}>{errors.courseType}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className={styles.courseInputGroup}>
                      <label htmlFor="intakeCapacity">
                        Intake Capacity{" "}
                        <span className={styles.span}>(% in numbers) </span>
                      </label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        id="intakeCapacity"
                        placeholder="intakeCapacity"
                        name="intakeCapacity"
                        value={currentRow?.intakeCapacity || ""}
                        onChange={handleChange}
                      />
                      {errors.intakeCapacity && (
                        <p className={styles.error}>{errors.intakeCapacity}</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12} className={styles.submitButton}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        showConfirmation(
                          " Want to Update Course ?",
                          handleSave,
                          e
                        );
                      }}
                      disabled={isSaveDisabled}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
}

export default CourseList;
