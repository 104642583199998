import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Dropdown from "../../../CommonComponent/DropDown/DropDown";
import styles from "../HODAttendance/HodAttendance.module.css";
import axios from "axios";
import UserContext from "../../../../Context/UserContext";
import { Button, Box } from "@mui/material";
import PercentWise from "../../../CommonComponent/Attendance/PercentWise/PercentWise";
import DateWise from "../../../CommonComponent/Attendance/DateWise/DateWise";
import EditAttendance from "../../../CommonComponent/Attendance/EditAttendance/EditAttendance";
import Toast from "../../../CommonComponent/ToastContainer/ToastContainer";
import { BASE_URL } from "./../../../../config/config";
import BulkAttendance from "../../../CommonComponent/Attendance/BulkAttendance/BulkAttendance";

const getHodAttendance = process.env.REACT_APP_HOD_GET_ATTENDANCE;

const HODAttendance = () => {
  const { getToken, getUserRole, setShowLoader } = useContext(UserContext);
  const [dropDownData, setDropDownData] = useState({
    courseList: [],
    batchList: [],
    subjectList: [],
    departmentCode: "",
    departmentName: "",
  });

  const [selectedField, setsSelectedField] = useState({
    subjectCode: "",
    subjectType: "",
    practicalBatchId: "",
    practicalBatchName: "",
    subjectName: "",
    semester: "",
    courseCode: "",
    batchId: "",
    sectionId: "",
  });
  const [mySubjectDropdown, setMySubjectDropdown] = useState([]);
  const [batchDropdown, setBatchDropdown] = useState([]);
  const [sectionDropdown, setSectionDropdown] = useState([]);
  const [semesterDropdown, setSemesterDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [practicalBatchList, setPracticalBatchList] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState(false);

  const navigate = useNavigate();

  const fetchDropdown = async () => {
    try {
      setShowLoader(true);

      const response = await axios.get(`${BASE_URL}${getHodAttendance}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setDropDownData(response.data?.data);
      setMySubjectDropdown(response.data?.data?.subjectList);

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setsSelectedField({
      ...selectedField,
      [name]: value,
    });

    if (value) {
    }
  };

  useEffect(() => {
    const {
      courseCode,
      batchId,
      sectionId,
      semester,
      subjectCode,
      subjectType,
      practicalBatchId,
    } = selectedField;
    if (courseCode && batchId && sectionId && semester && subjectCode) {
      if (subjectType === "LAB" && !practicalBatchId) {
        setDropdownSelected(false);
      } else {
        setDropdownSelected(true);
      }
    }
  }, [selectedField]);

  useEffect(() => {
    fetchDropdown();
  }, []);

  useEffect(() => {
    if (!selectedField.courseCode) {
      setBatchDropdown([]);
      setSectionDropdown([]);
      setSemesterDropdown([]);
      setSubjectDropdown([]);
      setsSelectedField({
        ...selectedField,
        batchId: "",
        sectionId: "",
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
      return;
    }

    const filteredBatch = dropDownData?.batchList?.filter(
      (data) => data.value === selectedField.courseCode
    );
    setBatchDropdown(() => filteredBatch[0].batches || []);

    const filteredSemester = dropDownData?.courseList?.filter(
      (data) => data.value === selectedField.courseCode
    );
    setSemesterDropdown(() => filteredSemester[0].semesters || []);
    // semesters
  }, [selectedField.courseCode]);

  useEffect(() => {
    if (!selectedField.batchId) {
      setSectionDropdown([]);
      setSemesterDropdown([]);
      setSubjectDropdown([]);
      setsSelectedField({
        ...selectedField,
        sectionId: "",
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
      return;
    }
    const filteredSections = batchDropdown?.filter(
      (data) => data.value === selectedField.batchId
    );
    setSectionDropdown(() => filteredSections[0].sections || []);
  }, [selectedField.batchId]);

  useEffect(() => {
    if (!selectedField.semester) {
      setSubjectDropdown([]);
      setsSelectedField({
        ...selectedField,
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
      return;
    }
    const subjectData = semesterDropdown.filter(
      (data) => data.value === selectedField.semester
    );
    setSubjectDropdown(() => subjectData[0].subjectList || []);
  }, [selectedField.semester]);

  useEffect(() => {
    if (selectedField.sectionId) {
      const practicalBatch = dropDownData?.practicalBatchList?.filter(
        (data) => data.sectionId === selectedField.sectionId
      );
      if (practicalBatch.length !== 0) {
        setPracticalBatchList(() => practicalBatch[0]?.batchList || []);
      }
      setsSelectedField({
        ...selectedField,
        semester: "",
        subjectCode: "",
        practicalBatchId: "",
      });
    }
  }, [selectedField.sectionId]);
  useEffect(() => {
    if (!selectedField.subjectCode) {
      return;
    }

    const subjectName = subjectDropdown.filter(
      (data) => data.value === selectedField.subjectCode
    );

    if (subjectName.length > 0) {
    }
    setsSelectedField({
      ...selectedField,
      practicalBatchId: "",
      subjectType: subjectName[0]?.subjectType,
      subjectName: `${subjectName[0].label || "N/A"}`,
    });
  }, [selectedField.subjectCode]);

  useEffect(() => {
    if (!selectedField.practicalBatchId) {
      return;
    }
    const practicalBatch = practicalBatchList.filter(
      (data) => data.value === selectedField.practicalBatchId
    );
    if (practicalBatch.length > 0) {
      setsSelectedField({
        ...selectedField,
        practicalBatchName: practicalBatch[0].label,
      });
    }
  }, [selectedField.practicalBatchId]);


  const handleviewDateWise = () => {
    navigate(`/${getUserRole()}/attendance/datewise-attendance`);
  };

  const handleviewPercentWise = () => {
    navigate(`/${getUserRole()}/attendance/percentwise-attendance`);
  };

  const handleBulkAttendance = () => {
    navigate(`/${getUserRole()}/attendance/bulk-attendance`);
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.attendanceHeader}>Student's Attendance</h1>

      <Box className={styles.dropDownParentContainer}>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="courseName" className={styles.dropDownLabel}>
            Course Name
          </label>
          <Dropdown
            name="courseCode"
            options={dropDownData?.courseList}
            onChange={handleChange}
            value={selectedField.courseCode}
            className={styles.dropDownSelect}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="batch" className={styles.dropDownLabel}>
            Batch
          </label>
          <Dropdown
            name="batchId"
            options={batchDropdown}
            onChange={handleChange}
            value={selectedField.batchId}
            disabled={!selectedField.courseCode}
            className={styles.dropDownSelect}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="section" className={styles.dropDownLabel}>
            Section
          </label>
          <Dropdown
            name="sectionId"
            options={sectionDropdown}
            onChange={handleChange}
            value={selectedField.sectionId}
            disabled={!selectedField.batchId}
            className={styles.dropDownSelect}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="semester" className={styles.dropDownLabel}>
            Semester
          </label>
          <Dropdown
            name="semester"
            options={semesterDropdown}
            onChange={handleChange}
            value={selectedField.semester}
            disabled={!selectedField.sectionId}
            className={styles.dropDownSelect}
          />
        </Box>
        <Box className={styles.dropDownContainer}>
          <label htmlFor="subjectName" className={styles.dropDownLabel}>
            Subject Name
          </label>
          <Dropdown
            name="subjectCode"
            options={subjectDropdown}
            onChange={handleChange}
            value={selectedField.subjectCode}
            disabled={!selectedField.semester}
            className={styles.dropDownSelect}
          />
        </Box>
        {
          <Box className={styles.dropDownContainer}>
            <label htmlFor="practicalBatch" className={styles.dropDownLabel}>
              Practical Batch
            </label>
            <Dropdown
              name="practicalBatchId"
              options={practicalBatchList}
              onChange={handleChange}
              value={selectedField.practicalBatchId}
              disabled={selectedField.subjectType !== "LAB"}
              className={styles.dropDownSelect}
            />
          </Box>
        }
      </Box>

      <Box className={styles.buttonsContainer}>
        <Button
          className={styles.dateWiseButton}
          variant="contained"
          onClick={handleviewDateWise}
          disabled={!dropdownSelected}
        >
          View Datewise
        </Button>
        <Button
          className={styles.percentWiseButton}
          variant="contained"
          onClick={handleviewPercentWise}
          disabled={!dropdownSelected}
        >
          View Percentwise
        </Button>

        <Button
          className={styles.percentWiseButton}
          variant="contained"
          onClick={handleBulkAttendance}
          disabled={!dropdownSelected}
        >
          Bulk Attendance
        </Button>
      </Box>

      <div className={styles.tableContainer}>
        <Routes>
          <Route
            path="datewise-attendance"
            element={<DateWise selectedField={selectedField} />}
          />
          <Route
            path="percentwise-attendance"
            element={<PercentWise selectedField={selectedField} />}
          />
          <Route
            path="edit-attendance"
            element={<EditAttendance selectedField={selectedField} />}
          />
          <Route
            path="bulk-attendance"
            element={<BulkAttendance selectedField={selectedField} />}
          />
        </Routes>
      </div>

      {!dropdownSelected && (
        <h2 className={styles?.noData}>
          Please select options from the dropdowns to view attendance details.
        </h2>
      )}
    </div>
  );
};

export default HODAttendance;
