import React, { useContext } from "react";
import ViewBatches from "./ViewBatches/ViewBatches";
import AddBatch from "./AddBatch/AddBatch";
import { Navigate, Route, Routes } from "react-router-dom";
import EditBatch from "./EditBatch/EditBatch";
import CreatePracticalBatch from "./PracticalBatches/CreatePracticalBatch";
import AllocateStudents from "./AllocateStudents/AllocateStudents";
import ViewPracticalBatches from "./PracticalBatches/ViewPracticalBatches/ViewPracticalBatches";
import UserContext from "../../../Context/UserContext";

const BatchManager = (FetchDataForaddCourse) => {
  const { getUserRole } = useContext(UserContext);
  return (
    <>
      <Routes>
        <Route path="/" element={<ViewBatches />}></Route>
        {/* <Route
          path="/add-new-batch"
          element={
            getUserRole() === "hod" ? <AddBatch /> : <Navigate to={"/"} />
          }
        ></Route> */}

        <Route path="/add-new-batch" element={<AddBatch />}></Route>
        <Route path="/edit-batch/:batchId" element={<EditBatch />}></Route>
        <Route path="/batch-users" element={<AllocateStudents />}></Route>
        <Route
          path="/add-practical-batch"
          element={
            getUserRole() === "hod"  ?
            <CreatePracticalBatch
              FetchDataForaddCourse={FetchDataForaddCourse}
            /> :
            <Navigate to={"/"}/>
          }
        ></Route>
        <Route
          path="/view-practical-batches"
          element={<ViewPracticalBatches />}
        ></Route>
      </Routes>
    </>
  );
};

export default BatchManager;
