import { useState, useEffect, useContext } from "react";
import styles from "./ChatPrimaryBox.module.css";
import UserContext from "../../../../Context/UserContext";
import { Avatar, Box } from "@mui/material";
import axios from "axios";
import CreateGroup from "../Group/CreateGroup/CreateGroup";
import Draggable from "react-draggable";
// import Toast from "../../ToastContainer/ToastContainer";
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups";
import SearchIcon from "@mui/icons-material/Search";
import { FaCommentDots } from "react-icons/fa";
// import { BASE_URL } from "../../../../config/config";


// const updateUnreadMessage = process.env.REACT_APP_CHAT_UPDATE_UNREAD_MESSAGE;

const ChatPrimaryBox = ({
  handleUserSelect,
  isVisible,
  toggleVisibility,
  messageCount,
  fetchMessageCount,
}) => {
  const {
    users,
    fetchUsers,
    setUsers,
    // handleMessageCall,
    receivedMessageCount,
    getToken,
    // handleClickUser,
    creategroupSocketClient,
    socket,
    groupSocket,
    Cookies,
    createSocketClient,
    setShowLoader,
  } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [groupMessageCount, setgroupMessageCount] = useState(0);
  const [groupMsgCount, setgroupMsgCount] = useState();
  const [showUserList, setShowUserList] = useState(true);
  const [showGroupPopup, setShowGroupPopup] = useState(false);



  useEffect(() => {
  }, [messageCount]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = users.filter(
      (user) =>
        user.fname && user.fname.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleUserClick = async (user) => {
    createSocketClient();
    if (groupSocket) {
      groupSocket.disconnect();
    }
    handleUserSelect(user);
  };

  // const handleChatHeaderClick = () => {
  //   handleUserSelect(null);
  // };

  const onClose = () => {
    toggleVisibility();
  };

  // const handleUpdateUnreadMsg = async (email) => {
  //   try {
  //     const token = Cookies.get("token");
  //     setShowLoader(true);

  //     const response = await axios.post(
  //       `${BASE_URL}${updateUnreadMessage}`,
  //       { email },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response.status === 200) {
  //     } else {
  //       console.error(
  //         "Failed to update unread messages. Status:",
  //         response.status
  //       );
  //     }
  //     setShowLoader(false);
  //   } catch (error) {
  //     setShowLoader(false);
  //     Toast.error(error?.response?.data?.message || "Something went wrong!");

  //     console.error("An error occurred while updating unread messages:", error);
  //   }
  // };

  // const handlefetchUsers = () => {
  //   fetchUsers();
  // };

  // const handlemessageCountResponse = () => {
  //   fetchMessageCount();
  // };

  // Sorting filteredUsers based on total message count

  const sortedUsers = (filteredUsers ?? []).slice().sort((a, b) => {
    const messageCountA =
      messageCount.count?.find((item) => item.element === a.email)?.count || 0;
    const receivedMessageCountA = receivedMessageCount[a.email] || 0;
    const totalCountA = messageCountA + receivedMessageCountA;

    const messageCountB =
      messageCount.count?.find((item) => item.element === b.email)?.count || 0;
    const receivedMessageCountB = receivedMessageCount[b.email] || 0;
    const totalCountB = messageCountB + receivedMessageCountB;

    return totalCountB - totalCountA;
  });

  const toggleGroupPopup = () => {
    if (groupSocket) {
      groupSocket?.disconnect();
    }
    setShowGroupPopup(!showGroupPopup);
    setShowUserList(false);
  };

  const handleGroupNameClick = () => {
    if (groupSocket) {
      groupSocket?.disconnect();
    }
    creategroupSocketClient();
  };

  return (
    // <Draggable>
    <Box className={styles.messageContainer}>
      <Box className={styles.closeIconContainer}>
        <h2 className={styles.messageContainerTitle}>Messaging</h2>
        <Box className={styles.closeIconContainerIcons}>
          <GroupsIcon
            className={styles.groupButton}
            onClick={() => {
              toggleGroupPopup();
              handleGroupNameClick();
            }}
          />
          {groupMsgCount > 0 && (
            <span className={styles.messageCount}>{groupMsgCount}</span>
          )}
          <CloseIcon onClick={onClose} className={styles.closeButton} />
        </Box>
      </Box>

      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <SearchIcon className={styles.searchIcon} />
      </div>

      {isVisible && (
        <Box className={styles.chatUsersList}>
          {filteredUsers?.map((user) => {
            const messageCountValue =
              messageCount?.count?.find((item) => item?.element === user.email)
                ?.count || 0;
            const receivedMessageCountValue =
              receivedMessageCount[user?.email] || 0;
            const totalCount = messageCountValue + receivedMessageCountValue;

            return (
              <div
                key={user._id}
                className={styles.userListContainer}
                onClick={() => handleUserClick(user)}
              >
                <Avatar
                  style={{
                    backgroundColor: "#69c",
                    height: "20px",
                    width: "20px",
                    padding: "8px",
                    fontSize: "16px",
                  }}
                >
                  {user.fname[0]}
                  {user.lname[0]}
                </Avatar>
                {user.fname} {user.lname}
                <FaCommentDots style={{ color: "#69c", fontSize: "20px" }} />
              </div>
            );
          })}
        </Box>
      )}
      {showGroupPopup && (
        // <Draggable>
        <CreateGroup onClose={toggleGroupPopup} />
        // </Draggable>
      )}
    </Box>
    // </Draggable>
  );
};

export default ChatPrimaryBox;
