import React from "react";
// import FacultySidebar from "../FacultySidebar/FacultySidebar";
import Sidebar from "../../CommonComponent/Sidebar/Sidebar";
import RollCall from "../../CommonComponent/Attendance/RollCall/RollCall";
import { Route, Routes } from "react-router-dom";

function FacultyDesktop({ logout, user }) {
  return (
    <div>
      {/* <FacultySidebar logout={logout} user={user} /> */}
      <Sidebar logout={logout} user={user} />
      <>
        <Routes>
          <Route
            path="takeAttendance/:lectureId"
            element={<RollCall />}
          />
        </Routes>
      </>
    </div>
  );
}

export default FacultyDesktop;
