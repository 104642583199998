import React, { useContext, useEffect, useState } from "react";
import styles from "./HODViewTimetable.module.css";
import {
  Card,
  Typography,
  IconButton,
  Modal,
  Button,
  Box,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  Edit,
  Delete,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import RestoreIcon from "@mui/icons-material/Restore";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "axios";
import UserContext from "../../../../../Context/UserContext";
import DropDown from "../../../../CommonComponent/DropDown/DropDown";
import Toast from "../../../../CommonComponent/ToastContainer/ToastContainer";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { BASE_URL } from "./../../../../../config/config";

const editTimeTable = process.env.REACT_APP_TIMETABLE_EDIT;
const deleteTimeTable = process.env.REACT_APP_TIMETABLE_DELETE_LECTURE;
const registerTimeTable = process.env.REACT_APP_TIMETABLE_REGISTER
const recoverTimeTable = process.env.REACT_APP_TIMETABLE_RECOVER_LECTURE


dayjs.extend(utc);
dayjs.extend(timezone);

const HODViewTimetable = ({
  practicalBatchDropdown,
  timeTableData,
  fetchTimeTableData,
  courseCode,
  semester,
  batchId,
  sectionId,
  editTimetableDropdownData,
}) => {
  const { Cookies, setShowLoader, showConfirmation } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [currentLecture, setCurrentLecture] = useState({});
  const [timeSlotsList, setTimeSlotsList] = useState([]);
  const [timSlotDropdown, setTimeSlotsListDropdown] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    editTimetableDropdownData?.timeSlots?.sort((a, b) => {
      let timeA =
        new Date(a.startTime).getHours() * 60 +
        new Date(a.startTime).getMinutes(); // Extract hours and minutes
      let timeB =
        new Date(b.startTime).getHours() * 60 +
        new Date(b.startTime).getMinutes(); // Extract hours and minutes

      return timeA - timeB; // Sort ascending (for descending, use timeB - timeA)
    });

    const changedZone = editTimetableDropdownData?.timeSlots?.map((OBJ, index) => {
      OBJ.startTime = dayjs(OBJ?.startTime)
      OBJ.endTime = dayjs(OBJ?.endTime)
      OBJ.label = `${dayjs(OBJ?.startTime)
        .local()
        .format("h:mm A")} TO ${dayjs(OBJ?.endTime)
          .local()
          .format("h:mm A")} (${OBJ.slotType})`
      // OBJ.value = OBJ
      OBJ.value = index
      OBJ.slotType = OBJ.slotType

      if ((`${dayjs(currentLecture?.startTime).local()
        .format("h:mm A")}` === `${dayjs(OBJ?.startTime)
          .local()
          .format("h:mm A")}`) && (`${dayjs(currentLecture?.endTime).local()
            .format("h:mm A")}` === `${dayjs(OBJ?.endTime)
              .local()
              .format("h:mm A")}`)) {

        currentLecture.timeSlot = index
      }
      return OBJ
    })

    setTimeSlotsList(() => changedZone)
    if (!currentLecture.subjectType) {
      setTimeSlotsListDropdown(() => changedZone)
    }

  }, [editTimetableDropdownData, currentLecture]);

  const handleEditLecture = (lectureData, day) => {
    try {
      // const filteredTimeSlots = timeSlotsList?.filter((slot) => slot.slotType === lectureData.subjectType);
      setCurrentLecture({ ...lectureData });
      let indexOf;

      for (let index = 0; index < timeSlotsList.length; index++) {
        const Slot = timeSlotsList[index];
        if (dayjs(Slot?.startTime) === dayjs(lectureData?.startTime) && dayjs(Slot?.endTime) === dayjs(lectureData?.endTime)) {
          indexOf = Slot.value;
        }
      }

      lectureData.timeSlot = indexOf
      lectureData.day = day

      setIsEditing(true)
      setCurrentLecture({ ...lectureData });
      setOpen(true);
    } catch (error) {
    }
  };

  // Handling Adding new Lecture
  const handleAddNewLecture = async (day) => {
    setCurrentLecture({
      day: day,
      subjectCode: "",
      startTime: null,
      endTime: null,
      roomNo: "",
      facultyId: "",
      practicalBatchId: null
    });
    setOpen(true);
  };

  const handleClose = () => {
    setIsEditing(false);
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setCurrentLecture({
        day: null,
        subjectCode: "",
        startTime: null,
        endTime: null,
        roomNo: "",
        facultyId: "",
        practicalBatchId: null,
        timeSlot: 0
      })
      setIsEditing(false);
    }
  }, [open])

  const handleChange = (value, field) => {

    if (field === 'practicalBatchId') {
      setCurrentLecture({ ...currentLecture, [field]: value });
    }

    if (field === 'timeSlot') {

      const Slot = timeSlotsList[value];
      currentLecture.startTime = Slot?.startTime || currentLecture.startTime
      currentLecture.endTime = Slot?.endTime || currentLecture.endTime
      currentLecture.timeSlot = value
    }

    // OnSubject Code change Set Faculty To It
    if (field === 'subjectCode') {
      editTimetableDropdownData?.subjectList?.map((OBJ) => {
        if (value === OBJ.value) {
          if (OBJ?.facultyId) {
            currentLecture.facultyId = OBJ?.facultyId
          }

          currentLecture.subjectType = OBJ?.subjectType
        }
      })
    }

    // const newLecture = { ...currentLecture, [field]: value };
    setCurrentLecture({ ...currentLecture, [field]: value });
  };

  useEffect(() => {
    if (!currentLecture.subjectType) {
      return
    }
    const filteredTimeSlots = timeSlotsList?.filter((slot) => slot.slotType === currentLecture.subjectType);
    if (filteredTimeSlots.length > 0) {
      setTimeSlotsListDropdown([...filteredTimeSlots]);
    } else {
      setTimeSlotsListDropdown([...timeSlotsList]);
    }
    return
  }, [currentLecture.subjectType])

  const areTimeSlotsNotOverlapping = (startTime1ISO, endTime1ISO, startTime2ISO, endTime2ISO) => {
    const startTime1 = dayjs(startTime1ISO).format("HH:mm");
    const endTime1 = dayjs(endTime1ISO).format("HH:mm");
    const startTime2 = dayjs(startTime2ISO).format("HH:mm");
    const endTime2 = dayjs(endTime2ISO).format("HH:mm");

    // Compare only the time portion, ignoring the date
    return endTime1 <= startTime2 || startTime1 >= endTime2;
  };



  const isTimeSlotOverlapping = (lectureData) => {

    const filtered = timeTableData?.filter(data => data?.day === lectureData?.day)


    let overlapp = false
    if (filtered.length > 0) {
      const lecturesToCheck = filtered[0]?.lectures;
      const checked = lecturesToCheck.map((DATA) => {
        if (!areTimeSlotsNotOverlapping(lectureData.startTime, lectureData.endTime, DATA.startTime, DATA.endTime)) {
          overlapp = true
          return
        }
      })
    }
    return overlapp

  }

  const handleSaveNewLecture = async () => {
    try {
      currentLecture.courseCode = courseCode;
      currentLecture.semester = semester;
      currentLecture.batchId = batchId;
      currentLecture.sectionId = sectionId;
      currentLecture.startTime = dayjs(currentLecture?.startTime)?.toISOString();
      currentLecture.endTime = dayjs(currentLecture?.endTime)?.toISOString();
      
      if (currentLecture.subjectType !== 'LAB') {
        const ch = isTimeSlotOverlapping(currentLecture)
        if (ch) {
          window.alert("This Time Slot have Already Scheduled Lecture...Please Select Another Timeslot...!")
          return
        }
      }
      // }      
      setShowLoader(true)

      const response = await axios.post(
        `${BASE_URL}${registerTimeTable}`,
        currentLecture,
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      );
      setShowLoader(false);


      if (response.data.result) {
        fetchTimeTableData();
        Toast.success(response?.data?.message || "Success !")
        handleClose();
      } else {
        Toast.error(response?.data?.message || "Something went wrong!")
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!")

    }
  };

  const handleSaveEditedLecture = async () => {
    try {
      currentLecture.courseCode = courseCode;
      currentLecture.semester = semester;
      currentLecture.batchId = batchId;
      currentLecture.sectionId = sectionId;

      try {

        currentLecture.startTime = currentLecture?.startTime?.toISOString()
        currentLecture.endTime = currentLecture?.endTime?.toISOString()
      } catch (error) {

      }

      if (!isEditing) {
        const ch = isTimeSlotOverlapping(currentLecture)
        if (ch) {
          window.alert("This Time Slot have Already Scheduled Lecture...Plz Select Another Timeslot...!")
          setOpen(false);
          return
        }
      }
      setShowLoader(true)

      const response = await axios.put(
        `${BASE_URL}${editTimeTable}`,
        currentLecture,
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      );
      setShowLoader(false);


      if (response.data.result) {
        fetchTimeTableData();
        Toast.success(response?.data?.message || "Success !")
      } else {
        Toast.error(response?.data?.message || "Something went wrong!")
      }

      setOpen(false);
    } catch (error) {
      Toast.error(error?.response?.data?.message || "Something went wrong!")

      setShowLoader(false)
    }
  };

  const handleDeleteLecture = async (lectureData) => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);
      const response = await axios.delete(
        `${BASE_URL}${deleteTimeTable}/${lectureData.lectureId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShowLoader(false);
      if (response.data.result) {
        fetchTimeTableData();
        Toast.success(response?.data?.message || "Success !")

      } else {
        Toast.error(response?.data?.message || "Something went wrong!")
      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!")
    }
  };

  const handleRecoverLecture = async (lectureData, day) => {
    try {
      const token = Cookies.get("token");
      setShowLoader(true);
      const response = await axios.post(
        `${BASE_URL}${recoverTimeTable}/${lectureData.lectureId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setShowLoader(false);
      if (response.data.result) {
        fetchTimeTableData();
        Toast.success(response?.data?.message || "Lecture recovered successfully !")

      } else {
        Toast.error(response?.data?.message || "Something went wrong!")

      }
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!")
    }
  };

  return (
    <Box className={styles.scheduleContainer}>
      <div className={styles.timetable}>
        {timeTableData.length > 0 ? (
          <>
            <h2 className={styles.timetableHeader}>Week Timetable</h2>
            {timeTableData.map((day, dayIndex) => (
              <Box className={styles.lectureContainer} key={dayIndex}>
                <div className={styles.dayTitleContainer}>
                  <h2 className={styles.dayTitle}>
                    {day.day}
                  </h2>
                </div>
                <Grid container spacing={0.5} className={styles.cardContainer}>
                  {day.lectures.length > 0 ? (
                    day.lectures
                      .sort((a, b) => {
                        const timeA = dayjs(a.startTime).hour() + dayjs(a.startTime).minute() / 60; // Convert to decimal hours
                        const timeB = dayjs(b.startTime).hour() + dayjs(b.startTime).minute() / 60; // Convert to decimal hours
                        return timeA - timeB; // Sort ascending
                      })
                      .map((lecture, lectureIndex) => (
                        <Grid item key={lectureIndex} xs={9} sm={4} md={3.5} lg={2}>
                          <Card className={`${styles.timetableCard} ${lecture.isDeleted ? styles.deletedCard : ""}`}>
                            <Box className={styles.cardContent}>
                              <Typography variant="h6" className={styles.subjectName}>
                                {lecture.subjectName}
                              </Typography>
                              <Typography variant="body2" className={styles.time}>
                                {lecture.startTime ? dayjs(lecture.startTime).format("h:mm A") : "NA"} to{" "}
                                {lecture.endTime ? dayjs(lecture.endTime).format("h:mm A") : "NA"}
                              </Typography>
                              <Box className={styles.cardDetails}>
                                <Typography variant="body2">{lecture.facultyName}</Typography>
                                <Typography variant="body2">Room No: {lecture.roomNo}</Typography>
                                <Box className={styles.cardActions}>
                                  <Tooltip title="Edit Lecture" arrow>
                                    <IconButton className={styles.actionButtons} onClick={() => handleEditLecture(lecture, day.day)}>
                                      <Edit />
                                    </IconButton>
                                  </Tooltip>
                                  {lecture.isDeleted ? (
                                    <Tooltip title="Recover Lecture" arrow>
                                      <IconButton className={styles.actionButtons} onClick={() => handleRecoverLecture(lecture)}>
                                        <RestoreIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Delete Lecture" arrow>
                                      <IconButton className={styles.actionButtons} onClick={() => {
                                        showConfirmation("want to delete lecture ?", handleDeleteLecture, lecture);
                                      }}>
                                        <Delete />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      ))
                  ) : (
                    <Grid item xs={12}>
                      <h3 className={styles.noLectures}>
                        No lectures scheduled for this day.
                      </h3>
                    </Grid>
                  )}
                </Grid>

                <Box className={styles.addLectureButtonContainer}>
                  <Tooltip title="Add New Lecture" arrow>
                    <LibraryAddIcon
                      onClick={() => handleAddNewLecture(day.day)}
                      variant="contained"
                      color="primary"
                      className={styles.addLectureButton}
                    />
                  </Tooltip>
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <h2 className={styles.noData}>
            Please select options from the dropdowns to view timetable details.
          </h2>
        )}
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalBackground}>
          <Box className={styles.modal}>
            <Box className={styles.closeIconContainer}>
              <IconButton
                onClick={handleClose}
                className={styles.closeIcon}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <h2 className={styles.modalSubjectHeader}>
              {isEditing ? "EDIT LECTURE" : "ADD NEW LECTURE"}
            </h2>
            <div className={styles.editInputs}>
              <label htmlFor="subjectCode" className={styles.formLabel}>
                Subject Code
              </label>
              <DropDown
                options={editTimetableDropdownData.subjectList}
                onChange={(e) => handleChange(e.target.value, "subjectCode")}
                value={currentLecture.subjectCode}
              />
            </div>

            {currentLecture.subjectType === 'LAB' &&
              <div className={styles.editInputs}>
                <label htmlFor="practicalBatch" className={styles.formLabel}>
                  Practical Batch
                </label>
                <DropDown
                  options={practicalBatchDropdown}
                  onChange={(e) => handleChange(e.target.value, "practicalBatchId")}
                  value={currentLecture?.practicalBatchId}
                />
              </div>
            }
            <div className={styles.editInputs}>
              <label htmlFor="faculty" className={styles.formLabel}>
                Faculty
              </label>
              <DropDown
                options={editTimetableDropdownData.facultyList}
                onChange={(e) => handleChange(e.target.value, "facultyId")}
                value={currentLecture?.facultyId}
              />
            </div>

            <div className={styles.editInputs}>
              <label htmlFor="timeSlot" className={styles.formLabel}>
                Time Slot
              </label>
              <DropDown
                options={timSlotDropdown}
                onChange={(e) => handleChange(e.target.value, "timeSlot")}
                value={currentLecture?.timeSlot}
              />
            </div>

            <div className={styles.editInputs}>
              <label htmlFor="roomNo" className={styles.formLabel}>
                Room No</label>
              <DropDown
                options={editTimetableDropdownData.roomsList}
                onChange={(e) => handleChange(e.target.value, "roomNo")}
                value={currentLecture.roomNo}
              />
            </div>

            <Button
              onClick={(e) => {
                const handleSave = () => {
                  if (isEditing) {
                    handleSaveEditedLecture();
                  } else {
                    handleSaveNewLecture();
                  }
                };

                showConfirmation("Do you want to save this lecture?", handleSave, e);
              }}
              variant="contained"
              color="primary"
              sx={{ marginTop: "15px" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box >
  );
};

export default HODViewTimetable;