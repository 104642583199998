import { useContext, useEffect, useState } from "react";
import styles from "./ViewUserProfile.module.css";
import {
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, Stack } from "@mui/material";
import { Routes, Route, Link, useLocation, useParams } from "react-router-dom";
import UserContext from "../../../Context/UserContext";
import VUPPhotoUpload from "./VUPPhotoUpload/VUPPhotoUpload";
import VUPBasicDetails from "./VUPProfilePages/VUPBasicDetails/VUPBasicDetails";
import VUPEducationalDetails from "./VUPProfilePages/VUPEducationalDetails/VUPEducationalDetails";
import VUPSkills from "./VUPProfilePages/VUPSkills/VUPSkills";
import VUPWorkExperience from "./VUPProfilePages/VUPWorkExperience/VUPWorkExperience";
import axios from "axios";
import { BASE_URL } from "./../../../config/config";
import VUPCertifications from "./VUPProfilePages/VUPCertifications/VUPCertifications";
import VUPProjects from "./VUPProfilePages/VUPProjects/VUPProjects";
import VUPSocialMedia from "./VUPProfilePages/VUPSocialMedia/VUPSocialMedia";
import VUPResume from "./VUPProfilePages/VUPResume/VUPResume";

const viewUserBasicData = process.env.REACT_APP_VUP_USER_BASIC_DATA;

function ViewUserProfile() {
  const { getUserRole, getToken } = useContext(UserContext);
  const [isEditable, setIsEditable] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const { enrollmentId } = useParams();

  const checkISEditable = () => {
    const role = getUserRole();

    if (role === selectedUserData.role || role === "student") {
      setIsEditable(false);
      return;
    }

    if (
      role === "hod" &&
      (selectedUserData.role === "collegeadmin" ||
        selectedUserData.role === "instituteadmin" ||
        selectedUserData.role === "superadmin")
    ) {
      setIsEditable(false);
      return;
    }
    if (
      role === "faculty" &&
      (selectedUserData.role === "hod" ||
        selectedUserData.role === "collegeadmin" ||
        selectedUserData.role === "instituteadmin")
    ) {
      setIsEditable(false);
      return;
    }

    setIsEditable(true);
  };

  const fetchUserBasicData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}${viewUserBasicData}/${enrollmentId}`,
        { headers: { Authorization: `Bearer ${getToken()}` } }
      );
      let userData = response.data.data;

      if (userData.image) {
        localStorage.setItem("viewUserImage", userData.image);
      }
      setSelectedUserData(() => response.data.data);
    } catch (error) { }
  };
  useEffect(() => {
    const data = location.state;
    setUserData(() => data);
    setSelectedUserData(() => data);
    fetchUserBasicData();
    return () => {
      localStorage.removeItem("viewUserImage");
    };
  }, []);

  useEffect(() => {
    if (selectedUserData) {
      checkISEditable();
    }
  }, [selectedUserData]);

  const UserImag = selectedUserData?.image;
  const currentUser = getUserRole();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Card className={styles.leftSideContainer}>
        <Card className={styles.photoContainer}>
          <Stack direction={"column"}>
            <VUPPhotoUpload
              isEditable={isEditable}
              UserImag={UserImag}
              userData={selectedUserData}
            />
            <Typography
              className={styles.nameCardHeader}
            >{`${selectedUserData?.fname} ${selectedUserData?.mname} ${selectedUserData?.lname}`}</Typography>
          </Stack>
        </Card>

        <div className={styles.leftSideContainerNavbar}>
          <div className={styles.navbarOption}>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/community/view-user/${enrollmentId}`}
                className={styles.link}
              >
                <span className={styles.optionText}>Basic Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/community/view-user/${enrollmentId}/education`}
                className={styles.link}
              >
                <span className={styles.optionText}>Educational Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/community/view-user/${enrollmentId}/workexperience`}
                className={styles.link}
              >
                <span className={styles.optionText}>Experiential Details</span>
              </Link>
            </div>
            <div className={styles.optionContainer}>
              <Link
                to={`/${currentUser}/community/view-user/${enrollmentId}/skills`}
                className={styles.link}
              >
                <span className={styles.optionText}>
                  Skills, Languages & Hobbies
                </span>
              </Link>
            </div>

            {getUserRole() !== "student" && (
              <>
                <div className={styles.optionContainer}>
                  <Link
                    to={`/${currentUser}/community/view-user/${enrollmentId}/certifications-achievements`}
                    className={styles.link}
                  >
                    <span className={styles.optionText}>
                      Certifications & Achievements
                    </span>
                  </Link>
                </div>

                <div className={styles.optionContainer}>
                  <Link
                    to={`/${currentUser}/community/view-user/${enrollmentId}/projects`}
                    className={styles.link}
                  >
                    <span className={styles.optionText}>
                      Projects
                    </span>
                  </Link>
                </div>

                <div className={styles.optionContainer}>
                  <Link
                    to={`/${currentUser}/community/view-user/${enrollmentId}/social-media`}
                    className={styles.link}
                  >
                    <span className={styles.optionText}>
                      Social Media
                    </span>
                  </Link>
                </div>

                <div className={styles.optionContainer}>
                  <Link
                    to={`/${currentUser}/community/view-user/${enrollmentId}/resume`}
                    className={styles.link}
                  >
                    <span className={styles.optionText}>
                      Resume
                    </span>
                  </Link>
                </div>
              </>
            )}

          </div>
        </div>
      </Card>

      {/* Responsive burger icon for opening Navbar options for profile */}
      <IconButton onClick={handleDrawerToggle} aria-label="menu">
        <MenuIcon className={styles.burgerIcon} />
      </IconButton>

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        className={styles.drawer}
      >
        <List>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${currentUser}/community/view-user/${enrollmentId}`}
          >
            <ListItemText
              primary="Basic Details"
              className={styles.drawerListItems}
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${currentUser}/community/view-user/${enrollmentId}/education`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Educational Details"
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${currentUser}/community/view-user/${enrollmentId}/workexperience`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Experiential Details"
            />
          </ListItem>
          <ListItem
            className={styles.drawerListItems}
            component={Link}
            to={`/${currentUser}/community/view-user/${enrollmentId}/skills`}
          >
            <ListItemText
              className={styles.drawerListItems}
              primary="Skills, Languages & Hobbies"
            />
          </ListItem>

          {getUserRole() !== "student" && (
            <>
              <ListItem
                className={styles.drawerListItems}
                component={Link}
                to={`/${currentUser}/community/view-user/${enrollmentId}/certifications-achievements`}
              >
                <ListItemText
                  className={styles.drawerListItems}
                  primary="Certifications & Achievements"
                />
              </ListItem>
              <ListItem
                className={styles.drawerListItems}
                component={Link}
                to={`/${currentUser}/community/view-user/${enrollmentId}/projects`}
              >
                <ListItemText
                  className={styles.drawerListItems}
                  primary="Projects"
                />
              </ListItem>
              <ListItem
                className={styles.drawerListItems}
                component={Link}
                to={`/${currentUser}/community/view-user/${enrollmentId}/social-media`}
              >
                <ListItemText
                  className={styles.drawerListItems}
                  primary="Social Media"
                />
              </ListItem>
              <ListItem
                className={styles.drawerListItems}
                component={Link}
                to={`/${currentUser}/community/view-user/${enrollmentId}/resume`}
              >
                <ListItemText
                  className={styles.drawerListItems}
                  primary="Resume"
                />
              </ListItem>
            </>
          )}

        </List>
      </Drawer>

      <div className={styles.rightSideContainer}>
        <Routes>
          <Route
            path="/"
            element={
              <VUPBasicDetails
                currentUser={currentUser}
                userData={selectedUserData}
                isEditable={isEditable}
              />
            }
          ></Route>
          <Route
            path="/basicDetails"
            element={
              <VUPBasicDetails
                isEditable={isEditable}
                userData={selectedUserData}
              />
            }
          />
          <Route
            path="/education"
            element={
              <VUPEducationalDetails
                isEditable={isEditable}
                userData={selectedUserData}
              />
            }
          ></Route>
          <Route
            path="/workexperience"
            element={
              <VUPWorkExperience
                isEditable={isEditable}
                userData={selectedUserData}
              />
            }
          ></Route>
          <Route
            path="/skills"
            element={
              <VUPSkills isEditable={isEditable} userData={selectedUserData} />
            }
          ></Route>

          <Route
            path="/certifications-achievements"
            element={
              <VUPCertifications isEditable={isEditable} userData={selectedUserData} />
            }
          ></Route>

          <Route
            path="/projects"
            element={
              <VUPProjects isEditable={isEditable} userData={selectedUserData} />
            }
          ></Route>

          <Route
            path="/social-media"
            element={
              <VUPSocialMedia isEditable={isEditable} userData={selectedUserData} />
            }
          ></Route>

          <Route
            path="/resume"
            element={
              <VUPResume isEditable={isEditable} userData={selectedUserData} />
            }
          ></Route>
        </Routes>
      </div>
    </>
  );
}

export default ViewUserProfile;
