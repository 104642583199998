import React, { useState, useEffect } from "react";
import { Button, IconButton, Grid, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./VUPEditSocialMedia.module.css";


const VUPEditSocialMedia = ({ socialMediaData, handleAddSocialMedia, handleCloseModal }) => {
    const [name, setName] = useState("");
    const [link, setLink] = useState("");
    const [error, setError] = useState({});
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);

    useEffect(() => {
        if (socialMediaData) {
            setName(socialMediaData.name || "");
            setLink(socialMediaData.link || "");
        }
    }, [socialMediaData]);

    useEffect(() => {
        validateForm();
    }, [name, link]);


    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleLinkChange = (e) => {
        setLink(e.target.value);
    };

    const validateForm = () => {
        let validationErrors = {};
        let isValid = true;

        if (!name) {
            validationErrors.name = "**This field is required**";
            isValid = false;
        } else if (name.length > 60) {
            validationErrors.name = "**Description must be at most 60 characters**";
            isValid = false;
        }

        if (!link || link.trim() === "") {
            validationErrors.link = "**Link is required**";
            isValid = false;
        } else if (!isValidUrl(link)) {
            validationErrors.link = "**Please enter a valid URL**";
            isValid = false;
        }

        setError(validationErrors);
        setIsSaveDisabled(!isValid);
    };

    const isValidUrl = (url) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(url);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateForm();

        if (isSaveDisabled) {
            return;
        }

        const formattedCertification = {
            name: name || "",
            link: link || "",
        };

        handleAddSocialMedia(formattedCertification);
    };

    return (
        <div className={styles.editSocialMediaContainer}>
            <Box className={styles.closeIconContainer}>
                <IconButton onClick={handleCloseModal}>
                    <CloseIcon className={styles.deleteSocialMedia} />
                </IconButton>
            </Box>
            <h2 className={styles.editSocialMediaHeader}>Add Social Media</h2>
            <form onSubmit={handleSubmit} className={styles.editeditSocialMediaScrollable}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="name">Name</label>
                            <input
                                id="name"
                                type="text"
                                value={name}
                                onChange={handleNameChange}
                            // maxLength="60"
                            />
                            {error.name && <p className={styles.error}>{error.name}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={styles.editInputs}>
                            <label htmlFor="link">Link</label>
                            <input
                                id="link"
                                type="text"
                                value={link}
                                onChange={handleLinkChange}
                            // maxLength="200"
                            />
                            {error.link && <p className={styles.error}>{error.link}</p>}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={styles.submitButton}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSaveDisabled}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default VUPEditSocialMedia;
