import React, { useState, useContext, useEffect } from "react";
import { Box, Grid, IconButton, TextField, Button } from "@mui/material";
import DropDown from "../../DropDown/DropDown";
import UserContext from "../../../../Context/UserContext";
import axios from "axios";
import Toast from "../../ToastContainer/ToastContainer";
import styles from "./AddCourses.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { BASE_URL } from "./../../../../config/config";
import Dropdown from "../../DropDown/DropDown";

const addCourse = process.env.REACT_APP_ADD_COURSE;
const getDropdownCourseRegister = process.env.REACT_APP_GET_DROPDOWN_DATA_COURSE_REG;

function AddCourses() {
  const { getToken, setShowLoader, getUserRole, showConfirmation } = useContext(UserContext);
  const [DropDownData, setDropDownData] = useState({});
  const [department, setDepartment] = useState("");
  const [courseCategory, setCourseCategory] = useState("");
  const [courseType, setCourseType] = useState("");
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [formData, setFormData] = useState({
    departmentCode: "",
    courseCode: "",
    courseName: "",
    courseCategory: "",
    startingYear: "",
    durationYear: "",
    semesters: "",
    courseType: "",
    intakeCapacity: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({
      ...prev,
      startingYear: date ? date.format("YYYY-MM-DD") : "",
    }));
  };

  const handleDepartmentSelect = (e) => {
    setDepartment(e.target.value);
    setFormData((prev) => ({
      ...prev,
      departmentCode: e.target.value,
    }));
  };

  const handleCourseCategorySelect = (e) => {
    setCourseCategory(e.target.value);
    setFormData((prev) => ({
      ...prev,
      courseCategory: e.target.value,
    }));
  };

  const handleCourseTypeSelect = (e) => {
    setCourseType(e.target.value);
    setFormData((prev) => ({
      ...prev,
      courseType: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    if (isSaveDisabled) return;

    try {
      const headers = {
        Authorization: `Bearer ${getToken()}`,
      };

      // Including necessary data before submission
      formData.departmentCode = department;
      formData.courseCategory = courseCategory;
      formData.courseType = courseType;
      formData.instituteCode = DropDownData.instituteCode;
      setShowLoader(true);

      const response = await axios.post(`${BASE_URL}${addCourse}`, formData, { headers });

      if (response.data.result) {
        Toast.success(response.data.message || "Success!");

        // Resetting form data after a successful submission
        setFormData({
          departmentCode: "",
          courseCode: "",
          courseName: "",
          courseCategory: "",
          startingYear: "",
          durationYear: "",
          semesters: "",
          courseType: "",
          intakeCapacity: "",
        });
        navigate(-1);
        setDepartment("");
        setCourseCategory("");
        setCourseType("");
        setErrors({});
      }

      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      Toast.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  const validateForm = () => {
    let validationErrors = {};
    const requiredFields = [
      // "departmentCode",
      "courseCode",
      "courseName",
      "courseCategory",
      "startingYear",
      "durationYear",
      "semesters",
      "courseType",
      "intakeCapacity",
    ];

    requiredFields.forEach((field) => {
      const value = formData[field];
      // Check if value is a string before using .trim()
      if (typeof value !== "number" && (value == null || String(value).trim() === "")) {
        validationErrors[field] = "**This field is required**";
      } else if (typeof value === "string" && value.trim() === "") {
        validationErrors[field] = "**This field is required**";
      }
    });

    if (formData.courseCode && formData.courseCode.length > 20) {
      validationErrors["courseCode"] = "**Course Code must be less than 20 characters**";
    }

    if (formData.courseName && formData.courseName.length > 50) {
      validationErrors["courseName"] = "**Course Name must be less than 50 characters**";
    }

    if (formData.intakeCapacity && formData.intakeCapacity <= 0) {
      validationErrors.intakeCapacity = "**Must be greater than 0**";
    }

    if (formData.semesters) {
      const semesters = Number(formData.semesters); // Convert to a number
      if (semesters <= 0 || semesters >= 16 || !Number.isInteger(semesters)) {
        validationErrors.semesters = "**Must be an integer greater than 0 and less than 16**";
      }
    }

    if (formData.startingYear) {
      const startingYear = dayjs(formData.startingYear);
      if (!startingYear.isValid()) {
        validationErrors.startingYear = "**Invalid date format**";
      } else if (startingYear.isAfter(dayjs(), "day")) {
        validationErrors.startingYear = "**Date cannot be in the future**";
      }
    }

    setErrors(validationErrors);
    setIsSaveDisabled(Object.keys(validationErrors).length > 0);
  };

  useEffect(() => {
    const fetchDataForAddCourse = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${getToken()}`,
        };
        setShowLoader(true);

        const response = await axios.get(`${BASE_URL}${getDropdownCourseRegister}`, { headers });
        setDropDownData(response?.data?.data || {});
        setShowLoader(false);
      } catch (error) {
        setShowLoader(false);
        Toast.error(error?.response?.data?.message || "Something went wrong!");
      }
    };
    fetchDataForAddCourse();
  }, []);

  return (
    <Box className={styles.formContainer}>
      <Box className={styles.closeIconContainer}>
        <IconButton onClick={() => navigate(-1)} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <h2 className={styles.formContainerTitle}>REGISTER COURSE</h2>

      <div className={styles.addCourseScrollable}>
        <form onSubmit={(e) => {
          e.preventDefault();
          showConfirmation("Want to register new Course?", handleSubmit, e);
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {getUserRole() !== "hod" && (
                <div className={styles.courseInputGroup}>
                  <label htmlFor="departmentCode">Department</label>
                  <DropDown
                    options={DropDownData.departmentList}
                    onChange={handleDepartmentSelect}
                    value={department}
                  />
                  {errors.departmentCode && <p className={styles.error}>{errors.departmentCode}</p>}
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="courseCode">Course Code</label>
                <input
                  type="text"
                  id="courseCode"
                  placeholder="Course Code"
                  name="courseCode"
                  value={formData.courseCode}
                  onChange={handleChange}
                />
                {errors.courseCode && <p className={styles.error}>{errors.courseCode}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="courseName">Course Name</label>
                <input
                  type="text"
                  id="courseName"
                  placeholder="Course Name"
                  name="courseName"
                  value={formData.courseName}
                  onChange={handleChange}
                />
                {errors.courseName && <p className={styles.error}>{errors.courseName}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="courseCategory">Course Category</label>
                <DropDown
                  options={DropDownData.courseCategories}
                  onChange={handleCourseCategorySelect}
                  value={courseCategory}
                />
                {errors.courseCategory && <p className={styles.error}>{errors.courseCategory}</p>}
              </div>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <div className={styles.courseInputGroup}>
                  <label htmlFor="startingYear">Start Date</label>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    className={styles.datePicker}
                    value={formData.startingYear ? dayjs(formData.startingYear) : null}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                  {errors.startingYear && <p className={styles.error}>{errors.startingYear}</p>}
                </div>
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="durationYear">Duration </label>
                <Dropdown
                  options={DropDownData.durationYear}
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="durationYear"
                  placeholder="Duration in Years"
                  name="durationYear"
                  value={formData.durationYear}
                  onChange={handleChange}
                />
                {errors.durationYear && <p className={styles.error}>{errors.durationYear}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="semesters">Semesters <span className={styles.span}>(in numbers) </span></label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="semesters"
                  placeholder="Semesters"
                  name="semesters"
                  value={formData.semesters}
                  onChange={handleChange}
                />
                {errors.semesters && <p className={styles.error}>{errors.semesters}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="courseType">Course Type</label>
                <DropDown
                  options={DropDownData.courseType}
                  onChange={handleCourseTypeSelect}
                  value={courseType}
                />
                {errors.courseType && <p className={styles.error}>{errors.courseType}</p>}
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={styles.courseInputGroup}>
                <label htmlFor="intakeCapacity">Intake Capacity <span className={styles.span}>(in numbers) </span></label>
                <input
                  type="number" 
                  onWheel={(e) => e.target.blur()}
                  id="intakeCapacity"
                  placeholder="Intake Capacity"
                  name="intakeCapacity"
                  value={formData.intakeCapacity}
                  onChange={handleChange}
                />
                {errors.intakeCapacity && <p className={styles.error}>{errors.intakeCapacity}</p>}
              </div>
            </Grid>

            <Grid item xs={12} className={styles.saveButtonContainer}>
              <Button
                type="submit"
                variant="contained"
                className={styles.saveButton}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Box>
  );
}

export default AddCourses;
